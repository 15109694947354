@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&display=swap");
@import url(~video.js/dist/video-js.min.css);
@font-face {
  font-family: barlow-black;
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/barlow-black.eot"), url("../fonts/barlow-black.eot?#iefix") format("embedded-opentype"), url("../fonts/barlow-black.woff2") format("woff2"), url("../fonts/barlow-black.woff") format("woff"), url("../fonts/barlow-black.ttf") format("truetype"), url("../fonts/barlow-black.svg#barlow-black") format("svg"); }

@font-face {
  font-family: barlow-bold;
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/barlow-bold.eot"), url("../fonts/barlow-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/barlow-bold.woff2") format("woff2"), url("../fonts/barlow-bold.woff") format("woff"), url("../fonts/barlow-bold.ttf") format("truetype"), url("../fonts/barlow-bold.svg#barlow-bold") format("svg"); }

@font-face {
  font-family: nexa_light;
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/nexa_light.eot"), url("../fonts/nexa_light.eot?#iefix") format("embedded-opentype"), url("../fonts/nexa_light.woff2") format("woff2"), url("../fonts/nexa_light.woff") format("woff"), url("../fonts/nexa_light.ttf") format("truetype"), url("../fonts/nexa_light.svg#nexa_light") format("svg"); }

@font-face {
  font-family: nexa_bold;
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/nexa_bold.eot"), url("../fonts/nexa_bold.eot?#iefix") format("embedded-opentype"), url("../fonts/nexa_bold.woff2") format("woff2"), url("../fonts/nexa_bold.woff") format("woff"), url("../fonts/nexa_bold.ttf") format("truetype"), url("../fonts/nexa_bold.svg#nexa_bold") format("svg"); }

@font-face {
  font-family: 'Univers';
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/Linotype-UniversLTStd-Light.otf"), url("../fonts/Linotype-UniversLTStd-Light.otf?#iefix") format("opentype"); }

@font-face {
  font-family: 'Univers';
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/Linotype-UniversLTStd-Light.otf"), url("../fonts/Linotype-UniversLTStd-Light.otf?#iefix") format("opentype"); }

@font-face {
  font-family: 'Univers';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Linotype-UniversLTStd-Light.otf"), url("../fonts/Linotype-UniversLTStd-Light.otf?#iefix") format("opentype"); }

@font-face {
  font-family: 'Univers';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Linotype-UniversLTStd.otf"), url("../fonts/Linotype-UniversLTStd.otf?#iefix") format("opentype"); }

@font-face {
  font-family: 'Univers';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Linotype-UniversLTStd.otf"), url("../fonts/Linotype-UniversLTStd.otf?#iefix") format("opentype"); }

@font-face {
  font-family: 'Univers';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Linotype-UniversLTStd-Bold.otf"), url("../fonts/Linotype-UniversLTStd-Bold.otf?#iefix") format("opentype"); }

@font-face {
  font-family: 'Univers';
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/Linotype-UniversLTStd-Bold.otf"), url("../fonts/Linotype-UniversLTStd-Bold.otf?#iefix") format("opentype"); }

@font-face {
  font-family: 'Univers';
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/Linotype-UniversLTStd-Black.otf"), url("../fonts/Linotype-UniversLTStd-Black.otf?#iefix") format("opentype"); }

/*
* ICON SETUP INSTRUCTIONS
* 1) To edit Icomoon fonts, import the /fonts/icomoon/selection.json file at this URL: https://icomoon.io/app/#/select
* 2) After making changes and generating the font, replace everything in /fonts/icomoon with the downloaded files and folders
* 3) Copy the font files from /fonts/icomoon/fonts to /static/fonts/
* 4) Copy over the contents of the style.scss and variables.scss files from /fonts/icomoon to this file per instructions below
* 5) Check the Icons section of the Style Guide to make sure any new/updated icons are rendering properly. They should get automatically updated there based on changes from /fonts/icomoon/selection.json
*/
@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?d4dkv5");
  src: url("../fonts/icomoon.eot?d4dkv5#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?d4dkv5") format("truetype"), url("../fonts/icomoon.woff?d4dkv5") format("woff"), url("../fonts/icomoon.svg?d4dkv5#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"]:not(.btn),
[class*=" icon-"]:not(.btn) {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/*
* Copy line 4 down from fonts/icomoon/variables.scss
*/
/*
* Copy line 30 down from from fonts/icomoon/style.scss
*/
.icon-menu:before {
  content: ""; }

.icon-images:before {
  content: ""; }

.icon-alert:before {
  content: ""; }

.icon-account:before {
  content: ""; }

.icon-add-to-cart:before {
  content: "";
  color: #444444; }

.icon-add-to-cart .path2:before {
  content: "";
  margin-left: -1.03125em;
  color: silver; }

.icon-add-to-cart .path3:before {
  content: "";
  margin-left: -1.03125em;
  color: white; }

.icon-arrow-down:before {
  content: ""; }

.icon-arrow-left-thin:before {
  content: ""; }

.icon-arrow-left:before {
  content: ""; }

.icon-arrow-right-thin:before {
  content: ""; }

.icon-arrow-right:before {
  content: ""; }

.icon-arrow-top:before {
  content: ""; }

.icon-arrow-up:before {
  content: ""; }

.icon-call:before {
  content: ""; }

.icon-cart:before {
  content: ""; }

.icon-checkmark:before {
  content: ""; }

.icon-close-small:before {
  content: ""; }

.icon-close:before {
  content: ""; }

.icon-collapse:before {
  content: ""; }

.icon-edit:before {
  content: ""; }

.icon-email:before {
  content: ""; }

.icon-expand:before {
  content: ""; }

.icon-facebook:before {
  content: ""; }

.icon-favorite-selected:before {
  content: ""; }

.icon-favorite:before {
  content: ""; }

.icon-film:before {
  content: ""; }

.icon-globe:before {
  content: ""; }

.icon-google:before {
  content: ""; }

.icon-guarantee:before {
  content: ""; }

.icon-image:before {
  content: ""; }

.icon-info:before {
  content: ""; }

.icon-instagram:before {
  content: ""; }

.icon-link:before {
  content: ""; }

.icon-linkedin:before {
  content: ""; }

.icon-livechat:before {
  content: "";
  color: #444444; }

.icon-livechat .path2:before {
  content: "";
  margin-left: -1em;
  color: white; }

.icon-location:before {
  content: ""; }

.icon-lock:before {
  content: ""; }

.icon-minus:before {
  content: ""; }

.icon-offsite:before {
  content: ""; }

.icon-pause:before {
  content: ""; }

.icon-pinterest:before {
  content: ""; }

.icon-play-hollow:before {
  content: ""; }

.icon-plus:before {
  content: ""; }

.icon-print:before {
  content: ""; }

.icon-question:before {
  content: ""; }

.icon-remove:before {
  content: ""; }

.icon-search:before {
  content: ""; }

.icon-size:before {
  content: ""; }

.icon-star-empty:before {
  content: ""; }

.icon-star-half:before {
  content: ""; }

.icon-star:before {
  content: ""; }

.icon-twitter:before {
  content: ""; }

.icon-youtube:before {
  content: ""; }

.icon-zoom-out:before {
  content: ""; }

.icon-zoom:before {
  content: ""; }

.fa {
  display: inline-block;
  font-weight: normal;
  font-style: normal;
  text-rendering: auto;
  font-size: inherit; }

.fa-stack {
  position: relative;
  display: inline-block;
  vertical-align: middle; }

.fa-stack-1x,
.fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: inherit; }

/*
* Root
*/
:root {
  --brand-primary: #393939;
  --brand-primary-hue: 0deg;
  --brand-primary-saturation: 0%;
  --brand-primary-lightness: 22.35294%;
  --brand-secondary: #0092bc;
  --brand-secondary-hue: 193.40426deg;
  --brand-secondary-saturation: 100%;
  --brand-secondary-lightness: 36.86275%;
  --brand-tertiary: #a3b7c4;
  --brand-tertiary-hue: 203.63636deg;
  --brand-tertiary-saturation: 21.8543%;
  --brand-tertiary-lightness: 70.39216%;
  --secondary-font: 'Montserrat', sans-serif;
  --backup-font: 'Montserrat', sans-serif;
  --h1-desktop-size: 54px;
  --h2-desktop-size: 40px;
  --h3-desktop-size: 24px;
  --h4-desktop-size: 20px;
  --h5-desktop-size: 16px;
  --h6-desktop-size: 16px;
  --h1-mobile-size: 28px;
  --h2-mobile-size: 24px;
  --h3-mobile-size: 20px;
  --h4-mobile-size: 16px;
  --h5-mobile-size: 14px;
  --h6-mobile-size: 13px;
  --h1-tablet-size: var(--h1-mobile-size);
  --h2-tablet-size: var(--h2-mobile-size);
  --h3-tablet-size: var(--h3-mobile-size);
  --h4-tablet-size: var(--h4-mobile-size);
  --h5-tablet-size: var(--h5-mobile-size);
  --h6-tablet-size: var(--h6-mobile-size);
  --b1-desktop-size: 96px;
  --b2-desktop-size: 84px;
  --b3-desktop-size: 72px;
  --b4-desktop-size: 60px;
  --b1-mobile-size: 72px;
  --b2-mobile-size: 60px;
  --b3-mobile-size: 48px;
  --b4-mobile-size: 36px;
  --b1-tablet-size: var(--b1-mobile-size);
  --b2-tablet-size: var(--b2-mobile-size);
  --b3-tablet-size: var(--b3-mobile-size);
  --b4-tablet-size: var(--b4-mobile-size);
  --border-radius: 0px;
  --base-font-color: #000000;
  --base-font-size: 13px;
  --base-border-color: #C5C5C5;
  --tab-border-color:var(--base-border-color);
  --tab-border-top: none;
  --tab-border-right: none;
  --tab-border-bottom: 1px;
  --tab-border-left: none;
  --tab-border-color-active: var(--tab-border-color);
  --tab-border-top-active: 1px;
  --tab-border-right-active: 1px;
  --tab-border-bottom-active: none;
  --tab-border-left-active: 1px;
  --brand-favicon: url('../images/favicons/favicon-32x32.png');
  --brand-logo-mobile: url("../images/logo.png");
  --brand-logo-desktop: url("../images/logo.png");
  --brand-logo-mobile-size: 160px;
  --brand-logo-desktop-size: 212px;
  --button-primary-color: #FFFFFF;
  --button-primary-color-hover: #FFFFFF;
  --button-primary-background: var(--brand-primary);
  --button-primary-background-hover: var(--brand-secondary);
  --button-primary-border-color: var(--brand-primary);
  --button-primary-border-color-hover: var(--brand-secondary);
  --button-primary-border-width: 1px;
  --button-secondary-color: var(--brand-primary);
  --button-secondary-color-hover: #FFFFFF;
  --button-secondary-background: #FFFFFF;
  --button-secondary-background-hover: var(--brand-secondary);
  --button-secondary-border-color: var(--brand-secondary);
  --button-secondary-border-color-hover: var(--brand-secondary);
  --button-secondary-border-width: 1px;
  --button-tertiary-text-decoration: underline;
  --button-tertiary-text-decoration-hover: underline;
  --button-tertiary-color: var(--brand-primary);
  --button-tertiary-color-hover: var(--brand-secondary);
  --button-padding-x: 20px;
  --button-padding-y: 12px;
  --button-font-size: 13px;
  --button-border-radius: 0px;
  --button-font-weight: normal;
  --button-text-transform: none;
  --primary-nav-font-weight: normal;
  --primary-nav-text-transform: none;
  --primary-nav-color: inherit;
  --primary-nav-color-hover: inherit;
  --secondary-nav-font-weight: normal;
  --secondary-nav-text-transform: none;
  --secondary-nav-color: inherit;
  --secondary-nav-color-hover: inherit;
  --tertiary-nav-font-weight: normal;
  --tertiary-nav-text-transform: none;
  --tertiary-nav-color: inherit;
  --tertiary-nav-color-hover: inherit;
  --header-background-color: #FFFFFF;
  --header-navbar-background-color: var(--brand-secondary);
  --header-navbar-background-color-hover: #FFFFFF;
  --header-navbar-text-color: #FFFFFF;
  --header-navbar-text-color-hover: var(--header-navbar-background-color);
  --header-link-color-hover-not-hovered: #767676;
  --header-logo-padding-y: 0px;
  --footer-background-color: inherit;
  --footer-header-text-color: inherit;
  --footer-link-color: inherit;
  --footer-link-color-hover: inherit;
  --footer-padding-y: 72px;
  --footer-margin-top: 100px;
  --product-name-font-size-desktop: var(--base-font-size);
  --product-name-font-size-mobile: var(--base-font-size);
  --product-tile-swatch-size: 18px;
  --product-tile-thumbnail-size: 30px;
  --product-tile-price-size: 13px;
  --product-tile-price-color: var(--base-font-color);
  --product-tile-sale-color: var(--red);
  --product-tile-star-color: #000000;
  --product-tile-star-size: 11px;
  --pdp-color-swatch-size: 18px;
  --pdp-color-swatch-thumbnail-size: 44px;
  --pdp-non-color-swatch-size: 44px; }

/*
* COLORS
// */
/*
* GRID SYSTEM
*/
/*
* Z Indexes
*/
/*
* ASPECT RATIOS
*/
/*
* MISC
*/
/*
* FONTS (setup is handled in utilities/_fonts.scss)
*/
/*
* TEXT
*/
/*
* HEADERS
*/
/*
* BANNER STYLES
*/
/*
* BUTTONS
*/
/*
* FORMs
*/
/*
* BREADCRUMBS
*/
/*
* TOOLTIPS
*/
/*
* POPOVERS
*/
/*
* MODALS
*/
/*
* ALERTS (also affects toast messages)
*/
/*
* TOAST MESSAGES
*/
/*
* CARDS
*/
/*
* COLLAPSIBLE
*/
/*
* ACCORDION
*/
/*
* TABS
*/
/*
* SLIDER
*/
/*
* DROPDOWNS
*/
/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
/*
* HEADER
*/
/*
* FOOTER
*/
/*
* PAGE-LEVEL SETTINGS
*/
/*
* PRODUCT
*/
/*
* MINICART
*/
/*
* CART AND CHECKOUT
*/
/*
* PAGE DESIGNER MODULES
*/
/*
* HERO ASSETS
*/
/*
* CATEGORY TILE ASSETS
*/
/*
* IMAGE TEXT BLOCK ASSETS
*/
/*
* PHOTO HOTSPOTS ASSETS
*/
/*
* VIDEO ASSETS
*/
/*
* BLOG LANDING
*/
/*
* BLOG DETAIL
*/
/*
* CONTENT TILES
*/
/*
* DISCOUNT STATUS BAR
*/
/*
* STORE LOCATOR
*/
/*
* STORE DETAIL PAGE
*/
/*
* ACCOUNT
*/
/*
* WISHLIST
*/
/*
* GIFTCERTIFICATES
*/
/*
* PAYMENTMETHODS
*/
/*
* ACCESSIBILITY
*/
/*
* CONSENT DRAWER
*/
/*
* FOLDER-BASED NAVIGATION (e.g. Customer Service pages)
*/
/*
* CUSTOM SCROLLBARS
*/
:root {
  --blue: #007c9e;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #A3080F;
  --orange: #fd7e14;
  --yellow: #FFCC00;
  --green: #008827;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #FFFFFF;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --white: #FFFFFF;
  --off-white: #F2F2F2;
  --light-gray: #C5C5C5;
  --gray: #BEBEBE;
  --dark-gray: #767676;
  --black: #000000;
  --green: #008827;
  --red: #A3080F;
  --yellow: #FFCC00;
  --blue: #007c9e;
  --success: #008827;
  --danger: #A3080F;
  --warning: #FFCC00;
  --info: #007c9e;
  --primary: #007c9e;
  --secondary: #6c757d;
  --success: #008827;
  --info: #007c9e;
  --warning: #FFCC00;
  --danger: #A3080F;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 544px;
  --breakpoint-md: 769px;
  --breakpoint-lg: 1024px;
  --breakpoint-xl: 1440px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #FFFFFF; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 8px; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: var(--brand-primary);
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: var(--brand-secondary);
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 8px;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: var(--h1-mobile-size); }

h2, .h2 {
  font-size: var(--h2-mobile-size); }

h3, .h3 {
  font-size: var(--h3-mobile-size); }

h4, .h4 {
  font-size: var(--h4-mobile-size); }

h5, .h5 {
  font-size: var(--h5-mobile-size); }

h6, .h6 {
  font-size: var(--h6-mobile-size); }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 16px;
  margin-bottom: 16px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 16px;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #FFFFFF;
  border: 1px solid #dee2e6;
  border-radius: var(--border-radius);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 8px;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #FFFFFF;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 769px) {
    .container {
      max-width: 1420px; } }
  @media (min-width: 1024px) {
    .container {
      max-width: 1430px; } }
  @media (min-width: 1440px) {
    .container {
      max-width: 1440px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 544px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 769px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1024px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1440px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 16px;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid var(--base-border-color); }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid var(--base-border-color); }
  .table tbody + tbody {
    border-top: 2px solid var(--base-border-color); }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid var(--base-border-color); }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid var(--base-border-color); }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8dae4; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abbcd; }

.table-hover .table-primary:hover {
  background-color: #a6d0dd; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a6d0dd; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8dec3; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7ac18f; }

.table-hover .table-success:hover {
  background-color: #a7d6b4; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #a7d6b4; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8dae4; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #7abbcd; }

.table-hover .table-info:hover {
  background-color: #a6d0dd; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #a6d0dd; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff1b8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffe47a; }

.table-hover .table-warning:hover {
  background-color: #ffec9f; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffec9f; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #e5babc; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #cf7f82; }

.table-hover .table-danger:hover {
  background-color: #dea7aa; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #dea7aa; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #FFFFFF;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: var(--base-border-color); }

.table-dark {
  color: #FFFFFF;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #FFFFFF;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 543.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 768.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 1023.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1439.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(16px + 12px + 14px + (1px*2));
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid #C5C5C5;
  border-radius: var(--border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #FFFFFF;
    border-color: #1fcfff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 124, 158, 0.25); }
  .form-control::placeholder {
    color: #767676;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #FFFFFF; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #008827; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.625rem;
  line-height: 1.5;
  color: #FFFFFF;
  background-color: #008827;
  border-radius: var(--border-radius); }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #008827;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23008827' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #008827;
    box-shadow: 0 0 0 0.2rem rgba(0, 136, 39, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .was-validated select.form-control:valid,
.was-validated select:valid, .custom-select.is-valid,
select.is-valid {
  border-color: #008827;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23008827' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #FFFFFF no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .was-validated select.form-control:valid:focus,
  .was-validated select:valid:focus, .custom-select.is-valid:focus,
  select.is-valid:focus {
    border-color: #008827;
    box-shadow: 0 0 0 0.2rem rgba(0, 136, 39, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback, .was-validated select.form-control:valid ~ .valid-feedback, .was-validated select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip,
  .was-validated select.form-control:valid ~ .valid-tooltip,
  .was-validated select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback, select.is-valid.form-control ~ .valid-feedback, select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip,
  select.is-valid.form-control ~ .valid-tooltip,
  select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #008827; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #008827; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #008827; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #00bb36;
  background-color: #00bb36; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 136, 39, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #008827; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #008827; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #008827;
  box-shadow: 0 0 0 0.2rem rgba(0, 136, 39, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #A3080F; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.625rem;
  line-height: 1.5;
  color: #FFFFFF;
  background-color: #a3080f;
  border-radius: var(--border-radius); }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #A3080F;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23A3080F' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23A3080F' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #A3080F;
    box-shadow: 0 0 0 0.2rem rgba(163, 8, 15, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .was-validated select.form-control:invalid,
.was-validated select:invalid, .custom-select.is-invalid,
select.is-invalid {
  border-color: #A3080F;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23A3080F' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23A3080F' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #FFFFFF no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .was-validated select.form-control:invalid:focus,
  .was-validated select:invalid:focus, .custom-select.is-invalid:focus,
  select.is-invalid:focus {
    border-color: #A3080F;
    box-shadow: 0 0 0 0.2rem rgba(163, 8, 15, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback, .was-validated select.form-control:invalid ~ .invalid-feedback, .was-validated select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip,
  .was-validated select.form-control:invalid ~ .invalid-tooltip,
  .was-validated select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback, select.is-invalid.form-control ~ .invalid-feedback, select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip,
  select.is-invalid.form-control ~ .invalid-tooltip,
  select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #A3080F; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #A3080F; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #A3080F; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #d40a13;
  background-color: #d40a13; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(163, 8, 15, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #A3080F; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #A3080F; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #A3080F;
  box-shadow: 0 0 0 0.2rem rgba(163, 8, 15, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 544px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select,
    .form-inline select.form-control,
    .form-inline select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: var(--border-radius); }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 544px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 769px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 1024px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1440px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 8px 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #007c9e; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > select.form-control,
  .input-group > select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + select.form-control,
    .input-group > .form-control + select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + select.form-control,
    .input-group > .form-control-plaintext + select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > select.form-control + .form-control,
    .input-group > select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > select.form-control + .custom-select,
    .input-group > select + .custom-select,
    .input-group > .custom-select + select.form-control,
    .input-group > select.form-control + select.form-control,
    .input-group > select + select.form-control,
    .input-group > .custom-select + select,
    .input-group > select.form-control + select,
    .input-group > select + select,
    .input-group > .custom-select + .custom-file,
    .input-group > select.form-control + .custom-file,
    .input-group > select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + select.form-control,
    .input-group > .custom-file + select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > select.form-control:focus,
  .input-group > select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child),
  .input-group > select.form-control:not(:last-child),
  .input-group > select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child),
  .input-group > select.form-control:not(:first-child),
  .input-group > select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #C5C5C5;
  border-radius: var(--border-radius); }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select,
.input-group-lg > select.form-control,
.input-group-lg > select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > select.form-control,
.input-group-lg > select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select,
.input-group-sm > select.form-control,
.input-group-sm > select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > select.form-control,
.input-group-sm > select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }


.input-group-lg > .custom-select,
.input-group-lg > select.form-control,
.input-group-lg > select,
.input-group-sm > .custom-select,
.input-group-sm > select.form-control,
.input-group-sm > select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #FFFFFF;
    border-color: #007c9e;
    background-color: #007c9e; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 124, 158, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #1fcfff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #FFFFFF;
    background-color: #52daff;
    border-color: #52daff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #FFFFFF;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: var(--border-radius); }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007c9e;
  background-color: #007c9e; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23FFFFFF' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 124, 158, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 124, 158, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 124, 158, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #FFFFFF;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 124, 158, 0.5); }

.custom-select, select.form-control,
select {
  display: inline-block;
  width: 100%;
  height: calc(16px + 12px + 14px + (1px*2));
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #FFFFFF;
  border: 1px solid #C5C5C5;
  border-radius: var(--border-radius);
  appearance: none; }
  .custom-select:focus,
  select:focus {
    border-color: #1fcfff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 124, 158, 0.25); }
    .custom-select:focus::-ms-value,
    select:focus::-ms-value {
      color: #495057;
      background-color: #FFFFFF; }
  .custom-select[multiple],
  select[multiple], .custom-select[size]:not([size="1"]),
  select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled,
  select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand, select.form-control::-ms-expand,
  select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(16px + 12px + 14px + (1px*2));
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(16px + 12px + 14px + (1px*2));
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #1fcfff;
    box-shadow: 0 0 0 0.2rem rgba(0, 124, 158, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(16px + 12px + 14px + (1px*2));
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #FFFFFF;
  border: 1px solid #C5C5C5;
  border-radius: var(--border-radius); }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 var(--border-radius) var(--border-radius) 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(0, 124, 158, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(0, 124, 158, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(0, 124, 158, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007c9e;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #52daff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007c9e;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #52daff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007c9e;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #52daff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label, .custom-select, select.form-control,
select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label, .custom-select, select.form-control,
    select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius); }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #FFFFFF;
    border-color: #dee2e6 #dee2e6 #FFFFFF; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: var(--border-radius); }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #FFFFFF;
  background-color: #007c9e; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 16px;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: var(--border-radius); }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 543.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 544px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 768.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 769px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 1023.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1024px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1439.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1440px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #FFFFFF; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #FFFFFF; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #FFFFFF; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #FFFFFF; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #FFFFFF; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: border-box;
  border: 1px solid #C5C5C5;
  border-radius: var(--border-radius); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius); }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius); }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid #C5C5C5; }
  .card-header:first-child {
    border-radius: calc(var(--border-radius) - 1px) calc(var(--border-radius) - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid #C5C5C5; }
  .card-footer:last-child {
    border-radius: 0 0 calc(var(--border-radius) - 1px) calc(var(--border-radius) - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(var(--border-radius) - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(var(--border-radius) - 1px);
  border-top-right-radius: calc(var(--border-radius) - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(var(--border-radius) - 1px);
  border-bottom-left-radius: calc(var(--border-radius) - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 544px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 544px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 544px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: var(--border-radius); }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: var(--brand-primary);
  background-color: #FFFFFF;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: var(--brand-secondary);
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 124, 158, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius); }

.page-item:last-child .page-link {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius); }

.page-item.active .page-link {
  z-index: 1;
  color: #FFFFFF;
  background-color: #007c9e;
  border-color: #007c9e; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #FFFFFF;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--border-radius);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #FFFFFF;
  background-color: #007c9e; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #FFFFFF;
    background-color: #00546b; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 124, 158, 0.5); }

.badge-secondary {
  color: #FFFFFF;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #FFFFFF;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #FFFFFF;
  background-color: #008827; }
  a.badge-success:hover, a.badge-success:focus {
    color: #FFFFFF;
    background-color: #005518; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 136, 39, 0.5); }

.badge-info {
  color: #FFFFFF;
  background-color: #007c9e; }
  a.badge-info:hover, a.badge-info:focus {
    color: #FFFFFF;
    background-color: #00546b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 124, 158, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #FFCC00; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #cca300; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 204, 0, 0.5); }

.badge-danger {
  color: #FFFFFF;
  background-color: #A3080F; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #FFFFFF;
    background-color: #72060b; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(163, 8, 15, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #FFFFFF;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #FFFFFF;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 544px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: var(--border-radius); }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004052;
  background-color: #cce5ec;
  border-color: #b8dae4; }
  .alert-primary hr {
    border-top-color: #a6d0dd; }
  .alert-primary .alert-link {
    color: #00181f; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #004714;
  background-color: #cce7d4;
  border-color: #b8dec3; }
  .alert-success hr {
    border-top-color: #a7d6b4; }
  .alert-success .alert-link {
    color: #001406; }

.alert-info {
  color: #004052;
  background-color: #cce5ec;
  border-color: #b8dae4; }
  .alert-info hr {
    border-top-color: #a6d0dd; }
  .alert-info .alert-link {
    color: #00181f; }

.alert-warning {
  color: #856a00;
  background-color: #fff5cc;
  border-color: #fff1b8; }
  .alert-warning hr {
    border-top-color: #ffec9f; }
  .alert-warning .alert-link {
    color: #524100; }

.alert-danger {
  color: #550408;
  background-color: #edcecf;
  border-color: #e5babc; }
  .alert-danger hr {
    border-top-color: #dea7aa; }
  .alert-danger .alert-link {
    color: #240203; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: var(--border-radius); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #FFFFFF;
  text-align: center;
  white-space: nowrap;
  background-color: #007c9e;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius); }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius); }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #FFFFFF; }
  .list-group-item.active {
    z-index: 2;
    color: #FFFFFF;
    background-color: #007c9e;
    border-color: #007c9e; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
      border-bottom-left-radius: 0; }

@media (min-width: 544px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
        border-bottom-left-radius: 0; } }

@media (min-width: 769px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
        border-bottom-left-radius: 0; } }

@media (min-width: 1024px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
        border-bottom-left-radius: 0; } }

@media (min-width: 1440px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #004052;
  background-color: #b8dae4; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004052;
    background-color: #a6d0dd; }
  .list-group-item-primary.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #004052;
    border-color: #004052; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #004714;
  background-color: #b8dec3; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #004714;
    background-color: #a7d6b4; }
  .list-group-item-success.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #004714;
    border-color: #004714; }

.list-group-item-info {
  color: #004052;
  background-color: #b8dae4; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #004052;
    background-color: #a6d0dd; }
  .list-group-item-info.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #004052;
    border-color: #004052; }

.list-group-item-warning {
  color: #856a00;
  background-color: #fff1b8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856a00;
    background-color: #ffec9f; }
  .list-group-item-warning.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #856a00;
    border-color: #856a00; }

.list-group-item-danger {
  color: #550408;
  background-color: #e5babc; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #550408;
    background-color: #dea7aa; }
  .list-group-item-danger.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #550408;
    border-color: #550408; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #FFFFFF;
  opacity: .5; }
  .close:hover {
    color: #000000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 20px 9px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 10px 20px 9px;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid transparent;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 544px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 1024px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1440px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.625rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 1; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #FFFFFF;
  text-align: center;
  background-color: #000000;
  border-radius: var(--border-radius); }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 300px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: var(--border-radius); }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.05); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #FFFFFF; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.05); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #FFFFFF; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.05); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #FFFFFF; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.05); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #FFFFFF; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #FFFFFF;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #FFFFFF;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #FFFFFF;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #FFFFFF;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007c9e !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #00546b !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #008827 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #005518 !important; }

.bg-info {
  background-color: #007c9e !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #00546b !important; }

.bg-warning {
  background-color: #FFCC00 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cca300 !important; }

.bg-danger {
  background-color: #A3080F !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #72060b !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #FFFFFF !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid var(--base-border-color) !important; }

.border-top {
  border-top: 1px solid var(--base-border-color) !important; }

.border-right {
  border-right: 1px solid var(--base-border-color) !important; }

.border-bottom {
  border-bottom: 1px solid var(--base-border-color) !important; }

.border-left {
  border-left: 1px solid var(--base-border-color) !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007c9e !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #008827 !important; }

.border-info {
  border-color: #007c9e !important; }

.border-warning {
  border-color: #FFCC00 !important; }

.border-danger {
  border-color: #A3080F !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #FFFFFF !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: var(--border-radius) !important; }

.rounded-top {
  border-top-left-radius: var(--border-radius) !important;
  border-top-right-radius: var(--border-radius) !important; }

.rounded-right {
  border-top-right-radius: var(--border-radius) !important;
  border-bottom-right-radius: var(--border-radius) !important; }

.rounded-bottom {
  border-bottom-right-radius: var(--border-radius) !important;
  border-bottom-left-radius: var(--border-radius) !important; }

.rounded-left {
  border-top-left-radius: var(--border-radius) !important;
  border-bottom-left-radius: var(--border-radius) !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 544px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 769px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1024px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1440px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 544px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 769px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1024px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1440px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 544px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 769px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1024px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1440px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 4px !important; }

.mt-1,
.my-1 {
  margin-top: 4px !important; }

.mr-1,
.mx-1 {
  margin-right: 4px !important; }

.mb-1,
.my-1 {
  margin-bottom: 4px !important; }

.ml-1,
.mx-1 {
  margin-left: 4px !important; }

.m-2 {
  margin: 8px !important; }

.mt-2,
.my-2 {
  margin-top: 8px !important; }

.mr-2,
.mx-2 {
  margin-right: 8px !important; }

.mb-2,
.my-2 {
  margin-bottom: 8px !important; }

.ml-2,
.mx-2 {
  margin-left: 8px !important; }

.m-3 {
  margin: 16px !important; }

.mt-3,
.my-3 {
  margin-top: 16px !important; }

.mr-3,
.mx-3 {
  margin-right: 16px !important; }

.mb-3,
.my-3 {
  margin-bottom: 16px !important; }

.ml-3,
.mx-3 {
  margin-left: 16px !important; }

.m-4 {
  margin: 24px !important; }

.mt-4,
.my-4 {
  margin-top: 24px !important; }

.mr-4,
.mx-4 {
  margin-right: 24px !important; }

.mb-4,
.my-4 {
  margin-bottom: 24px !important; }

.ml-4,
.mx-4 {
  margin-left: 24px !important; }

.m-5 {
  margin: 48px !important; }

.mt-5,
.my-5 {
  margin-top: 48px !important; }

.mr-5,
.mx-5 {
  margin-right: 48px !important; }

.mb-5,
.my-5 {
  margin-bottom: 48px !important; }

.ml-5,
.mx-5 {
  margin-left: 48px !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 4px !important; }

.pt-1,
.py-1 {
  padding-top: 4px !important; }

.pr-1,
.px-1 {
  padding-right: 4px !important; }

.pb-1,
.py-1 {
  padding-bottom: 4px !important; }

.pl-1,
.px-1 {
  padding-left: 4px !important; }

.p-2 {
  padding: 8px !important; }

.pt-2,
.py-2 {
  padding-top: 8px !important; }

.pr-2,
.px-2 {
  padding-right: 8px !important; }

.pb-2,
.py-2 {
  padding-bottom: 8px !important; }

.pl-2,
.px-2 {
  padding-left: 8px !important; }

.p-3 {
  padding: 16px !important; }

.pt-3,
.py-3 {
  padding-top: 16px !important; }

.pr-3,
.px-3 {
  padding-right: 16px !important; }

.pb-3,
.py-3 {
  padding-bottom: 16px !important; }

.pl-3,
.px-3 {
  padding-left: 16px !important; }

.p-4 {
  padding: 24px !important; }

.pt-4,
.py-4 {
  padding-top: 24px !important; }

.pr-4,
.px-4 {
  padding-right: 24px !important; }

.pb-4,
.py-4 {
  padding-bottom: 24px !important; }

.pl-4,
.px-4 {
  padding-left: 24px !important; }

.p-5 {
  padding: 48px !important; }

.pt-5,
.py-5 {
  padding-top: 48px !important; }

.pr-5,
.px-5 {
  padding-right: 48px !important; }

.pb-5,
.py-5 {
  padding-bottom: 48px !important; }

.pl-5,
.px-5 {
  padding-left: 48px !important; }

.m-n1 {
  margin: -4px !important; }

.mt-n1,
.my-n1 {
  margin-top: -4px !important; }

.mr-n1,
.mx-n1 {
  margin-right: -4px !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -4px !important; }

.ml-n1,
.mx-n1 {
  margin-left: -4px !important; }

.m-n2 {
  margin: -8px !important; }

.mt-n2,
.my-n2 {
  margin-top: -8px !important; }

.mr-n2,
.mx-n2 {
  margin-right: -8px !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -8px !important; }

.ml-n2,
.mx-n2 {
  margin-left: -8px !important; }

.m-n3 {
  margin: -16px !important; }

.mt-n3,
.my-n3 {
  margin-top: -16px !important; }

.mr-n3,
.mx-n3 {
  margin-right: -16px !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -16px !important; }

.ml-n3,
.mx-n3 {
  margin-left: -16px !important; }

.m-n4 {
  margin: -24px !important; }

.mt-n4,
.my-n4 {
  margin-top: -24px !important; }

.mr-n4,
.mx-n4 {
  margin-right: -24px !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -24px !important; }

.ml-n4,
.mx-n4 {
  margin-left: -24px !important; }

.m-n5 {
  margin: -48px !important; }

.mt-n5,
.my-n5 {
  margin-top: -48px !important; }

.mr-n5,
.mx-n5 {
  margin-right: -48px !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -48px !important; }

.ml-n5,
.mx-n5 {
  margin-left: -48px !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 544px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 4px !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 4px !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 4px !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 4px !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 4px !important; }
  .m-sm-2 {
    margin: 8px !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 8px !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 8px !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 8px !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 8px !important; }
  .m-sm-3 {
    margin: 16px !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 16px !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 16px !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 16px !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 16px !important; }
  .m-sm-4 {
    margin: 24px !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 24px !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 24px !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 24px !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 24px !important; }
  .m-sm-5 {
    margin: 48px !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 48px !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 48px !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 48px !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 48px !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 4px !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 4px !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 4px !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 4px !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 4px !important; }
  .p-sm-2 {
    padding: 8px !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 8px !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 8px !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 8px !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 8px !important; }
  .p-sm-3 {
    padding: 16px !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 16px !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 16px !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 16px !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 16px !important; }
  .p-sm-4 {
    padding: 24px !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 24px !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 24px !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 24px !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 24px !important; }
  .p-sm-5 {
    padding: 48px !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 48px !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 48px !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 48px !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 48px !important; }
  .m-sm-n1 {
    margin: -4px !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -4px !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -4px !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -4px !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -4px !important; }
  .m-sm-n2 {
    margin: -8px !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -8px !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -8px !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -8px !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -8px !important; }
  .m-sm-n3 {
    margin: -16px !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -16px !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -16px !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -16px !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -16px !important; }
  .m-sm-n4 {
    margin: -24px !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -24px !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -24px !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -24px !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -24px !important; }
  .m-sm-n5 {
    margin: -48px !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -48px !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -48px !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -48px !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -48px !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 769px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 4px !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 4px !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 4px !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 4px !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 4px !important; }
  .m-md-2 {
    margin: 8px !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 8px !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 8px !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 8px !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 8px !important; }
  .m-md-3 {
    margin: 16px !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 16px !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 16px !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 16px !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 16px !important; }
  .m-md-4 {
    margin: 24px !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 24px !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 24px !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 24px !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 24px !important; }
  .m-md-5 {
    margin: 48px !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 48px !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 48px !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 48px !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 48px !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 4px !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 4px !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 4px !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 4px !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 4px !important; }
  .p-md-2 {
    padding: 8px !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 8px !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 8px !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 8px !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 8px !important; }
  .p-md-3 {
    padding: 16px !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 16px !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 16px !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 16px !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 16px !important; }
  .p-md-4 {
    padding: 24px !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 24px !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 24px !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 24px !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 24px !important; }
  .p-md-5 {
    padding: 48px !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 48px !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 48px !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 48px !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 48px !important; }
  .m-md-n1 {
    margin: -4px !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -4px !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -4px !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -4px !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -4px !important; }
  .m-md-n2 {
    margin: -8px !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -8px !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -8px !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -8px !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -8px !important; }
  .m-md-n3 {
    margin: -16px !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -16px !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -16px !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -16px !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -16px !important; }
  .m-md-n4 {
    margin: -24px !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -24px !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -24px !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -24px !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -24px !important; }
  .m-md-n5 {
    margin: -48px !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -48px !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -48px !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -48px !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -48px !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1024px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 4px !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 4px !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 4px !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 4px !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 4px !important; }
  .m-lg-2 {
    margin: 8px !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 8px !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 8px !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 8px !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 8px !important; }
  .m-lg-3 {
    margin: 16px !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 16px !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 16px !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 16px !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 16px !important; }
  .m-lg-4 {
    margin: 24px !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 24px !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 24px !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 24px !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 24px !important; }
  .m-lg-5 {
    margin: 48px !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 48px !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 48px !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 48px !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 48px !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 4px !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 4px !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 4px !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 4px !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 4px !important; }
  .p-lg-2 {
    padding: 8px !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 8px !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 8px !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 8px !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 8px !important; }
  .p-lg-3 {
    padding: 16px !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 16px !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 16px !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 16px !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 16px !important; }
  .p-lg-4 {
    padding: 24px !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 24px !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 24px !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 24px !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 24px !important; }
  .p-lg-5 {
    padding: 48px !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 48px !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 48px !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 48px !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 48px !important; }
  .m-lg-n1 {
    margin: -4px !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -4px !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -4px !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -4px !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -4px !important; }
  .m-lg-n2 {
    margin: -8px !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -8px !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -8px !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -8px !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -8px !important; }
  .m-lg-n3 {
    margin: -16px !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -16px !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -16px !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -16px !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -16px !important; }
  .m-lg-n4 {
    margin: -24px !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -24px !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -24px !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -24px !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -24px !important; }
  .m-lg-n5 {
    margin: -48px !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -48px !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -48px !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -48px !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -48px !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1440px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 4px !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 4px !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 4px !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 4px !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 4px !important; }
  .m-xl-2 {
    margin: 8px !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 8px !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 8px !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 8px !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 8px !important; }
  .m-xl-3 {
    margin: 16px !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 16px !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 16px !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 16px !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 16px !important; }
  .m-xl-4 {
    margin: 24px !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 24px !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 24px !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 24px !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 24px !important; }
  .m-xl-5 {
    margin: 48px !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 48px !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 48px !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 48px !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 48px !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 4px !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 4px !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 4px !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 4px !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 4px !important; }
  .p-xl-2 {
    padding: 8px !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 8px !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 8px !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 8px !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 8px !important; }
  .p-xl-3 {
    padding: 16px !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 16px !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 16px !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 16px !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 16px !important; }
  .p-xl-4 {
    padding: 24px !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 24px !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 24px !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 24px !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 24px !important; }
  .p-xl-5 {
    padding: 48px !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 48px !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 48px !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 48px !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 48px !important; }
  .m-xl-n1 {
    margin: -4px !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -4px !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -4px !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -4px !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -4px !important; }
  .m-xl-n2 {
    margin: -8px !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -8px !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -8px !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -8px !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -8px !important; }
  .m-xl-n3 {
    margin: -16px !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -16px !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -16px !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -16px !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -16px !important; }
  .m-xl-n4 {
    margin: -24px !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -24px !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -24px !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -24px !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -24px !important; }
  .m-xl-n5 {
    margin: -48px !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -48px !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -48px !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -48px !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -48px !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 544px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 769px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1024px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1440px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #FFFFFF !important; }

.text-primary {
  color: #007c9e !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #004052 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #008827 !important; }

a.text-success:hover, a.text-success:focus {
  color: #003c11 !important; }

.text-info {
  color: #007c9e !important; }

a.text-info:hover, a.text-info:focus {
  color: #004052 !important; }

.text-warning {
  color: #FFCC00 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #b38f00 !important; }

.text-danger {
  color: #A3080F !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #5a0408 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 1024px !important; }
  .container {
    min-width: 1024px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #FFFFFF !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: var(--base-border-color); }
  .table .thead-dark th {
    color: inherit;
    border-color: var(--base-border-color); } }

dt {
  color: #495057;
  font-weight: normal; }

a {
  color: inherit; }
  a:hover {
    color: inherit; }

i {
  font-style: normal;
  display: inline-block; }

button {
  background: transparent;
  border: none;
  padding: 0;
  letter-spacing: inherit; }

ul {
  padding-left: 16px; }

figure {
  margin: 0; }

.modal-footer > :not(:first-child) {
  margin-left: 0; }

.modal-footer > :not(:last-child) {
  margin-right: 0; }

.tooltip {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit; }

.text-muted {
  color: #767676 !important; }

.row:not(.no-gutters) {
  margin-right: -16px;
  margin-left: -16px; }

.row:not(.no-gutters) > .col,
.row:not(.no-gutters) > [class*="col-"] {
  padding-right: 16px;
  padding-left: 16px; }

@media (min-width: 769px) {
  .row:not(.no-gutters) {
    margin-right: -16px;
    margin-left: -16px; }
  .row:not(.no-gutters) > .col,
  .row:not(.no-gutters) > [class*="col-"] {
    padding-right: 16px;
    padding-left: 16px; } }

@media (min-width: 1440px) {
  .row:not(.no-gutters) {
    margin-right: -24px;
    margin-left: -24px; }
  .row:not(.no-gutters) > .col,
  .row:not(.no-gutters) > [class*="col-"] {
    padding-right: 24px;
    padding-left: 24px; } }

.container {
  padding-right: 16px;
  padding-left: 16px; }
  @media (min-width: 769px) {
    .container {
      padding-right: 16px;
      padding-left: 16px; } }
  @media (min-width: 1440px) {
    .container {
      padding-right: 24px;
      padding-left: 24px; } }

.container-fluid {
  padding-right: 16px;
  padding-left: 16px; }
  @media (min-width: 769px) {
    .container-fluid {
      padding-right: 16px;
      padding-left: 16px; } }
  @media (min-width: 1440px) {
    .container-fluid {
      padding-right: 24px;
      padding-left: 24px; } }

:focus, button:focus {
  outline: 2px solid #767676; }

:focus:not(.focus-visible) {
  outline: none; }

.tns-outer {
  padding: 0 !important; }
  .tns-outer [hidden] {
    display: none !important; }
  .tns-outer [aria-controls], .tns-outer [data-action] {
    cursor: pointer; }

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s; }
  .tns-slider > .tns-item {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }

.tns-horizontal.tns-subpixel {
  white-space: nowrap; }
  .tns-horizontal.tns-subpixel > .tns-item {
    display: inline-block;
    vertical-align: top;
    white-space: normal; }

.tns-horizontal.tns-no-subpixel:after {
  content: '';
  display: table;
  clear: both; }

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left; }

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%; }

.tns-no-calc {
  position: relative;
  left: 0; }

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px; }
  .tns-gallery > .tns-item {
    position: absolute;
    left: -100%;
    -webkit-transition: transform 0s, opacity 0s;
    -moz-transition: transform 0s, opacity 0s;
    transition: transform 0s, opacity 0s; }
  .tns-gallery > .tns-slide-active {
    position: relative;
    left: auto !important; }
  .tns-gallery > .tns-moving {
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    transition: all 0.25s; }

.tns-autowidth {
  display: inline-block; }

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6; }
  .tns-lazy-img.tns-complete {
    opacity: 1; }

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s; }

.tns-ovh {
  overflow: hidden; }

.tns-visually-hidden {
  position: absolute;
  left: -10000em; }

.tns-transparent {
  opacity: 0;
  visibility: hidden; }

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0; }

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1; }

.tns-vpfix {
  white-space: nowrap; }
  .tns-vpfix > div, .tns-vpfix > li {
    display: inline-block; }

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden; }

.tns-t-ct {
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0; }
  .tns-t-ct:after {
    content: '';
    display: table;
    clear: both; }
  .tns-t-ct > div {
    width: calc(100% / 70);
    height: 10px;
    float: left; }

/* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fade-in-long {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes scale-in-center-long {
  0% {
    transform: scale(0);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes hinge-drop-long {
  0% {
    transform: rotateX(-90deg) translateZ(0);
    opacity: 0; }
  10% {
    opacity: 1; }
  100% {
    transform: rotateX(0deg) translateZ(0);
    opacity: 1; } }

@keyframes flip-in-hor-top-long {
  0% {
    transform: rotateX(60deg) translateY(-120px);
    opacity: 0; }
  100% {
    transform: rotateX(0deg) translateY(0);
    opacity: 1; } }

@keyframes flip-in-hor-bottom-long {
  0% {
    transform: rotateX(-60deg) translateY(120px);
    opacity: 0; }
  100% {
    transform: rotateX(0) translateY(0px);
    opacity: 1; } }

@keyframes slide-in-top-long {
  0% {
    transform: translateY(-100%);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes slide-in-left-long {
  0% {
    transform: translateX(-100%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes slide-in-right-long {
  0% {
    transform: translateX(100%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes slide-in-bottom-long {
  0% {
    transform: translateY(100%);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes fade-in-medium {
  0% {
    opacity: 0.33; }
  100% {
    opacity: 1; } }

@keyframes scale-in-center-medium {
  0% {
    transform: scale(0.33);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes hinge-drop-medium {
  0% {
    transform: rotateX(-90deg) translateZ(0);
    opacity: 0; }
  10% {
    opacity: 1; }
  100% {
    transform: rotateX(0deg) translateZ(0);
    opacity: 1; } }

@keyframes flip-in-hor-top-medium {
  0% {
    transform: rotateX(60deg) translateY(-80px);
    opacity: 0; }
  100% {
    transform: rotateX(0deg) translateY(0);
    opacity: 1; } }

@keyframes flip-in-hor-bottom-medium {
  0% {
    transform: rotateX(-60deg) translateY(80px);
    opacity: 0; }
  100% {
    transform: rotateX(0) translateY(0px);
    opacity: 1; } }

@keyframes slide-in-top-medium {
  0% {
    transform: translateY(-66%);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes slide-in-left-medium {
  0% {
    transform: translateX(-66%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes slide-in-right-medium {
  0% {
    transform: translateX(66%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes slide-in-bottom-medium {
  0% {
    transform: translateY(66%);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes fade-in-short {
  0% {
    opacity: 0.66; }
  100% {
    opacity: 1; } }

@keyframes scale-in-center-short {
  0% {
    transform: scale(0.66);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes hinge-drop-short {
  0% {
    transform: rotateX(-90deg) translateZ(0);
    opacity: 0; }
  10% {
    opacity: 1; }
  100% {
    transform: rotateX(0deg) translateZ(0);
    opacity: 1; } }

@keyframes flip-in-hor-top-short {
  0% {
    transform: rotateX(60deg) translateY(-40px);
    opacity: 0; }
  100% {
    transform: rotateX(0deg) translateY(0);
    opacity: 1; } }

@keyframes flip-in-hor-bottom-short {
  0% {
    transform: rotateX(-60deg) translateY(40px);
    opacity: 0; }
  100% {
    transform: rotateX(0) translateY(0px);
    opacity: 1; } }

@keyframes slide-in-top-short {
  0% {
    transform: translateY(-33%);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes slide-in-left-short {
  0% {
    transform: translateX(-33%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes slide-in-right-short {
  0% {
    transform: translateX(33%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes slide-in-bottom-short {
  0% {
    transform: translateY(33%);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes slide-up {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(-100%); } }

@keyframes slide-down {
  0% {
    transform: translateY(-100%); }
  100% {
    transform: translateY(0); } }

.swatch-circle-beige {
  width: 20px;
  height: 20px;
  background-color: #f5f5dc;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-black {
  width: 20px;
  height: 20px;
  background-color: #000000;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-blue {
  width: 20px;
  height: 20px;
  background-color: #007c9e;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-brown {
  width: 20px;
  height: 20px;
  background-color: #a52a2a;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-green {
  width: 20px;
  height: 20px;
  background-color: #008827;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-grey {
  width: 20px;
  height: 20px;
  background-color: #8f979d;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-navy {
  width: 20px;
  height: 20px;
  background-color: #000080;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-orange {
  width: 20px;
  height: 20px;
  background-color: #ffa500;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-pink {
  width: 20px;
  height: 20px;
  background-color: #fe249a;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-purple {
  width: 20px;
  height: 20px;
  background-color: #800080;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-red {
  width: 20px;
  height: 20px;
  background-color: #f00;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-white {
  width: 20px;
  height: 20px;
  background-color: #FFFFFF;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-yellow {
  width: 20px;
  height: 20px;
  background-color: #ff0;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-miscellaneous {
  background: linear-gradient(0deg, #821e91 0, #821e91 25%, #edd134 25%, yellow 50%, #edd134 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, #0e5cd1 0, #0e5cd1 50%, #e20b0b 50%, #e20b0b 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 1px solid var(--base-border-color);
  border-radius: 50%;
  display: inline-block;
  height: 20px;
  width: 20px;
  position: relative;
  transform: rotate(35deg); }
  .swatch-circle-miscellaneous.swatch-mark.color-value.selected::after {
    transform: translate(50%, -50%) rotate(-35deg); }

.swatch-circle-white:not(.swatch-thumbnail),
.swatch[data-displayvalue="Sugar"]:not(.swatch-thumbnail),
.swatch[data-displayvalue="White"]:not(.swatch-thumbnail) {
  border: 1px solid var(--base-border-color); }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-25 {
  max-width: 25% !important; }

.mw-50 {
  max-width: 50% !important; }

.mw-75 {
  max-width: 75% !important; }

.mw-100 {
  max-width: 100% !important; }

.mw-auto {
  max-width: auto !important; }

.mh-25 {
  max-height: 25% !important; }

.mh-50 {
  max-height: 50% !important; }

.mh-75 {
  max-height: 75% !important; }

.mh-100 {
  max-height: 100% !important; }

.mh-auto {
  max-height: auto !important; }

@media (min-width: 544px) {
  .w-sm-25 {
    width: 25% !important; }
  .w-sm-50 {
    width: 50% !important; }
  .w-sm-75 {
    width: 75% !important; }
  .w-sm-100 {
    width: 100% !important; }
  .w-sm-auto {
    width: auto !important; }
  .h-sm-25 {
    height: 25% !important; }
  .h-sm-50 {
    height: 50% !important; }
  .h-sm-75 {
    height: 75% !important; }
  .h-sm-100 {
    height: 100% !important; }
  .h-sm-auto {
    height: auto !important; }
  .mw-sm-25 {
    max-width: 25% !important; }
  .mw-sm-50 {
    max-width: 50% !important; }
  .mw-sm-75 {
    max-width: 75% !important; }
  .mw-sm-100 {
    max-width: 100% !important; }
  .mw-sm-auto {
    max-width: auto !important; }
  .mh-sm-25 {
    max-height: 25% !important; }
  .mh-sm-50 {
    max-height: 50% !important; }
  .mh-sm-75 {
    max-height: 75% !important; }
  .mh-sm-100 {
    max-height: 100% !important; }
  .mh-sm-auto {
    max-height: auto !important; } }

@media (min-width: 769px) {
  .w-md-25 {
    width: 25% !important; }
  .w-md-50 {
    width: 50% !important; }
  .w-md-75 {
    width: 75% !important; }
  .w-md-100 {
    width: 100% !important; }
  .w-md-auto {
    width: auto !important; }
  .h-md-25 {
    height: 25% !important; }
  .h-md-50 {
    height: 50% !important; }
  .h-md-75 {
    height: 75% !important; }
  .h-md-100 {
    height: 100% !important; }
  .h-md-auto {
    height: auto !important; }
  .mw-md-25 {
    max-width: 25% !important; }
  .mw-md-50 {
    max-width: 50% !important; }
  .mw-md-75 {
    max-width: 75% !important; }
  .mw-md-100 {
    max-width: 100% !important; }
  .mw-md-auto {
    max-width: auto !important; }
  .mh-md-25 {
    max-height: 25% !important; }
  .mh-md-50 {
    max-height: 50% !important; }
  .mh-md-75 {
    max-height: 75% !important; }
  .mh-md-100 {
    max-height: 100% !important; }
  .mh-md-auto {
    max-height: auto !important; } }

@media (min-width: 1024px) {
  .w-lg-25 {
    width: 25% !important; }
  .w-lg-50 {
    width: 50% !important; }
  .w-lg-75 {
    width: 75% !important; }
  .w-lg-100 {
    width: 100% !important; }
  .w-lg-auto {
    width: auto !important; }
  .h-lg-25 {
    height: 25% !important; }
  .h-lg-50 {
    height: 50% !important; }
  .h-lg-75 {
    height: 75% !important; }
  .h-lg-100 {
    height: 100% !important; }
  .h-lg-auto {
    height: auto !important; }
  .mw-lg-25 {
    max-width: 25% !important; }
  .mw-lg-50 {
    max-width: 50% !important; }
  .mw-lg-75 {
    max-width: 75% !important; }
  .mw-lg-100 {
    max-width: 100% !important; }
  .mw-lg-auto {
    max-width: auto !important; }
  .mh-lg-25 {
    max-height: 25% !important; }
  .mh-lg-50 {
    max-height: 50% !important; }
  .mh-lg-75 {
    max-height: 75% !important; }
  .mh-lg-100 {
    max-height: 100% !important; }
  .mh-lg-auto {
    max-height: auto !important; } }

@media (min-width: 1440px) {
  .w-xl-25 {
    width: 25% !important; }
  .w-xl-50 {
    width: 50% !important; }
  .w-xl-75 {
    width: 75% !important; }
  .w-xl-100 {
    width: 100% !important; }
  .w-xl-auto {
    width: auto !important; }
  .h-xl-25 {
    height: 25% !important; }
  .h-xl-50 {
    height: 50% !important; }
  .h-xl-75 {
    height: 75% !important; }
  .h-xl-100 {
    height: 100% !important; }
  .h-xl-auto {
    height: auto !important; }
  .mw-xl-25 {
    max-width: 25% !important; }
  .mw-xl-50 {
    max-width: 50% !important; }
  .mw-xl-75 {
    max-width: 75% !important; }
  .mw-xl-100 {
    max-width: 100% !important; }
  .mw-xl-auto {
    max-width: auto !important; }
  .mh-xl-25 {
    max-height: 25% !important; }
  .mh-xl-50 {
    max-height: 50% !important; }
  .mh-xl-75 {
    max-height: 75% !important; }
  .mh-xl-100 {
    max-height: 100% !important; }
  .mh-xl-auto {
    max-height: auto !important; } }

.text {
  /* AB CORE MODFICIATION - add important flag to ensure they have highest priority */
  /* END of AB CORE MODFICIATION */ }
  .text--blue {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #007c9e !important; }
  .text--indigo {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #6610f2 !important; }
  .text--purple {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #6f42c1 !important; }
  .text--pink {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #e83e8c !important; }
  .text--red {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #A3080F !important; }
  .text--orange {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #fd7e14 !important; }
  .text--yellow {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #FFCC00 !important; }
  .text--green {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #008827 !important; }
  .text--teal {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #20c997 !important; }
  .text--cyan {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #17a2b8 !important; }
  .text--white {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #FFFFFF !important; }
  .text--gray {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #6c757d !important; }
  .text--gray-dark {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #343a40 !important; }
  .text--white {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #FFFFFF !important; }
  .text--off-white {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #F2F2F2 !important; }
  .text--light-gray {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #C5C5C5 !important; }
  .text--gray {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #BEBEBE !important; }
  .text--dark-gray {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #767676 !important; }
  .text--black {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #000000 !important; }
  .text--green {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #008827 !important; }
  .text--red {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #A3080F !important; }
  .text--yellow {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #FFCC00 !important; }
  .text--blue {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #007c9e !important; }
  .text--success {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #008827 !important; }
  .text--danger {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #A3080F !important; }
  .text--warning {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #FFCC00 !important; }
  .text--info {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    color: #007c9e !important; }
  @media (min-width: 769px) {
    .text--md--blue {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #007c9e !important; }
    .text--md--indigo {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #6610f2 !important; }
    .text--md--purple {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #6f42c1 !important; }
    .text--md--pink {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #e83e8c !important; }
    .text--md--red {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #A3080F !important; }
    .text--md--orange {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #fd7e14 !important; }
    .text--md--yellow {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #FFCC00 !important; }
    .text--md--green {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #008827 !important; }
    .text--md--teal {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #20c997 !important; }
    .text--md--cyan {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #17a2b8 !important; }
    .text--md--white {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #FFFFFF !important; }
    .text--md--gray {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #6c757d !important; }
    .text--md--gray-dark {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #343a40 !important; }
    .text--md--white {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #FFFFFF !important; }
    .text--md--off-white {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #F2F2F2 !important; }
    .text--md--light-gray {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #C5C5C5 !important; }
    .text--md--gray {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #BEBEBE !important; }
    .text--md--dark-gray {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #767676 !important; }
    .text--md--black {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #000000 !important; }
    .text--md--green {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #008827 !important; }
    .text--md--red {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #A3080F !important; }
    .text--md--yellow {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #FFCC00 !important; }
    .text--md--blue {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #007c9e !important; }
    .text--md--success {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #008827 !important; }
    .text--md--danger {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #A3080F !important; }
    .text--md--warning {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #FFCC00 !important; }
    .text--md--info {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #007c9e !important; } }
  @media (min-width: 1024px) {
    .text--lg--blue {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #007c9e !important; }
    .text--lg--indigo {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #6610f2 !important; }
    .text--lg--purple {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #6f42c1 !important; }
    .text--lg--pink {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #e83e8c !important; }
    .text--lg--red {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #A3080F !important; }
    .text--lg--orange {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #fd7e14 !important; }
    .text--lg--yellow {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #FFCC00 !important; }
    .text--lg--green {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #008827 !important; }
    .text--lg--teal {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #20c997 !important; }
    .text--lg--cyan {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #17a2b8 !important; }
    .text--lg--white {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #FFFFFF !important; }
    .text--lg--gray {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #6c757d !important; }
    .text--lg--gray-dark {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #343a40 !important; }
    .text--lg--white {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #FFFFFF !important; }
    .text--lg--off-white {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #F2F2F2 !important; }
    .text--lg--light-gray {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #C5C5C5 !important; }
    .text--lg--gray {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #BEBEBE !important; }
    .text--lg--dark-gray {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #767676 !important; }
    .text--lg--black {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #000000 !important; }
    .text--lg--green {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #008827 !important; }
    .text--lg--red {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #A3080F !important; }
    .text--lg--yellow {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #FFCC00 !important; }
    .text--lg--blue {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #007c9e !important; }
    .text--lg--success {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #008827 !important; }
    .text--lg--danger {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #A3080F !important; }
    .text--lg--warning {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #FFCC00 !important; }
    .text--lg--info {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      color: #007c9e !important; } }
  .text--brand-primary {
    color: var(--brand-primary) !important; }
  .text--brand-secondary {
    color: var(--brand-secondary) !important; }
  .text--brand-tertiary {
    color: var(--brand-tertiary) !important; }

.background {
  /* AB CORE MODFICIATION - add important flag to ensure they have highest priority */
  /* END of AB CORE MODFICIATION */ }
  .background--blue {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #007c9e !important; }
  .background--indigo {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #6610f2 !important; }
  .background--purple {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #6f42c1 !important; }
  .background--pink {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #e83e8c !important; }
  .background--red {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #A3080F !important; }
  .background--orange {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #fd7e14 !important; }
  .background--yellow {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #FFCC00 !important; }
  .background--green {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #008827 !important; }
  .background--teal {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #20c997 !important; }
  .background--cyan {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #17a2b8 !important; }
  .background--white {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #FFFFFF !important; }
  .background--gray {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #6c757d !important; }
  .background--gray-dark {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #343a40 !important; }
  .background--white {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #FFFFFF !important; }
  .background--off-white {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #F2F2F2 !important; }
  .background--light-gray {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #C5C5C5 !important; }
  .background--gray {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #BEBEBE !important; }
  .background--dark-gray {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #767676 !important; }
  .background--black {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #000000 !important; }
  .background--green {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #008827 !important; }
  .background--red {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #A3080F !important; }
  .background--yellow {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #FFCC00 !important; }
  .background--blue {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #007c9e !important; }
  .background--success {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #008827 !important; }
  .background--danger {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #A3080F !important; }
  .background--warning {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #FFCC00 !important; }
  .background--info {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    background-color: #007c9e !important; }
  @media (min-width: 769px) {
    .background--md--blue {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #007c9e !important; }
    .background--md--indigo {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #6610f2 !important; }
    .background--md--purple {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #6f42c1 !important; }
    .background--md--pink {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #e83e8c !important; }
    .background--md--red {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #A3080F !important; }
    .background--md--orange {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #fd7e14 !important; }
    .background--md--yellow {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #FFCC00 !important; }
    .background--md--green {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #008827 !important; }
    .background--md--teal {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #20c997 !important; }
    .background--md--cyan {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #17a2b8 !important; }
    .background--md--white {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #FFFFFF !important; }
    .background--md--gray {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #6c757d !important; }
    .background--md--gray-dark {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #343a40 !important; }
    .background--md--white {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #FFFFFF !important; }
    .background--md--off-white {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #F2F2F2 !important; }
    .background--md--light-gray {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #C5C5C5 !important; }
    .background--md--gray {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #BEBEBE !important; }
    .background--md--dark-gray {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #767676 !important; }
    .background--md--black {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #000000 !important; }
    .background--md--green {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #008827 !important; }
    .background--md--red {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #A3080F !important; }
    .background--md--yellow {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #FFCC00 !important; }
    .background--md--blue {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #007c9e !important; }
    .background--md--success {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #008827 !important; }
    .background--md--danger {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #A3080F !important; }
    .background--md--warning {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #FFCC00 !important; }
    .background--md--info {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #007c9e !important; } }
  @media (min-width: 1024px) {
    .background--lg--blue {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #007c9e !important; }
    .background--lg--indigo {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #6610f2 !important; }
    .background--lg--purple {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #6f42c1 !important; }
    .background--lg--pink {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #e83e8c !important; }
    .background--lg--red {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #A3080F !important; }
    .background--lg--orange {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #fd7e14 !important; }
    .background--lg--yellow {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #FFCC00 !important; }
    .background--lg--green {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #008827 !important; }
    .background--lg--teal {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #20c997 !important; }
    .background--lg--cyan {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #17a2b8 !important; }
    .background--lg--white {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #FFFFFF !important; }
    .background--lg--gray {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #6c757d !important; }
    .background--lg--gray-dark {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #343a40 !important; }
    .background--lg--white {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #FFFFFF !important; }
    .background--lg--off-white {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #F2F2F2 !important; }
    .background--lg--light-gray {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #C5C5C5 !important; }
    .background--lg--gray {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #BEBEBE !important; }
    .background--lg--dark-gray {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #767676 !important; }
    .background--lg--black {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #000000 !important; }
    .background--lg--green {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #008827 !important; }
    .background--lg--red {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #A3080F !important; }
    .background--lg--yellow {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #FFCC00 !important; }
    .background--lg--blue {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #007c9e !important; }
    .background--lg--success {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #008827 !important; }
    .background--lg--danger {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #A3080F !important; }
    .background--lg--warning {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #FFCC00 !important; }
    .background--lg--info {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      background-color: #007c9e !important; } }
  .background--brand-primary {
    background-color: var(--brand-primary) !important; }
  .background--brand-secondary {
    background-color: var(--brand-secondary) !important; }
  .background--brand-tertiary {
    background-color: var(--brand-tertiary) !important; }

.border {
  /* AB CORE MODFICIATION - add important flag to ensure they have highest priority */
  /* END of AB CORE MODFICIATION */ }
  .border--blue {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #007c9e !important; }
  .border--indigo {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #6610f2 !important; }
  .border--purple {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #6f42c1 !important; }
  .border--pink {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #e83e8c !important; }
  .border--red {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #A3080F !important; }
  .border--orange {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #fd7e14 !important; }
  .border--yellow {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #FFCC00 !important; }
  .border--green {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #008827 !important; }
  .border--teal {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #20c997 !important; }
  .border--cyan {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #17a2b8 !important; }
  .border--white {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #FFFFFF !important; }
  .border--gray {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #6c757d !important; }
  .border--gray-dark {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #343a40 !important; }
  .border--white {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #FFFFFF !important; }
  .border--off-white {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #F2F2F2 !important; }
  .border--light-gray {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #C5C5C5 !important; }
  .border--gray {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #BEBEBE !important; }
  .border--dark-gray {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #767676 !important; }
  .border--black {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #000000 !important; }
  .border--green {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #008827 !important; }
  .border--red {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #A3080F !important; }
  .border--yellow {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #FFCC00 !important; }
  .border--blue {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #007c9e !important; }
  .border--success {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #008827 !important; }
  .border--danger {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #A3080F !important; }
  .border--warning {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #FFCC00 !important; }
  .border--info {
    /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
    border-color: #007c9e !important; }
  @media (min-width: 769px) {
    .border--md--blue {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #007c9e !important; }
    .border--md--indigo {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #6610f2 !important; }
    .border--md--purple {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #6f42c1 !important; }
    .border--md--pink {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #e83e8c !important; }
    .border--md--red {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #A3080F !important; }
    .border--md--orange {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #fd7e14 !important; }
    .border--md--yellow {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #FFCC00 !important; }
    .border--md--green {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #008827 !important; }
    .border--md--teal {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #20c997 !important; }
    .border--md--cyan {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #17a2b8 !important; }
    .border--md--white {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #FFFFFF !important; }
    .border--md--gray {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #6c757d !important; }
    .border--md--gray-dark {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #343a40 !important; }
    .border--md--white {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #FFFFFF !important; }
    .border--md--off-white {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #F2F2F2 !important; }
    .border--md--light-gray {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #C5C5C5 !important; }
    .border--md--gray {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #BEBEBE !important; }
    .border--md--dark-gray {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #767676 !important; }
    .border--md--black {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #000000 !important; }
    .border--md--green {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #008827 !important; }
    .border--md--red {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #A3080F !important; }
    .border--md--yellow {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #FFCC00 !important; }
    .border--md--blue {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #007c9e !important; }
    .border--md--success {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #008827 !important; }
    .border--md--danger {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #A3080F !important; }
    .border--md--warning {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #FFCC00 !important; }
    .border--md--info {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #007c9e !important; } }
  @media (min-width: 1024px) {
    .border--lg--blue {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #007c9e !important; }
    .border--lg--indigo {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #6610f2 !important; }
    .border--lg--purple {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #6f42c1 !important; }
    .border--lg--pink {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #e83e8c !important; }
    .border--lg--red {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #A3080F !important; }
    .border--lg--orange {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #fd7e14 !important; }
    .border--lg--yellow {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #FFCC00 !important; }
    .border--lg--green {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #008827 !important; }
    .border--lg--teal {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #20c997 !important; }
    .border--lg--cyan {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #17a2b8 !important; }
    .border--lg--white {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #FFFFFF !important; }
    .border--lg--gray {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #6c757d !important; }
    .border--lg--gray-dark {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #343a40 !important; }
    .border--lg--white {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #FFFFFF !important; }
    .border--lg--off-white {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #F2F2F2 !important; }
    .border--lg--light-gray {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #C5C5C5 !important; }
    .border--lg--gray {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #BEBEBE !important; }
    .border--lg--dark-gray {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #767676 !important; }
    .border--lg--black {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #000000 !important; }
    .border--lg--green {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #008827 !important; }
    .border--lg--red {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #A3080F !important; }
    .border--lg--yellow {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #FFCC00 !important; }
    .border--lg--blue {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #007c9e !important; }
    .border--lg--success {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #008827 !important; }
    .border--lg--danger {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #A3080F !important; }
    .border--lg--warning {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #FFCC00 !important; }
    .border--lg--info {
      /* AB CORE MODFICIATION - add optional important flag to ensure they have highest priority */
      border-color: #007c9e !important; } }
  .border--brand-primary {
    border-color: var(--brand-primary) !important; }
  .border--brand-secondary {
    border-color: var(--brand-secondary) !important; }
  .border--brand-tertiary {
    border-color: var(--brand-tertiary) !important; }

.font-size--0 {
  font-size: 0px !important; }

@media (min-width: 769px) {
  .font-size--md--0 {
    font-size: 0px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--0 {
    font-size: 0px !important; } }

.font-size--1 {
  font-size: 1px !important; }

@media (min-width: 769px) {
  .font-size--md--1 {
    font-size: 1px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--1 {
    font-size: 1px !important; } }

.font-size--2 {
  font-size: 2px !important; }

@media (min-width: 769px) {
  .font-size--md--2 {
    font-size: 2px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--2 {
    font-size: 2px !important; } }

.font-size--3 {
  font-size: 3px !important; }

@media (min-width: 769px) {
  .font-size--md--3 {
    font-size: 3px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--3 {
    font-size: 3px !important; } }

.font-size--4 {
  font-size: 4px !important; }

@media (min-width: 769px) {
  .font-size--md--4 {
    font-size: 4px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--4 {
    font-size: 4px !important; } }

.font-size--5 {
  font-size: 5px !important; }

@media (min-width: 769px) {
  .font-size--md--5 {
    font-size: 5px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--5 {
    font-size: 5px !important; } }

.font-size--6 {
  font-size: 6px !important; }

@media (min-width: 769px) {
  .font-size--md--6 {
    font-size: 6px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--6 {
    font-size: 6px !important; } }

.font-size--7 {
  font-size: 7px !important; }

@media (min-width: 769px) {
  .font-size--md--7 {
    font-size: 7px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--7 {
    font-size: 7px !important; } }

.font-size--8 {
  font-size: 8px !important; }

@media (min-width: 769px) {
  .font-size--md--8 {
    font-size: 8px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--8 {
    font-size: 8px !important; } }

.font-size--9 {
  font-size: 9px !important; }

@media (min-width: 769px) {
  .font-size--md--9 {
    font-size: 9px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--9 {
    font-size: 9px !important; } }

.font-size--10 {
  font-size: 10px !important; }

@media (min-width: 769px) {
  .font-size--md--10 {
    font-size: 10px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--10 {
    font-size: 10px !important; } }

.font-size--11 {
  font-size: 11px !important; }

@media (min-width: 769px) {
  .font-size--md--11 {
    font-size: 11px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--11 {
    font-size: 11px !important; } }

.font-size--12 {
  font-size: 12px !important; }

@media (min-width: 769px) {
  .font-size--md--12 {
    font-size: 12px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--12 {
    font-size: 12px !important; } }

.font-size--13 {
  font-size: 13px !important; }

@media (min-width: 769px) {
  .font-size--md--13 {
    font-size: 13px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--13 {
    font-size: 13px !important; } }

.font-size--14 {
  font-size: 14px !important; }

@media (min-width: 769px) {
  .font-size--md--14 {
    font-size: 14px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--14 {
    font-size: 14px !important; } }

.font-size--15 {
  font-size: 15px !important; }

@media (min-width: 769px) {
  .font-size--md--15 {
    font-size: 15px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--15 {
    font-size: 15px !important; } }

.font-size--16 {
  font-size: 16px !important; }

@media (min-width: 769px) {
  .font-size--md--16 {
    font-size: 16px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--16 {
    font-size: 16px !important; } }

.font-size--17 {
  font-size: 17px !important; }

@media (min-width: 769px) {
  .font-size--md--17 {
    font-size: 17px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--17 {
    font-size: 17px !important; } }

.font-size--18 {
  font-size: 18px !important; }

@media (min-width: 769px) {
  .font-size--md--18 {
    font-size: 18px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--18 {
    font-size: 18px !important; } }

.font-size--19 {
  font-size: 19px !important; }

@media (min-width: 769px) {
  .font-size--md--19 {
    font-size: 19px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--19 {
    font-size: 19px !important; } }

.font-size--20 {
  font-size: 20px !important; }

@media (min-width: 769px) {
  .font-size--md--20 {
    font-size: 20px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--20 {
    font-size: 20px !important; } }

.font-size--21 {
  font-size: 21px !important; }

@media (min-width: 769px) {
  .font-size--md--21 {
    font-size: 21px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--21 {
    font-size: 21px !important; } }

.font-size--22 {
  font-size: 22px !important; }

@media (min-width: 769px) {
  .font-size--md--22 {
    font-size: 22px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--22 {
    font-size: 22px !important; } }

.font-size--23 {
  font-size: 23px !important; }

@media (min-width: 769px) {
  .font-size--md--23 {
    font-size: 23px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--23 {
    font-size: 23px !important; } }

.font-size--24 {
  font-size: 24px !important; }

@media (min-width: 769px) {
  .font-size--md--24 {
    font-size: 24px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--24 {
    font-size: 24px !important; } }

.font-size--25 {
  font-size: 25px !important; }

@media (min-width: 769px) {
  .font-size--md--25 {
    font-size: 25px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--25 {
    font-size: 25px !important; } }

.font-size--26 {
  font-size: 26px !important; }

@media (min-width: 769px) {
  .font-size--md--26 {
    font-size: 26px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--26 {
    font-size: 26px !important; } }

.font-size--27 {
  font-size: 27px !important; }

@media (min-width: 769px) {
  .font-size--md--27 {
    font-size: 27px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--27 {
    font-size: 27px !important; } }

.font-size--28 {
  font-size: 28px !important; }

@media (min-width: 769px) {
  .font-size--md--28 {
    font-size: 28px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--28 {
    font-size: 28px !important; } }

.font-size--29 {
  font-size: 29px !important; }

@media (min-width: 769px) {
  .font-size--md--29 {
    font-size: 29px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--29 {
    font-size: 29px !important; } }

.font-size--30 {
  font-size: 30px !important; }

@media (min-width: 769px) {
  .font-size--md--30 {
    font-size: 30px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--30 {
    font-size: 30px !important; } }

.font-size--31 {
  font-size: 31px !important; }

@media (min-width: 769px) {
  .font-size--md--31 {
    font-size: 31px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--31 {
    font-size: 31px !important; } }

.font-size--32 {
  font-size: 32px !important; }

@media (min-width: 769px) {
  .font-size--md--32 {
    font-size: 32px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--32 {
    font-size: 32px !important; } }

.font-size--33 {
  font-size: 33px !important; }

@media (min-width: 769px) {
  .font-size--md--33 {
    font-size: 33px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--33 {
    font-size: 33px !important; } }

.font-size--34 {
  font-size: 34px !important; }

@media (min-width: 769px) {
  .font-size--md--34 {
    font-size: 34px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--34 {
    font-size: 34px !important; } }

.font-size--35 {
  font-size: 35px !important; }

@media (min-width: 769px) {
  .font-size--md--35 {
    font-size: 35px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--35 {
    font-size: 35px !important; } }

.font-size--36 {
  font-size: 36px !important; }

@media (min-width: 769px) {
  .font-size--md--36 {
    font-size: 36px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--36 {
    font-size: 36px !important; } }

.font-size--37 {
  font-size: 37px !important; }

@media (min-width: 769px) {
  .font-size--md--37 {
    font-size: 37px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--37 {
    font-size: 37px !important; } }

.font-size--38 {
  font-size: 38px !important; }

@media (min-width: 769px) {
  .font-size--md--38 {
    font-size: 38px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--38 {
    font-size: 38px !important; } }

.font-size--39 {
  font-size: 39px !important; }

@media (min-width: 769px) {
  .font-size--md--39 {
    font-size: 39px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--39 {
    font-size: 39px !important; } }

.font-size--40 {
  font-size: 40px !important; }

@media (min-width: 769px) {
  .font-size--md--40 {
    font-size: 40px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--40 {
    font-size: 40px !important; } }

.font-size--41 {
  font-size: 41px !important; }

@media (min-width: 769px) {
  .font-size--md--41 {
    font-size: 41px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--41 {
    font-size: 41px !important; } }

.font-size--42 {
  font-size: 42px !important; }

@media (min-width: 769px) {
  .font-size--md--42 {
    font-size: 42px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--42 {
    font-size: 42px !important; } }

.font-size--43 {
  font-size: 43px !important; }

@media (min-width: 769px) {
  .font-size--md--43 {
    font-size: 43px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--43 {
    font-size: 43px !important; } }

.font-size--44 {
  font-size: 44px !important; }

@media (min-width: 769px) {
  .font-size--md--44 {
    font-size: 44px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--44 {
    font-size: 44px !important; } }

.font-size--45 {
  font-size: 45px !important; }

@media (min-width: 769px) {
  .font-size--md--45 {
    font-size: 45px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--45 {
    font-size: 45px !important; } }

.font-size--46 {
  font-size: 46px !important; }

@media (min-width: 769px) {
  .font-size--md--46 {
    font-size: 46px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--46 {
    font-size: 46px !important; } }

.font-size--47 {
  font-size: 47px !important; }

@media (min-width: 769px) {
  .font-size--md--47 {
    font-size: 47px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--47 {
    font-size: 47px !important; } }

.font-size--48 {
  font-size: 48px !important; }

@media (min-width: 769px) {
  .font-size--md--48 {
    font-size: 48px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--48 {
    font-size: 48px !important; } }

.font-size--49 {
  font-size: 49px !important; }

@media (min-width: 769px) {
  .font-size--md--49 {
    font-size: 49px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--49 {
    font-size: 49px !important; } }

.font-size--50 {
  font-size: 50px !important; }

@media (min-width: 769px) {
  .font-size--md--50 {
    font-size: 50px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--50 {
    font-size: 50px !important; } }

.font-size--51 {
  font-size: 51px !important; }

@media (min-width: 769px) {
  .font-size--md--51 {
    font-size: 51px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--51 {
    font-size: 51px !important; } }

.font-size--52 {
  font-size: 52px !important; }

@media (min-width: 769px) {
  .font-size--md--52 {
    font-size: 52px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--52 {
    font-size: 52px !important; } }

.font-size--53 {
  font-size: 53px !important; }

@media (min-width: 769px) {
  .font-size--md--53 {
    font-size: 53px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--53 {
    font-size: 53px !important; } }

.font-size--54 {
  font-size: 54px !important; }

@media (min-width: 769px) {
  .font-size--md--54 {
    font-size: 54px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--54 {
    font-size: 54px !important; } }

.font-size--55 {
  font-size: 55px !important; }

@media (min-width: 769px) {
  .font-size--md--55 {
    font-size: 55px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--55 {
    font-size: 55px !important; } }

.font-size--56 {
  font-size: 56px !important; }

@media (min-width: 769px) {
  .font-size--md--56 {
    font-size: 56px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--56 {
    font-size: 56px !important; } }

.font-size--57 {
  font-size: 57px !important; }

@media (min-width: 769px) {
  .font-size--md--57 {
    font-size: 57px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--57 {
    font-size: 57px !important; } }

.font-size--58 {
  font-size: 58px !important; }

@media (min-width: 769px) {
  .font-size--md--58 {
    font-size: 58px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--58 {
    font-size: 58px !important; } }

.font-size--59 {
  font-size: 59px !important; }

@media (min-width: 769px) {
  .font-size--md--59 {
    font-size: 59px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--59 {
    font-size: 59px !important; } }

.font-size--60 {
  font-size: 60px !important; }

@media (min-width: 769px) {
  .font-size--md--60 {
    font-size: 60px !important; } }

@media (min-width: 1024px) {
  .font-size--lg--60 {
    font-size: 60px !important; } }

.line-height--0 {
  line-height: 0px !important; }

@media (min-width: 769px) {
  .line-height--md--0 {
    line-height: 0px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--0 {
    line-height: 0px !important; } }

.line-height--1 {
  line-height: 1px !important; }

@media (min-width: 769px) {
  .line-height--md--1 {
    line-height: 1px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--1 {
    line-height: 1px !important; } }

.line-height--2 {
  line-height: 2px !important; }

@media (min-width: 769px) {
  .line-height--md--2 {
    line-height: 2px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--2 {
    line-height: 2px !important; } }

.line-height--3 {
  line-height: 3px !important; }

@media (min-width: 769px) {
  .line-height--md--3 {
    line-height: 3px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--3 {
    line-height: 3px !important; } }

.line-height--4 {
  line-height: 4px !important; }

@media (min-width: 769px) {
  .line-height--md--4 {
    line-height: 4px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--4 {
    line-height: 4px !important; } }

.line-height--5 {
  line-height: 5px !important; }

@media (min-width: 769px) {
  .line-height--md--5 {
    line-height: 5px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--5 {
    line-height: 5px !important; } }

.line-height--6 {
  line-height: 6px !important; }

@media (min-width: 769px) {
  .line-height--md--6 {
    line-height: 6px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--6 {
    line-height: 6px !important; } }

.line-height--7 {
  line-height: 7px !important; }

@media (min-width: 769px) {
  .line-height--md--7 {
    line-height: 7px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--7 {
    line-height: 7px !important; } }

.line-height--8 {
  line-height: 8px !important; }

@media (min-width: 769px) {
  .line-height--md--8 {
    line-height: 8px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--8 {
    line-height: 8px !important; } }

.line-height--9 {
  line-height: 9px !important; }

@media (min-width: 769px) {
  .line-height--md--9 {
    line-height: 9px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--9 {
    line-height: 9px !important; } }

.line-height--10 {
  line-height: 10px !important; }

@media (min-width: 769px) {
  .line-height--md--10 {
    line-height: 10px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--10 {
    line-height: 10px !important; } }

.line-height--11 {
  line-height: 11px !important; }

@media (min-width: 769px) {
  .line-height--md--11 {
    line-height: 11px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--11 {
    line-height: 11px !important; } }

.line-height--12 {
  line-height: 12px !important; }

@media (min-width: 769px) {
  .line-height--md--12 {
    line-height: 12px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--12 {
    line-height: 12px !important; } }

.line-height--13 {
  line-height: 13px !important; }

@media (min-width: 769px) {
  .line-height--md--13 {
    line-height: 13px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--13 {
    line-height: 13px !important; } }

.line-height--14 {
  line-height: 14px !important; }

@media (min-width: 769px) {
  .line-height--md--14 {
    line-height: 14px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--14 {
    line-height: 14px !important; } }

.line-height--15 {
  line-height: 15px !important; }

@media (min-width: 769px) {
  .line-height--md--15 {
    line-height: 15px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--15 {
    line-height: 15px !important; } }

.line-height--16 {
  line-height: 16px !important; }

@media (min-width: 769px) {
  .line-height--md--16 {
    line-height: 16px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--16 {
    line-height: 16px !important; } }

.line-height--17 {
  line-height: 17px !important; }

@media (min-width: 769px) {
  .line-height--md--17 {
    line-height: 17px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--17 {
    line-height: 17px !important; } }

.line-height--18 {
  line-height: 18px !important; }

@media (min-width: 769px) {
  .line-height--md--18 {
    line-height: 18px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--18 {
    line-height: 18px !important; } }

.line-height--19 {
  line-height: 19px !important; }

@media (min-width: 769px) {
  .line-height--md--19 {
    line-height: 19px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--19 {
    line-height: 19px !important; } }

.line-height--20 {
  line-height: 20px !important; }

@media (min-width: 769px) {
  .line-height--md--20 {
    line-height: 20px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--20 {
    line-height: 20px !important; } }

.line-height--21 {
  line-height: 21px !important; }

@media (min-width: 769px) {
  .line-height--md--21 {
    line-height: 21px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--21 {
    line-height: 21px !important; } }

.line-height--22 {
  line-height: 22px !important; }

@media (min-width: 769px) {
  .line-height--md--22 {
    line-height: 22px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--22 {
    line-height: 22px !important; } }

.line-height--23 {
  line-height: 23px !important; }

@media (min-width: 769px) {
  .line-height--md--23 {
    line-height: 23px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--23 {
    line-height: 23px !important; } }

.line-height--24 {
  line-height: 24px !important; }

@media (min-width: 769px) {
  .line-height--md--24 {
    line-height: 24px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--24 {
    line-height: 24px !important; } }

.line-height--25 {
  line-height: 25px !important; }

@media (min-width: 769px) {
  .line-height--md--25 {
    line-height: 25px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--25 {
    line-height: 25px !important; } }

.line-height--26 {
  line-height: 26px !important; }

@media (min-width: 769px) {
  .line-height--md--26 {
    line-height: 26px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--26 {
    line-height: 26px !important; } }

.line-height--27 {
  line-height: 27px !important; }

@media (min-width: 769px) {
  .line-height--md--27 {
    line-height: 27px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--27 {
    line-height: 27px !important; } }

.line-height--28 {
  line-height: 28px !important; }

@media (min-width: 769px) {
  .line-height--md--28 {
    line-height: 28px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--28 {
    line-height: 28px !important; } }

.line-height--29 {
  line-height: 29px !important; }

@media (min-width: 769px) {
  .line-height--md--29 {
    line-height: 29px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--29 {
    line-height: 29px !important; } }

.line-height--30 {
  line-height: 30px !important; }

@media (min-width: 769px) {
  .line-height--md--30 {
    line-height: 30px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--30 {
    line-height: 30px !important; } }

.line-height--31 {
  line-height: 31px !important; }

@media (min-width: 769px) {
  .line-height--md--31 {
    line-height: 31px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--31 {
    line-height: 31px !important; } }

.line-height--32 {
  line-height: 32px !important; }

@media (min-width: 769px) {
  .line-height--md--32 {
    line-height: 32px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--32 {
    line-height: 32px !important; } }

.line-height--33 {
  line-height: 33px !important; }

@media (min-width: 769px) {
  .line-height--md--33 {
    line-height: 33px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--33 {
    line-height: 33px !important; } }

.line-height--34 {
  line-height: 34px !important; }

@media (min-width: 769px) {
  .line-height--md--34 {
    line-height: 34px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--34 {
    line-height: 34px !important; } }

.line-height--35 {
  line-height: 35px !important; }

@media (min-width: 769px) {
  .line-height--md--35 {
    line-height: 35px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--35 {
    line-height: 35px !important; } }

.line-height--36 {
  line-height: 36px !important; }

@media (min-width: 769px) {
  .line-height--md--36 {
    line-height: 36px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--36 {
    line-height: 36px !important; } }

.line-height--37 {
  line-height: 37px !important; }

@media (min-width: 769px) {
  .line-height--md--37 {
    line-height: 37px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--37 {
    line-height: 37px !important; } }

.line-height--38 {
  line-height: 38px !important; }

@media (min-width: 769px) {
  .line-height--md--38 {
    line-height: 38px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--38 {
    line-height: 38px !important; } }

.line-height--39 {
  line-height: 39px !important; }

@media (min-width: 769px) {
  .line-height--md--39 {
    line-height: 39px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--39 {
    line-height: 39px !important; } }

.line-height--40 {
  line-height: 40px !important; }

@media (min-width: 769px) {
  .line-height--md--40 {
    line-height: 40px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--40 {
    line-height: 40px !important; } }

.line-height--41 {
  line-height: 41px !important; }

@media (min-width: 769px) {
  .line-height--md--41 {
    line-height: 41px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--41 {
    line-height: 41px !important; } }

.line-height--42 {
  line-height: 42px !important; }

@media (min-width: 769px) {
  .line-height--md--42 {
    line-height: 42px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--42 {
    line-height: 42px !important; } }

.line-height--43 {
  line-height: 43px !important; }

@media (min-width: 769px) {
  .line-height--md--43 {
    line-height: 43px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--43 {
    line-height: 43px !important; } }

.line-height--44 {
  line-height: 44px !important; }

@media (min-width: 769px) {
  .line-height--md--44 {
    line-height: 44px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--44 {
    line-height: 44px !important; } }

.line-height--45 {
  line-height: 45px !important; }

@media (min-width: 769px) {
  .line-height--md--45 {
    line-height: 45px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--45 {
    line-height: 45px !important; } }

.line-height--46 {
  line-height: 46px !important; }

@media (min-width: 769px) {
  .line-height--md--46 {
    line-height: 46px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--46 {
    line-height: 46px !important; } }

.line-height--47 {
  line-height: 47px !important; }

@media (min-width: 769px) {
  .line-height--md--47 {
    line-height: 47px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--47 {
    line-height: 47px !important; } }

.line-height--48 {
  line-height: 48px !important; }

@media (min-width: 769px) {
  .line-height--md--48 {
    line-height: 48px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--48 {
    line-height: 48px !important; } }

.line-height--49 {
  line-height: 49px !important; }

@media (min-width: 769px) {
  .line-height--md--49 {
    line-height: 49px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--49 {
    line-height: 49px !important; } }

.line-height--50 {
  line-height: 50px !important; }

@media (min-width: 769px) {
  .line-height--md--50 {
    line-height: 50px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--50 {
    line-height: 50px !important; } }

.line-height--51 {
  line-height: 51px !important; }

@media (min-width: 769px) {
  .line-height--md--51 {
    line-height: 51px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--51 {
    line-height: 51px !important; } }

.line-height--52 {
  line-height: 52px !important; }

@media (min-width: 769px) {
  .line-height--md--52 {
    line-height: 52px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--52 {
    line-height: 52px !important; } }

.line-height--53 {
  line-height: 53px !important; }

@media (min-width: 769px) {
  .line-height--md--53 {
    line-height: 53px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--53 {
    line-height: 53px !important; } }

.line-height--54 {
  line-height: 54px !important; }

@media (min-width: 769px) {
  .line-height--md--54 {
    line-height: 54px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--54 {
    line-height: 54px !important; } }

.line-height--55 {
  line-height: 55px !important; }

@media (min-width: 769px) {
  .line-height--md--55 {
    line-height: 55px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--55 {
    line-height: 55px !important; } }

.line-height--56 {
  line-height: 56px !important; }

@media (min-width: 769px) {
  .line-height--md--56 {
    line-height: 56px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--56 {
    line-height: 56px !important; } }

.line-height--57 {
  line-height: 57px !important; }

@media (min-width: 769px) {
  .line-height--md--57 {
    line-height: 57px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--57 {
    line-height: 57px !important; } }

.line-height--58 {
  line-height: 58px !important; }

@media (min-width: 769px) {
  .line-height--md--58 {
    line-height: 58px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--58 {
    line-height: 58px !important; } }

.line-height--59 {
  line-height: 59px !important; }

@media (min-width: 769px) {
  .line-height--md--59 {
    line-height: 59px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--59 {
    line-height: 59px !important; } }

.line-height--60 {
  line-height: 60px !important; }

@media (min-width: 769px) {
  .line-height--md--60 {
    line-height: 60px !important; } }

@media (min-width: 1024px) {
  .line-height--lg--60 {
    line-height: 60px !important; } }

.font--primary {
  /* AB CORE MODFICIATION - add important flag to ensure they have highest priority */
  font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif !important; }

.font--secondary {
  /* AB CORE MODFICIATION - add important flag to ensure they have highest priority */
  font-family: var(--secondary-font), helvetica, arial, sans-serif !important; }

.font--tertiary {
  /* AB CORE MODFICIATION - add important flag to ensure they have highest priority */
  font-family: var(--tertiary-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif), helvetica, arial, sans-serif !important; }

.font--header {
  /* AB CORE MODFICIATION - add important flag to ensure they have highest priority */
  font-family: var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif) !important; }

.font--body {
  /* AB CORE MODFICIATION - add important flag to ensure they have highest priority */
  font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif !important; }

h1, .h1 {
  font-family: var(--h1-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
  font-size: var(--h1-mobile-size);
  line-height: 1.14;
  font-weight: 700;
  letter-spacing: 0.01em;
  text-transform: none;
  font-style: normal; }
  @media (min-width: 769px) {
    h1, .h1 {
      font-size: var(--h1-tablet-size);
      line-height: 1.14;
      font-weight: 700;
      letter-spacing: 0.01em;
      text-transform: none;
      font-style: normal; } }
  @media (min-width: 1024px) {
    h1, .h1 {
      font-size: var(--h1-desktop-size);
      line-height: 1.11;
      font-weight: 700;
      letter-spacing: 0.01em;
      text-transform: none;
      font-style: normal; } }

h2, .h2 {
  font-family: var(--h2-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
  font-size: var(--h2-mobile-size);
  line-height: 1.14;
  font-weight: 700;
  letter-spacing: 0.01em;
  text-transform: none;
  font-style: normal; }
  @media (min-width: 769px) {
    h2, .h2 {
      font-size: var(--h2-tablet-size);
      line-height: 1.17;
      font-weight: 700;
      letter-spacing: 0.01em;
      text-transform: none;
      font-style: normal; } }
  @media (min-width: 1024px) {
    h2, .h2 {
      font-size: var(--h2-desktop-size);
      line-height: 1.15;
      font-weight: 700;
      letter-spacing: 0.01em;
      text-transform: none;
      font-style: normal; } }

h3, .h3 {
  font-family: var(--h3-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
  font-size: var(--h3-mobile-size);
  line-height: 1.2;
  font-weight: 400;
  letter-spacing: 0.01em;
  text-transform: none;
  font-style: normal; }
  @media (min-width: 769px) {
    h3, .h3 {
      font-size: var(--h3-tablet-size);
      line-height: 1.2;
      font-weight: 400;
      letter-spacing: 0.01em;
      text-transform: none;
      font-style: normal; } }
  @media (min-width: 1024px) {
    h3, .h3 {
      font-size: var(--h3-desktop-size);
      line-height: 1.17;
      font-weight: 400;
      letter-spacing: 0.01em;
      text-transform: none;
      font-style: normal; } }

h4, .h4 {
  font-family: var(--h4-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
  font-size: var(--h4-mobile-size);
  line-height: 1.25;
  font-weight: 400;
  letter-spacing: 0.01em;
  text-transform: none;
  font-style: normal; }
  @media (min-width: 769px) {
    h4, .h4 {
      font-size: var(--h4-tablet-size);
      line-height: 1.25;
      font-weight: 400;
      letter-spacing: 0.01em;
      text-transform: none;
      font-style: normal; } }
  @media (min-width: 1024px) {
    h4, .h4 {
      font-size: var(--h4-desktop-size);
      line-height: 1.2;
      font-weight: 400;
      letter-spacing: 0.01em;
      text-transform: none;
      font-style: normal; } }

h5, .h5 {
  font-family: var(--h5-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
  font-size: var(--h5-mobile-size);
  line-height: 1.25;
  font-weight: 400;
  letter-spacing: 0.01em;
  text-transform: none;
  font-style: normal; }
  @media (min-width: 769px) {
    h5, .h5 {
      font-size: var(--h5-tablet-size);
      line-height: 1.25;
      font-weight: 400;
      letter-spacing: 0.01em;
      text-transform: none;
      font-style: normal; } }
  @media (min-width: 1024px) {
    h5, .h5 {
      font-size: var(--h5-desktop-size);
      line-height: 1.2;
      font-weight: 400;
      letter-spacing: 0.01em;
      text-transform: none;
      font-style: normal; } }

h6, .h6 {
  font-family: var(--h6-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
  font-size: var(--h6-mobile-size);
  line-height: 1.4;
  font-weight: normal;
  letter-spacing: 0.1em;
  text-transform: none;
  font-style: normal; }
  @media (min-width: 769px) {
    h6, .h6 {
      font-size: var(--h6-tablet-size);
      line-height: 1.4;
      font-weight: normal;
      letter-spacing: 0.1em;
      text-transform: none;
      font-style: normal; } }
  @media (min-width: 1024px) {
    h6, .h6 {
      font-size: var(--h6-desktop-size);
      line-height: 1.4;
      font-weight: normal;
      letter-spacing: 0.1em;
      text-transform: none;
      font-style: normal; } }

.b1 {
  font-family: var(--b1-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
  font-size: var(--b1-mobile-size);
  line-height: 1.19;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }
  @media (min-width: 769px) {
    .b1 {
      font-size: var(--b1-tablet-size);
      line-height: 1.19;
      font-weight: 700;
      letter-spacing: 0;
      text-transform: none;
      font-style: normal; } }
  @media (min-width: 1024px) {
    .b1 {
      font-size: var(--b1-desktop-size);
      line-height: 1.19;
      font-weight: 700;
      letter-spacing: 0;
      text-transform: none;
      font-style: normal; } }

.b2 {
  font-family: var(--b2-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
  font-size: var(--b2-mobile-size);
  line-height: 1.19;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }
  @media (min-width: 769px) {
    .b2 {
      font-size: var(--b2-tablet-size);
      line-height: 1.19;
      font-weight: 700;
      letter-spacing: 0;
      text-transform: none;
      font-style: normal; } }
  @media (min-width: 1024px) {
    .b2 {
      font-size: var(--b2-desktop-size);
      line-height: 1.19;
      font-weight: 700;
      letter-spacing: 0;
      text-transform: none;
      font-style: normal; } }

.b3 {
  font-family: var(--b3-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
  font-size: var(--b3-mobile-size);
  line-height: 1.19;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }
  @media (min-width: 769px) {
    .b3 {
      font-size: var(--b3-tablet-size);
      line-height: 1.19;
      font-weight: 700;
      letter-spacing: 0;
      text-transform: none;
      font-style: normal; } }
  @media (min-width: 1024px) {
    .b3 {
      font-size: var(--b3-desktop-size);
      line-height: 1.19;
      font-weight: 700;
      letter-spacing: 0;
      text-transform: none;
      font-style: normal; } }

.b4 {
  font-family: var(--b4-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
  font-size: var(--b4-mobile-size);
  line-height: 1.19;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }
  @media (min-width: 769px) {
    .b4 {
      font-size: var(--b4-tablet-size);
      line-height: 1.19;
      font-weight: 700;
      letter-spacing: 0;
      text-transform: none;
      font-style: normal; } }
  @media (min-width: 1024px) {
    .b4 {
      font-size: var(--b4-desktop-size);
      line-height: 1.19;
      font-weight: 700;
      letter-spacing: 0;
      text-transform: none;
      font-style: normal; } }

p,
.p {
  letter-spacing: 0.1em;
  font-size: calc(var(--base-font-size));
  margin-bottom: 16px;
  line-height: 1.4; }
  @media (min-width: 1024px) {
    p,
    .p {
      font-size: calc(var(--base-font-size)); } }
  @media (min-width: 1024px) {
    p,
    .p {
      line-height: 1.4; } }
  p--small,
  .p--small {
    letter-spacing: 0.1em;
    font-size: calc(var(--base-font-size) - 2px);
    line-height: 1.4; }
    @media (min-width: 1024px) {
      p--small,
      .p--small {
        font-size: calc(var(--base-font-size) - 1px); } }
    @media (min-width: 1024px) {
      p--small,
      .p--small {
        line-height: 1.4; } }
  p--large,
  .p--large {
    letter-spacing: 0.1em;
    font-size: calc(var(--base-font-size) + 1px); }
    @media (min-width: 1024px) {
      p--large,
      .p--large {
        font-size: calc(var(--base-font-size) + 2px); } }

small {
  letter-spacing: 0.1em;
  font-size: calc(var(--base-font-size) - 2px); }
  @media (min-width: 1024px) {
    small {
      font-size: calc(var(--base-font-size) - 1px); } }

.lead {
  letter-spacing: 0.1em;
  font-size: calc(var(--base-font-size) + 1px); }
  @media (min-width: 1024px) {
    .lead {
      font-size: calc(var(--base-font-size) + 2px); } }

.btn {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  border-width: 1px;
  border-style: solid;
  font-family: var(--button-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif);
  text-transform: var(--button-text-transform);
  transition: all 200ms ease-out;
  text-decoration: none;
  border-radius: var(--button-border-radius);
  text-align: center;
  font-weight: var(--button-font-weight);
  font-size: var(--button-font-size);
  letter-spacing: 0.1em;
  line-height: 18px;
  padding: var(--button-padding-y) var(--button-padding-x) var(--button-padding-y) var(--button-padding-x); }
  .btn[class*="icon-"], .btn[class^="icon-"] {
    padding-left: calc(var(--button-padding-x) * 2); }
    .btn[class*="icon-"]:before, .btn[class^="icon-"]:before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
  .btn-primary {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-width: 1px;
    border-style: solid;
    font-family: var(--button-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif);
    text-transform: var(--button-text-transform);
    transition: all 200ms ease-out;
    text-decoration: none;
    border-radius: var(--button-border-radius);
    text-align: center;
    font-weight: var(--button-font-weight);
    font-size: var(--button-font-size);
    letter-spacing: 0.1em;
    line-height: 18px;
    padding: var(--button-padding-y) var(--button-padding-x) var(--button-padding-y) var(--button-padding-x);
    font-family: var(--button-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif);
    text-transform: var(--button-text-transform);
    color: var(--button-primary-color);
    background-color: var(--button-primary-background);
    border-width: var(--button-primary-border-width);
    border-radius: var(--button-border-radius);
    border-color: var(--button-primary-border-color);
    text-decoration: none;
    text-align: center;
    font-weight: var(--button-font-weight);
    padding: var(--button-padding-y) var(--button-padding-x) var(--button-padding-y) var(--button-padding-x); }
    .btn-primary:hover, .btn-primary:focus, .btn-primary[aria-describedby*="popover"] {
      color: var(--button-primary-color-hover);
      background-color: var(--button-primary-background-hover);
      border-color: var(--button-primary-border-color-hover);
      text-decoration: none; }
    .dark-theme .btn-primary {
      color: var(--button-primary-color); }
      .dark-theme .btn-primary:hover {
        color: var(--button-primary-color-hover); }
    .btn-primary[class*="icon-"], .btn-primary[class^="icon-"] {
      padding-left: calc(var(--button-padding-x) * 2); }
      .btn-primary[class*="icon-"]:before, .btn-primary[class^="icon-"]:before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
  .btn-secondary, .btn-outline-primary {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-width: 1px;
    border-style: solid;
    font-family: var(--button-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif);
    text-transform: var(--button-text-transform);
    transition: all 200ms ease-out;
    text-decoration: none;
    border-radius: var(--button-border-radius);
    text-align: center;
    font-weight: var(--button-font-weight);
    font-size: var(--button-font-size);
    letter-spacing: 0.1em;
    line-height: 18px;
    padding: var(--button-padding-y) var(--button-padding-x) var(--button-padding-y) var(--button-padding-x);
    font-family: var(--button-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif);
    text-transform: var(--button-text-transform);
    color: var(--button-secondary-color);
    border-width: var(--button-secondary-border-width);
    border-radius: var(--button-border-radius);
    background-color: var(--button-secondary-background);
    border-color: var(--button-secondary-border-color);
    text-decoration: none;
    text-align: center;
    font-weight: var(--button-font-weight);
    padding: var(--button-padding-y) var(--button-padding-x) var(--button-padding-y) var(--button-padding-x); }
    .btn-secondary:hover, .btn-secondary:focus, .btn-secondary[aria-describedby*="popover"], .btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary[aria-describedby*="popover"] {
      color: var(--button-secondary-color-hover);
      background-color: var(--button-secondary-background-hover);
      border-color: var(--button-secondary-border-color-hover);
      text-decoration: none; }
    .dark-theme .btn-secondary, .dark-theme .btn-outline-primary {
      color: var(--button-secondary-color); }
      .dark-theme .btn-secondary:hover, .dark-theme .btn-outline-primary:hover {
        color: var(--button-secondary-color-hover); }
    .btn-secondary[class*="icon-"], .btn-secondary[class^="icon-"], .btn-outline-primary[class*="icon-"], .btn-outline-primary[class^="icon-"] {
      padding-left: calc(var(--button-padding-x) * 2); }
      .btn-secondary[class*="icon-"]:before, .btn-secondary[class^="icon-"]:before, .btn-outline-primary[class*="icon-"]:before, .btn-outline-primary[class^="icon-"]:before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
  .btn-tertiary {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-width: 1px;
    border-style: solid;
    font-family: var(--button-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif);
    text-transform: var(--button-text-transform);
    transition: all 200ms ease-out;
    text-decoration: none;
    border-radius: var(--button-border-radius);
    text-align: center;
    font-weight: var(--button-font-weight);
    font-size: var(--button-font-size);
    letter-spacing: 0.1em;
    line-height: 18px;
    padding: var(--button-padding-y) var(--button-padding-x) var(--button-padding-y) var(--button-padding-x);
    font-family: var(--button-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif);
    text-transform: var(--button-text-transform);
    color: var(--button-tertiary-color);
    background-color: transparent;
    border-radius: var(--button-border-radius);
    border-width: 1px;
    border-color: transparent;
    text-decoration: var(--button-tertiary-text-decoration);
    text-align: center;
    font-weight: var(--button-font-weight);
    padding: var(--button-padding-y) 0 var(--button-padding-y) 0; }
    .btn-tertiary:hover, .btn-tertiary:focus, .btn-tertiary[aria-describedby*="popover"] {
      color: var(--button-tertiary-color-hover);
      background-color: transparent;
      border-color: transparent;
      text-decoration: var(--button-tertiary-text-decoration-hover); }
    .dark-theme .btn-tertiary {
      color: #FFFFFF; }
      .dark-theme .btn-tertiary:hover {
        color: #FFFFFF; }
    .btn-tertiary[class*="icon-"], .btn-tertiary[class^="icon-"] {
      padding-left: calc(var(--button-padding-x) * 2); }
      .btn-tertiary[class*="icon-"]:before, .btn-tertiary[class^="icon-"]:before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
  .btn-sm, .btn-group-sm > .btn {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-width: 1px;
    border-style: solid;
    font-family: var(--button-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif);
    text-transform: var(--button-text-transform);
    transition: all 200ms ease-out;
    text-decoration: none;
    border-radius: var(--button-border-radius);
    text-align: center;
    font-weight: var(--button-font-weight);
    font-size: 14px;
    line-height: 18px;
    padding: 5px 20px 5px 20px;
    letter-spacing: 0.05em; }
    .btn-sm[class*="icon-"], .btn-group-sm > .btn[class*="icon-"], .btn-sm[class^="icon-"], .btn-group-sm > .btn[class^="icon-"] {
      padding-left: calc(var(--button-padding-x) * 2);
      padding-left: calc(20px * 2); }
      .btn-sm[class*="icon-"]:before, .btn-group-sm > .btn[class*="icon-"]:before, .btn-sm[class^="icon-"]:before, .btn-group-sm > .btn[class^="icon-"]:before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .btn-sm.btn-primary, .btn-group-sm > .btn-primary.btn {
      display: inline-block;
      position: relative;
      cursor: pointer;
      user-select: none;
      border-width: 1px;
      border-style: solid;
      font-family: var(--button-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif);
      text-transform: var(--button-text-transform);
      transition: all 200ms ease-out;
      text-decoration: none;
      border-radius: var(--button-border-radius);
      text-align: center;
      font-weight: var(--button-font-weight);
      font-size: 14px;
      line-height: 18px;
      padding: 5px 20px 5px 20px;
      letter-spacing: 0.05em;
      font-family: var(--button-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif);
      text-transform: var(--button-text-transform);
      color: var(--button-primary-color);
      background-color: var(--button-primary-background);
      border-width: var(--button-primary-border-width);
      border-radius: var(--button-border-radius);
      border-color: var(--button-primary-border-color);
      text-decoration: none;
      text-align: center;
      font-weight: var(--button-font-weight);
      padding: 5px 20px 5px 20px; }
      .btn-sm.btn-primary:hover, .btn-group-sm > .btn-primary.btn:hover, .btn-sm.btn-primary:focus, .btn-group-sm > .btn-primary.btn:focus, .btn-sm.btn-primary[aria-describedby*="popover"], .btn-group-sm > .btn-primary.btn[aria-describedby*="popover"] {
        color: var(--button-primary-color-hover);
        background-color: var(--button-primary-background-hover);
        border-color: var(--button-primary-border-color-hover);
        text-decoration: none; }
      .dark-theme .btn-sm.btn-primary, .dark-theme .btn-group-sm > .btn-primary.btn {
        color: var(--button-primary-color); }
        .dark-theme .btn-sm.btn-primary:hover, .dark-theme .btn-group-sm > .btn-primary.btn:hover {
          color: var(--button-primary-color-hover); }
      .btn-sm.btn-primary[class*="icon-"], .btn-group-sm > .btn-primary.btn[class*="icon-"], .btn-sm.btn-primary[class^="icon-"], .btn-group-sm > .btn-primary.btn[class^="icon-"] {
        padding-left: calc(var(--button-padding-x) * 2);
        padding-left: calc(20px * 2); }
        .btn-sm.btn-primary[class*="icon-"]:before, .btn-group-sm > .btn-primary.btn[class*="icon-"]:before, .btn-sm.btn-primary[class^="icon-"]:before, .btn-group-sm > .btn-primary.btn[class^="icon-"]:before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
    .btn-sm.btn-secondary, .btn-group-sm > .btn-secondary.btn, .btn-sm.btn-outline-primary, .btn-group-sm > .btn-outline-primary.btn {
      display: inline-block;
      position: relative;
      cursor: pointer;
      user-select: none;
      border-width: 1px;
      border-style: solid;
      font-family: var(--button-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif);
      text-transform: var(--button-text-transform);
      transition: all 200ms ease-out;
      text-decoration: none;
      border-radius: var(--button-border-radius);
      text-align: center;
      font-weight: var(--button-font-weight);
      font-size: 14px;
      line-height: 18px;
      padding: 5px 20px 5px 20px;
      letter-spacing: 0.05em;
      font-family: var(--button-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif);
      text-transform: var(--button-text-transform);
      color: var(--button-secondary-color);
      border-width: var(--button-secondary-border-width);
      border-radius: var(--button-border-radius);
      background-color: var(--button-secondary-background);
      border-color: var(--button-secondary-border-color);
      text-decoration: none;
      text-align: center;
      font-weight: var(--button-font-weight);
      padding: 5px 20px 5px 20px; }
      .btn-sm.btn-secondary:hover, .btn-group-sm > .btn-secondary.btn:hover, .btn-sm.btn-secondary:focus, .btn-group-sm > .btn-secondary.btn:focus, .btn-sm.btn-secondary[aria-describedby*="popover"], .btn-group-sm > .btn-secondary.btn[aria-describedby*="popover"], .btn-sm.btn-outline-primary:hover, .btn-group-sm > .btn-outline-primary.btn:hover, .btn-sm.btn-outline-primary:focus, .btn-group-sm > .btn-outline-primary.btn:focus, .btn-sm.btn-outline-primary[aria-describedby*="popover"], .btn-group-sm > .btn-outline-primary.btn[aria-describedby*="popover"] {
        color: var(--button-secondary-color-hover);
        background-color: var(--button-secondary-background-hover);
        border-color: var(--button-secondary-border-color-hover);
        text-decoration: none; }
      .dark-theme .btn-sm.btn-secondary, .dark-theme .btn-group-sm > .btn-secondary.btn, .dark-theme .btn-sm.btn-outline-primary, .dark-theme .btn-group-sm > .btn-outline-primary.btn {
        color: var(--button-secondary-color); }
        .dark-theme .btn-sm.btn-secondary:hover, .dark-theme .btn-group-sm > .btn-secondary.btn:hover, .dark-theme .btn-sm.btn-outline-primary:hover, .dark-theme .btn-group-sm > .btn-outline-primary.btn:hover {
          color: var(--button-secondary-color-hover); }
      .btn-sm.btn-secondary[class*="icon-"], .btn-group-sm > .btn-secondary.btn[class*="icon-"], .btn-sm.btn-secondary[class^="icon-"], .btn-group-sm > .btn-secondary.btn[class^="icon-"], .btn-sm.btn-outline-primary[class*="icon-"], .btn-group-sm > .btn-outline-primary.btn[class*="icon-"], .btn-sm.btn-outline-primary[class^="icon-"], .btn-group-sm > .btn-outline-primary.btn[class^="icon-"] {
        padding-left: calc(var(--button-padding-x) * 2);
        padding-left: calc(20px * 2); }
        .btn-sm.btn-secondary[class*="icon-"]:before, .btn-group-sm > .btn-secondary.btn[class*="icon-"]:before, .btn-sm.btn-secondary[class^="icon-"]:before, .btn-group-sm > .btn-secondary.btn[class^="icon-"]:before, .btn-sm.btn-outline-primary[class*="icon-"]:before, .btn-group-sm > .btn-outline-primary.btn[class*="icon-"]:before, .btn-sm.btn-outline-primary[class^="icon-"]:before, .btn-group-sm > .btn-outline-primary.btn[class^="icon-"]:before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
    .btn-sm.btn-tertiary, .btn-group-sm > .btn-tertiary.btn {
      display: inline-block;
      position: relative;
      cursor: pointer;
      user-select: none;
      border-width: 1px;
      border-style: solid;
      font-family: var(--button-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif);
      text-transform: var(--button-text-transform);
      transition: all 200ms ease-out;
      text-decoration: none;
      border-radius: var(--button-border-radius);
      text-align: center;
      font-weight: var(--button-font-weight);
      font-size: 14px;
      line-height: 18px;
      padding: 5px 20px 5px 20px;
      letter-spacing: 0.05em;
      font-family: var(--button-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif);
      text-transform: var(--button-text-transform);
      color: var(--button-tertiary-color);
      background-color: transparent;
      border-radius: var(--button-border-radius);
      border-width: 1px;
      border-color: transparent;
      text-decoration: var(--button-tertiary-text-decoration);
      text-align: center;
      font-weight: var(--button-font-weight);
      padding: 5px 0 5px 0; }
      .btn-sm.btn-tertiary:hover, .btn-group-sm > .btn-tertiary.btn:hover, .btn-sm.btn-tertiary:focus, .btn-group-sm > .btn-tertiary.btn:focus, .btn-sm.btn-tertiary[aria-describedby*="popover"], .btn-group-sm > .btn-tertiary.btn[aria-describedby*="popover"] {
        color: var(--button-tertiary-color-hover);
        background-color: transparent;
        border-color: transparent;
        text-decoration: var(--button-tertiary-text-decoration-hover); }
      .dark-theme .btn-sm.btn-tertiary, .dark-theme .btn-group-sm > .btn-tertiary.btn {
        color: #FFFFFF; }
        .dark-theme .btn-sm.btn-tertiary:hover, .dark-theme .btn-group-sm > .btn-tertiary.btn:hover {
          color: #FFFFFF; }
      .btn-sm.btn-tertiary[class*="icon-"], .btn-group-sm > .btn-tertiary.btn[class*="icon-"], .btn-sm.btn-tertiary[class^="icon-"], .btn-group-sm > .btn-tertiary.btn[class^="icon-"] {
        padding-left: calc(var(--button-padding-x) * 2);
        padding-left: calc(20px * 2); }
        .btn-sm.btn-tertiary[class*="icon-"]:before, .btn-group-sm > .btn-tertiary.btn[class*="icon-"]:before, .btn-sm.btn-tertiary[class^="icon-"]:before, .btn-group-sm > .btn-tertiary.btn[class^="icon-"]:before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
  .btn-block {
    display: block;
    width: 100%; }

a,
.text-link {
  color: var(--brand-primary);
  font-weight: normal;
  text-decoration: none;
  cursor: pointer; }
  a:hover,
  .text-link:hover {
    color: var(--brand-secondary);
    text-decoration: underline; }
  .dark-theme a, .dark-theme
  .text-link {
    color: #FFFFFF; }
    .dark-theme a:hover, .dark-theme
    .text-link:hover {
      color: #FFFFFF; }

.pull-left {
  float: left; }

.pull-right {
  float: right; }

.header-link {
  font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
  text-decoration: none;
  text-transform: var(--primary-nav-text-transform);
  font-size: 14px;
  font-weight: var(--primary-nav-font-weight);
  line-height: 20px;
  letter-spacing: 0.05em; }
  .header-link:hover {
    text-decoration: none; }

.navigation-link {
  font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
  text-decoration: none;
  text-transform: var(--primary-nav-text-transform);
  font-size: 14px;
  font-weight: var(--primary-nav-font-weight);
  line-height: 20px;
  letter-spacing: 0.03em; }
  .navigation-link:hover {
    text-decoration: none; }
  .navigation-link-secondary {
    font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
    font-size: 14px;
    font-weight: var(--secondary-nav-font-weight);
    line-height: 20px;
    text-transform: var(--secondary-nav-text-transform);
    color: var(--secondary-nav-color, var(--brand-primary)); }
    .navigation-link-secondary:hover {
      color: var(--secondary-nav-color-hover, var(--brand-primary)); }
  .navigation-link-tertiary {
    color: var(--tertiary-nav-color, var(--brand-secondary));
    font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
    font-size: 14px;
    font-weight: var(--tertiary-nav-font-weight);
    text-transform: var(--tertiary-nav-text-transform); }
    .navigation-link-tertiary:hover {
      color: var(--tertiary-nav-color-hover, var(--brand-primary)); }

.visually-hidden {
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  height: 1px;
  width: 1px; }

.lock-scroll {
  height: calc(100vh - 1px); }
  .lock-scroll body {
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%; }

.custom-scrollbar {
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: #C5C5C5 #F2F2F2;
  /* Webkit / Blink */ }
  .custom-scrollbar::-webkit-scrollbar {
    height: 8px;
    width: 8px; }
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #C5C5C5;
    border-radius: 9999px; }
  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: #F2F2F2; }

.hide-scrollbar {
  scrollbar-width: none; }
  .hide-scrollbar::-webkit-scrollbar {
    width: 0;
    height: 0; }

.leading-lines {
  overflow: hidden;
  margin: 0 0 16px; }
  .leading-lines label {
    background-color: #FFFFFF; }
  .leading-lines p {
    margin-bottom: 0; }
  .leading-lines .start-lines {
    padding: 0;
    align-self: center; }
    .leading-lines .start-lines span {
      position: relative;
      background-color: #FFFFFF;
      z-index: 2;
      display: inline-block; }
      .leading-lines .start-lines span:only-child, .leading-lines .start-lines span:last-child {
        padding-right: 16px; }
    .leading-lines .start-lines::before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100vw;
      white-space: nowrap;
      content: "";
      border-bottom: 0 dotted transparent;
      margin-top: 1px;
      z-index: 1; }
  .leading-lines .end-lines {
    padding: 0;
    align-self: center; }
    .leading-lines .end-lines span {
      position: relative;
      background-color: #FFFFFF;
      z-index: 2;
      display: inline-block; }
      .leading-lines .end-lines span:only-child, .leading-lines .end-lines span:last-child {
        padding-left: 16px;
        padding-right: 16px;
        margin-right: -16px; }

.email-signup-message {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  position: fixed;
  top: 0;
  padding: 50px 16px 16px;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  z-index: 1100; }

.email-signup-alert {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  text-align: center; }
  .email-signup-alert.show {
    display: block; }
  .email-signup-alert:not(.alert-dismissible) {
    animation: fadeIn 200ms, fadeOut 200ms linear calc(5s - 200ms); }
  .email-signup-alert.alert-dismissible {
    animation: fadeIn 200ms;
    padding-right: 50px; }
    .email-signup-alert.alert-dismissible .close {
      padding: 16px; }

.swatch-mark.color-value {
  transition: box-shadow 200ms ease-out; }
  .swatch-mark.color-value:hover {
    box-shadow: 0 0 0 calc(3px - 1px) #FFFFFF, 0 0 0 3px #BEBEBE; }
  .swatch-mark.color-value.selected {
    box-shadow: 0 0 0 calc(3px - 1px) #FFFFFF, 0 0 0 3px #000000; }
    .swatch-mark.color-value.selected:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 7px;
      color: #FFFFFF;
      font-weight: normal; }
    .swatch-mark.color-value.selected[class*="btn-"]:not(.title) {
      padding-right: calc(var(--button-padding-x) + 20px); }
      .swatch-mark.color-value.selected[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #FFFFFF;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .swatch-mark.color-value.selected[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .swatch-mark.color-value.selected.btn[class*="btn-"]:not(.title) {
        padding-right: calc(20px + 20px); }
        .swatch-mark.color-value.selected[class*="btn-"]:not(.title).btn-sm:after, .btn-group-sm > .swatch-mark.color-value.selected.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFFFFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .swatch-mark.color-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
      .swatch-mark.color-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
      .swatch-mark.color-value.selected[class*="btn-"]:not(.title) .fa {
        display: none; }
    .swatch-mark.color-value.selected::after {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      display: none;
      background: rgba(0, 0, 0, 0.7);
      width: 14px;
      height: 14px;
      line-height: 14px;
      padding-left: 1px;
      text-align: center;
      border-radius: 50%;
      z-index: 1; }
  .swatch-mark.color-value.unselectable:before, .swatch-mark.color-value.unselectable:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%) rotate(45deg);
    height: 100%;
    width: 1px; }
  .swatch-mark.color-value.unselectable:after {
    background-color: #000000; }
  .swatch-mark.color-value.unselectable:before {
    background-color: #FFFFFF;
    margin-right: 1px; }
  .swatch-mark.color-value.unselectable, .swatch-mark.color-value.unavailable {
    opacity: 0.5;
    pointer-events: none;
    pointer-events: auto;
    position: relative; }
  .swatch-mark.color-value.unselectable {
    cursor: not-allowed; }
  .swatch-mark.color-value.out-of-stock {
    cursor: pointer;
    opacity: 0.5;
    pointer-events: none; }
    .swatch-mark.color-value.out-of-stock:before, .swatch-mark.color-value.out-of-stock:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%) rotate(45deg);
      height: calc(100% + 15px);
      width: 1px; }
    .swatch-mark.color-value.out-of-stock:after {
      background-color: #BEBEBE; }
    .swatch-mark.color-value.out-of-stock:before {
      background-color: #FFFFFF;
      margin-right: 1px; }
    .swatch-mark.color-value.out-of-stock.selected:after {
      display: block; }

.swatch-mark.non-color-swatch-value {
  transition: border 200ms ease-out, box-shadow 200ms ease-out; }
  .swatch-mark.non-color-swatch-value:not(.unselectable):hover {
    border-color: #767676;
    box-shadow: none; }
  .swatch-mark.non-color-swatch-value.selected, .swatch-mark.non-color-swatch-value.selected:hover {
    background: #FFFFFF;
    color: #000000;
    border-color: #000000;
    box-shadow: none; }
    .swatch-mark.non-color-swatch-value.selected:after, .swatch-mark.non-color-swatch-value.selected:hover:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 8px;
      color: #FFFFFF;
      font-weight: normal; }
    .swatch-mark.non-color-swatch-value.selected[class*="btn-"]:not(.title), .swatch-mark.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) {
      padding-right: calc(var(--button-padding-x) + 20px); }
      .swatch-mark.non-color-swatch-value.selected[class*="btn-"]:not(.title):after, .swatch-mark.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #FFFFFF;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .swatch-mark.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .swatch-mark.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .swatch-mark.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .swatch-mark.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
        padding-right: calc(20px + 20px); }
        .swatch-mark.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm:after, .btn-group-sm > .swatch-mark.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .swatch-mark.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm:after, .btn-group-sm > .swatch-mark.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFFFFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .swatch-mark.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
      .swatch-mark.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
      .swatch-mark.non-color-swatch-value.selected[class*="btn-"]:not(.title) .fa, .swatch-mark.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class*="icon-"],
      .swatch-mark.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class^="icon-"],
      .swatch-mark.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) .fa {
        display: none; }
    .swatch-mark.non-color-swatch-value.selected::after, .swatch-mark.non-color-swatch-value.selected:hover::after {
      position: absolute;
      top: -5.33333px;
      right: -5.33333px;
      background: var(--brand-primary);
      width: 16px;
      height: 16px;
      line-height: 16px;
      padding-left: 1px;
      text-align: center;
      border-radius: 50%;
      display: none; }
  .swatch-mark.non-color-swatch-value.unselectable, .swatch-mark.non-color-swatch-value.unavailable {
    opacity: 0.5;
    pointer-events: none;
    pointer-events: auto;
    position: relative; }
  .swatch-mark.non-color-swatch-value.unselectable {
    cursor: not-allowed; }
    .swatch-mark.non-color-swatch-value.unselectable:before, .swatch-mark.non-color-swatch-value.unselectable:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%) rotate(45deg);
      height: calc(100% + 15px);
      width: 1px; }
    .swatch-mark.non-color-swatch-value.unselectable:after {
      background-color: #BEBEBE; }
    .swatch-mark.non-color-swatch-value.unselectable:before {
      background-color: #FFFFFF;
      margin-right: 1px; }
  .swatch-mark.non-color-swatch-value.out-of-stock {
    cursor: pointer;
    opacity: 0.5;
    pointer-events: none; }
    .swatch-mark.non-color-swatch-value.out-of-stock:before, .swatch-mark.non-color-swatch-value.out-of-stock:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%) rotate(45deg);
      height: calc(100% + 15px);
      width: 1px; }
    .swatch-mark.non-color-swatch-value.out-of-stock:after {
      background-color: #BEBEBE; }
    .swatch-mark.non-color-swatch-value.out-of-stock:before {
      background-color: #FFFFFF;
      margin-right: 1px; }
    .swatch-mark.non-color-swatch-value.out-of-stock.selected:after {
      display: block; }

.disabled,
:disabled,
[disabled] {
  opacity: 0.5;
  pointer-events: none; }

.vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.horizontal-center {
  position: absolute;
  right: 50%;
  transform: translateX(50%); }

.absolute-center {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%); }

.navbar-nav .megamenu .megamenu-link-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  columns: 1; }
  .navbar-nav .megamenu .megamenu-link-list ul li {
    padding: 0; }
  .navbar-nav .megamenu .megamenu-link-list ul li a {
    font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
    text-decoration: none;
    text-transform: var(--primary-nav-text-transform);
    font-size: 14px;
    font-weight: var(--primary-nav-font-weight);
    line-height: 20px;
    letter-spacing: 0.03em;
    display: block;
    padding: 10px 5px 10px 5px; }
    .navbar-nav .megamenu .megamenu-link-list ul li a:hover {
      text-decoration: none; }
    .navbar-nav .megamenu .megamenu-link-list ul li a-secondary {
      font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
      font-size: 14px;
      font-weight: var(--secondary-nav-font-weight);
      line-height: 20px;
      text-transform: var(--secondary-nav-text-transform);
      color: var(--secondary-nav-color, var(--brand-primary)); }
      .navbar-nav .megamenu .megamenu-link-list ul li a-secondary:hover {
        color: var(--secondary-nav-color-hover, var(--brand-primary)); }
    .navbar-nav .megamenu .megamenu-link-list ul li a-tertiary {
      color: var(--tertiary-nav-color, var(--brand-secondary));
      font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
      font-size: 14px;
      font-weight: var(--tertiary-nav-font-weight);
      text-transform: var(--tertiary-nav-text-transform); }
      .navbar-nav .megamenu .megamenu-link-list ul li a-tertiary:hover {
        color: var(--tertiary-nav-color-hover, var(--brand-primary)); }

.fade-in {
  animation-name: fadeIn;
  animation-timing-function: ease-out; }

.fade-out {
  animation-name: fadeOut;
  animation-timing-function: ease-out; }

.zoom-in {
  animation-name: scale-in-center-long;
  animation-timing-function: ease-out; }

[data-animation]:not(.disable-mobile-animation) {
  will-change: transform, opacity; }
  [data-animation]:not(.disable-mobile-animation) > [style*="animation-delay"],
  [data-animation]:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    will-change: transform, opacity; }

@media (min-width: 1024px) {
  [data-animation].disable-mobile-animation {
    will-change: transform, opacity; }
    [data-animation].disable-mobile-animation > [style*="animation-delay"],
    [data-animation].disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      will-change: transform, opacity; } }

.animation-initial-state:not(.disable-mobile-animation) {
  opacity: 0; }

@media (min-width: 1024px) {
  .animation-initial-state.disable-mobile-animation {
    opacity: 0; } }

.scale-in-center--short:not(.disable-mobile-animation) > [style*="animation-delay"],
.scale-in-center--short:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
  animation: scale-in-center-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .scale-in-center--short.disable-mobile-animation > [style*="animation-delay"],
  .scale-in-center--short.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
    animation: scale-in-center-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.scale-in-center--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
.scale-in-center--medium:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
  animation: scale-in-center-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .scale-in-center--medium.disable-mobile-animation > [style*="animation-delay"],
  .scale-in-center--medium.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
    animation: scale-in-center-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.scale-in-center--long:not(.disable-mobile-animation) > [style*="animation-delay"],
.scale-in-center--long:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
  animation: scale-in-center-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .scale-in-center--long.disable-mobile-animation > [style*="animation-delay"],
  .scale-in-center--long.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
    animation: scale-in-center-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.hinge-drop--short:not(.disable-mobile-animation) {
  perspective: 1000px; }
  .hinge-drop--short:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hinge-drop--short:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
    animation: hinge-drop-short 400ms cubic-bezier(0.2, 1, 0.2, 1) both;
    transform-origin: 0px 0px 0px; }

@media (min-width: 1024px) {
  .hinge-drop--short.disable-mobile-animation {
    perspective: 1000px; }
    .hinge-drop--short.disable-mobile-animation > [style*="animation-delay"],
    .hinge-drop--short.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
      animation: hinge-drop-short 400ms cubic-bezier(0.2, 1, 0.2, 1) both;
      transform-origin: 0px 0px 0px; } }

.hinge-drop--medium:not(.disable-mobile-animation) {
  perspective: 750px; }
  .hinge-drop--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hinge-drop--medium:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
    animation: hinge-drop-medium 1200ms cubic-bezier(0.2, 1, 0.2, 1) both;
    transform-origin: 0px 0px 0px; }

@media (min-width: 1024px) {
  .hinge-drop--medium.disable-mobile-animation {
    perspective: 750px; }
    .hinge-drop--medium.disable-mobile-animation > [style*="animation-delay"],
    .hinge-drop--medium.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
      animation: hinge-drop-medium 1200ms cubic-bezier(0.2, 1, 0.2, 1) both;
      transform-origin: 0px 0px 0px; } }

.hinge-drop--long:not(.disable-mobile-animation) {
  perspective: 500px; }
  .hinge-drop--long:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hinge-drop--long:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
    animation: hinge-drop-long 2000ms cubic-bezier(0.2, 1, 0.2, 1) both;
    transform-origin: 0px 0px 0px; }

@media (min-width: 1024px) {
  .hinge-drop--long.disable-mobile-animation {
    perspective: 500px; }
    .hinge-drop--long.disable-mobile-animation > [style*="animation-delay"],
    .hinge-drop--long.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
      animation: hinge-drop-long 2000ms cubic-bezier(0.2, 1, 0.2, 1) both;
      transform-origin: 0px 0px 0px; } }

.flip-in-hor-top--short:not(.disable-mobile-animation) {
  perspective: 2000px; }
  .flip-in-hor-top--short:not(.disable-mobile-animation) > [style*="animation-delay"],
  .flip-in-hor-top--short:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
    animation: flip-in-hor-top-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }

@media (min-width: 1024px) {
  .flip-in-hor-top--short.disable-mobile-animation {
    perspective: 2000px; }
    .flip-in-hor-top--short.disable-mobile-animation > [style*="animation-delay"],
    .flip-in-hor-top--short.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
      animation: flip-in-hor-top-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; } }

.flip-in-hor-top--medium:not(.disable-mobile-animation) {
  perspective: 2000px; }
  .flip-in-hor-top--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
  .flip-in-hor-top--medium:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
    animation: flip-in-hor-top-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }

@media (min-width: 1024px) {
  .flip-in-hor-top--medium.disable-mobile-animation {
    perspective: 2000px; }
    .flip-in-hor-top--medium.disable-mobile-animation > [style*="animation-delay"],
    .flip-in-hor-top--medium.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
      animation: flip-in-hor-top-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; } }

.flip-in-hor-top--long:not(.disable-mobile-animation) {
  perspective: 2000px; }
  .flip-in-hor-top--long:not(.disable-mobile-animation) > [style*="animation-delay"],
  .flip-in-hor-top--long:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
    animation: flip-in-hor-top-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; }

@media (min-width: 1024px) {
  .flip-in-hor-top--long.disable-mobile-animation {
    perspective: 2000px; }
    .flip-in-hor-top--long.disable-mobile-animation > [style*="animation-delay"],
    .flip-in-hor-top--long.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
      animation: flip-in-hor-top-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; } }

.flip-in-hor-bottom--short:not(.disable-mobile-animation) {
  perspective: 2000px; }
  .flip-in-hor-bottom--short:not(.disable-mobile-animation) > [style*="animation-delay"],
  .flip-in-hor-bottom--short:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
    animation: flip-in-hor-bottom-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }

@media (min-width: 1024px) {
  .flip-in-hor-bottom--short.disable-mobile-animation {
    perspective: 2000px; }
    .flip-in-hor-bottom--short.disable-mobile-animation > [style*="animation-delay"],
    .flip-in-hor-bottom--short.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
      animation: flip-in-hor-bottom-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; } }

.flip-in-hor-bottom--medium:not(.disable-mobile-animation) {
  perspective: 2000px; }
  .flip-in-hor-bottom--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
  .flip-in-hor-bottom--medium:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
    animation: flip-in-hor-bottom-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }

@media (min-width: 1024px) {
  .flip-in-hor-bottom--medium.disable-mobile-animation {
    perspective: 2000px; }
    .flip-in-hor-bottom--medium.disable-mobile-animation > [style*="animation-delay"],
    .flip-in-hor-bottom--medium.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
      animation: flip-in-hor-bottom-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; } }

.flip-in-hor-bottom--long:not(.disable-mobile-animation) {
  perspective: 2000px; }
  .flip-in-hor-bottom--long:not(.disable-mobile-animation) > [style*="animation-delay"],
  .flip-in-hor-bottom--long:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
    animation: flip-in-hor-bottom-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; }

@media (min-width: 1024px) {
  .flip-in-hor-bottom--long.disable-mobile-animation {
    perspective: 2000px; }
    .flip-in-hor-bottom--long.disable-mobile-animation > [style*="animation-delay"],
    .flip-in-hor-bottom--long.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
      animation: flip-in-hor-bottom-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; } }

.slide-in-top--short:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-top--short:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
  animation: slide-in-top-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-top--short.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-top--short.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
    animation: slide-in-top-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-top--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-top--medium:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
  animation: slide-in-top-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-top--medium.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-top--medium.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
    animation: slide-in-top-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-top--long:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-top--long:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
  animation: slide-in-top-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-top--long.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-top--long.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
    animation: slide-in-top-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-left--short:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-left--short:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
  animation: slide-in-left-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-left--short.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-left--short.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
    animation: slide-in-left-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-left--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-left--medium:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
  animation: slide-in-left-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-left--medium.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-left--medium.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
    animation: slide-in-left-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-left--long:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-left--long:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
  animation: slide-in-left-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-left--long.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-left--long.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
    animation: slide-in-left-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-right--short:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-right--short:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
  animation: slide-in-right-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-right--short.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-right--short.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
    animation: slide-in-right-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-right--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-right--medium:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
  animation: slide-in-right-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-right--medium.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-right--medium.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
    animation: slide-in-right-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-right--long:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-right--long:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
  animation: slide-in-right-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-right--long.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-right--long.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
    animation: slide-in-right-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-bottom--short:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-bottom--short:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
  animation: slide-in-bottom-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-bottom--short.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-bottom--short.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
    animation: slide-in-bottom-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-bottom--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-bottom--medium:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
  animation: slide-in-bottom-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-bottom--medium.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-bottom--medium.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
    animation: slide-in-bottom-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-bottom--long:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-bottom--long:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
  animation: slide-in-bottom-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-bottom--long.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-bottom--long.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
    animation: slide-in-bottom-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.fade-in--short:not(.disable-mobile-animation) > [style*="animation-delay"],
.fade-in--short:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
  animation: fade-in-short 200ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }

@media (min-width: 1024px) {
  .fade-in--short.disable-mobile-animation > [style*="animation-delay"],
  .fade-in--short.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
    animation: fade-in-short 200ms cubic-bezier(0.39, 0.575, 0.565, 1) both; } }

.fade-in--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
.fade-in--medium:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
  animation: fade-in-medium 600ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }

@media (min-width: 1024px) {
  .fade-in--medium.disable-mobile-animation > [style*="animation-delay"],
  .fade-in--medium.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
    animation: fade-in-medium 600ms cubic-bezier(0.39, 0.575, 0.565, 1) both; } }

.fade-in--long:not(.disable-mobile-animation) > [style*="animation-delay"],
.fade-in--long:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
  animation: fade-in-long 1000ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }

@media (min-width: 1024px) {
  .fade-in--long.disable-mobile-animation > [style*="animation-delay"],
  .fade-in--long.disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
    animation: fade-in-long 1000ms cubic-bezier(0.39, 0.575, 0.565, 1) both; } }

.hero-has-animation .tns-item [data-animation^="scale-in-center"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="scale-in-center"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="scale-in-center"]:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="scale-in-center"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="scale-in-center"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="scale-in-center"].disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--short {
  animation: scale-in-center-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--short > [class*="container"] > .row > [style*="animation-delay"] {
    animation: scale-in-center-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--medium {
  animation: scale-in-center-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--medium > [class*="container"] > .row > [style*="animation-delay"] {
    animation: scale-in-center-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--long {
  animation: scale-in-center-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--long > [class*="container"] > .row > [style*="animation-delay"] {
    animation: scale-in-center-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--short {
    animation: scale-in-center-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--short > [class*="container"] > .row > [style*="animation-delay"] {
      animation: scale-in-center-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--medium {
    animation: scale-in-center-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--medium > [class*="container"] > .row > [style*="animation-delay"] {
      animation: scale-in-center-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--long {
    animation: scale-in-center-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--long > [class*="container"] > .row > [style*="animation-delay"] {
      animation: scale-in-center-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.hero-has-animation .tns-item [data-animation^="hinge-drop"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="hinge-drop"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="hinge-drop"]:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="hinge-drop"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="hinge-drop"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="hinge-drop"].disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--short {
  animation: hinge-drop-short 200ms cubic-bezier(0.2, 1, 0.2, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--short > [class*="container"] > .row > [style*="animation-delay"] {
    animation: hinge-drop-short 200ms cubic-bezier(0.2, 1, 0.2, 1) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--medium {
  animation: hinge-drop-medium 600ms cubic-bezier(0.2, 1, 0.2, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--medium > [class*="container"] > .row > [style*="animation-delay"] {
    animation: hinge-drop-medium 600ms cubic-bezier(0.2, 1, 0.2, 1) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--long {
  animation: hinge-drop-long 1000ms cubic-bezier(0.2, 1, 0.2, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--long > [class*="container"] > .row > [style*="animation-delay"] {
    animation: hinge-drop-long 1000ms cubic-bezier(0.2, 1, 0.2, 1) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--short {
    animation: hinge-drop-short 200ms cubic-bezier(0.2, 1, 0.2, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--short > [class*="container"] > .row > [style*="animation-delay"] {
      animation: hinge-drop-short 200ms cubic-bezier(0.2, 1, 0.2, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--medium {
    animation: hinge-drop-medium 600ms cubic-bezier(0.2, 1, 0.2, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--medium > [class*="container"] > .row > [style*="animation-delay"] {
      animation: hinge-drop-medium 600ms cubic-bezier(0.2, 1, 0.2, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--long {
    animation: hinge-drop-long 1000ms cubic-bezier(0.2, 1, 0.2, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--long > [class*="container"] > .row > [style*="animation-delay"] {
      animation: hinge-drop-long 1000ms cubic-bezier(0.2, 1, 0.2, 1) both; } }

.hero-has-animation .tns-item [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="flip-in-hor-top"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="flip-in-hor-top"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="flip-in-hor-top"].disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--short {
  animation: flip-in-hor-top-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--short > [class*="container"] > .row > [style*="animation-delay"] {
    animation: flip-in-hor-top-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--medium {
  animation: flip-in-hor-top-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--medium > [class*="container"] > .row > [style*="animation-delay"] {
    animation: flip-in-hor-top-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--long {
  animation: flip-in-hor-top-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--long > [class*="container"] > .row > [style*="animation-delay"] {
    animation: flip-in-hor-top-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--short {
    animation: flip-in-hor-top-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--short > [class*="container"] > .row > [style*="animation-delay"] {
      animation: flip-in-hor-top-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--medium {
    animation: flip-in-hor-top-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--medium > [class*="container"] > .row > [style*="animation-delay"] {
      animation: flip-in-hor-top-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--long {
    animation: flip-in-hor-top-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--long > [class*="container"] > .row > [style*="animation-delay"] {
      animation: flip-in-hor-top-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; } }

.hero-has-animation .tns-item [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="flip-in-hor-bottom"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="flip-in-hor-bottom"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="flip-in-hor-bottom"].disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--short {
  animation: flip-in-hor-bottom-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--short > [class*="container"] > .row > [style*="animation-delay"] {
    animation: flip-in-hor-bottom-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--medium {
  animation: flip-in-hor-bottom-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--medium > [class*="container"] > .row > [style*="animation-delay"] {
    animation: flip-in-hor-bottom-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--long {
  animation: flip-in-hor-bottom-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--long > [class*="container"] > .row > [style*="animation-delay"] {
    animation: flip-in-hor-bottom-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--short {
    animation: flip-in-hor-bottom-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--short > [class*="container"] > .row > [style*="animation-delay"] {
      animation: flip-in-hor-bottom-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--medium {
    animation: flip-in-hor-bottom-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--medium > [class*="container"] > .row > [style*="animation-delay"] {
      animation: flip-in-hor-bottom-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--long {
    animation: flip-in-hor-bottom-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--long > [class*="container"] > .row > [style*="animation-delay"] {
      animation: flip-in-hor-bottom-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; } }

.hero-has-animation .tns-item [data-animation^="slide-in-top"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="slide-in-top"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="slide-in-top"]:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="slide-in-top"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="slide-in-top"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="slide-in-top"].disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--short {
  animation: slide-in-top-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--short > [class*="container"] > .row > [style*="animation-delay"] {
    animation: slide-in-top-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--medium {
  animation: slide-in-top-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--medium > [class*="container"] > .row > [style*="animation-delay"] {
    animation: slide-in-top-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--long {
  animation: slide-in-top-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--long > [class*="container"] > .row > [style*="animation-delay"] {
    animation: slide-in-top-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--short {
    animation: slide-in-top-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--short > [class*="container"] > .row > [style*="animation-delay"] {
      animation: slide-in-top-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--medium {
    animation: slide-in-top-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--medium > [class*="container"] > .row > [style*="animation-delay"] {
      animation: slide-in-top-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--long {
    animation: slide-in-top-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--long > [class*="container"] > .row > [style*="animation-delay"] {
      animation: slide-in-top-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.hero-has-animation .tns-item [data-animation^="slide-in-left"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="slide-in-left"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="slide-in-left"]:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="slide-in-left"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="slide-in-left"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="slide-in-left"].disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--short {
  animation: slide-in-left-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--short > [class*="container"] > .row > [style*="animation-delay"] {
    animation: slide-in-left-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--medium {
  animation: slide-in-left-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--medium > [class*="container"] > .row > [style*="animation-delay"] {
    animation: slide-in-left-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--long {
  animation: slide-in-left-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--long > [class*="container"] > .row > [style*="animation-delay"] {
    animation: slide-in-left-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--short {
    animation: slide-in-left-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--short > [class*="container"] > .row > [style*="animation-delay"] {
      animation: slide-in-left-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--medium {
    animation: slide-in-left-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--medium > [class*="container"] > .row > [style*="animation-delay"] {
      animation: slide-in-left-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--long {
    animation: slide-in-left-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--long > [class*="container"] > .row > [style*="animation-delay"] {
      animation: slide-in-left-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.hero-has-animation .tns-item [data-animation^="slide-in-right"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="slide-in-right"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="slide-in-right"]:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="slide-in-right"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="slide-in-right"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="slide-in-right"].disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--short {
  animation: slide-in-right-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--short > [class*="container"] > .row > [style*="animation-delay"] {
    animation: slide-in-right-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--medium {
  animation: slide-in-right-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--medium > [class*="container"] > .row > [style*="animation-delay"] {
    animation: slide-in-right-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--long {
  animation: slide-in-right-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--long > [class*="container"] > .row > [style*="animation-delay"] {
    animation: slide-in-right-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--short {
    animation: slide-in-right-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--short > [class*="container"] > .row > [style*="animation-delay"] {
      animation: slide-in-right-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--medium {
    animation: slide-in-right-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--medium > [class*="container"] > .row > [style*="animation-delay"] {
      animation: slide-in-right-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--long {
    animation: slide-in-right-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--long > [class*="container"] > .row > [style*="animation-delay"] {
      animation: slide-in-right-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.hero-has-animation .tns-item [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="slide-in-bottom"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="slide-in-bottom"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="slide-in-bottom"].disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--short {
  animation: slide-in-bottom-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--short > [class*="container"] > .row > [style*="animation-delay"] {
    animation: slide-in-bottom-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--medium {
  animation: slide-in-bottom-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--medium > [class*="container"] > .row > [style*="animation-delay"] {
    animation: slide-in-bottom-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--long {
  animation: slide-in-bottom-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--long > [class*="container"] > .row > [style*="animation-delay"] {
    animation: slide-in-bottom-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--short {
    animation: slide-in-bottom-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--short > [class*="container"] > .row > [style*="animation-delay"] {
      animation: slide-in-bottom-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--medium {
    animation: slide-in-bottom-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--medium > [class*="container"] > .row > [style*="animation-delay"] {
      animation: slide-in-bottom-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--long {
    animation: slide-in-bottom-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--long > [class*="container"] > .row > [style*="animation-delay"] {
      animation: slide-in-bottom-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.hero-has-animation .tns-item [data-animation^="fade-in"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="fade-in"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="fade-in"]:not(.disable-mobile-animation) > [class*="container"] > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="fade-in"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="fade-in"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="fade-in"].disable-mobile-animation > [class*="container"] > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--short {
  animation: fade-in-short 200ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--short > [class*="container"] > .row > [style*="animation-delay"] {
    animation: fade-in-short 200ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--medium {
  animation: fade-in-medium 600ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--medium > [class*="container"] > .row > [style*="animation-delay"] {
    animation: fade-in-medium 600ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--long {
  animation: fade-in-long 1000ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--long > [class*="container"] > .row > [style*="animation-delay"] {
    animation: fade-in-long 1000ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--short {
    animation: fade-in-short 200ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--short > [class*="container"] > .row > [style*="animation-delay"] {
      animation: fade-in-short 200ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--medium {
    animation: fade-in-medium 600ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--medium > [class*="container"] > .row > [style*="animation-delay"] {
      animation: fade-in-medium 600ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--long {
    animation: fade-in-long 1000ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--long > [class*="container"] > .row > [style*="animation-delay"] {
      animation: fade-in-long 1000ms cubic-bezier(0.39, 0.575, 0.565, 1) both; } }

.pull-left-xs {
  float: left; }

.pull-right-xs {
  float: right; }

@media (min-width: 544px) and (max-width: 768.98px) {
  .pull-left-sm {
    float: left; }
  .pull-right-sm {
    float: right; } }

@media (min-width: 769px) and (max-width: 1023.98px) {
  .pull-left-md {
    float: left; }
  .pull-right-md {
    float: right; } }

@media (min-width: 1024px) and (max-width: 1439.98px) {
  .pull-left-lg {
    float: left; }
  .pull-right-lg {
    float: right; } }

@media (min-width: 1440px) {
  .pull-left-xl {
    float: left; }
  .pull-right-xl {
    float: right; } }

@media (max-width: 543.98px) {
  .hidden-xs-down {
    display: none !important; } }

@media (max-width: 768.98px) {
  .hidden-sm-down {
    display: none !important; } }

@media (max-width: 1023.98px) {
  .hidden-md-down {
    display: none !important; } }

@media (max-width: 1439.98px) {
  .hidden-lg-down {
    display: none !important; } }

.hidden-xl-down {
  display: none !important; }

.row.equal-height > [class^="col"] {
  display: flex; }

.flag-icon-background, .flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat; }

.flag-icon {
  position: relative;
  display: inline-block;
  width: 1.33333em;
  line-height: 1em; }
  .flag-icon:before {
    content: '\00a0'; }
  .flag-icon.flag-icon-squared {
    width: 1em; }

.flag-icon-ad {
  background-image: url(../fonts/flags//4x3/ad.svg); }
  .flag-icon-ad.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ad.svg); }

.flag-icon-ae {
  background-image: url(../fonts/flags//4x3/ae.svg); }
  .flag-icon-ae.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ae.svg); }

.flag-icon-af {
  background-image: url(../fonts/flags//4x3/af.svg); }
  .flag-icon-af.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/af.svg); }

.flag-icon-ag {
  background-image: url(../fonts/flags//4x3/ag.svg); }
  .flag-icon-ag.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ag.svg); }

.flag-icon-ai {
  background-image: url(../fonts/flags//4x3/ai.svg); }
  .flag-icon-ai.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ai.svg); }

.flag-icon-al {
  background-image: url(../fonts/flags//4x3/al.svg); }
  .flag-icon-al.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/al.svg); }

.flag-icon-am {
  background-image: url(../fonts/flags//4x3/am.svg); }
  .flag-icon-am.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/am.svg); }

.flag-icon-ao {
  background-image: url(../fonts/flags//4x3/ao.svg); }
  .flag-icon-ao.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ao.svg); }

.flag-icon-aq {
  background-image: url(../fonts/flags//4x3/aq.svg); }
  .flag-icon-aq.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/aq.svg); }

.flag-icon-ar {
  background-image: url(../fonts/flags//4x3/ar.svg); }
  .flag-icon-ar.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ar.svg); }

.flag-icon-as {
  background-image: url(../fonts/flags//4x3/as.svg); }
  .flag-icon-as.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/as.svg); }

.flag-icon-at {
  background-image: url(../fonts/flags//4x3/at.svg); }
  .flag-icon-at.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/at.svg); }

.flag-icon-au {
  background-image: url(../fonts/flags//4x3/au.svg); }
  .flag-icon-au.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/au.svg); }

.flag-icon-aw {
  background-image: url(../fonts/flags//4x3/aw.svg); }
  .flag-icon-aw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/aw.svg); }

.flag-icon-ax {
  background-image: url(../fonts/flags//4x3/ax.svg); }
  .flag-icon-ax.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ax.svg); }

.flag-icon-az {
  background-image: url(../fonts/flags//4x3/az.svg); }
  .flag-icon-az.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/az.svg); }

.flag-icon-ba {
  background-image: url(../fonts/flags//4x3/ba.svg); }
  .flag-icon-ba.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ba.svg); }

.flag-icon-bb {
  background-image: url(../fonts/flags//4x3/bb.svg); }
  .flag-icon-bb.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bb.svg); }

.flag-icon-bd {
  background-image: url(../fonts/flags//4x3/bd.svg); }
  .flag-icon-bd.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bd.svg); }

.flag-icon-be {
  background-image: url(../fonts/flags//4x3/be.svg); }
  .flag-icon-be.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/be.svg); }

.flag-icon-bf {
  background-image: url(../fonts/flags//4x3/bf.svg); }
  .flag-icon-bf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bf.svg); }

.flag-icon-bg {
  background-image: url(../fonts/flags//4x3/bg.svg); }
  .flag-icon-bg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bg.svg); }

.flag-icon-bh {
  background-image: url(../fonts/flags//4x3/bh.svg); }
  .flag-icon-bh.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bh.svg); }

.flag-icon-bi {
  background-image: url(../fonts/flags//4x3/bi.svg); }
  .flag-icon-bi.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bi.svg); }

.flag-icon-bj {
  background-image: url(../fonts/flags//4x3/bj.svg); }
  .flag-icon-bj.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bj.svg); }

.flag-icon-bl {
  background-image: url(../fonts/flags//4x3/bl.svg); }
  .flag-icon-bl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bl.svg); }

.flag-icon-bm {
  background-image: url(../fonts/flags//4x3/bm.svg); }
  .flag-icon-bm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bm.svg); }

.flag-icon-bn {
  background-image: url(../fonts/flags//4x3/bn.svg); }
  .flag-icon-bn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bn.svg); }

.flag-icon-bo {
  background-image: url(../fonts/flags//4x3/bo.svg); }
  .flag-icon-bo.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bo.svg); }

.flag-icon-bq {
  background-image: url(../fonts/flags//4x3/bq.svg); }
  .flag-icon-bq.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bq.svg); }

.flag-icon-br {
  background-image: url(../fonts/flags//4x3/br.svg); }
  .flag-icon-br.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/br.svg); }

.flag-icon-bs {
  background-image: url(../fonts/flags//4x3/bs.svg); }
  .flag-icon-bs.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bs.svg); }

.flag-icon-bt {
  background-image: url(../fonts/flags//4x3/bt.svg); }
  .flag-icon-bt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bt.svg); }

.flag-icon-bv {
  background-image: url(../fonts/flags//4x3/bv.svg); }
  .flag-icon-bv.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bv.svg); }

.flag-icon-bw {
  background-image: url(../fonts/flags//4x3/bw.svg); }
  .flag-icon-bw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bw.svg); }

.flag-icon-by {
  background-image: url(../fonts/flags//4x3/by.svg); }
  .flag-icon-by.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/by.svg); }

.flag-icon-bz {
  background-image: url(../fonts/flags//4x3/bz.svg); }
  .flag-icon-bz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bz.svg); }

.flag-icon-ca {
  background-image: url(../fonts/flags//4x3/ca.svg); }
  .flag-icon-ca.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ca.svg); }

.flag-icon-cc {
  background-image: url(../fonts/flags//4x3/cc.svg); }
  .flag-icon-cc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cc.svg); }

.flag-icon-cd {
  background-image: url(../fonts/flags//4x3/cd.svg); }
  .flag-icon-cd.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cd.svg); }

.flag-icon-cf {
  background-image: url(../fonts/flags//4x3/cf.svg); }
  .flag-icon-cf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cf.svg); }

.flag-icon-cg {
  background-image: url(../fonts/flags//4x3/cg.svg); }
  .flag-icon-cg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cg.svg); }

.flag-icon-ch {
  background-image: url(../fonts/flags//4x3/ch.svg); }
  .flag-icon-ch.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ch.svg); }

.flag-icon-ci {
  background-image: url(../fonts/flags//4x3/ci.svg); }
  .flag-icon-ci.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ci.svg); }

.flag-icon-ck {
  background-image: url(../fonts/flags//4x3/ck.svg); }
  .flag-icon-ck.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ck.svg); }

.flag-icon-cl {
  background-image: url(../fonts/flags//4x3/cl.svg); }
  .flag-icon-cl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cl.svg); }

.flag-icon-cm {
  background-image: url(../fonts/flags//4x3/cm.svg); }
  .flag-icon-cm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cm.svg); }

.flag-icon-cn {
  background-image: url(../fonts/flags//4x3/cn.svg); }
  .flag-icon-cn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cn.svg); }

.flag-icon-co {
  background-image: url(../fonts/flags//4x3/co.svg); }
  .flag-icon-co.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/co.svg); }

.flag-icon-cr {
  background-image: url(../fonts/flags//4x3/cr.svg); }
  .flag-icon-cr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cr.svg); }

.flag-icon-cu {
  background-image: url(../fonts/flags//4x3/cu.svg); }
  .flag-icon-cu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cu.svg); }

.flag-icon-cv {
  background-image: url(../fonts/flags//4x3/cv.svg); }
  .flag-icon-cv.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cv.svg); }

.flag-icon-cw {
  background-image: url(../fonts/flags//4x3/cw.svg); }
  .flag-icon-cw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cw.svg); }

.flag-icon-cx {
  background-image: url(../fonts/flags//4x3/cx.svg); }
  .flag-icon-cx.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cx.svg); }

.flag-icon-cy {
  background-image: url(../fonts/flags//4x3/cy.svg); }
  .flag-icon-cy.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cy.svg); }

.flag-icon-cz {
  background-image: url(../fonts/flags//4x3/cz.svg); }
  .flag-icon-cz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cz.svg); }

.flag-icon-de {
  background-image: url(../fonts/flags//4x3/de.svg); }
  .flag-icon-de.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/de.svg); }

.flag-icon-dj {
  background-image: url(../fonts/flags//4x3/dj.svg); }
  .flag-icon-dj.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/dj.svg); }

.flag-icon-dk {
  background-image: url(../fonts/flags//4x3/dk.svg); }
  .flag-icon-dk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/dk.svg); }

.flag-icon-dm {
  background-image: url(../fonts/flags//4x3/dm.svg); }
  .flag-icon-dm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/dm.svg); }

.flag-icon-do {
  background-image: url(../fonts/flags//4x3/do.svg); }
  .flag-icon-do.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/do.svg); }

.flag-icon-dz {
  background-image: url(../fonts/flags//4x3/dz.svg); }
  .flag-icon-dz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/dz.svg); }

.flag-icon-ec {
  background-image: url(../fonts/flags//4x3/ec.svg); }
  .flag-icon-ec.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ec.svg); }

.flag-icon-ee {
  background-image: url(../fonts/flags//4x3/ee.svg); }
  .flag-icon-ee.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ee.svg); }

.flag-icon-eg {
  background-image: url(../fonts/flags//4x3/eg.svg); }
  .flag-icon-eg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/eg.svg); }

.flag-icon-eh {
  background-image: url(../fonts/flags//4x3/eh.svg); }
  .flag-icon-eh.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/eh.svg); }

.flag-icon-er {
  background-image: url(../fonts/flags//4x3/er.svg); }
  .flag-icon-er.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/er.svg); }

.flag-icon-es {
  background-image: url(../fonts/flags//4x3/es.svg); }
  .flag-icon-es.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/es.svg); }

.flag-icon-et {
  background-image: url(../fonts/flags//4x3/et.svg); }
  .flag-icon-et.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/et.svg); }

.flag-icon-fi {
  background-image: url(../fonts/flags//4x3/fi.svg); }
  .flag-icon-fi.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/fi.svg); }

.flag-icon-fj {
  background-image: url(../fonts/flags//4x3/fj.svg); }
  .flag-icon-fj.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/fj.svg); }

.flag-icon-fk {
  background-image: url(../fonts/flags//4x3/fk.svg); }
  .flag-icon-fk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/fk.svg); }

.flag-icon-fm {
  background-image: url(../fonts/flags//4x3/fm.svg); }
  .flag-icon-fm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/fm.svg); }

.flag-icon-fo {
  background-image: url(../fonts/flags//4x3/fo.svg); }
  .flag-icon-fo.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/fo.svg); }

.flag-icon-fr {
  background-image: url(../fonts/flags//4x3/fr.svg); }
  .flag-icon-fr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/fr.svg); }

.flag-icon-ga {
  background-image: url(../fonts/flags//4x3/ga.svg); }
  .flag-icon-ga.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ga.svg); }

.flag-icon-gb {
  background-image: url(../fonts/flags//4x3/gb.svg); }
  .flag-icon-gb.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gb.svg); }

.flag-icon-gd {
  background-image: url(../fonts/flags//4x3/gd.svg); }
  .flag-icon-gd.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gd.svg); }

.flag-icon-ge {
  background-image: url(../fonts/flags//4x3/ge.svg); }
  .flag-icon-ge.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ge.svg); }

.flag-icon-gf {
  background-image: url(../fonts/flags//4x3/gf.svg); }
  .flag-icon-gf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gf.svg); }

.flag-icon-gg {
  background-image: url(../fonts/flags//4x3/gg.svg); }
  .flag-icon-gg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gg.svg); }

.flag-icon-gh {
  background-image: url(../fonts/flags//4x3/gh.svg); }
  .flag-icon-gh.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gh.svg); }

.flag-icon-gi {
  background-image: url(../fonts/flags//4x3/gi.svg); }
  .flag-icon-gi.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gi.svg); }

.flag-icon-gl {
  background-image: url(../fonts/flags//4x3/gl.svg); }
  .flag-icon-gl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gl.svg); }

.flag-icon-gm {
  background-image: url(../fonts/flags//4x3/gm.svg); }
  .flag-icon-gm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gm.svg); }

.flag-icon-gn {
  background-image: url(../fonts/flags//4x3/gn.svg); }
  .flag-icon-gn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gn.svg); }

.flag-icon-gp {
  background-image: url(../fonts/flags//4x3/gp.svg); }
  .flag-icon-gp.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gp.svg); }

.flag-icon-gq {
  background-image: url(../fonts/flags//4x3/gq.svg); }
  .flag-icon-gq.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gq.svg); }

.flag-icon-gr {
  background-image: url(../fonts/flags//4x3/gr.svg); }
  .flag-icon-gr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gr.svg); }

.flag-icon-gs {
  background-image: url(../fonts/flags//4x3/gs.svg); }
  .flag-icon-gs.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gs.svg); }

.flag-icon-gt {
  background-image: url(../fonts/flags//4x3/gt.svg); }
  .flag-icon-gt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gt.svg); }

.flag-icon-gu {
  background-image: url(../fonts/flags//4x3/gu.svg); }
  .flag-icon-gu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gu.svg); }

.flag-icon-gw {
  background-image: url(../fonts/flags//4x3/gw.svg); }
  .flag-icon-gw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gw.svg); }

.flag-icon-gy {
  background-image: url(../fonts/flags//4x3/gy.svg); }
  .flag-icon-gy.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gy.svg); }

.flag-icon-hk {
  background-image: url(../fonts/flags//4x3/hk.svg); }
  .flag-icon-hk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/hk.svg); }

.flag-icon-hm {
  background-image: url(../fonts/flags//4x3/hm.svg); }
  .flag-icon-hm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/hm.svg); }

.flag-icon-hn {
  background-image: url(../fonts/flags//4x3/hn.svg); }
  .flag-icon-hn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/hn.svg); }

.flag-icon-hr {
  background-image: url(../fonts/flags//4x3/hr.svg); }
  .flag-icon-hr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/hr.svg); }

.flag-icon-ht {
  background-image: url(../fonts/flags//4x3/ht.svg); }
  .flag-icon-ht.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ht.svg); }

.flag-icon-hu {
  background-image: url(../fonts/flags//4x3/hu.svg); }
  .flag-icon-hu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/hu.svg); }

.flag-icon-id {
  background-image: url(../fonts/flags//4x3/id.svg); }
  .flag-icon-id.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/id.svg); }

.flag-icon-ie {
  background-image: url(../fonts/flags//4x3/ie.svg); }
  .flag-icon-ie.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ie.svg); }

.flag-icon-il {
  background-image: url(../fonts/flags//4x3/il.svg); }
  .flag-icon-il.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/il.svg); }

.flag-icon-im {
  background-image: url(../fonts/flags//4x3/im.svg); }
  .flag-icon-im.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/im.svg); }

.flag-icon-in {
  background-image: url(../fonts/flags//4x3/in.svg); }
  .flag-icon-in.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/in.svg); }

.flag-icon-io {
  background-image: url(../fonts/flags//4x3/io.svg); }
  .flag-icon-io.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/io.svg); }

.flag-icon-iq {
  background-image: url(../fonts/flags//4x3/iq.svg); }
  .flag-icon-iq.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/iq.svg); }

.flag-icon-ir {
  background-image: url(../fonts/flags//4x3/ir.svg); }
  .flag-icon-ir.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ir.svg); }

.flag-icon-is {
  background-image: url(../fonts/flags//4x3/is.svg); }
  .flag-icon-is.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/is.svg); }

.flag-icon-it {
  background-image: url(../fonts/flags//4x3/it.svg); }
  .flag-icon-it.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/it.svg); }

.flag-icon-je {
  background-image: url(../fonts/flags//4x3/je.svg); }
  .flag-icon-je.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/je.svg); }

.flag-icon-jm {
  background-image: url(../fonts/flags//4x3/jm.svg); }
  .flag-icon-jm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/jm.svg); }

.flag-icon-jo {
  background-image: url(../fonts/flags//4x3/jo.svg); }
  .flag-icon-jo.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/jo.svg); }

.flag-icon-jp {
  background-image: url(../fonts/flags//4x3/jp.svg); }
  .flag-icon-jp.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/jp.svg); }

.flag-icon-ke {
  background-image: url(../fonts/flags//4x3/ke.svg); }
  .flag-icon-ke.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ke.svg); }

.flag-icon-kg {
  background-image: url(../fonts/flags//4x3/kg.svg); }
  .flag-icon-kg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kg.svg); }

.flag-icon-kh {
  background-image: url(../fonts/flags//4x3/kh.svg); }
  .flag-icon-kh.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kh.svg); }

.flag-icon-ki {
  background-image: url(../fonts/flags//4x3/ki.svg); }
  .flag-icon-ki.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ki.svg); }

.flag-icon-km {
  background-image: url(../fonts/flags//4x3/km.svg); }
  .flag-icon-km.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/km.svg); }

.flag-icon-kn {
  background-image: url(../fonts/flags//4x3/kn.svg); }
  .flag-icon-kn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kn.svg); }

.flag-icon-kp {
  background-image: url(../fonts/flags//4x3/kp.svg); }
  .flag-icon-kp.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kp.svg); }

.flag-icon-kr {
  background-image: url(../fonts/flags//4x3/kr.svg); }
  .flag-icon-kr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kr.svg); }

.flag-icon-kw {
  background-image: url(../fonts/flags//4x3/kw.svg); }
  .flag-icon-kw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kw.svg); }

.flag-icon-ky {
  background-image: url(../fonts/flags//4x3/ky.svg); }
  .flag-icon-ky.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ky.svg); }

.flag-icon-kz {
  background-image: url(../fonts/flags//4x3/kz.svg); }
  .flag-icon-kz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kz.svg); }

.flag-icon-la {
  background-image: url(../fonts/flags//4x3/la.svg); }
  .flag-icon-la.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/la.svg); }

.flag-icon-lb {
  background-image: url(../fonts/flags//4x3/lb.svg); }
  .flag-icon-lb.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lb.svg); }

.flag-icon-lc {
  background-image: url(../fonts/flags//4x3/lc.svg); }
  .flag-icon-lc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lc.svg); }

.flag-icon-li {
  background-image: url(../fonts/flags//4x3/li.svg); }
  .flag-icon-li.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/li.svg); }

.flag-icon-lk {
  background-image: url(../fonts/flags//4x3/lk.svg); }
  .flag-icon-lk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lk.svg); }

.flag-icon-lr {
  background-image: url(../fonts/flags//4x3/lr.svg); }
  .flag-icon-lr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lr.svg); }

.flag-icon-ls {
  background-image: url(../fonts/flags//4x3/ls.svg); }
  .flag-icon-ls.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ls.svg); }

.flag-icon-lt {
  background-image: url(../fonts/flags//4x3/lt.svg); }
  .flag-icon-lt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lt.svg); }

.flag-icon-lu {
  background-image: url(../fonts/flags//4x3/lu.svg); }
  .flag-icon-lu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lu.svg); }

.flag-icon-lv {
  background-image: url(../fonts/flags//4x3/lv.svg); }
  .flag-icon-lv.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lv.svg); }

.flag-icon-ly {
  background-image: url(../fonts/flags//4x3/ly.svg); }
  .flag-icon-ly.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ly.svg); }

.flag-icon-ma {
  background-image: url(../fonts/flags//4x3/ma.svg); }
  .flag-icon-ma.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ma.svg); }

.flag-icon-mc {
  background-image: url(../fonts/flags//4x3/mc.svg); }
  .flag-icon-mc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mc.svg); }

.flag-icon-md {
  background-image: url(../fonts/flags//4x3/md.svg); }
  .flag-icon-md.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/md.svg); }

.flag-icon-me {
  background-image: url(../fonts/flags//4x3/me.svg); }
  .flag-icon-me.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/me.svg); }

.flag-icon-mf {
  background-image: url(../fonts/flags//4x3/mf.svg); }
  .flag-icon-mf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mf.svg); }

.flag-icon-mg {
  background-image: url(../fonts/flags//4x3/mg.svg); }
  .flag-icon-mg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mg.svg); }

.flag-icon-mh {
  background-image: url(../fonts/flags//4x3/mh.svg); }
  .flag-icon-mh.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mh.svg); }

.flag-icon-mk {
  background-image: url(../fonts/flags//4x3/mk.svg); }
  .flag-icon-mk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mk.svg); }

.flag-icon-ml {
  background-image: url(../fonts/flags//4x3/ml.svg); }
  .flag-icon-ml.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ml.svg); }

.flag-icon-mm {
  background-image: url(../fonts/flags//4x3/mm.svg); }
  .flag-icon-mm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mm.svg); }

.flag-icon-mn {
  background-image: url(../fonts/flags//4x3/mn.svg); }
  .flag-icon-mn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mn.svg); }

.flag-icon-mo {
  background-image: url(../fonts/flags//4x3/mo.svg); }
  .flag-icon-mo.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mo.svg); }

.flag-icon-mp {
  background-image: url(../fonts/flags//4x3/mp.svg); }
  .flag-icon-mp.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mp.svg); }

.flag-icon-mq {
  background-image: url(../fonts/flags//4x3/mq.svg); }
  .flag-icon-mq.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mq.svg); }

.flag-icon-mr {
  background-image: url(../fonts/flags//4x3/mr.svg); }
  .flag-icon-mr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mr.svg); }

.flag-icon-ms {
  background-image: url(../fonts/flags//4x3/ms.svg); }
  .flag-icon-ms.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ms.svg); }

.flag-icon-mt {
  background-image: url(../fonts/flags//4x3/mt.svg); }
  .flag-icon-mt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mt.svg); }

.flag-icon-mu {
  background-image: url(../fonts/flags//4x3/mu.svg); }
  .flag-icon-mu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mu.svg); }

.flag-icon-mv {
  background-image: url(../fonts/flags//4x3/mv.svg); }
  .flag-icon-mv.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mv.svg); }

.flag-icon-mw {
  background-image: url(../fonts/flags//4x3/mw.svg); }
  .flag-icon-mw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mw.svg); }

.flag-icon-mx {
  background-image: url(../fonts/flags//4x3/mx.svg); }
  .flag-icon-mx.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mx.svg); }

.flag-icon-my {
  background-image: url(../fonts/flags//4x3/my.svg); }
  .flag-icon-my.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/my.svg); }

.flag-icon-mz {
  background-image: url(../fonts/flags//4x3/mz.svg); }
  .flag-icon-mz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mz.svg); }

.flag-icon-na {
  background-image: url(../fonts/flags//4x3/na.svg); }
  .flag-icon-na.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/na.svg); }

.flag-icon-nc {
  background-image: url(../fonts/flags//4x3/nc.svg); }
  .flag-icon-nc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/nc.svg); }

.flag-icon-ne {
  background-image: url(../fonts/flags//4x3/ne.svg); }
  .flag-icon-ne.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ne.svg); }

.flag-icon-nf {
  background-image: url(../fonts/flags//4x3/nf.svg); }
  .flag-icon-nf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/nf.svg); }

.flag-icon-ng {
  background-image: url(../fonts/flags//4x3/ng.svg); }
  .flag-icon-ng.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ng.svg); }

.flag-icon-ni {
  background-image: url(../fonts/flags//4x3/ni.svg); }
  .flag-icon-ni.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ni.svg); }

.flag-icon-nl {
  background-image: url(../fonts/flags//4x3/nl.svg); }
  .flag-icon-nl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/nl.svg); }

.flag-icon-no {
  background-image: url(../fonts/flags//4x3/no.svg); }
  .flag-icon-no.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/no.svg); }

.flag-icon-np {
  background-image: url(../fonts/flags//4x3/np.svg); }
  .flag-icon-np.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/np.svg); }

.flag-icon-nr {
  background-image: url(../fonts/flags//4x3/nr.svg); }
  .flag-icon-nr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/nr.svg); }

.flag-icon-nu {
  background-image: url(../fonts/flags//4x3/nu.svg); }
  .flag-icon-nu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/nu.svg); }

.flag-icon-nz {
  background-image: url(../fonts/flags//4x3/nz.svg); }
  .flag-icon-nz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/nz.svg); }

.flag-icon-om {
  background-image: url(../fonts/flags//4x3/om.svg); }
  .flag-icon-om.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/om.svg); }

.flag-icon-pa {
  background-image: url(../fonts/flags//4x3/pa.svg); }
  .flag-icon-pa.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pa.svg); }

.flag-icon-pe {
  background-image: url(../fonts/flags//4x3/pe.svg); }
  .flag-icon-pe.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pe.svg); }

.flag-icon-pf {
  background-image: url(../fonts/flags//4x3/pf.svg); }
  .flag-icon-pf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pf.svg); }

.flag-icon-pg {
  background-image: url(../fonts/flags//4x3/pg.svg); }
  .flag-icon-pg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pg.svg); }

.flag-icon-ph {
  background-image: url(../fonts/flags//4x3/ph.svg); }
  .flag-icon-ph.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ph.svg); }

.flag-icon-pk {
  background-image: url(../fonts/flags//4x3/pk.svg); }
  .flag-icon-pk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pk.svg); }

.flag-icon-pl {
  background-image: url(../fonts/flags//4x3/pl.svg); }
  .flag-icon-pl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pl.svg); }

.flag-icon-pm {
  background-image: url(../fonts/flags//4x3/pm.svg); }
  .flag-icon-pm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pm.svg); }

.flag-icon-pn {
  background-image: url(../fonts/flags//4x3/pn.svg); }
  .flag-icon-pn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pn.svg); }

.flag-icon-pr {
  background-image: url(../fonts/flags//4x3/pr.svg); }
  .flag-icon-pr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pr.svg); }

.flag-icon-ps {
  background-image: url(../fonts/flags//4x3/ps.svg); }
  .flag-icon-ps.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ps.svg); }

.flag-icon-pt {
  background-image: url(../fonts/flags//4x3/pt.svg); }
  .flag-icon-pt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pt.svg); }

.flag-icon-pw {
  background-image: url(../fonts/flags//4x3/pw.svg); }
  .flag-icon-pw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pw.svg); }

.flag-icon-py {
  background-image: url(../fonts/flags//4x3/py.svg); }
  .flag-icon-py.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/py.svg); }

.flag-icon-qa {
  background-image: url(../fonts/flags//4x3/qa.svg); }
  .flag-icon-qa.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/qa.svg); }

.flag-icon-re {
  background-image: url(../fonts/flags//4x3/re.svg); }
  .flag-icon-re.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/re.svg); }

.flag-icon-ro {
  background-image: url(../fonts/flags//4x3/ro.svg); }
  .flag-icon-ro.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ro.svg); }

.flag-icon-rs {
  background-image: url(../fonts/flags//4x3/rs.svg); }
  .flag-icon-rs.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/rs.svg); }

.flag-icon-ru {
  background-image: url(../fonts/flags//4x3/ru.svg); }
  .flag-icon-ru.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ru.svg); }

.flag-icon-rw {
  background-image: url(../fonts/flags//4x3/rw.svg); }
  .flag-icon-rw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/rw.svg); }

.flag-icon-sa {
  background-image: url(../fonts/flags//4x3/sa.svg); }
  .flag-icon-sa.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sa.svg); }

.flag-icon-sb {
  background-image: url(../fonts/flags//4x3/sb.svg); }
  .flag-icon-sb.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sb.svg); }

.flag-icon-sc {
  background-image: url(../fonts/flags//4x3/sc.svg); }
  .flag-icon-sc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sc.svg); }

.flag-icon-sd {
  background-image: url(../fonts/flags//4x3/sd.svg); }
  .flag-icon-sd.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sd.svg); }

.flag-icon-se {
  background-image: url(../fonts/flags//4x3/se.svg); }
  .flag-icon-se.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/se.svg); }

.flag-icon-sg {
  background-image: url(../fonts/flags//4x3/sg.svg); }
  .flag-icon-sg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sg.svg); }

.flag-icon-sh {
  background-image: url(../fonts/flags//4x3/sh.svg); }
  .flag-icon-sh.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sh.svg); }

.flag-icon-si {
  background-image: url(../fonts/flags//4x3/si.svg); }
  .flag-icon-si.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/si.svg); }

.flag-icon-sj {
  background-image: url(../fonts/flags//4x3/sj.svg); }
  .flag-icon-sj.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sj.svg); }

.flag-icon-sk {
  background-image: url(../fonts/flags//4x3/sk.svg); }
  .flag-icon-sk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sk.svg); }

.flag-icon-sl {
  background-image: url(../fonts/flags//4x3/sl.svg); }
  .flag-icon-sl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sl.svg); }

.flag-icon-sm {
  background-image: url(../fonts/flags//4x3/sm.svg); }
  .flag-icon-sm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sm.svg); }

.flag-icon-sn {
  background-image: url(../fonts/flags//4x3/sn.svg); }
  .flag-icon-sn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sn.svg); }

.flag-icon-so {
  background-image: url(../fonts/flags//4x3/so.svg); }
  .flag-icon-so.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/so.svg); }

.flag-icon-sr {
  background-image: url(../fonts/flags//4x3/sr.svg); }
  .flag-icon-sr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sr.svg); }

.flag-icon-ss {
  background-image: url(../fonts/flags//4x3/ss.svg); }
  .flag-icon-ss.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ss.svg); }

.flag-icon-st {
  background-image: url(../fonts/flags//4x3/st.svg); }
  .flag-icon-st.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/st.svg); }

.flag-icon-sv {
  background-image: url(../fonts/flags//4x3/sv.svg); }
  .flag-icon-sv.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sv.svg); }

.flag-icon-sx {
  background-image: url(../fonts/flags//4x3/sx.svg); }
  .flag-icon-sx.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sx.svg); }

.flag-icon-sy {
  background-image: url(../fonts/flags//4x3/sy.svg); }
  .flag-icon-sy.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sy.svg); }

.flag-icon-sz {
  background-image: url(../fonts/flags//4x3/sz.svg); }
  .flag-icon-sz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sz.svg); }

.flag-icon-tc {
  background-image: url(../fonts/flags//4x3/tc.svg); }
  .flag-icon-tc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tc.svg); }

.flag-icon-td {
  background-image: url(../fonts/flags//4x3/td.svg); }
  .flag-icon-td.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/td.svg); }

.flag-icon-tf {
  background-image: url(../fonts/flags//4x3/tf.svg); }
  .flag-icon-tf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tf.svg); }

.flag-icon-tg {
  background-image: url(../fonts/flags//4x3/tg.svg); }
  .flag-icon-tg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tg.svg); }

.flag-icon-th {
  background-image: url(../fonts/flags//4x3/th.svg); }
  .flag-icon-th.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/th.svg); }

.flag-icon-tj {
  background-image: url(../fonts/flags//4x3/tj.svg); }
  .flag-icon-tj.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tj.svg); }

.flag-icon-tk {
  background-image: url(../fonts/flags//4x3/tk.svg); }
  .flag-icon-tk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tk.svg); }

.flag-icon-tl {
  background-image: url(../fonts/flags//4x3/tl.svg); }
  .flag-icon-tl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tl.svg); }

.flag-icon-tm {
  background-image: url(../fonts/flags//4x3/tm.svg); }
  .flag-icon-tm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tm.svg); }

.flag-icon-tn {
  background-image: url(../fonts/flags//4x3/tn.svg); }
  .flag-icon-tn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tn.svg); }

.flag-icon-to {
  background-image: url(../fonts/flags//4x3/to.svg); }
  .flag-icon-to.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/to.svg); }

.flag-icon-tr {
  background-image: url(../fonts/flags//4x3/tr.svg); }
  .flag-icon-tr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tr.svg); }

.flag-icon-tt {
  background-image: url(../fonts/flags//4x3/tt.svg); }
  .flag-icon-tt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tt.svg); }

.flag-icon-tv {
  background-image: url(../fonts/flags//4x3/tv.svg); }
  .flag-icon-tv.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tv.svg); }

.flag-icon-tw {
  background-image: url(../fonts/flags//4x3/tw.svg); }
  .flag-icon-tw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tw.svg); }

.flag-icon-tz {
  background-image: url(../fonts/flags//4x3/tz.svg); }
  .flag-icon-tz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tz.svg); }

.flag-icon-ua {
  background-image: url(../fonts/flags//4x3/ua.svg); }
  .flag-icon-ua.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ua.svg); }

.flag-icon-ug {
  background-image: url(../fonts/flags//4x3/ug.svg); }
  .flag-icon-ug.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ug.svg); }

.flag-icon-um {
  background-image: url(../fonts/flags//4x3/um.svg); }
  .flag-icon-um.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/um.svg); }

.flag-icon-us {
  background-image: url(../fonts/flags//4x3/us.svg); }
  .flag-icon-us.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/us.svg); }

.flag-icon-uy {
  background-image: url(../fonts/flags//4x3/uy.svg); }
  .flag-icon-uy.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/uy.svg); }

.flag-icon-uz {
  background-image: url(../fonts/flags//4x3/uz.svg); }
  .flag-icon-uz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/uz.svg); }

.flag-icon-va {
  background-image: url(../fonts/flags//4x3/va.svg); }
  .flag-icon-va.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/va.svg); }

.flag-icon-vc {
  background-image: url(../fonts/flags//4x3/vc.svg); }
  .flag-icon-vc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/vc.svg); }

.flag-icon-ve {
  background-image: url(../fonts/flags//4x3/ve.svg); }
  .flag-icon-ve.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ve.svg); }

.flag-icon-vg {
  background-image: url(../fonts/flags//4x3/vg.svg); }
  .flag-icon-vg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/vg.svg); }

.flag-icon-vi {
  background-image: url(../fonts/flags//4x3/vi.svg); }
  .flag-icon-vi.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/vi.svg); }

.flag-icon-vn {
  background-image: url(../fonts/flags//4x3/vn.svg); }
  .flag-icon-vn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/vn.svg); }

.flag-icon-vu {
  background-image: url(../fonts/flags//4x3/vu.svg); }
  .flag-icon-vu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/vu.svg); }

.flag-icon-wf {
  background-image: url(../fonts/flags//4x3/wf.svg); }
  .flag-icon-wf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/wf.svg); }

.flag-icon-ws {
  background-image: url(../fonts/flags//4x3/ws.svg); }
  .flag-icon-ws.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ws.svg); }

.flag-icon-ye {
  background-image: url(../fonts/flags//4x3/ye.svg); }
  .flag-icon-ye.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ye.svg); }

.flag-icon-yt {
  background-image: url(../fonts/flags//4x3/yt.svg); }
  .flag-icon-yt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/yt.svg); }

.flag-icon-za {
  background-image: url(../fonts/flags//4x3/za.svg); }
  .flag-icon-za.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/za.svg); }

.flag-icon-zm {
  background-image: url(../fonts/flags//4x3/zm.svg); }
  .flag-icon-zm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/zm.svg); }

.flag-icon-zw {
  background-image: url(../fonts/flags//4x3/zw.svg); }
  .flag-icon-zw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/zw.svg); }

.flag-icon-es-ct {
  background-image: url(../fonts/flags//4x3/es-ct.svg); }
  .flag-icon-es-ct.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/es-ct.svg); }

.flag-icon-eu {
  background-image: url(../fonts/flags//4x3/eu.svg); }
  .flag-icon-eu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/eu.svg); }

.flag-icon-gb-eng {
  background-image: url(../fonts/flags//4x3/gb-eng.svg); }
  .flag-icon-gb-eng.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gb-eng.svg); }

.flag-icon-gb-nir {
  background-image: url(../fonts/flags//4x3/gb-nir.svg); }
  .flag-icon-gb-nir.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gb-nir.svg); }

.flag-icon-gb-sct {
  background-image: url(../fonts/flags//4x3/gb-sct.svg); }
  .flag-icon-gb-sct.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gb-sct.svg); }

.flag-icon-gb-wls {
  background-image: url(../fonts/flags//4x3/gb-wls.svg); }
  .flag-icon-gb-wls.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gb-wls.svg); }

.flag-icon-un {
  background-image: url(../fonts/flags//4x3/un.svg); }
  .flag-icon-un.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/un.svg); }

.toast-messages {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  position: fixed;
  top: 0;
  padding: 50px 16px 16px;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  z-index: 1100; }
  .toast-messages .alert {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    text-align: center; }
    .toast-messages .alert.show {
      display: block; }
    .toast-messages .alert:not(.alert-dismissible) {
      animation: fadeIn 200ms, fadeOut 200ms linear calc(5s - 200ms); }
    .toast-messages .alert.alert-dismissible {
      animation: fadeIn 200ms;
      padding-right: 50px; }
      .toast-messages .alert.alert-dismissible .close {
        padding: 16px; }

.alert {
  border-radius: var(--border-radius);
  padding: 16px; }
  .alert-success {
    background: #eaf4ed;
    border-color: #eaf4ed;
    color: #005518; }
    .alert-success a, .alert-success a:hover {
      color: #00220a; }
    .alert-success .close {
      color: #005518; }
  .alert-warning {
    background: #fcf8e9;
    border-color: #fcf8e9;
    color: #997a00; }
    .alert-warning a, .alert-warning a:hover {
      color: #665200; }
    .alert-warning .close {
      color: #997a00; }
  .alert-danger {
    background: #f4e9ea;
    border-color: #f4e9ea;
    color: #72060b; }
    .alert-danger a, .alert-danger a:hover {
      color: #420306; }
    .alert-danger .close {
      color: #72060b; }
  .alert-info {
    background: #5cd4f5;
    border-color: #5cd4f5;
    color: black; }
    .alert-info a, .alert-info a:hover {
      color: black; }
    .alert-info .close {
      color: black; }
  .alert-dismissible {
    padding-right: calc(32px + 12px); }
  .alert .close {
    padding: 16px;
    line-height: 22px; }
    .alert .close:before {
      position: static;
      transform: none;
      margin: 0;
      font-size: 12px; }

.badge-product-container {
  position: relative;
  z-index: 1;
  margin: 1px;
  display: flex;
  align-items: flex-start;
  pointer-events: none;
  top: 0; }
  @media (min-width: 769px) {
    .badge-product-container.badge-image-over {
      position: absolute;
      flex-direction: column; } }

.product-detail .badge-product-container {
  position: relative; }
  @media (min-width: 769px) {
    .product-detail .badge-product-container.badge-image-over {
      position: absolute;
      flex-direction: column; } }

.badge-product {
  text-align: left;
  padding: 2px 3px;
  margin-bottom: 1px;
  font-weight: bold;
  line-height: 1.4;
  border-radius: var(--border-radius);
  border-width: 1px;
  border-style: solid; }
  .badge-product:not([class*='text--']) {
    color: var(--base-font-color); }
  .badge-product:not([class*='background--']) {
    background-color: #FFFFFF; }
  .badge-product:not([class*='border--']) {
    border-color: transparent; }
  .badge-product:not([class*='font-size--']) {
    font-size: 10px; }
  .badge-product:not([class*='font--']) {
    font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif; }
  @media (min-width: 1024px) {
    .badge-product {
      padding: 5px 6px;
      font-size: var(--base-font-size); } }

.blog-nav .nav {
  background: #F2F2F2;
  border-bottom: 1px solid var(--base-border-color); }

.blog-nav .nav-item .nav-link {
  text-decoration: none; }

.blog-nav .nav-item.active .nav-link {
  font-weight: bold; }

.blog-results {
  padding-top: 16px;
  padding-bottom: 16px; }
  .blog-results .result-count {
    padding: 24px 0; }

.blog-detail-header .page-header-subtitle {
  display: flex; }

.blog-detail-header.text-center .page-header-subtitle {
  justify-content: center; }

.blog-detail-header .page-header-title {
  margin-top: 10px; }

.blog-detail-header .blog-category {
  color: #767676; }
  .blog-detail-header .blog-category ~ .blog-creation-date::before {
    content: "|";
    color: #767676;
    padding-left: 10px;
    padding-right: 10px; }

.blog-detail-header .blog-creation-date {
  color: #767676; }

.blog-detail-header .page-header-author {
  margin-top: 10px;
  color: #767676; }

.blog .social-container .social-icons,
.storepage .social-container .social-icons {
  display: inline-flex;
  align-items: center;
  justify-content: center; }
  .blog .social-container .social-icons li:not(:last-child),
  .storepage .social-container .social-icons li:not(:last-child) {
    margin-right: 30px; }
  .blog .social-container .social-icons .share-icons,
  .storepage .social-container .social-icons .share-icons {
    font-size: 30px;
    color: var(--brand-primary); }
    .blog .social-container .social-icons .share-icons:hover,
    .storepage .social-container .social-icons .share-icons:hover {
      color: var(--brand-secondary); }

.blog .blog-footer {
  text-align: center; }

.blog .social-container {
  border-top: none;
  margin-top: 80px;
  padding-top: 0; }

.blog .search-words-container {
  display: inline-flex;
  justify-content: center;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--base-border-color); }
  .blog .search-words-container label {
    margin-right: 7px;
    margin-bottom: 0; }
  .blog .search-words-container .search-words {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap; }
    .blog .search-words-container .search-words li {
      padding: 0; }
    .blog .search-words-container .search-words li:not(:last-child) a::after {
      content: ",";
      margin-left: 0;
      margin-right: 7px; }

.breadcrumb {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin: 0;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0.05em; }
  .breadcrumb li {
    padding: 0; }
  .breadcrumb .breadcrumb-item a {
    color: var(--brand-primary);
    font-weight: 500;
    text-decoration: none; }
    .breadcrumb .breadcrumb-item a:hover {
      color: var(--brand-secondary);
      text-decoration: underline; }
  .breadcrumb .breadcrumb-item + .breadcrumb-item {
    display: flex; }
    .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
      content: "/";
      color: var(--brand-primary);
      font-weight: 500;
      padding: 0 8px; }
    .breadcrumb .breadcrumb-item + .breadcrumb-item:last-of-type a {
      color: var(--brand-secondary); }
  .breadcrumb .breadcrumb-item h1 {
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    margin: 0; }

.card {
  border: 1px solid #C5C5C5;
  border-radius: var(--border-radius);
  margin-bottom: 16px;
  font-size: var(--base-font-size);
  letter-spacing: 0.1em;
  line-height: 1.4;
  background-color: transparent; }
  .card .card-header {
    background-color: #FFFFFF;
    color: var(--brand-primary);
    border-bottom: none;
    padding: 0;
    flex-basis: auto; }
    .card .card-header .pull-right {
      color: var(--brand-primary);
      font-size: var(--base-font-size);
      letter-spacing: 0.1em;
      line-height: 1.4;
      padding-top: 17px;
      padding-right: 20px; }
  .card .card-title,
  .card .card-header h2,
  .card .card-header h3,
  .card .card-header h4,
  .card .card-header .checkout-card-header,
  .card h2.card-header {
    text-decoration: none;
    position: relative;
    margin-bottom: 0;
    padding-top: 17px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 22px; }
  .card .card-title:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom),
  .card .card-header h2,
  .card .card-header h3,
  .card .card-header h4,
  .card .card-header .checkout-card-header,
  .card h2.card-header {
    font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.01em;
    line-height: 20px;
    text-transform: none; }
  .card .card-body {
    background-color: #FFFFFF;
    color: var(--base-font-color);
    padding: 20px; }
  .card .card-header ~ .card-body {
    padding: 0 20px 20px 20px; }
  .card .card-footer {
    background-color: #FFFFFF;
    color: var(--brand-primary);
    padding: 17px 20px 22px 20px; }
    .card .card-footer a {
      color: var(--brand-primary); }
  .card .card-header,
  .card .card-body,
  .card .card-footer {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    border-radius: 0; }
  .card:first-child {
    border-radius: calc(var(--border-radius) - 1px) calc(var(--border-radius) - 1px) 0 0; }
    .card:first-child .card-header {
      border-top: 0;
      border-radius: calc(var(--border-radius) - 1px) calc(var(--border-radius) - 1px) 0 0; }
  .card + .card {
    border-top: 0;
    border-radius: 0; }
    .card + .card:last-child {
      border-radius: 0 0 calc(var(--border-radius) - 1px) calc(var(--border-radius) - 1px); }
  .card .card {
    margin: -1px; }
  .card:last-child {
    border-top: 0;
    border-radius: 0 0 calc(var(--border-radius) - 1px) calc(var(--border-radius) - 1px); }
    .card:last-child .card-header:last-child,
    .card:last-child .card-body:last-child,
    .card:last-child .card-footer:last-child {
      border-radius: 0 0 calc(var(--border-radius) - 1px) calc(var(--border-radius) - 1px); }
  .card:only-child {
    border-top: 1px solid #C5C5C5;
    border-bottom: 1px solid #C5C5C5;
    border-radius: calc(var(--border-radius) - 1px); }
    .card:only-child .card-header {
      border-top: 0; }
    .card:only-child .card-header:only-child,
    .card:only-child .card-body:only-child,
    .card:only-child .card-footer:only-child {
      border-radius: calc(var(--border-radius) - 1px); }
  .accordion .card {
    margin-bottom: 0; }
    .accordion .card .card-header [type="button"] {
      text-align: left;
      width: 100%; }
      .accordion .card .card-header [type="button"]:after {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 12px;
        color: #000000;
        font-weight: normal; }
      .accordion .card .card-header [type="button"][class*="btn-"]:not(.title) {
        padding-right: calc(var(--button-padding-x) + 20px); }
        .accordion .card .card-header [type="button"][class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #000000;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
        .accordion .card .card-header [type="button"][class*="btn-"]:not(.title).btn-sm, .accordion .card .card-header .btn-group-sm > .btn[type="button"][class*="btn-"]:not(.title) {
          padding-right: calc(20px + 20px); }
          .accordion .card .card-header [type="button"][class*="btn-"]:not(.title).btn-sm:after, .accordion .card .card-header .btn-group-sm > .btn[type="button"][class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #000000;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .accordion .card .card-header [type="button"][class*="btn-"]:not(.title) [class*="icon-"],
        .accordion .card .card-header [type="button"][class*="btn-"]:not(.title) [class^="icon-"],
        .accordion .card .card-header [type="button"][class*="btn-"]:not(.title) .fa {
          display: none; }
      .accordion .card .card-header [type="button"].collapsed:after {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 12px;
        color: #000000;
        font-weight: normal; }
      .accordion .card .card-header [type="button"].collapsed[class*="btn-"]:not(.title) {
        padding-right: calc(var(--button-padding-x) + 20px); }
        .accordion .card .card-header [type="button"].collapsed[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #000000;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
        .accordion .card .card-header [type="button"].collapsed[class*="btn-"]:not(.title).btn-sm, .accordion .card .card-header .btn-group-sm > .btn[type="button"].collapsed[class*="btn-"]:not(.title) {
          padding-right: calc(20px + 20px); }
          .accordion .card .card-header [type="button"].collapsed[class*="btn-"]:not(.title).btn-sm:after, .accordion .card .card-header .btn-group-sm > .btn[type="button"].collapsed[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #000000;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .accordion .card .card-header [type="button"].collapsed[class*="btn-"]:not(.title) [class*="icon-"],
        .accordion .card .card-header [type="button"].collapsed[class*="btn-"]:not(.title) [class^="icon-"],
        .accordion .card .card-header [type="button"].collapsed[class*="btn-"]:not(.title) .fa {
          display: none; }
      .accordion .card .card-header [type="button"]:after {
        position: absolute;
        top: 17px;
        right: 20px; }
    .accordion .card .card-header .no-icon [type="button"]:after {
      content: ''; }
    .accordion .card .card-header .no-icon [type="button"].collapsed:after {
      content: ''; }
    .accordion .card .card-header,
    .accordion .card .card-body {
      border-top: 1px solid #C5C5C5; }
    .accordion .card .card-body {
      padding: 0;
      margin: 0; }
    .accordion .card:first-child .card-header:first-child,
    .accordion .card:first-child .card-body:first-child {
      border-top: 0; }
    .accordion .card:only-child:last-of-type {
      border-top: 1px solid #C5C5C5;
      border-bottom: 1px solid #C5C5C5;
      border-radius: calc(var(--border-radius) - 1px); }
  .accordion.no-icon .card .card-header [type="button"]:after {
    content: ''; }
  .accordion.no-icon .card .card-header [type="button"].collapsed:after {
    content: ''; }
  .card.ghost {
    opacity: 0.5; }
  .card.aside {
    border: 1px solid #C5C5C5;
    border-width: 1px 0; }
    .card.aside .card-header {
      border: none; }
      .card.aside .card-header .card-title {
        font-size: 13px;
        font-weight: bold;
        line-height: 18px;
        padding-top: 20px;
        padding-left: 0;
        padding-right: 20px;
        padding-bottom: 20px; }
    .card.aside .card-body {
      padding: 0;
      border: none;
      z-index: 1; }
    .card.aside .card-footer {
      border: none; }
  .card.no-borders {
    border: none; }
    .card.no-borders .card-header,
    .card.no-borders .card-title,
    .card.no-borders .card-body,
    .card.no-borders .card-footer {
      border: none;
      padding-left: 0;
      padding-right: 0; }

.card .card-header .product-line-item {
  padding: 20px; }
  .card .card-header .product-line-item .line-item-header {
    margin-bottom: 5px; }
  .card .card-header .product-line-item .product-line-item-details .item-image {
    width: 100px; }
    .card .card-header .product-line-item .product-line-item-details .item-image .product-image {
      max-width: 100%; }

.carousel .icon-prev,
.carousel .icon-next {
  background-color: #FFFFFF;
  font-size: 18px;
  padding: 16px;
  color: #000000; }

.carousel .icon-prev:before {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: inherit;
  color: inherit;
  font-weight: normal; }

.carousel .icon-prev[class*="btn-"]:not(.title) {
  padding-left: calc(var(--button-padding-x) + 20px); }
  .carousel .icon-prev[class*="btn-"]:not(.title):before {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: inherit;
    font-weight: normal;
    position: absolute;
    transform: translateX(-30px); }
  .carousel .icon-prev[class*="btn-"]:not(.title).btn-sm, .carousel .btn-group-sm > .icon-prev.btn[class*="btn-"]:not(.title) {
    padding-left: calc(20px + 20px); }
    .carousel .icon-prev[class*="btn-"]:not(.title).btn-sm:before, .carousel .btn-group-sm > .icon-prev.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .carousel .icon-prev[class*="btn-"]:not(.title) [class*="icon-"],
  .carousel .icon-prev[class*="btn-"]:not(.title) [class^="icon-"],
  .carousel .icon-prev[class*="btn-"]:not(.title) .fa {
    display: none; }

.carousel .icon-next:before {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: inherit;
  color: inherit;
  font-weight: normal; }

.carousel .icon-next[class*="btn-"]:not(.title) {
  padding-left: calc(var(--button-padding-x) + 20px); }
  .carousel .icon-next[class*="btn-"]:not(.title):before {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: inherit;
    font-weight: normal;
    position: absolute;
    transform: translateX(-30px); }
  .carousel .icon-next[class*="btn-"]:not(.title).btn-sm, .carousel .btn-group-sm > .icon-next.btn[class*="btn-"]:not(.title) {
    padding-left: calc(20px + 20px); }
    .carousel .icon-next[class*="btn-"]:not(.title).btn-sm:before, .carousel .btn-group-sm > .icon-next.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .carousel .icon-next[class*="btn-"]:not(.title) [class*="icon-"],
  .carousel .icon-next[class*="btn-"]:not(.title) [class^="icon-"],
  .carousel .icon-next[class*="btn-"]:not(.title) .fa {
    display: none; }

.carousel .carousel-control-prev {
  justify-content: flex-start; }

.carousel .carousel-control-next {
  justify-content: flex-end; }

.category-tile {
  display: block;
  position: relative;
  text-align: center;
  text-decoration: none; }
  .category-tile:hover {
    text-decoration: none; }
  @media (min-width: 769px) {
    .category-tile.zoom .image-wrapper {
      will-change: transform;
      transition: transform 200ms ease-out; }
    .category-tile.zoom:hover .image-wrapper {
      transform: scale(1.2); } }
  .category-tile .image-cropper {
    border-radius: var(--border-radius);
    overflow: hidden;
    margin: auto;
    width: 100%;
    max-width: 100%;
    max-height: 100%; }
  .category-tile .image-wrapper {
    width: 100%;
    position: relative; }
    .category-tile .image-wrapper .background-image {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .category-tile .image-wrapper-custom .background-image-tablet,
    .category-tile .image-wrapper-custom .background-image-desktop {
      display: none; }
    @media (min-width: 769px) {
      .category-tile .image-wrapper-custom .background-image-mobile,
      .category-tile .image-wrapper-custom .background-image-desktop {
        display: none; }
      .category-tile .image-wrapper-custom .background-image-tablet {
        display: block; } }
    @media (min-width: 1024px) {
      .category-tile .image-wrapper-custom .background-image-mobile,
      .category-tile .image-wrapper-custom .background-image-tablet {
        display: none; }
      .category-tile .image-wrapper-custom .background-image-desktop {
        display: block; } }
    .category-tile .image-wrapper .video-container .video-aspect-ratio {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      padding-top: calc(9 / 16 * 100%);
      padding-left: calc(16 / 9 * 100vh);
      width: 0;
      height: 0;
      min-height: 100%;
      min-width: 100%; }
      .category-tile .image-wrapper .video-container .video-aspect-ratio .video-player {
        position: static;
        pointer-events: none; }
      .category-tile .image-wrapper .video-container .video-aspect-ratio iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0; }
        .category-tile .image-wrapper .video-container .video-aspect-ratio iframe.video-player {
          position: absolute; }
  .category-tile .category-tile-text .primary-text:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
    font-size: calc(var(--base-font-size) + 1px); }
  .category-tile .category-tile-text .secondary-text:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
    font-size: calc(var(--base-font-size)); }
  .category-tile.text-placement-below .category-tile-text {
    margin-top: 16px; }
  .category-tile.text-placement-inside::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: var(--border-radius); }
  .category-tile.text-placement-inside .category-tile-text {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    color: #FFFFFF;
    z-index: 1;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.6); }
  .category-tile.aspect-ratio-square .image-wrapper::after {
    display: block;
    content: "";
    clear: both; }
  .category-tile.aspect-ratio-square .image-wrapper::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(1 / 1 * 100%); }
  .category-tile.aspect-ratio-landscape .image-wrapper::after {
    display: block;
    content: "";
    clear: both; }
  .category-tile.aspect-ratio-landscape .image-wrapper::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(1 / 1.5 * 100%); }
  .category-tile.aspect-ratio-portrait .image-wrapper::after {
    display: block;
    content: "";
    clear: both; }
  .category-tile.aspect-ratio-portrait .image-wrapper::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(1.5 / 1 * 100%); }

@media (max-width: 543.98px) {
  .collapsible-xs {
    border-color: #C5C5C5; }
    .collapsible-xs .card-header {
      padding: 0;
      border-color: #C5C5C5;
      border-radius: 0; }
      .collapsible-xs .card-header:first-child {
        border-radius: 0; }
      .collapsible-xs .card-header:last-child {
        border-radius: 0; }
      .collapsible-xs .card-header button {
        text-align: left;
        display: block;
        width: 100%; }
        .collapsible-xs .card-header button:focus {
          z-index: 1; }
    .collapsible-xs .title {
      background-color: transparent;
      color: var(--brand-primary);
      padding-top: 17px;
      padding-right: 37px;
      padding-bottom: 22px;
      padding-left: 20px;
      user-select: none;
      border-width: 0;
      cursor: pointer; }
      .collapsible-xs .title:after {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 12px;
        color: #000000;
        font-weight: normal; }
      .collapsible-xs .title[class*="btn-"]:not(.title) {
        padding-right: calc(var(--button-padding-x) + 20px); }
        .collapsible-xs .title[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #000000;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
        .collapsible-xs .title[class*="btn-"]:not(.title).btn-sm, .collapsible-xs .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
          padding-right: calc(20px + 20px); }
          .collapsible-xs .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-xs .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #000000;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .collapsible-xs .title[class*="btn-"]:not(.title) [class*="icon-"],
        .collapsible-xs .title[class*="btn-"]:not(.title) [class^="icon-"],
        .collapsible-xs .title[class*="btn-"]:not(.title) .fa {
          display: none; }
      .collapsible-xs .title::after {
        position: absolute;
        top: 17px;
        right: 20px; }
    .collapsible-xs .content,
    .collapsible-xs .card-body {
      display: none;
      background-color: transparent;
      color: var(--base-font-color);
      border-radius: 0; }
      .collapsible-xs .content:first-child,
      .collapsible-xs .card-body:first-child {
        border-radius: 0; }
      .collapsible-xs .content:last-child,
      .collapsible-xs .card-body:last-child {
        border-radius: 0;
        border-bottom: none; }
    .collapsible-xs:not(:last-child) {
      margin-bottom: 0;
      border-bottom: none;
      border-radius: 0; }
      .collapsible-xs:not(:last-child) + .collapsible-xs {
        border-radius: 0; }
    .collapsible-xs.aside .title::after, .collapsible-xs.no-borders .title::after {
      right: 0; }
    .collapsible-xs.aside .title::after {
      top: 20px; }
    .collapsible-xs.no-borders .title::after {
      top: 17px; }
    .collapsible-xs:first-child .card-header,
    .collapsible-xs:first-child .card-title {
      border-radius: calc(var(--border-radius) - 1px) calc(var(--border-radius) - 1px) 0 0; }
    .collapsible-xs:last-child .card-header,
    .collapsible-xs:last-child .card-title,
    .collapsible-xs:last-child .card-body {
      border-radius: 0 0 calc(var(--border-radius) - 1px) calc(var(--border-radius) - 1px); }
    .collapsible-xs:last-child:not(.active):not(.aside):not(.no-borders) .card-header {
      border-bottom: 1px solid #C5C5C5; }
    .collapsible-xs.active > .card-header > .title:after,
    .collapsible-xs.active > .title:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: #000000;
      font-weight: normal; }
    .collapsible-xs.active > .card-header > .title[class*="btn-"]:not(.title),
    .collapsible-xs.active > .title[class*="btn-"]:not(.title) {
      padding-right: calc(var(--button-padding-x) + 20px); }
      .collapsible-xs.active > .card-header > .title[class*="btn-"]:not(.title):after,
      .collapsible-xs.active > .title[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #000000;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .collapsible-xs.active > .card-header > .title[class*="btn-"]:not(.title).btn-sm, .collapsible-xs.active > .btn-group-sm.card-header > .title.btn[class*="btn-"]:not(.title),
      .collapsible-xs.active > .title[class*="btn-"]:not(.title).btn-sm,
      .btn-group-sm.collapsible-xs.active > .title.btn[class*="btn-"]:not(.title) {
        padding-right: calc(20px + 20px); }
        .collapsible-xs.active > .card-header > .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-xs.active > .btn-group-sm.card-header > .title.btn[class*="btn-"]:not(.title):after,
        .collapsible-xs.active > .title[class*="btn-"]:not(.title).btn-sm:after,
        .btn-group-sm.collapsible-xs.active > .title.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #000000;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .collapsible-xs.active > .card-header > .title[class*="btn-"]:not(.title) [class*="icon-"],
      .collapsible-xs.active > .card-header > .title[class*="btn-"]:not(.title) [class^="icon-"],
      .collapsible-xs.active > .card-header > .title[class*="btn-"]:not(.title) .fa,
      .collapsible-xs.active > .title[class*="btn-"]:not(.title) [class*="icon-"],
      .collapsible-xs.active > .title[class*="btn-"]:not(.title) [class^="icon-"],
      .collapsible-xs.active > .title[class*="btn-"]:not(.title) .fa {
        display: none; }
    .collapsible-xs.active > .content,
    .collapsible-xs.active > .content-asset > .content,
    .collapsible-xs.active > .card-body {
      display: block; }
      .collapsible-xs.active > .content > .content:not(.values),
      .collapsible-xs.active > .content-asset > .content > .content:not(.values),
      .collapsible-xs.active > .card-body > .content:not(.values) {
        display: block; }
    .collapsible-xs.active:last-child .card-header,
    .collapsible-xs.active:last-child .card-title {
      border-radius: 0;
      border-bottom: none; }
    .collapsible-xs.active:last-child:not(.aside):not(.no-borders) .card-body {
      border-bottom: 1px solid #C5C5C5; } }

@media (max-width: 768.98px) {
  .collapsible-sm {
    border-color: #C5C5C5; }
    .collapsible-sm .card-header {
      padding: 0;
      border-color: #C5C5C5;
      border-radius: 0; }
      .collapsible-sm .card-header:first-child {
        border-radius: 0; }
      .collapsible-sm .card-header:last-child {
        border-radius: 0; }
      .collapsible-sm .card-header button {
        text-align: left;
        display: block;
        width: 100%; }
        .collapsible-sm .card-header button:focus {
          z-index: 1; }
    .collapsible-sm .title {
      background-color: transparent;
      color: var(--brand-primary);
      padding-top: 17px;
      padding-right: 37px;
      padding-bottom: 22px;
      padding-left: 20px;
      user-select: none;
      border-width: 0;
      cursor: pointer; }
      .collapsible-sm .title:after {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 12px;
        color: #000000;
        font-weight: normal; }
      .collapsible-sm .title[class*="btn-"]:not(.title) {
        padding-right: calc(var(--button-padding-x) + 20px); }
        .collapsible-sm .title[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #000000;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
        .collapsible-sm .title[class*="btn-"]:not(.title).btn-sm, .collapsible-sm .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
          padding-right: calc(20px + 20px); }
          .collapsible-sm .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-sm .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #000000;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .collapsible-sm .title[class*="btn-"]:not(.title) [class*="icon-"],
        .collapsible-sm .title[class*="btn-"]:not(.title) [class^="icon-"],
        .collapsible-sm .title[class*="btn-"]:not(.title) .fa {
          display: none; }
      .collapsible-sm .title::after {
        position: absolute;
        top: 17px;
        right: 20px; }
    .collapsible-sm .content,
    .collapsible-sm .card-body {
      display: none;
      background-color: transparent;
      color: var(--base-font-color);
      border-radius: 0; }
      .collapsible-sm .content:first-child,
      .collapsible-sm .card-body:first-child {
        border-radius: 0; }
      .collapsible-sm .content:last-child,
      .collapsible-sm .card-body:last-child {
        border-radius: 0;
        border-bottom: none; }
    .collapsible-sm:not(:last-child) {
      margin-bottom: 0;
      border-bottom: none;
      border-radius: 0; }
      .collapsible-sm:not(:last-child) + .collapsible-sm {
        border-radius: 0; }
    .collapsible-sm.aside .title::after, .collapsible-sm.no-borders .title::after {
      right: 0; }
    .collapsible-sm.aside .title::after {
      top: 20px; }
    .collapsible-sm.no-borders .title::after {
      top: 17px; }
    .collapsible-sm:first-child .card-header,
    .collapsible-sm:first-child .card-title {
      border-radius: calc(var(--border-radius) - 1px) calc(var(--border-radius) - 1px) 0 0; }
    .collapsible-sm:last-child .card-header,
    .collapsible-sm:last-child .card-title,
    .collapsible-sm:last-child .card-body {
      border-radius: 0 0 calc(var(--border-radius) - 1px) calc(var(--border-radius) - 1px); }
    .collapsible-sm:last-child:not(.active):not(.aside):not(.no-borders) .card-header {
      border-bottom: 1px solid #C5C5C5; }
    .collapsible-sm.active > .card-header > .title:after,
    .collapsible-sm.active > .title:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: #000000;
      font-weight: normal; }
    .collapsible-sm.active > .card-header > .title[class*="btn-"]:not(.title),
    .collapsible-sm.active > .title[class*="btn-"]:not(.title) {
      padding-right: calc(var(--button-padding-x) + 20px); }
      .collapsible-sm.active > .card-header > .title[class*="btn-"]:not(.title):after,
      .collapsible-sm.active > .title[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #000000;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .collapsible-sm.active > .card-header > .title[class*="btn-"]:not(.title).btn-sm, .collapsible-sm.active > .btn-group-sm.card-header > .title.btn[class*="btn-"]:not(.title),
      .collapsible-sm.active > .title[class*="btn-"]:not(.title).btn-sm,
      .btn-group-sm.collapsible-sm.active > .title.btn[class*="btn-"]:not(.title) {
        padding-right: calc(20px + 20px); }
        .collapsible-sm.active > .card-header > .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-sm.active > .btn-group-sm.card-header > .title.btn[class*="btn-"]:not(.title):after,
        .collapsible-sm.active > .title[class*="btn-"]:not(.title).btn-sm:after,
        .btn-group-sm.collapsible-sm.active > .title.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #000000;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .collapsible-sm.active > .card-header > .title[class*="btn-"]:not(.title) [class*="icon-"],
      .collapsible-sm.active > .card-header > .title[class*="btn-"]:not(.title) [class^="icon-"],
      .collapsible-sm.active > .card-header > .title[class*="btn-"]:not(.title) .fa,
      .collapsible-sm.active > .title[class*="btn-"]:not(.title) [class*="icon-"],
      .collapsible-sm.active > .title[class*="btn-"]:not(.title) [class^="icon-"],
      .collapsible-sm.active > .title[class*="btn-"]:not(.title) .fa {
        display: none; }
    .collapsible-sm.active > .content,
    .collapsible-sm.active > .content-asset > .content,
    .collapsible-sm.active > .card-body {
      display: block; }
      .collapsible-sm.active > .content > .content:not(.values),
      .collapsible-sm.active > .content-asset > .content > .content:not(.values),
      .collapsible-sm.active > .card-body > .content:not(.values) {
        display: block; }
    .collapsible-sm.active:last-child .card-header,
    .collapsible-sm.active:last-child .card-title {
      border-radius: 0;
      border-bottom: none; }
    .collapsible-sm.active:last-child:not(.aside):not(.no-borders) .card-body {
      border-bottom: 1px solid #C5C5C5; } }

@media (max-width: 1023.98px) {
  .collapsible-md {
    border-color: #C5C5C5; }
    .collapsible-md .card-header {
      padding: 0;
      border-color: #C5C5C5;
      border-radius: 0; }
      .collapsible-md .card-header:first-child {
        border-radius: 0; }
      .collapsible-md .card-header:last-child {
        border-radius: 0; }
      .collapsible-md .card-header button {
        text-align: left;
        display: block;
        width: 100%; }
        .collapsible-md .card-header button:focus {
          z-index: 1; }
    .collapsible-md .title {
      background-color: transparent;
      color: var(--brand-primary);
      padding-top: 17px;
      padding-right: 37px;
      padding-bottom: 22px;
      padding-left: 20px;
      user-select: none;
      border-width: 0;
      cursor: pointer; }
      .collapsible-md .title:after {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 12px;
        color: #000000;
        font-weight: normal; }
      .collapsible-md .title[class*="btn-"]:not(.title) {
        padding-right: calc(var(--button-padding-x) + 20px); }
        .collapsible-md .title[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #000000;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
        .collapsible-md .title[class*="btn-"]:not(.title).btn-sm, .collapsible-md .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
          padding-right: calc(20px + 20px); }
          .collapsible-md .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-md .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #000000;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .collapsible-md .title[class*="btn-"]:not(.title) [class*="icon-"],
        .collapsible-md .title[class*="btn-"]:not(.title) [class^="icon-"],
        .collapsible-md .title[class*="btn-"]:not(.title) .fa {
          display: none; }
      .collapsible-md .title::after {
        position: absolute;
        top: 17px;
        right: 20px; }
    .collapsible-md .content,
    .collapsible-md .card-body {
      display: none;
      background-color: transparent;
      color: var(--base-font-color);
      border-radius: 0; }
      .collapsible-md .content:first-child,
      .collapsible-md .card-body:first-child {
        border-radius: 0; }
      .collapsible-md .content:last-child,
      .collapsible-md .card-body:last-child {
        border-radius: 0;
        border-bottom: none; }
    .collapsible-md:not(:last-child) {
      margin-bottom: 0;
      border-bottom: none;
      border-radius: 0; }
      .collapsible-md:not(:last-child) + .collapsible-md {
        border-radius: 0; }
    .collapsible-md.aside .title::after, .collapsible-md.no-borders .title::after {
      right: 0; }
    .collapsible-md.aside .title::after {
      top: 20px; }
    .collapsible-md.no-borders .title::after {
      top: 17px; }
    .collapsible-md:first-child .card-header,
    .collapsible-md:first-child .card-title {
      border-radius: calc(var(--border-radius) - 1px) calc(var(--border-radius) - 1px) 0 0; }
    .collapsible-md:last-child .card-header,
    .collapsible-md:last-child .card-title,
    .collapsible-md:last-child .card-body {
      border-radius: 0 0 calc(var(--border-radius) - 1px) calc(var(--border-radius) - 1px); }
    .collapsible-md:last-child:not(.active):not(.aside):not(.no-borders) .card-header {
      border-bottom: 1px solid #C5C5C5; }
    .collapsible-md.active > .card-header > .title:after,
    .collapsible-md.active > .title:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: #000000;
      font-weight: normal; }
    .collapsible-md.active > .card-header > .title[class*="btn-"]:not(.title),
    .collapsible-md.active > .title[class*="btn-"]:not(.title) {
      padding-right: calc(var(--button-padding-x) + 20px); }
      .collapsible-md.active > .card-header > .title[class*="btn-"]:not(.title):after,
      .collapsible-md.active > .title[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #000000;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .collapsible-md.active > .card-header > .title[class*="btn-"]:not(.title).btn-sm, .collapsible-md.active > .btn-group-sm.card-header > .title.btn[class*="btn-"]:not(.title),
      .collapsible-md.active > .title[class*="btn-"]:not(.title).btn-sm,
      .btn-group-sm.collapsible-md.active > .title.btn[class*="btn-"]:not(.title) {
        padding-right: calc(20px + 20px); }
        .collapsible-md.active > .card-header > .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-md.active > .btn-group-sm.card-header > .title.btn[class*="btn-"]:not(.title):after,
        .collapsible-md.active > .title[class*="btn-"]:not(.title).btn-sm:after,
        .btn-group-sm.collapsible-md.active > .title.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #000000;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .collapsible-md.active > .card-header > .title[class*="btn-"]:not(.title) [class*="icon-"],
      .collapsible-md.active > .card-header > .title[class*="btn-"]:not(.title) [class^="icon-"],
      .collapsible-md.active > .card-header > .title[class*="btn-"]:not(.title) .fa,
      .collapsible-md.active > .title[class*="btn-"]:not(.title) [class*="icon-"],
      .collapsible-md.active > .title[class*="btn-"]:not(.title) [class^="icon-"],
      .collapsible-md.active > .title[class*="btn-"]:not(.title) .fa {
        display: none; }
    .collapsible-md.active > .content,
    .collapsible-md.active > .content-asset > .content,
    .collapsible-md.active > .card-body {
      display: block; }
      .collapsible-md.active > .content > .content:not(.values),
      .collapsible-md.active > .content-asset > .content > .content:not(.values),
      .collapsible-md.active > .card-body > .content:not(.values) {
        display: block; }
    .collapsible-md.active:last-child .card-header,
    .collapsible-md.active:last-child .card-title {
      border-radius: 0;
      border-bottom: none; }
    .collapsible-md.active:last-child:not(.aside):not(.no-borders) .card-body {
      border-bottom: 1px solid #C5C5C5; } }

@media (max-width: 1439.98px) {
  .collapsible-lg {
    border-color: #C5C5C5; }
    .collapsible-lg .card-header {
      padding: 0;
      border-color: #C5C5C5;
      border-radius: 0; }
      .collapsible-lg .card-header:first-child {
        border-radius: 0; }
      .collapsible-lg .card-header:last-child {
        border-radius: 0; }
      .collapsible-lg .card-header button {
        text-align: left;
        display: block;
        width: 100%; }
        .collapsible-lg .card-header button:focus {
          z-index: 1; }
    .collapsible-lg .title {
      background-color: transparent;
      color: var(--brand-primary);
      padding-top: 17px;
      padding-right: 37px;
      padding-bottom: 22px;
      padding-left: 20px;
      user-select: none;
      border-width: 0;
      cursor: pointer; }
      .collapsible-lg .title:after {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 12px;
        color: #000000;
        font-weight: normal; }
      .collapsible-lg .title[class*="btn-"]:not(.title) {
        padding-right: calc(var(--button-padding-x) + 20px); }
        .collapsible-lg .title[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #000000;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
        .collapsible-lg .title[class*="btn-"]:not(.title).btn-sm, .collapsible-lg .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
          padding-right: calc(20px + 20px); }
          .collapsible-lg .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-lg .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #000000;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .collapsible-lg .title[class*="btn-"]:not(.title) [class*="icon-"],
        .collapsible-lg .title[class*="btn-"]:not(.title) [class^="icon-"],
        .collapsible-lg .title[class*="btn-"]:not(.title) .fa {
          display: none; }
      .collapsible-lg .title::after {
        position: absolute;
        top: 17px;
        right: 20px; }
    .collapsible-lg .content,
    .collapsible-lg .card-body {
      display: none;
      background-color: transparent;
      color: var(--base-font-color);
      border-radius: 0; }
      .collapsible-lg .content:first-child,
      .collapsible-lg .card-body:first-child {
        border-radius: 0; }
      .collapsible-lg .content:last-child,
      .collapsible-lg .card-body:last-child {
        border-radius: 0;
        border-bottom: none; }
    .collapsible-lg:not(:last-child) {
      margin-bottom: 0;
      border-bottom: none;
      border-radius: 0; }
      .collapsible-lg:not(:last-child) + .collapsible-lg {
        border-radius: 0; }
    .collapsible-lg.aside .title::after, .collapsible-lg.no-borders .title::after {
      right: 0; }
    .collapsible-lg.aside .title::after {
      top: 20px; }
    .collapsible-lg.no-borders .title::after {
      top: 17px; }
    .collapsible-lg:first-child .card-header,
    .collapsible-lg:first-child .card-title {
      border-radius: calc(var(--border-radius) - 1px) calc(var(--border-radius) - 1px) 0 0; }
    .collapsible-lg:last-child .card-header,
    .collapsible-lg:last-child .card-title,
    .collapsible-lg:last-child .card-body {
      border-radius: 0 0 calc(var(--border-radius) - 1px) calc(var(--border-radius) - 1px); }
    .collapsible-lg:last-child:not(.active):not(.aside):not(.no-borders) .card-header {
      border-bottom: 1px solid #C5C5C5; }
    .collapsible-lg.active > .card-header > .title:after,
    .collapsible-lg.active > .title:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: #000000;
      font-weight: normal; }
    .collapsible-lg.active > .card-header > .title[class*="btn-"]:not(.title),
    .collapsible-lg.active > .title[class*="btn-"]:not(.title) {
      padding-right: calc(var(--button-padding-x) + 20px); }
      .collapsible-lg.active > .card-header > .title[class*="btn-"]:not(.title):after,
      .collapsible-lg.active > .title[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #000000;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .collapsible-lg.active > .card-header > .title[class*="btn-"]:not(.title).btn-sm, .collapsible-lg.active > .btn-group-sm.card-header > .title.btn[class*="btn-"]:not(.title),
      .collapsible-lg.active > .title[class*="btn-"]:not(.title).btn-sm,
      .btn-group-sm.collapsible-lg.active > .title.btn[class*="btn-"]:not(.title) {
        padding-right: calc(20px + 20px); }
        .collapsible-lg.active > .card-header > .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-lg.active > .btn-group-sm.card-header > .title.btn[class*="btn-"]:not(.title):after,
        .collapsible-lg.active > .title[class*="btn-"]:not(.title).btn-sm:after,
        .btn-group-sm.collapsible-lg.active > .title.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #000000;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .collapsible-lg.active > .card-header > .title[class*="btn-"]:not(.title) [class*="icon-"],
      .collapsible-lg.active > .card-header > .title[class*="btn-"]:not(.title) [class^="icon-"],
      .collapsible-lg.active > .card-header > .title[class*="btn-"]:not(.title) .fa,
      .collapsible-lg.active > .title[class*="btn-"]:not(.title) [class*="icon-"],
      .collapsible-lg.active > .title[class*="btn-"]:not(.title) [class^="icon-"],
      .collapsible-lg.active > .title[class*="btn-"]:not(.title) .fa {
        display: none; }
    .collapsible-lg.active > .content,
    .collapsible-lg.active > .content-asset > .content,
    .collapsible-lg.active > .card-body {
      display: block; }
      .collapsible-lg.active > .content > .content:not(.values),
      .collapsible-lg.active > .content-asset > .content > .content:not(.values),
      .collapsible-lg.active > .card-body > .content:not(.values) {
        display: block; }
    .collapsible-lg.active:last-child .card-header,
    .collapsible-lg.active:last-child .card-title {
      border-radius: 0;
      border-bottom: none; }
    .collapsible-lg.active:last-child:not(.aside):not(.no-borders) .card-body {
      border-bottom: 1px solid #C5C5C5; } }

.collapsible-xl {
  border-color: #C5C5C5; }
  .collapsible-xl .card-header {
    padding: 0;
    border-color: #C5C5C5;
    border-radius: 0; }
    .collapsible-xl .card-header:first-child {
      border-radius: 0; }
    .collapsible-xl .card-header:last-child {
      border-radius: 0; }
    .collapsible-xl .card-header button {
      text-align: left;
      display: block;
      width: 100%; }
      .collapsible-xl .card-header button:focus {
        z-index: 1; }
  .collapsible-xl .title {
    background-color: transparent;
    color: var(--brand-primary);
    padding-top: 17px;
    padding-right: 37px;
    padding-bottom: 22px;
    padding-left: 20px;
    user-select: none;
    border-width: 0;
    cursor: pointer; }
    .collapsible-xl .title:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: #000000;
      font-weight: normal; }
    .collapsible-xl .title[class*="btn-"]:not(.title) {
      padding-right: calc(var(--button-padding-x) + 20px); }
      .collapsible-xl .title[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #000000;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .collapsible-xl .title[class*="btn-"]:not(.title).btn-sm, .collapsible-xl .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
        padding-right: calc(20px + 20px); }
        .collapsible-xl .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-xl .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #000000;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .collapsible-xl .title[class*="btn-"]:not(.title) [class*="icon-"],
      .collapsible-xl .title[class*="btn-"]:not(.title) [class^="icon-"],
      .collapsible-xl .title[class*="btn-"]:not(.title) .fa {
        display: none; }
    .collapsible-xl .title::after {
      position: absolute;
      top: 17px;
      right: 20px; }
  .collapsible-xl .content,
  .collapsible-xl .card-body {
    display: none;
    background-color: transparent;
    color: var(--base-font-color);
    border-radius: 0; }
    .collapsible-xl .content:first-child,
    .collapsible-xl .card-body:first-child {
      border-radius: 0; }
    .collapsible-xl .content:last-child,
    .collapsible-xl .card-body:last-child {
      border-radius: 0;
      border-bottom: none; }
  .collapsible-xl:not(:last-child) {
    margin-bottom: 0;
    border-bottom: none;
    border-radius: 0; }
    .collapsible-xl:not(:last-child) + .collapsible-xl {
      border-radius: 0; }
  .collapsible-xl.aside .title::after, .collapsible-xl.no-borders .title::after {
    right: 0; }
  .collapsible-xl.aside .title::after {
    top: 20px; }
  .collapsible-xl.no-borders .title::after {
    top: 17px; }
  .collapsible-xl:first-child .card-header,
  .collapsible-xl:first-child .card-title {
    border-radius: calc(var(--border-radius) - 1px) calc(var(--border-radius) - 1px) 0 0; }
  .collapsible-xl:last-child .card-header,
  .collapsible-xl:last-child .card-title,
  .collapsible-xl:last-child .card-body {
    border-radius: 0 0 calc(var(--border-radius) - 1px) calc(var(--border-radius) - 1px); }
  .collapsible-xl:last-child:not(.active):not(.aside):not(.no-borders) .card-header {
    border-bottom: 1px solid #C5C5C5; }
  .collapsible-xl.active > .card-header > .title:after,
  .collapsible-xl.active > .title:after {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 12px;
    color: #000000;
    font-weight: normal; }
  .collapsible-xl.active > .card-header > .title[class*="btn-"]:not(.title),
  .collapsible-xl.active > .title[class*="btn-"]:not(.title) {
    padding-right: calc(var(--button-padding-x) + 20px); }
    .collapsible-xl.active > .card-header > .title[class*="btn-"]:not(.title):after,
    .collapsible-xl.active > .title[class*="btn-"]:not(.title):after {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: #000000;
      font-weight: normal;
      position: absolute;
      transform: translateX(10px); }
    .collapsible-xl.active > .card-header > .title[class*="btn-"]:not(.title).btn-sm, .collapsible-xl.active > .btn-group-sm.card-header > .title.btn[class*="btn-"]:not(.title),
    .collapsible-xl.active > .title[class*="btn-"]:not(.title).btn-sm,
    .btn-group-sm.collapsible-xl.active > .title.btn[class*="btn-"]:not(.title) {
      padding-right: calc(20px + 20px); }
      .collapsible-xl.active > .card-header > .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-xl.active > .btn-group-sm.card-header > .title.btn[class*="btn-"]:not(.title):after,
      .collapsible-xl.active > .title[class*="btn-"]:not(.title).btn-sm:after,
      .btn-group-sm.collapsible-xl.active > .title.btn[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #000000;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
    .collapsible-xl.active > .card-header > .title[class*="btn-"]:not(.title) [class*="icon-"],
    .collapsible-xl.active > .card-header > .title[class*="btn-"]:not(.title) [class^="icon-"],
    .collapsible-xl.active > .card-header > .title[class*="btn-"]:not(.title) .fa,
    .collapsible-xl.active > .title[class*="btn-"]:not(.title) [class*="icon-"],
    .collapsible-xl.active > .title[class*="btn-"]:not(.title) [class^="icon-"],
    .collapsible-xl.active > .title[class*="btn-"]:not(.title) .fa {
      display: none; }
  .collapsible-xl.active > .content,
  .collapsible-xl.active > .content-asset > .content,
  .collapsible-xl.active > .card-body {
    display: block; }
    .collapsible-xl.active > .content > .content:not(.values),
    .collapsible-xl.active > .content-asset > .content > .content:not(.values),
    .collapsible-xl.active > .card-body > .content:not(.values) {
      display: block; }
  .collapsible-xl.active:last-child .card-header,
  .collapsible-xl.active:last-child .card-title {
    border-radius: 0;
    border-bottom: none; }
  .collapsible-xl.active:last-child:not(.aside):not(.no-borders) .card-body {
    border-bottom: 1px solid #C5C5C5; }

body {
  font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
  color: var(--base-font-color);
  font-size: var(--base-font-size);
  line-height: 1.4;
  letter-spacing: 0.1em;
  background-color: #FFFFFF; }

.page {
  background-color: #FFFFFF; }

.error-page {
  padding-bottom: 16px;
  padding-top: 16px; }

.error-messaging {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1; }

.error-message {
  margin-top: 50px;
  margin-bottom: 50px; }

.error.continue-shopping {
  margin-bottom: 100px; }

button {
  color: inherit; }

/*
* ORIGINAL CSS
* (everything under this comment was copied from Base and eventually
* should be removed or moved above this comment if in use)
*/
.modal-background {
  background-color: #000000;
  display: none;
  height: 100vh;
  position: fixed;
  opacity: 0.5;
  width: 100vw;
  top: 0;
  left: 0;
  transform: translateZ(0);
  z-index: 201; }

input[placeholder] {
  text-overflow: ellipsis; }

.hide-order-discount {
  display: none; }

.hide-shipping-discount {
  display: none; }

.order-discount {
  color: #008827; }

.giftcertificate-discount {
  color: #008827; }

.shipping-discount {
  color: #008827; }

.error-messaging {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1; }

.error-message {
  margin-top: 3.125rem;
  margin-bottom: 3.125rem; }

.error.continue-shopping {
  margin-bottom: 6.25em; }

.error-unassigned-category {
  color: #A3080F; }

.skip {
  position: absolute;
  left: 0;
  top: -4.2em;
  overflow: hidden;
  padding: 1em 1.5em;
  background: #FFFFFF;
  transition: all 200ms ease-in-out; }

a.skip:active,
a.skip:focus,
a.skip:hover {
  left: 0;
  top: 0;
  width: auto;
  height: auto;
  z-index: 10000000;
  background: #FFFFFF;
  transition: all 200ms ease-in-out; }

.login-oauth .oauth-facebook {
  margin-bottom: 16px; }
  .login-oauth .oauth-facebook:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .login-oauth .oauth-facebook[class*="btn-"]:not(.title) {
    padding-left: calc(var(--button-padding-x) + 20px); }
    .login-oauth .oauth-facebook[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .login-oauth .oauth-facebook[class*="btn-"]:not(.title).btn-sm, .login-oauth .btn-group-sm > .oauth-facebook.btn[class*="btn-"]:not(.title) {
      padding-left: calc(20px + 20px); }
      .login-oauth .oauth-facebook[class*="btn-"]:not(.title).btn-sm:before, .login-oauth .btn-group-sm > .oauth-facebook.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .login-oauth .oauth-facebook[class*="btn-"]:not(.title) [class*="icon-"],
    .login-oauth .oauth-facebook[class*="btn-"]:not(.title) [class^="icon-"],
    .login-oauth .oauth-facebook[class*="btn-"]:not(.title) .fa {
      display: none; }
  .login-oauth .oauth-facebook .fa-facebook-official {
    display: none; }

.login-oauth .oauth-google {
  margin-bottom: 16px; }
  .login-oauth .oauth-google:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .login-oauth .oauth-google[class*="btn-"]:not(.title) {
    padding-left: calc(var(--button-padding-x) + 20px); }
    .login-oauth .oauth-google[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .login-oauth .oauth-google[class*="btn-"]:not(.title).btn-sm, .login-oauth .btn-group-sm > .oauth-google.btn[class*="btn-"]:not(.title) {
      padding-left: calc(20px + 20px); }
      .login-oauth .oauth-google[class*="btn-"]:not(.title).btn-sm:before, .login-oauth .btn-group-sm > .oauth-google.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .login-oauth .oauth-google[class*="btn-"]:not(.title) [class*="icon-"],
    .login-oauth .oauth-google[class*="btn-"]:not(.title) [class^="icon-"],
    .login-oauth .oauth-google[class*="btn-"]:not(.title) .fa {
      display: none; }
  .login-oauth .oauth-google .fa-google {
    display: none; }

.grecaptcha-badge {
  z-index: 499; }

.content-grid + .content-grid {
  margin-top: 32px; }

@media (max-width: 768.98px) {
  .content-grid .content-column[class*='col-md'] + .content-column[class*='col-12'] {
    margin-top: 32px; }
  .content-grid .content-column[class*='col-md'] + .content-column[class*='col-6']:nth-child(4n),
  .content-grid .content-column[class*='col-md'] + .content-column[class*='col-6']:nth-child(4n-1) {
    margin-top: 32px; } }

.content-tile {
  display: block;
  text-decoration: none; }
  @media (min-width: 769px) {
    .content-tile .content-tile-image {
      will-change: transform;
      transition: transform 200ms ease-out; }
    .content-tile:hover .content-tile-image {
      transform: scale(1.2); } }
  .search-results .content-tile,
  .blog-results .content-tile,
  .content-grid .content-tile {
    margin-bottom: 45px; }
  .content-tile .content-tile-image-wrapper {
    display: block;
    overflow: hidden;
    position: relative;
    margin-bottom: 10px; }
  .content-tile .content-tile-image {
    display: block; }
    .content-tile .content-tile-image::after {
      display: block;
      content: "";
      clear: both; }
    .content-tile .content-tile-image::before {
      content: "";
      display: block;
      width: 1px;
      margin-left: -1px;
      float: left;
      height: 0;
      padding-top: calc(1 / 1 * 100%); }
  .content-tile .content-tile-body {
    display: block; }
  .content-tile .content-tile-category {
    display: block;
    font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
    font-size: calc(var(--base-font-size) - 2px);
    font-weight: normal;
    text-decoration: none;
    color: #767676;
    margin-bottom: 7px; }
  .content-tile .content-tile-name {
    font-family: var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif);
    font-size: 18px;
    font-weight: normal;
    text-decoration: none;
    color: var(--base-font-color);
    margin-bottom: 10px; }
  .content-tile .content-tile-date {
    display: block;
    font-family: var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif);
    font-size: calc(var(--base-font-size) - 2px);
    font-weight: normal;
    text-decoration: none;
    color: var(--base-font-color);
    margin-bottom: 7px;
    text-transform: uppercase; }
  .content-tile .content-tile-description {
    font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
    font-size: var(--base-font-size);
    font-weight: normal;
    text-decoration: none;
    color: var(--base-font-color); }
  .content-tile:hover {
    text-decoration: none; }
    .content-tile:hover .content-tile-image-wrapper::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      box-shadow: inset 0px -0 0px #000000; }
    .content-tile:hover .content-tile-name {
      text-decoration: none;
      color: var(--base-font-color); }
    .content-tile:hover .content-tile-description {
      text-decoration: none;
      color: var(--base-font-color); }

.card-number-wrapper {
  position: relative; }
  .card-number-wrapper input {
    padding-right: calc(20px + 48px); }
  .card-number-wrapper::after {
    content: '';
    position: absolute;
    top: calc(calc(16px + 12px + 14px + (1px*2))/2 - 15px);
    right: 10px;
    background-repeat: no-repeat;
    background-image: url("../../images/credit.png");
    background-size: contain;
    width: 48px;
    height: 30px; }
  .card-number-wrapper[data-type="visa"]::after {
    background-image: url("../../images/payment-types.png");
    background-size: auto;
    background-position: -162px -110px; }
  .card-number-wrapper[data-type="mastercard"]::after {
    background-image: url("../../images/payment-types.png");
    background-size: auto;
    background-position: -295px -110px; }
  .card-number-wrapper[data-type="amex"]::after {
    background-image: url("../../images/payment-types.png");
    background-size: auto;
    background-position: -230px -15px; }
  .card-number-wrapper[data-type="discover"]::after {
    background-image: url("../../images/payment-types.png");
    background-size: auto;
    background-position: -95px -110px; }

.discount-status-bar {
  background: #767676;
  color: #FFFFFF;
  padding: 16px; }
  .discount-status-bar .discount-status-bar-data {
    align-items: center; }
    .discount-status-bar .discount-status-bar-data .discount-status-bar-no-promo-message {
      text-align: center; }
  .discount-status-bar .discount-status-bar-checkpoints {
    align-items: flex-end;
    max-width: calc(100% - 24px/2); }
    @media (min-width: 544px) {
      .discount-status-bar .discount-status-bar-checkpoints {
        padding-bottom: calc((18.2px) + (16px/2) + 8px); } }
  .discount-status-bar .discount-checkpoint-section {
    margin-bottom: 32px; }
    .discount-status-bar .discount-checkpoint-section .discount-status-bar-progress {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 8px;
      border-top: 1px solid #FFFFFF;
      border-bottom: 1px solid #FFFFFF;
      border-left: 1px solid #FFFFFF;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px; }
      .discount-status-bar .discount-checkpoint-section .discount-status-bar-progress-fill {
        transition: width 200ms ease-out;
        height: 100%;
        background: #FFFFFF; }
    @media (min-width: 544px) {
      .discount-status-bar .discount-checkpoint-section {
        margin-bottom: 0; }
        .discount-status-bar .discount-checkpoint-section .discount-status-bar-progress {
          border-left: none;
          border-radius: unset; }
        .discount-status-bar .discount-checkpoint-section:first-child .discount-status-bar-progress {
          border-left: 1px solid #FFFFFF;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px; }
        .discount-status-bar .discount-checkpoint-section:last-child .discount-status-bar-progress {
          border-right: 1px solid #FFFFFF;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px; } }
  .discount-status-bar .discount-checkpoint {
    text-align: right;
    position: relative;
    z-index: 1;
    margin-right: calc(-24px/2);
    margin-bottom: calc(-24px/2 + (8px + 1px)/2); }
    .discount-status-bar .discount-checkpoint .discount-checkpoint-icon {
      position: relative;
      width: 24px;
      height: 24px;
      margin-left: auto;
      border: 1px solid #FFFFFF;
      border-radius: 50%;
      color: #FFFFFF;
      background-color: #C5C5C5;
      clear: both; }
      .discount-status-bar .discount-checkpoint .discount-checkpoint-icon:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: inherit;
        color: inherit;
        font-weight: normal; }
      .discount-status-bar .discount-checkpoint .discount-checkpoint-icon[class*="btn-"]:not(.title) {
        padding-left: calc(var(--button-padding-x) + 20px); }
        .discount-status-bar .discount-checkpoint .discount-checkpoint-icon[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        .discount-status-bar .discount-checkpoint .discount-checkpoint-icon[class*="btn-"]:not(.title).btn-sm, .discount-status-bar .discount-checkpoint .btn-group-sm > .discount-checkpoint-icon.btn[class*="btn-"]:not(.title) {
          padding-left: calc(20px + 20px); }
          .discount-status-bar .discount-checkpoint .discount-checkpoint-icon[class*="btn-"]:not(.title).btn-sm:before, .discount-status-bar .discount-checkpoint .btn-group-sm > .discount-checkpoint-icon.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: inherit;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .discount-status-bar .discount-checkpoint .discount-checkpoint-icon[class*="btn-"]:not(.title) [class*="icon-"],
        .discount-status-bar .discount-checkpoint .discount-checkpoint-icon[class*="btn-"]:not(.title) [class^="icon-"],
        .discount-status-bar .discount-checkpoint .discount-checkpoint-icon[class*="btn-"]:not(.title) .fa {
          display: none; }
      .discount-status-bar .discount-checkpoint .discount-checkpoint-icon::before {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        font-size: calc(24px/2); }
    .discount-status-bar .discount-checkpoint.applied .discount-checkpoint-icon {
      color: #FFFFFF;
      background: #008827;
      border-color: #FFFFFF; }
    .discount-status-bar .discount-checkpoint .discount-checkpoint-promo-message {
      font-size: var(--base-font-size);
      line-height: 1.4;
      margin-bottom: 8px;
      float: left;
      text-align: left; }
      .discount-status-bar .discount-checkpoint .discount-checkpoint-promo-message + .discount-checkpoint-promo-message {
        clear: left; }
      @media (min-width: 544px) {
        .discount-status-bar .discount-checkpoint .discount-checkpoint-promo-message {
          float: none;
          text-align: right;
          margin-left: auto;
          max-width: calc(100% - 24px); } }
    .discount-status-bar .discount-checkpoint .discount-checkpoint-threshold {
      font-size: 13px;
      line-height: 1.4;
      margin-bottom: 8px;
      float: right; }
      @media (min-width: 544px) {
        .discount-status-bar .discount-checkpoint .discount-checkpoint-threshold {
          float: none;
          position: absolute;
          top: 100%;
          right: 0;
          margin-top: 8px;
          margin-bottom: 0; } }
  .discount-status-bar .discount-status-bar-info {
    text-align: center;
    align-content: center;
    margin-top: 16px; }
    @media (min-width: 769px) {
      .discount-status-bar .discount-status-bar-info {
        margin-top: 0; } }
    .discount-status-bar .discount-status-bar-info .discount-status-bar-total {
      font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
      font-size: 20px;
      line-height: 1.4;
      margin-bottom: 16px; }
    .discount-status-bar .discount-status-bar-info .discount-status-bar-checkout-btn {
      display: inline-block;
      position: relative;
      cursor: pointer;
      user-select: none;
      border-width: 1px;
      border-style: solid;
      font-family: var(--button-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif);
      text-transform: var(--button-text-transform);
      transition: all 200ms ease-out;
      text-decoration: none;
      border-radius: var(--button-border-radius);
      text-align: center;
      font-weight: var(--button-font-weight);
      font-size: var(--button-font-size);
      letter-spacing: 0.1em;
      line-height: 18px;
      padding: var(--button-padding-y) var(--button-padding-x) var(--button-padding-y) var(--button-padding-x);
      font-family: var(--button-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif);
      text-transform: var(--button-text-transform);
      color: var(--button-secondary-color);
      border-width: var(--button-secondary-border-width);
      border-radius: var(--button-border-radius);
      background-color: var(--button-secondary-background);
      border-color: var(--button-secondary-border-color);
      text-decoration: none;
      text-align: center;
      font-weight: var(--button-font-weight);
      padding: var(--button-padding-y) var(--button-padding-x) var(--button-padding-y) var(--button-padding-x); }
      .discount-status-bar .discount-status-bar-info .discount-status-bar-checkout-btn:hover, .discount-status-bar .discount-status-bar-info .discount-status-bar-checkout-btn:focus, .discount-status-bar .discount-status-bar-info .discount-status-bar-checkout-btn[aria-describedby*="popover"] {
        color: var(--button-secondary-color-hover);
        background-color: var(--button-secondary-background-hover);
        border-color: var(--button-secondary-border-color-hover);
        text-decoration: none; }
      .dark-theme .discount-status-bar .discount-status-bar-info .discount-status-bar-checkout-btn {
        color: var(--button-secondary-color); }
        .dark-theme .discount-status-bar .discount-status-bar-info .discount-status-bar-checkout-btn:hover {
          color: var(--button-secondary-color-hover); }
      .discount-status-bar .discount-status-bar-info .discount-status-bar-checkout-btn[class*="icon-"], .discount-status-bar .discount-status-bar-info .discount-status-bar-checkout-btn[class^="icon-"] {
        padding-left: calc(var(--button-padding-x) * 2); }
        .discount-status-bar .discount-status-bar-info .discount-status-bar-checkout-btn[class*="icon-"]:before, .discount-status-bar .discount-status-bar-info .discount-status-bar-checkout-btn[class^="icon-"]:before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
  .discount-status-bar .discount-status-bar-completion-text {
    color: #FFFFFF;
    font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
    font-size: var(--base-font-size);
    line-height: 1.4;
    margin-bottom: 16px; }

.dropdown-toggle:after {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: 10px;
  color: inherit;
  font-weight: normal; }

.dropdown-toggle[class*="btn-"]:not(.title) {
  padding-right: calc(var(--button-padding-x) + 20px); }
  .dropdown-toggle[class*="btn-"]:not(.title):after {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: inherit;
    font-weight: normal;
    position: absolute;
    transform: translateX(10px); }
  .dropdown-toggle[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .dropdown-toggle.btn[class*="btn-"]:not(.title) {
    padding-right: calc(20px + 20px); }
    .dropdown-toggle[class*="btn-"]:not(.title).btn-sm:after, .btn-group-sm > .dropdown-toggle.btn[class*="btn-"]:not(.title):after {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(10px); }
  .dropdown-toggle[class*="btn-"]:not(.title) [class*="icon-"],
  .dropdown-toggle[class*="btn-"]:not(.title) [class^="icon-"],
  .dropdown-toggle[class*="btn-"]:not(.title) .fa {
    display: none; }

.dropdown-toggle:after {
  border: none;
  vertical-align: top;
  margin-left: 5px;
  position: relative; }

.dropdown.show .dropdown-toggle:after,
.custom-dropdown.show .dropdown-toggle:after {
  transform: rotate(180deg); }

.dropdown-toggle:not(a):not(button) {
  cursor: default; }

.dropdown-menu {
  margin: 0;
  padding: 0;
  border-radius: var(--border-radius);
  border: 1px solid #C5C5C5;
  animation: fadeIn 200ms; }
  .dropdown-menu .dropdown-item {
    text-decoration: none;
    padding: 0; }
    .dropdown-menu .dropdown-item + .dropdown-item {
      border-top: 1px solid #C5C5C5; }
    .dropdown-menu .dropdown-item:hover {
      background: #C5C5C5;
      color: var(--brand-secondary);
      text-decoration: none; }
    .dropdown-menu .dropdown-item:last-child:hover {
      border-radius: 0 0 var(--border-radius) var(--border-radius); }
    .dropdown-menu .dropdown-item .dropdown-link {
      display: block;
      padding: 16px;
      color: var(--brand-primary); }
      .dropdown-menu .dropdown-item .dropdown-link:hover {
        color: var(--brand-secondary); }
  .dropdown-menu [class*="sub-asset-"] + .dropdown-item {
    border-top: 1px solid #C5C5C5; }

.add-giftcertificate-messages {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  position: fixed;
  top: 0;
  padding: 50px 16px 16px;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  z-index: 1100; }

.check-balance .balancemsg {
  font-weight: bold;
  font-size: 18px;
  margin-top: 16px;
  justify-content: center; }

.remove-giftcertificate-pi {
  font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
  border: none;
  border-radius: 50%;
  font-size: 0;
  cursor: pointer;
  z-index: 1;
  background-color: #FFFFFF;
  padding: 4px 6px;
  height: 22px;
  width: 22px;
  padding: 0px 5px; }
  .remove-giftcertificate-pi:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 11px;
    color: inherit;
    font-weight: normal; }
  .remove-giftcertificate-pi[class*="btn-"]:not(.title) {
    padding-left: calc(var(--button-padding-x) + 20px); }
    .remove-giftcertificate-pi[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .remove-giftcertificate-pi[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .remove-giftcertificate-pi.btn[class*="btn-"]:not(.title) {
      padding-left: calc(20px + 20px); }
      .remove-giftcertificate-pi[class*="btn-"]:not(.title).btn-sm:before, .btn-group-sm > .remove-giftcertificate-pi.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .remove-giftcertificate-pi[class*="btn-"]:not(.title) [class*="icon-"],
    .remove-giftcertificate-pi[class*="btn-"]:not(.title) [class^="icon-"],
    .remove-giftcertificate-pi[class*="btn-"]:not(.title) .fa {
      display: none; }

.max-quantity-msg {
  padding: 16px 0px; }

.navbar {
  padding: 0; }
  @media (min-width: 1024px) {
    .navbar.bg-inverse {
      position: static; }
      .navbar.bg-inverse .navbar-nav {
        flex-wrap: nowrap; }
        .navbar.bg-inverse .navbar-nav + .nav-item {
          margin-left: 0; }
        .navbar.bg-inverse .navbar-nav .nav-link {
          padding: 16px 18px;
          white-space: nowrap; }
      .logo-center .navbar.bg-inverse .nav-item > .nav-link {
        color: var(--header-navbar-text-color); }
      .logo-center .navbar.bg-inverse .nav-item .nav-link > .nav-link, .logo-center .navbar.bg-inverse .nav-item .nav-link:hover, .logo-center .navbar.bg-inverse .nav-item .nav-link:focus, .logo-center .navbar.bg-inverse .nav-item.show > .nav-link, .logo-center .navbar.bg-inverse .nav-item.show:hover, .logo-center .navbar.bg-inverse .nav-item.show:focus {
        background-color: var(--header-navbar-background-color-hover);
        color: var(--header-navbar-text-color-hover); }
      .logo-center .navbar.bg-inverse .nav-item.highlight .nav-link {
        color: var(--brand-primary); }
        .logo-center .navbar.bg-inverse .nav-item.highlight .nav-link:hover, .logo-center .navbar.bg-inverse .nav-item.highlight .nav-link:focus {
          background-color: #FFFFFF;
          color: var(--brand-secondary); }
      .logo-center .navbar.bg-inverse .navbar-nav:not(.nav-item-hover) > .nav-item.is-inview > .nav-link, .logo-center .navbar.bg-inverse .navbar-nav:not(.nav-item-hover) > .nav-item.contains-inview > .nav-link {
        background-color: var(--header-navbar-background-color-hover);
        color: var(--header-navbar-text-color-hover); } }

.multilevel-dropdown .dropdown-menu {
  top: 100%;
  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
  @media (min-width: 1024px) {
    .multilevel-dropdown .dropdown-menu {
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
      left: auto; } }

.multilevel-dropdown .nav-item:not(.megamenu) > .dropdown-menu:not(:hover):not(:focus-within) .dropdown-item.contains-inview, .multilevel-dropdown .nav-item:not(.megamenu) > .dropdown-menu:not(:hover):not(:focus-within) .dropdown-item.is-inview {
  background: #C5C5C5;
  color: var(--brand-secondary);
  text-decoration: none; }

.multilevel-dropdown .dropdown-item.custom-dropdown > .dropdown-toggle::after {
  transform: rotate(-90deg); }

@media (min-width: 1024px) {
  .multilevel-dropdown .dropdown-menu > .custom-dropdown > .dropdown-menu {
    top: auto;
    left: 99%;
    margin-top: calc(-32px - 20px); } }

.multilevel-dropdown .navbar > .close-menu {
  display: none; }

.multilevel-dropdown .close-menu .back .caret-left:after {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: 10px;
  color: inherit;
  font-weight: normal; }

.multilevel-dropdown .close-menu .back .caret-left[class*="btn-"]:not(.title) {
  padding-right: calc(var(--button-padding-x) + 20px); }
  .multilevel-dropdown .close-menu .back .caret-left[class*="btn-"]:not(.title):after {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: inherit;
    font-weight: normal;
    position: absolute;
    transform: translateX(10px); }
  .multilevel-dropdown .close-menu .back .caret-left[class*="btn-"]:not(.title).btn-sm, .multilevel-dropdown .close-menu .back .btn-group-sm > .caret-left.btn[class*="btn-"]:not(.title) {
    padding-right: calc(20px + 20px); }
    .multilevel-dropdown .close-menu .back .caret-left[class*="btn-"]:not(.title).btn-sm:after, .multilevel-dropdown .close-menu .back .btn-group-sm > .caret-left.btn[class*="btn-"]:not(.title):after {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(10px); }
  .multilevel-dropdown .close-menu .back .caret-left[class*="btn-"]:not(.title) [class*="icon-"],
  .multilevel-dropdown .close-menu .back .caret-left[class*="btn-"]:not(.title) [class^="icon-"],
  .multilevel-dropdown .close-menu .back .caret-left[class*="btn-"]:not(.title) .fa {
    display: none; }

.multilevel-dropdown .dropdown-menu.show > .dropdown-item.show > .dropdown-toggle::after {
  transform: rotate(90deg); }

.multilevel-dropdown .menu-group {
  width: 100%; }

.multilevel-dropdown .menu-subcategories {
  padding-left: 0; }

@media (min-width: 1024px) {
  .navbar-nav .megamenu {
    position: static; }
    .navbar-nav .megamenu .experience-component > .demo,
    .navbar-nav .megamenu > .dropdown-menu {
      max-width: 1440px;
      padding: 0;
      box-shadow: none;
      background: #FFFFFF;
      z-index: 0;
      justify-content: center; }
      .navbar-nav .megamenu .experience-component > .demo:not(.demo),
      .navbar-nav .megamenu > .dropdown-menu:not(.demo) {
        position: absolute;
        right: 50%;
        transform: translateX(50%);
        left: auto;
        top: auto;
        width: 100vw; }
      .navbar-nav .megamenu .experience-component > .demo.show,
      .navbar-nav .megamenu > .dropdown-menu.show {
        display: flex; }
      .navbar-nav .megamenu .experience-component > .demo:before,
      .navbar-nav .megamenu > .dropdown-menu:before {
        content: '';
        position: absolute;
        right: 50%;
        transform: translateX(50%);
        left: auto;
        top: 0;
        bottom: 0;
        width: 100vw;
        z-index: -1;
        background: #FFFFFF;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2); }
      .navbar-nav .megamenu .experience-component > .demo .menu-subcategories,
      .navbar-nav .megamenu > .dropdown-menu .menu-subcategories {
        display: flex;
        flex-wrap: wrap;
        align-self: flex-start;
        padding-top: 30px;
        padding-bottom: 30px; } }
      @media screen and (min-width: 1024px) and (-ms-high-contrast: active), (min-width: 1024px) and (-ms-high-contrast: none) {
        .navbar-nav .megamenu .experience-component > .demo .menu-subcategories,
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories {
          flex-grow: 1; } }

@media (min-width: 1024px) {
        .navbar-nav .megamenu .experience-component > .demo .menu-subcategories > .dropdown-item,
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories > .dropdown-item {
          padding-left: 16px;
          padding-bottom: 16px; }
        .navbar-nav .megamenu .experience-component > .demo .menu-subcategories .dropdown-toggle:after,
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-toggle:after {
          display: none; }
        .navbar-nav .megamenu .experience-component > .demo .menu-subcategories .dropdown-item,
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-item {
          width: auto;
          border: none;
          white-space: normal; }
          .navbar-nav .megamenu .experience-component > .demo .menu-subcategories .dropdown-item:hover,
          .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-item:hover {
            background: none; }
        .navbar-nav .megamenu .experience-component > .demo .menu-subcategories .nav-link,
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .nav-link {
          font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
          font-size: 14px;
          font-weight: var(--secondary-nav-font-weight);
          line-height: 20px;
          text-transform: var(--secondary-nav-text-transform);
          color: var(--secondary-nav-color, var(--brand-primary)); }
          .navbar-nav .megamenu .experience-component > .demo .menu-subcategories .nav-link:hover,
          .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .nav-link:hover {
            color: var(--secondary-nav-color-hover, var(--brand-primary));
            background-color: transparent; }
        .navbar-nav .megamenu .experience-component > .demo .menu-subcategories .dropdown-link,
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-link {
          padding: 10px 5px 10px 5px;
          display: inline-block; }
        .navbar-nav .megamenu .experience-component > .demo .menu-subcategories .dropdown-menu,
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu {
          margin-left: 2px;
          position: static;
          display: flex;
          box-shadow: none;
          border-radius: 0;
          background: transparent;
          flex-flow: row wrap;
          align-content: flex-start; }
          .navbar-nav .megamenu .experience-component > .demo .menu-subcategories .dropdown-menu .dropdown-item,
          .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item {
            border: none;
            flex-basis: 100%; }
            .navbar-nav .megamenu .experience-component > .demo .menu-subcategories .dropdown-menu .dropdown-item.custom-dropdown,
            .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item.custom-dropdown {
              flex-basis: 25%; }
            .navbar-nav .megamenu .experience-component > .demo .menu-subcategories .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown .dropdown-item.last-item,
            .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown .dropdown-item.last-item {
              padding-top: unset; }
              .navbar-nav .megamenu .experience-component > .demo .menu-subcategories .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown .dropdown-item.last-item a,
              .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown .dropdown-item.last-item a {
                font-weight: var(--tertiary-nav-font-weight);
                text-transform: var(--tertiary-nav-text-transform); }
              .navbar-nav .megamenu .experience-component > .demo .menu-subcategories .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown .dropdown-item.last-item:before,
              .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown .dropdown-item.last-item:before {
                display: none; }
            .navbar-nav .megamenu .experience-component > .demo .menu-subcategories .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown + .dropdown-item.custom-dropdown .dropdown-item.last-item,
            .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown + .dropdown-item.custom-dropdown .dropdown-item.last-item {
              padding-top: unset; }
              .navbar-nav .megamenu .experience-component > .demo .menu-subcategories .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown + .dropdown-item.custom-dropdown .dropdown-item.last-item a,
              .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown + .dropdown-item.custom-dropdown .dropdown-item.last-item a {
                font-weight: var(--tertiary-nav-font-weight);
                text-transform: var(--tertiary-nav-text-transform); }
              .navbar-nav .megamenu .experience-component > .demo .menu-subcategories .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown + .dropdown-item.custom-dropdown .dropdown-item.last-item:before,
              .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown + .dropdown-item.custom-dropdown .dropdown-item.last-item:before {
                display: none; }
          .navbar-nav .megamenu .experience-component > .demo .menu-subcategories .dropdown-menu .dropdown-link,
          .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-link {
            color: var(--tertiary-nav-color, var(--brand-secondary));
            font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
            font-size: 14px;
            font-weight: var(--tertiary-nav-font-weight);
            text-transform: var(--tertiary-nav-text-transform); }
            .navbar-nav .megamenu .experience-component > .demo .menu-subcategories .dropdown-menu .dropdown-link:hover,
            .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-link:hover {
              color: var(--tertiary-nav-color-hover, var(--brand-primary)); }
          .navbar-nav .megamenu .experience-component > .demo .menu-subcategories .dropdown-menu .dropdown-menu,
          .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-menu {
            flex-flow: column; }
        .navbar-nav .megamenu .experience-component > .demo .menu-subcategories:not(:hover):not(:focus-within) .dropdown-item.contains-inview > .dropdown-menu > .dropdown-item.contains-inview > .dropdown-link, .navbar-nav .megamenu .experience-component > .demo .menu-subcategories:not(:hover):not(:focus-within) .dropdown-item.contains-inview > .dropdown-menu > .dropdown-item.is-inview > .dropdown-link,
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories:not(:hover):not(:focus-within) .dropdown-item.contains-inview > .dropdown-menu > .dropdown-item.contains-inview > .dropdown-link,
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories:not(:hover):not(:focus-within) .dropdown-item.contains-inview > .dropdown-menu > .dropdown-item.is-inview > .dropdown-link {
          color: var(--brand-primary); }
        .navbar-nav .megamenu .experience-component > .demo .menu-subcategories .menu-level-4 > .dropdown-item > .dropdown-link,
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .menu-level-4 > .dropdown-item > .dropdown-link {
          padding: 5px 5px 5px 15px; }
      .navbar-nav .megamenu .experience-component > .demo .view-all-link,
      .navbar-nav .megamenu > .dropdown-menu .view-all-link {
        order: 1000; }
        .navbar-nav .megamenu .experience-component > .demo .view-all-link > .view-all-offset, .navbar-nav .megamenu .experience-component > .demo .view-all-link.view-all-offset,
        .navbar-nav .megamenu > .dropdown-menu .view-all-link > .view-all-offset,
        .navbar-nav .megamenu > .dropdown-menu .view-all-link.view-all-offset {
          display: block;
          position: relative;
          padding-top: 16px;
          font-weight: bold;
          text-transform: uppercase; }
          .navbar-nav .megamenu .experience-component > .demo .view-all-link > .view-all-offset::before, .navbar-nav .megamenu .experience-component > .demo .view-all-link.view-all-offset::before,
          .navbar-nav .megamenu > .dropdown-menu .view-all-link > .view-all-offset::before,
          .navbar-nav .megamenu > .dropdown-menu .view-all-link.view-all-offset::before {
            content: '';
            position: absolute;
            top: 8px;
            left: 5px;
            border-top: 1px solid #C5C5C5;
            width: 48px; }
      .navbar-nav .megamenu .experience-component > .demo.mega-standard,
      .navbar-nav .megamenu > .dropdown-menu.mega-standard {
        flex-wrap: wrap; }
        .navbar-nav .megamenu .experience-component > .demo.mega-standard .menu-subcategories-container,
        .navbar-nav .megamenu > .dropdown-menu.mega-standard .menu-subcategories-container {
          flex-grow: 1;
          width: 50%; }
        .navbar-nav .megamenu .experience-component > .demo.mega-standard .subcat-columns,
        .navbar-nav .megamenu > .dropdown-menu.mega-standard .subcat-columns {
          flex-direction: column;
          align-content: flex-start;
          max-height: 500px; }
          .navbar-nav .megamenu .experience-component > .demo.mega-standard .subcat-columns > .dropdown-item,
          .navbar-nav .megamenu > .dropdown-menu.mega-standard .subcat-columns > .dropdown-item {
            width: 33%; }
          .navbar-nav .megamenu .experience-component > .demo.mega-standard .subcat-columns .menu-level-3 > .dropdown-item,
          .navbar-nav .megamenu > .dropdown-menu.mega-standard .subcat-columns .menu-level-3 > .dropdown-item {
            flex-basis: 100%; }
      .navbar-nav .megamenu .experience-component > .demo.mega-left,
      .navbar-nav .megamenu > .dropdown-menu.mega-left {
        justify-content: flex-start; }
        .navbar-nav .megamenu .experience-component > .demo.mega-left > .mega-left,
        .navbar-nav .megamenu > .dropdown-menu.mega-left > .mega-left {
          flex: 1 1 calc((100% - 15%)/2); }
          .navbar-nav .megamenu .experience-component > .demo.mega-left > .mega-left .menu-subcategories > .dropdown-item,
          .navbar-nav .megamenu > .dropdown-menu.mega-left > .mega-left .menu-subcategories > .dropdown-item {
            display: none; }
            .navbar-nav .megamenu .experience-component > .demo.mega-left > .mega-left .menu-subcategories > .dropdown-item.active-list,
            .navbar-nav .megamenu > .dropdown-menu.mega-left > .mega-left .menu-subcategories > .dropdown-item.active-list {
              display: flex;
              flex-flow: row wrap; }
            .navbar-nav .megamenu .experience-component > .demo.mega-left > .mega-left .menu-subcategories > .dropdown-item.has-view-all-link > .menu-level-3 > .view-all-link,
            .navbar-nav .megamenu > .dropdown-menu.mega-left > .mega-left .menu-subcategories > .dropdown-item.has-view-all-link > .menu-level-3 > .view-all-link {
              display: none !important; }
          .navbar-nav .megamenu .experience-component > .demo.mega-left > .mega-left.has-sub-asset,
          .navbar-nav .megamenu > .dropdown-menu.mega-left > .mega-left.has-sub-asset {
            flex: 1 1 calc(100% - 15%); }
            .navbar-nav .megamenu .experience-component > .demo.mega-left > .mega-left.has-sub-asset + [class*="megamenu-asset-"],
            .navbar-nav .megamenu > .dropdown-menu.mega-left > .mega-left.has-sub-asset + [class*="megamenu-asset-"] {
              display: none; }
              .navbar-nav .megamenu .experience-component > .demo.mega-left > .mega-left.has-sub-asset + [class*="megamenu-asset-"] + [class*="megamenu-asset-"],
              .navbar-nav .megamenu > .dropdown-menu.mega-left > .mega-left.has-sub-asset + [class*="megamenu-asset-"] + [class*="megamenu-asset-"] {
                display: none; }
        .navbar-nav .megamenu .experience-component > .demo.mega-left .subcat-columns .menu-level-3,
        .navbar-nav .megamenu > .dropdown-menu.mega-left .subcat-columns .menu-level-3 {
          flex-direction: column;
          flex-basis: 100%;
          align-content: flex-start;
          max-height: 500px; }
          .navbar-nav .megamenu .experience-component > .demo.mega-left .subcat-columns .menu-level-3 > .dropdown-item,
          .navbar-nav .megamenu > .dropdown-menu.mega-left .subcat-columns .menu-level-3 > .dropdown-item {
            width: 33%;
            flex-basis: auto; }
            .navbar-nav .megamenu .experience-component > .demo.mega-left .subcat-columns .menu-level-3 > .dropdown-item > a,
            .navbar-nav .megamenu > .dropdown-menu.mega-left .subcat-columns .menu-level-3 > .dropdown-item > a {
              font-weight: var(--secondary-nav-font-weight);
              font-size: 14px;
              text-transform: var(--secondary-nav-text-transform); }
      .navbar-nav .megamenu .experience-component > .demo > li > a.title,
      .navbar-nav .megamenu > .dropdown-menu > li > a.title {
        margin-bottom: 30px;
        padding: 0 5px;
        text-decoration: none; }
      .navbar-nav .megamenu .experience-component > .demo .megamenu-asset-only,
      .navbar-nav .megamenu > .dropdown-menu .megamenu-asset-only {
        width: 100%; }
      .navbar-nav .megamenu .experience-component > .demo .left-nav,
      .navbar-nav .megamenu > .dropdown-menu .left-nav {
        margin-top: 30px;
        margin-bottom: 30px;
        flex: 0 0 15%;
        border-right: 2px solid #F2F2F2; }
        .navbar-nav .megamenu .experience-component > .demo .left-nav li,
        .navbar-nav .megamenu > .dropdown-menu .left-nav li {
          border-top: none;
          padding: 0; }
          .navbar-nav .megamenu .experience-component > .demo .left-nav li:hover,
          .navbar-nav .megamenu > .dropdown-menu .left-nav li:hover {
            background: unset; }
        .navbar-nav .megamenu .experience-component > .demo .left-nav a,
        .navbar-nav .megamenu > .dropdown-menu .left-nav a {
          padding: 10px 15px;
          font-size: 18px;
          text-transform: var(--secondary-nav-text-transform);
          letter-spacing: 0;
          border-right: 2px solid #F2F2F2;
          margin-right: -2px; }
          .navbar-nav .megamenu .experience-component > .demo .left-nav a.active,
          .navbar-nav .megamenu > .dropdown-menu .left-nav a.active {
            color: var(--brand-secondary);
            border-right: 2px solid var(--brand-secondary); } }

.navbar-nav .megamenu .has-asset-1 .megamenu-asset-1 {
  padding: 16px;
  border-top: 1px solid #C5C5C5; }
  @media (min-width: 1024px) {
    .navbar-nav .megamenu .has-asset-1 .megamenu-asset-1 {
      padding: 30px 16px;
      margin: 0 auto;
      padding-left: 16px;
      border-top: none;
      border-right: none;
      flex-basis: 25%; } }

.navbar-nav .megamenu .has-asset-2 .megamenu-asset-2 {
  padding: 16px;
  border-top: 1px solid #C5C5C5; }
  @media (min-width: 1024px) {
    .navbar-nav .megamenu .has-asset-2 .megamenu-asset-2 {
      padding: 0;
      margin: 0 auto;
      padding-left: 16px;
      border-top: none;
      border-left: none;
      flex-basis: 25%; }
      .navbar-nav .megamenu .has-asset-2 .megamenu-asset-2 img {
        margin-bottom: -30px;
        margin-top: -30px;
        margin-left: 16px;
        margin-right: -16px; } }

@media (min-width: 1024px) {
  .navbar-nav .megamenu .mega-left .active-list + [class*="sub-asset-"] {
    display: flex;
    padding: 0;
    flex: 0 0 50%; } }

.navbar-nav .megamenu .mega-left .sub-asset-1,
.navbar-nav .megamenu .mega-left .sub-asset-2 {
  display: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  list-style: none; }
  .navbar-nav .megamenu .mega-left .sub-asset-1 .page-designer,
  .navbar-nav .megamenu .mega-left .sub-asset-2 .page-designer {
    flex: 0 0 100%;
    padding-right: 16px; }

.navbar-nav .megamenu .mega-left .sub-asset-1.sub-asset-2 .page-designer {
  flex: 0 0 50%; }

@media (min-width: 1024px) {
  .navbar-nav .megamenu .mega-left.has-asset-1 .megamenu-asset-1,
  .navbar-nav .megamenu .mega-left.has-asset-1 .megamenu-asset-2, .navbar-nav .megamenu .mega-left.has-asset-2 .megamenu-asset-1,
  .navbar-nav .megamenu .mega-left.has-asset-2 .megamenu-asset-2 {
    flex: 1 1 calc((100% - 15%)/2);
    padding: 30px 16px 30px 0; } }

@media (min-width: 1024px) {
  .navbar-nav .megamenu .mega-left.has-asset-1.has-asset-2 .megamenu-asset-1,
  .navbar-nav .megamenu .mega-left.has-asset-1.has-asset-2 .megamenu-asset-2 {
    flex: 1 1 calc((100% - 15%)/4); } }

@media (max-width: 1023.98px) {
  .navbar-nav .megamenu.show [class*="has-asset-"] .thirdCatOpen + [class*="megamenu-asset-"] {
    display: none; }
    .navbar-nav .megamenu.show [class*="has-asset-"] .thirdCatOpen + [class*="megamenu-asset-"] + [class*="megamenu-asset-"] {
      display: none; } }

@media (min-width: 1024px) {
  .navbar-nav .megamenu .menu-subcategories > .dropdown-item {
    flex-basis: 20%; }
  .navbar-nav .megamenu .has-asset-1 .menu-subcategories,
  .navbar-nav .megamenu .has-asset-2 .menu-subcategories {
    flex-grow: 1; }
    .navbar-nav .megamenu .has-asset-1 .menu-subcategories > .dropdown-item,
    .navbar-nav .megamenu .has-asset-2 .menu-subcategories > .dropdown-item {
      flex-basis: 33.33%; }
  .navbar-nav .megamenu .has-asset-1.has-asset-2 .menu-subcategories > .dropdown-item {
    flex-basis: 50%; } }

@media (min-width: 1440px) {
  .navbar-nav .megamenu .has-asset-1 .menu-subcategories > .dropdown-item,
  .navbar-nav .megamenu .has-asset-2 .menu-subcategories > .dropdown-item {
    flex-basis: 20%; }
  .navbar-nav .megamenu .has-asset-1.has-asset-2 .menu-subcategories > .dropdown-item {
    flex-basis: 33.33%; } }

@media (max-width: 1023.98px) {
  .main-menu.menu-toggleable-left {
    transform: translateX(-100%);
    transition: transform 200ms ease-out;
    display: block;
    width: 350px;
    height: calc(100vh - 70px);
    background-color: #FFFFFF;
    z-index: 300;
    overflow-x: hidden;
    overflow-y: auto; }
    .main-menu.menu-toggleable-left:not(.demo) {
      position: fixed;
      top: 0;
      left: 0; }
    .main-menu.menu-toggleable-left.in {
      transform: translateX(0); }
      .main-menu.menu-toggleable-left.in .show > .dropdown-menu {
        transform: translateX(0);
        overflow: auto; }
    .main-menu.menu-toggleable-left > .container {
      height: 100%;
      padding-left: 0;
      padding-right: 0; }
    .main-menu.menu-toggleable-left .menu-group {
      padding-bottom: 16px; }
    .main-menu.menu-toggleable-left .search-mobile .search-field {
      padding: 0 16px;
      border-radius: 0;
      border-left: none;
      border-right: none;
      font-size: 16px;
      line-height: normal; }
    .main-menu.menu-toggleable-left .search-mobile .fa-close,
    .main-menu.menu-toggleable-left .search-mobile .fa-search {
      right: 16px; }
    .main-menu.menu-toggleable-left .search-mobile .reset-button {
      display: none !important; }
    .main-menu.menu-toggleable-left .search-mobile .suggestions-wrapper {
      background-color: #C5C5C5;
      overflow: hidden; }
      .main-menu.menu-toggleable-left .search-mobile .suggestions-wrapper .suggestions {
        position: static;
        width: 100%;
        margin-bottom: 16px;
        box-shadow: none; }
        .main-menu.menu-toggleable-left .search-mobile .suggestions-wrapper .suggestions > .container {
          max-width: 350px;
          margin-left: 0; }
    .main-menu.menu-toggleable-left .dropdown-menu {
      width: 100%;
      height: 100%;
      padding-bottom: 70px;
      overflow: hidden;
      display: block; }
      .main-menu.menu-toggleable-left .dropdown-menu:not(.demo) {
        transform: translateX(-100%);
        transition: transform 200ms ease-out;
        position: fixed;
        left: 0;
        top: 0; }
    .main-menu.menu-toggleable-left .dropdown-toggle:after {
      transform: rotate(-90deg); }
    .main-menu.menu-toggleable-left .dropdown-item:hover {
      background: transparent; }
    .main-menu.menu-toggleable-left .top-category ~ .megamenu-asset-only .menu-subcategories,
    .main-menu.menu-toggleable-left .top-category ~ .mega-left .menu-subcategories,
    .main-menu.menu-toggleable-left .top-category ~ .menu-subcategories-container .menu-subcategories {
      border-top: 1px solid #C5C5C5;
      padding-left: 16px;
      padding-right: 16px; }
      .main-menu.menu-toggleable-left .top-category ~ .megamenu-asset-only .menu-subcategories > .dropdown-item > .dropdown-link,
      .main-menu.menu-toggleable-left .top-category ~ .mega-left .menu-subcategories > .dropdown-item > .dropdown-link,
      .main-menu.menu-toggleable-left .top-category ~ .menu-subcategories-container .menu-subcategories > .dropdown-item > .dropdown-link {
        padding-left: 0; }
      .main-menu.menu-toggleable-left .top-category ~ .megamenu-asset-only .menu-subcategories > .dropdown-item .top-category,
      .main-menu.menu-toggleable-left .top-category ~ .mega-left .menu-subcategories > .dropdown-item .top-category,
      .main-menu.menu-toggleable-left .top-category ~ .menu-subcategories-container .menu-subcategories > .dropdown-item .top-category {
        margin-bottom: -1px;
        border-bottom: 1px solid #C5C5C5; }
        .main-menu.menu-toggleable-left .top-category ~ .megamenu-asset-only .menu-subcategories > .dropdown-item .top-category ~ .dropdown-item,
        .main-menu.menu-toggleable-left .top-category ~ .mega-left .menu-subcategories > .dropdown-item .top-category ~ .dropdown-item,
        .main-menu.menu-toggleable-left .top-category ~ .menu-subcategories-container .menu-subcategories > .dropdown-item .top-category ~ .dropdown-item {
          margin-left: 16px;
          margin-right: 16px;
          width: auto; }
          .main-menu.menu-toggleable-left .top-category ~ .megamenu-asset-only .menu-subcategories > .dropdown-item .top-category ~ .dropdown-item > .dropdown-link,
          .main-menu.menu-toggleable-left .top-category ~ .mega-left .menu-subcategories > .dropdown-item .top-category ~ .dropdown-item > .dropdown-link,
          .main-menu.menu-toggleable-left .top-category ~ .menu-subcategories-container .menu-subcategories > .dropdown-item .top-category ~ .dropdown-item > .dropdown-link {
            padding-left: 0; }
    .main-menu.menu-toggleable-left .top-category .user-message {
      display: none; }
    .main-menu.menu-toggleable-left .support .top-category {
      border-bottom: 1px solid #C5C5C5; }
    .main-menu.menu-toggleable-left .nav-item ~ .nav-item {
      border-top: 1px solid #C5C5C5; }
    .main-menu.menu-toggleable-left .nav-item .nav-link {
      padding: 16px;
      color: var(--brand-primary); }
      .main-menu.menu-toggleable-left .nav-item .nav-link.dropdown-toggle:hover {
        color: var(--brand-secondary); }
    .main-menu.menu-toggleable-left .nav-item.highlight .nav-link {
      color: #A3080F; }
    .main-menu.menu-toggleable-left .close-menu {
      background-color: #C5C5C5; }
      .main-menu.menu-toggleable-left .close-menu button {
        display: block;
        width: 100%;
        padding: 16px;
        text-align: left; }
      .main-menu.menu-toggleable-left .close-menu .icon {
        float: left;
        display: block;
        margin-top: 3px;
        margin-right: 10px; }
    .main-menu.menu-toggleable-left .flag-icon {
      margin-right: 5px; }
    .main-menu.menu-toggleable-left .store-locator-header-button {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 16px); }
      .main-menu.menu-toggleable-left .store-locator-header-button:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: inherit;
        color: inherit;
        font-weight: normal; }
      .main-menu.menu-toggleable-left .store-locator-header-button[class*="btn-"]:not(.title) {
        padding-left: calc(var(--button-padding-x) + 20px); }
        .main-menu.menu-toggleable-left .store-locator-header-button[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        .main-menu.menu-toggleable-left .store-locator-header-button[class*="btn-"]:not(.title).btn-sm, .main-menu.menu-toggleable-left .btn-group-sm > .store-locator-header-button.btn[class*="btn-"]:not(.title) {
          padding-left: calc(20px + 20px); }
          .main-menu.menu-toggleable-left .store-locator-header-button[class*="btn-"]:not(.title).btn-sm:before, .main-menu.menu-toggleable-left .btn-group-sm > .store-locator-header-button.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: inherit;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .main-menu.menu-toggleable-left .store-locator-header-button[class*="btn-"]:not(.title) [class*="icon-"],
        .main-menu.menu-toggleable-left .store-locator-header-button[class*="btn-"]:not(.title) [class^="icon-"],
        .main-menu.menu-toggleable-left .store-locator-header-button[class*="btn-"]:not(.title) .fa {
          display: none; }
      .main-menu.menu-toggleable-left .store-locator-header-button::before {
        padding-right: 4px; } }

@media (max-width: 768.98px) {
  .main-menu.menu-toggleable-left {
    height: calc(100vh - 50px); } }

@media (max-width: 543.98px) {
  .main-menu.menu-toggleable-left {
    width: calc(100% - 50px);
    max-width: 350px; } }

.header-banner {
  background-color: #000000;
  text-align: center;
  color: #FFFFFF;
  font-size: 11px;
  line-height: 20px;
  position: relative;
  padding-bottom: 10px;
  padding-top: 10px; }
  .header-banner .header-banner-column-1 .pull-left:not(:empty) {
    margin-left: 16px; }
  .header-banner .header-banner-column-2 .content {
    width: 100%; }
  .header-banner .header-banner-column-2 .close-button .close {
    opacity: 1;
    color: #FFFFFF;
    font-size: 10px;
    padding: 0 16px 0 3px; }
  .header-banner .header-banner-column-3 .pull-right {
    margin-right: 16px; }
  .header-banner .container {
    padding-left: 0;
    padding-right: 0; }
  .header-banner .dropdown-toggle:after {
    font-size: 10px; }
  .header-banner .nav-link {
    color: #FFFFFF;
    padding: 0; }

.pre-nav-header {
  height: 30px;
  background-color: #F2F2F2; }
  @media (min-width: 769px) {
    .pre-nav-header {
      height: 40px; } }
  .pre-nav-header > .container {
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0; }
    .pre-nav-header > .container .row {
      width: auto; }
      @media (min-width: 769px) {
        .pre-nav-header > .container .row {
          width: 100%; } }
  .pre-nav-header .site-switcher {
    display: flex;
    flex-flow: row nowrap; }
    .pre-nav-header .site-switcher > a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: auto; }
      @media (min-width: 769px) {
        .pre-nav-header .site-switcher > a {
          height: 40px;
          padding: 0 16px; } }
      .pre-nav-header .site-switcher > a img {
        max-height: 15px;
        min-height: 15px;
        width: auto;
        padding: 0 5px;
        filter: grayscale(1);
        transition: 200ms; }
        @media (min-width: 769px) {
          .pre-nav-header .site-switcher > a img {
            max-height: 13.33333px;
            padding: 0; } }
      .pre-nav-header .site-switcher > a:hover img {
        filter: grayscale(0); }
      .pre-nav-header .site-switcher > a.active {
        background-color: #FFFFFF; }
        .pre-nav-header .site-switcher > a.active img {
          filter: grayscale(0); }
    .pre-nav-header .site-switcher p {
      margin-bottom: 0;
      color: #767676;
      font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
      font-weight: normal;
      font-size: 11px;
      text-transform: uppercase; }

.header-nav {
  position: relative;
  z-index: 300;
  background: var(--header-background-color);
  border-bottom: 1px solid var(--base-border-color); }
  .header-nav.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    transition: all 400ms cubic-bezier(0.77, 0, 0.175, 1);
    z-index: 400; }
    .mobile-menu-in .header-nav.fixed {
      box-shadow: none; }
    .header-nav.fixed .brand .logo-home {
      background: var(--brand-logo-mobile) no-repeat center;
      background-size: contain; }
      @media (min-width: 769px) {
        .header-nav.fixed .brand .logo-home {
          background: var(--brand-logo-desktop) no-repeat center;
          background-size: contain; } }
      @media (min-width: 1024px) {
        .header-nav.fixed .brand .logo-home {
          background: var(--brand-logo-desktop) no-repeat center;
          background-size: contain; } }
  @media (min-width: 1024px) {
    .header-nav.nav-center .navbar-nav {
      justify-content: center;
      text-align: center; } }
  @media (min-width: 1024px) {
    .logo-center .header-nav {
      border-bottom-width: 0; } }

.sticky-header .fixed-header-enhanced:not(.transparent-header) {
  padding-bottom: var(--header-height); }

.sticky-header .fixed-header-enhanced .header-nav {
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  z-index: 400;
  will-change: transform;
  transform: translateY(-100%);
  animation: slide-up 400ms ease-out forwards; }

.sticky-header.scroll-direction-up .fixed-header-enhanced .header-nav {
  animation: slide-down 400ms ease-out forwards; }

@media (min-width: 1024px) {
  .header .store-selector,
  .header-banner .store-selector {
    max-width: 200px; } }

.header .store-selector .store-locator-header-button,
.header-banner .store-selector .store-locator-header-button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  appearance: none; }
  .header .store-selector .store-locator-header-button:before,
  .header-banner .store-selector .store-locator-header-button:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .header .store-selector .store-locator-header-button[class*="btn-"]:not(.title),
  .header-banner .store-selector .store-locator-header-button[class*="btn-"]:not(.title) {
    padding-left: calc(var(--button-padding-x) + 20px); }
    .header .store-selector .store-locator-header-button[class*="btn-"]:not(.title):before,
    .header-banner .store-selector .store-locator-header-button[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .header .store-selector .store-locator-header-button[class*="btn-"]:not(.title).btn-sm, .header .store-selector .btn-group-sm > .store-locator-header-button.btn[class*="btn-"]:not(.title),
    .header-banner .store-selector .store-locator-header-button[class*="btn-"]:not(.title).btn-sm,
    .header-banner .store-selector .btn-group-sm > .store-locator-header-button.btn[class*="btn-"]:not(.title) {
      padding-left: calc(20px + 20px); }
      .header .store-selector .store-locator-header-button[class*="btn-"]:not(.title).btn-sm:before, .header .store-selector .btn-group-sm > .store-locator-header-button.btn[class*="btn-"]:not(.title):before,
      .header-banner .store-selector .store-locator-header-button[class*="btn-"]:not(.title).btn-sm:before,
      .header-banner .store-selector .btn-group-sm > .store-locator-header-button.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .header .store-selector .store-locator-header-button[class*="btn-"]:not(.title) [class*="icon-"],
    .header .store-selector .store-locator-header-button[class*="btn-"]:not(.title) [class^="icon-"],
    .header .store-selector .store-locator-header-button[class*="btn-"]:not(.title) .fa,
    .header-banner .store-selector .store-locator-header-button[class*="btn-"]:not(.title) [class*="icon-"],
    .header-banner .store-selector .store-locator-header-button[class*="btn-"]:not(.title) [class^="icon-"],
    .header-banner .store-selector .store-locator-header-button[class*="btn-"]:not(.title) .fa {
      display: none; }
  .header .store-selector .store-locator-header-button::before,
  .header-banner .store-selector .store-locator-header-button::before {
    padding-right: 4px; }
  .header .store-selector .store-locator-header-button:hover:before,
  .header-banner .store-selector .store-locator-header-button:hover:before {
    color: var(--primary-nav-color-hover, var(--brand-primary)); }

.header {
  position: relative;
  height: 50px;
  padding: 0; }
  @media (min-width: 769px) {
    .header {
      height: 70px; } }
  @media (min-width: 1024px) {
    .header {
      height: 64px; } }
  .header .row {
    height: 100%;
    align-items: center; }
    .header .row .col {
      display: flex;
      height: 100%;
      align-items: center; }
  .header > .row {
    flex-wrap: nowrap; }
  .header .header-column-1 > .pull-left:not(:empty) {
    margin-left: 16px; }
  .header .header-column-2 {
    flex-grow: 0; }
  .header .header-column-3 {
    justify-content: flex-end; }
    .header .header-column-3 > .pull-right {
      margin-right: 16px; }
  .header .nav-link,
  .header .country-selector {
    color: var(--primary-nav-color, var(--brand-primary));
    transition: color 200ms ease-out; }
    .header .nav-link:hover,
    .header .country-selector:hover {
      color: var(--primary-nav-color-hover, var(--brand-primary));
      transition: color 200ms ease-out; }
  .header .nav-link {
    padding: 5px; }
  .header .brand {
    height: 100%;
    padding-top: calc(var(--header-logo-padding-y) / 2);
    padding-bottom: calc(var(--header-logo-padding-y) / 2); }
    .header .brand .logo-home {
      width: var(--brand-logo-mobile-size);
      height: 100%;
      margin: 0 auto;
      background: var(--brand-logo-mobile) no-repeat center;
      background-size: contain; }
      @media (min-width: 769px) {
        .header .brand .logo-home {
          width: var(--brand-logo-desktop-size);
          background: var(--brand-logo-desktop) no-repeat center;
          background-size: contain; } }
      @media (min-width: 1024px) {
        .header .brand .logo-home {
          width: var(--brand-logo-desktop-size);
          background: var(--brand-logo-desktop) no-repeat center;
          background-size: contain; } }
  .header .navbar-toggler {
    padding-left: 16px;
    padding-right: 16px; }
    .header .navbar-toggler:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 18px;
      color: var(--primary-nav-color, var(--brand-primary));
      font-weight: normal; }
    .header .navbar-toggler[class*="btn-"]:not(.title) {
      padding-left: calc(var(--button-padding-x) + 20px); }
      .header .navbar-toggler[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: var(--primary-nav-color, var(--brand-primary));
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .header .navbar-toggler[class*="btn-"]:not(.title).btn-sm, .header .btn-group-sm > .navbar-toggler.btn[class*="btn-"]:not(.title) {
        padding-left: calc(20px + 20px); }
        .header .navbar-toggler[class*="btn-"]:not(.title).btn-sm:before, .header .btn-group-sm > .navbar-toggler.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: var(--primary-nav-color, var(--brand-primary));
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .header .navbar-toggler[class*="btn-"]:not(.title) [class*="icon-"],
      .header .navbar-toggler[class*="btn-"]:not(.title) [class^="icon-"],
      .header .navbar-toggler[class*="btn-"]:not(.title) .fa {
        display: none; }
    .header .navbar-toggler:hover {
      color: var(--primary-nav-color-hover, var(--brand-primary)); }
    .mobile-menu-in .header .navbar-toggler:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 18px;
      color: var(--primary-nav-color, var(--brand-primary));
      font-weight: normal; }
    .mobile-menu-in .header .navbar-toggler[class*="btn-"]:not(.title) {
      padding-left: calc(var(--button-padding-x) + 20px); }
      .mobile-menu-in .header .navbar-toggler[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: var(--primary-nav-color, var(--brand-primary));
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .mobile-menu-in .header .navbar-toggler[class*="btn-"]:not(.title).btn-sm, .mobile-menu-in .header .btn-group-sm > .navbar-toggler.btn[class*="btn-"]:not(.title) {
        padding-left: calc(20px + 20px); }
        .mobile-menu-in .header .navbar-toggler[class*="btn-"]:not(.title).btn-sm:before, .mobile-menu-in .header .btn-group-sm > .navbar-toggler.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: var(--primary-nav-color, var(--brand-primary));
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .mobile-menu-in .header .navbar-toggler[class*="btn-"]:not(.title) [class*="icon-"],
      .mobile-menu-in .header .navbar-toggler[class*="btn-"]:not(.title) [class^="icon-"],
      .mobile-menu-in .header .navbar-toggler[class*="btn-"]:not(.title) .fa {
        display: none; }
  .header .minicart .minicart-icon:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 22px;
    color: var(--primary-nav-color, var(--brand-primary));
    font-weight: normal; }
  .header .minicart .minicart-icon[class*="btn-"]:not(.title) {
    padding-left: calc(var(--button-padding-x) + 20px); }
    .header .minicart .minicart-icon[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: var(--primary-nav-color, var(--brand-primary));
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .header .minicart .minicart-icon[class*="btn-"]:not(.title).btn-sm, .header .minicart .btn-group-sm > .minicart-icon.btn[class*="btn-"]:not(.title) {
      padding-left: calc(20px + 20px); }
      .header .minicart .minicart-icon[class*="btn-"]:not(.title).btn-sm:before, .header .minicart .btn-group-sm > .minicart-icon.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: var(--primary-nav-color, var(--brand-primary));
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .header .minicart .minicart-icon[class*="btn-"]:not(.title) [class*="icon-"],
    .header .minicart .minicart-icon[class*="btn-"]:not(.title) [class^="icon-"],
    .header .minicart .minicart-icon[class*="btn-"]:not(.title) .fa {
      display: none; }
  .header .minicart .minicart-icon:hover:before {
    color: var(--primary-nav-color-hover, var(--brand-primary)); }
  .header .minicart .minicart-total {
    position: relative;
    margin-right: 10px; }
  .header .minicart .minicart-quantity {
    position: absolute;
    top: -2px;
    right: -9px;
    background-color: var(--brand-tertiary);
    color: #FFFFFF;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    line-height: 16px;
    display: inline-block;
    text-align: center;
    font-size: 9px; }
  .header .custom-dropdown.show .nav-link {
    color: var(--primary-nav-color-hover, var(--brand-primary)); }

@media (max-width: 1023.98px) {
  .logo-left .header-column-1 {
    order: 1; }
  .logo-left .header-column-2 {
    order: 2; }
  .logo-left .header-column-3 {
    order: 2; } }

@media (min-width: 1024px) {
  .logo-left .header-banner {
    overflow: visible; }
    .logo-left .header-banner-column-3 .dropdown-menu {
      left: auto;
      right: 0; }
  .logo-left .main-menu {
    background: transparent; }
  .logo-left .header-column-1 {
    margin-bottom: -1px;
    padding-left: 0;
    padding-right: 0;
    position: static;
    overflow-x: auto;
    overflow-y: hidden;
    mask-image: linear-gradient(90deg, black 90%, transparent);
    scrollbar-width: none; }
    .logo-left .header-column-1::-webkit-scrollbar {
      width: 0;
      height: 0; }
    .logo-left .header-column-1:hover, .logo-left .header-column-1:focus-within {
      mask-image: unset; }
  .logo-left .nav-center .header-column-1 {
    text-align: center; }
    .logo-left .nav-center .header-column-1 .main-menu {
      margin: 0 auto; }
  .logo-left .header-column-2 {
    padding-left: 16px;
    padding-right: 16px; } }
  @media (min-width: 1024px) and (min-width: 1024px) {
    .logo-left .header-column-2 {
      min-width: calc(var(--brand-logo-desktop-size) + (16px * 2)); } }
  @media (min-width: 1024px) and (min-width: 1440px) {
    .logo-left .header-column-2 {
      padding-right: 30px;
      min-width: calc(var(--brand-logo-desktop-size) + 16px + 30px); } }

@media (min-width: 1024px) {
  .logo-left .header-column-3 {
    flex-grow: 0; } }
  @media screen and (min-width: 1024px) and (-ms-high-contrast: active), (min-width: 1024px) and (-ms-high-contrast: none) {
    .logo-left .header-column-3 {
      flex: 1; } }

@media (min-width: 1024px) {
    .logo-left .header-column-3 .pull-right {
      margin-left: 16px;
      margin-right: 16px; }
      .logo-left .header-column-3 .pull-right.search {
        margin-right: 0; }
  .logo-left .navbar.bg-inverse .navbar-nav > .nav-item > .nav-link {
    padding-top: calc((64px - 20px) / 2);
    padding-bottom: calc((64px - 20px) / 2);
    position: relative;
    font-size: 14px; }
    .logo-left .navbar.bg-inverse .navbar-nav > .nav-item > .nav-link:before {
      content: '';
      display: block;
      height: 1px;
      background-color: var(--brand-primary);
      position: absolute;
      bottom: 0;
      transition: right 200ms ease-out;
      left: 18px;
      right: 100%;
      z-index: 1001; }
  .logo-left .navbar.bg-inverse .navbar-nav > .nav-item > .nav-link:hover:before,
  .logo-left .navbar.bg-inverse .navbar-nav > .nav-item.show .nav-link:before {
    right: 18px; }
  .logo-left .navbar.bg-inverse .navbar-nav > .nav-item.highlight .nav-link {
    color: #A3080F; }
  .logo-left .navbar.bg-inverse .navbar-nav.nav-item-hover > .nav-item:not(:hover) .nav-link {
    color: var(--header-link-color-hover-not-hovered);
    transition: color 200ms ease-out; }
  .logo-left .navbar.bg-inverse .navbar-nav.nav-item-hover > .nav-item.highlight .nav-link:hover {
    color: var(--brand-secondary); }
  .logo-left .navbar.bg-inverse .navbar-nav:not(.nav-item-hover) > .nav-item.is-inview > .nav-link:before, .logo-left .navbar.bg-inverse .navbar-nav:not(.nav-item-hover) > .nav-item.contains-inview > .nav-link:before {
    right: 18px;
    height: 1px;
    background-color: var(--brand-primary); } }

.main-menu {
  background-color: var(--header-navbar-background-color); }
  .main-menu .navbar .close-menu button,
  .main-menu .navbar .close-button button {
    background-color: transparent;
    border: 0;
    -webkit-appearance: none; }
  .main-menu .navbar .close-button button:after {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 12px;
    color: inherit;
    font-weight: normal; }
  .main-menu .navbar .close-button button[class*="btn-"]:not(.title) {
    padding-right: calc(var(--button-padding-x) + 20px); }
    .main-menu .navbar .close-button button[class*="btn-"]:not(.title):after {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(10px); }
    .main-menu .navbar .close-button button[class*="btn-"]:not(.title).btn-sm, .main-menu .navbar .close-button .btn-group-sm > button.btn[class*="btn-"]:not(.title) {
      padding-right: calc(20px + 20px); }
      .main-menu .navbar .close-button button[class*="btn-"]:not(.title).btn-sm:after, .main-menu .navbar .close-button .btn-group-sm > button.btn[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
    .main-menu .navbar .close-button button[class*="btn-"]:not(.title) [class*="icon-"],
    .main-menu .navbar .close-button button[class*="btn-"]:not(.title) [class^="icon-"],
    .main-menu .navbar .close-button button[class*="btn-"]:not(.title) .fa {
      display: none; }
  .main-menu .navbar .close-button button span {
    display: none; }
  @media (min-width: 1024px) {
    .main-menu .navbar .nav-item.custom-dropdown > .dropdown-toggle:after {
      content: none;
      font-family: "icomoon";
      display: inline-block;
      font-size: 10px;
      color: inherit;
      font-weight: normal; }
    .main-menu .navbar .nav-item.custom-dropdown > .dropdown-toggle[class*="btn-"]:not(.title) {
      padding-right: calc(var(--button-padding-x) + 20px); }
      .main-menu .navbar .nav-item.custom-dropdown > .dropdown-toggle[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .main-menu .navbar .nav-item.custom-dropdown > .dropdown-toggle[class*="btn-"]:not(.title).btn-sm, .main-menu .navbar .btn-group-sm.nav-item.custom-dropdown > .dropdown-toggle.btn[class*="btn-"]:not(.title) {
        padding-right: calc(20px + 20px); }
        .main-menu .navbar .nav-item.custom-dropdown > .dropdown-toggle[class*="btn-"]:not(.title).btn-sm:after, .main-menu .navbar .btn-group-sm.nav-item.custom-dropdown > .dropdown-toggle.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .main-menu .navbar .nav-item.custom-dropdown > .dropdown-toggle[class*="btn-"]:not(.title) [class*="icon-"],
      .main-menu .navbar .nav-item.custom-dropdown > .dropdown-toggle[class*="btn-"]:not(.title) [class^="icon-"],
      .main-menu .navbar .nav-item.custom-dropdown > .dropdown-toggle[class*="btn-"]:not(.title) .fa {
        display: none; } }
  .main-menu .dropdown-link,
  .main-menu .nav-link {
    font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
    text-decoration: none;
    text-transform: var(--primary-nav-text-transform);
    font-size: 14px;
    font-weight: var(--primary-nav-font-weight);
    line-height: 20px;
    letter-spacing: 0.03em; }
    .main-menu .dropdown-link:hover,
    .main-menu .nav-link:hover {
      text-decoration: none; }
    .main-menu .dropdown-link-secondary,
    .main-menu .nav-link-secondary {
      font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
      font-size: 14px;
      font-weight: var(--secondary-nav-font-weight);
      line-height: 20px;
      text-transform: var(--secondary-nav-text-transform);
      color: var(--secondary-nav-color, var(--brand-primary)); }
      .main-menu .dropdown-link-secondary:hover,
      .main-menu .nav-link-secondary:hover {
        color: var(--secondary-nav-color-hover, var(--brand-primary)); }
    .main-menu .dropdown-link-tertiary,
    .main-menu .nav-link-tertiary {
      color: var(--tertiary-nav-color, var(--brand-secondary));
      font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
      font-size: 14px;
      font-weight: var(--tertiary-nav-font-weight);
      text-transform: var(--tertiary-nav-text-transform); }
      .main-menu .dropdown-link-tertiary:hover,
      .main-menu .nav-link-tertiary:hover {
        color: var(--tertiary-nav-color-hover, var(--brand-primary)); }

.slide-up {
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
  max-height: 100px;
  overflow: hidden; }
  .slide-up.hide {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1); }

.cookie-warning-messaging.cookie-warning {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  display: none; }

.valid-cookie-warning {
  background-color: #007c9e;
  color: #FFFFFF;
  white-space: nowrap; }
  .valid-cookie-warning p {
    margin-top: 0;
    margin-bottom: 0.2em;
    padding-right: 2em; }

.checkout-header .header-locale-container .country-selector {
  display: block; }

.checkout-header .header-support-container .support {
  display: block; }

.checkout-header .header-account-container {
  display: block; }

.checkout-header .minicart {
  display: block; }

.header-account-container {
  display: none; }
  @media (min-width: 1024px) {
    .header-account-container {
      display: block; }
      .header-account-container .header-link.nav-link {
        font-size: 0;
        margin: 8px 0 0; }
        .header-account-container .header-link.nav-link:before {
          content: "";
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: var(--primary-nav-color, var(--brand-primary));
          font-weight: normal; }
        .header-account-container .header-link.nav-link[class*="btn-"]:not(.title) {
          padding-left: calc(var(--button-padding-x) + 20px); }
          .header-account-container .header-link.nav-link[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 20px;
            color: var(--primary-nav-color, var(--brand-primary));
            font-weight: normal;
            position: absolute;
            transform: translateX(-30px); }
          .header-account-container .header-link.nav-link[class*="btn-"]:not(.title).btn-sm, .header-account-container .btn-group-sm > .header-link.nav-link.btn[class*="btn-"]:not(.title) {
            padding-left: calc(20px + 20px); }
            .header-account-container .header-link.nav-link[class*="btn-"]:not(.title).btn-sm:before, .header-account-container .btn-group-sm > .header-link.nav-link.btn[class*="btn-"]:not(.title):before {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: var(--primary-nav-color, var(--brand-primary));
              font-weight: normal;
              position: absolute;
              transform: translateX(-32px); }
          .header-account-container .header-link.nav-link[class*="btn-"]:not(.title) [class*="icon-"],
          .header-account-container .header-link.nav-link[class*="btn-"]:not(.title) [class^="icon-"],
          .header-account-container .header-link.nav-link[class*="btn-"]:not(.title) .fa {
            display: none; }
        .header-account-container .header-link.nav-link > .header-link {
          display: none; } }

@media (min-width: 1024px) {
  header .user .dropdown-menu,
  header .support .dropdown-menu {
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08); }
    header .user .dropdown-menu .dropdown-item + .dropdown-item,
    header .support .dropdown-menu .dropdown-item + .dropdown-item {
      border: none; }
    header .user .dropdown-menu .dropdown-item .header-link,
    header .support .dropdown-menu .dropdown-item .header-link {
      text-align: center;
      padding: 8px; }
  header .user .nav,
  header .support .nav {
    padding: 16px 0;
    display: block; }
  header.logo-center .header-link {
    font-size: 14px; }
  header.logo-center .country-selector .dropdown-toggle,
  header.logo-center .support .dropdown-toggle {
    padding: calc((64px - 20px) / 2) 0; }
  header.logo-center .country-selector .dropdown-menu-right,
  header.logo-center .support .dropdown-menu-right {
    right: auto;
    max-width: 225px; }
  header.logo-center .header-column-2 {
    min-width: var(--brand-logo-desktop-size); } }

html:not(.sticky-header) .transparent-header .header-nav {
  width: 100%;
  transition: background-color 200ms linear, border-color 200ms linear; }
  @media (min-width: 769px) {
    html:not(.sticky-header) .transparent-header .header-nav.nav-center .main-menu {
      transition: background-color 200ms linear; }
    html:not(.sticky-header) .transparent-header .header-nav .brand a {
      transition: filter 200ms linear; }
    html:not(.sticky-header) .transparent-header .header-nav .nav-link,
    html:not(.sticky-header) .transparent-header .header-nav .nav-link::before,
    html:not(.sticky-header) .transparent-header .header-nav .fa-search::before,
    html:not(.sticky-header) .transparent-header .header-nav .site-search input,
    html:not(.sticky-header) .transparent-header .header-nav .site-search input::placeholder,
    html:not(.sticky-header) .transparent-header .header-nav .minicart-icon::before,
    html:not(.sticky-header) .transparent-header .header-nav .store-locator-header-button {
      transition: color 200ms linear; }
    html:not(.sticky-header) .transparent-header .header-nav .site-search {
      transition: border-color 200ms linear; }
      html:not(.sticky-header) .transparent-header .header-nav .site-search .search-field {
        transition: width 200ms linear; }
    html:not(.sticky-header) .transparent-header .header-nav .minicart-quantity {
      transition: color 200ms linear, background-color 200ms linear; } }
  html:not(.sticky-header) .transparent-header .header-nav:not(.fixed) {
    position: absolute; }
  html:not(.sticky-header) .transparent-header .header-nav.fixed .header {
    background-color: transparent; }
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav {
    transition: color 200ms ease-in-out, box-shadow 200ms ease-in-out; }
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark {
      color: #000000; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar-toggler:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 18px;
        color: #000000;
        font-weight: normal; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title) {
        padding-left: calc(var(--button-padding-x) + 20px); }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #000000;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title).btn-sm, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .btn-group-sm > .navbar-toggler.btn[class*="btn-"]:not(.title) {
          padding-left: calc(20px + 20px); }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title).btn-sm:before, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .btn-group-sm > .navbar-toggler.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #000000;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title) [class*="icon-"],
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title) [class^="icon-"],
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title) .fa {
          display: none; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .brand a {
        filter: brightness(0); }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before {
        color: #000000; }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before:before {
          content: "";
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: color;
          font-weight: normal; }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title) {
          padding-left: calc(var(--button-padding-x) + 20px); }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 20px;
            color: color;
            font-weight: normal;
            position: absolute;
            transform: translateX(-30px); }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title).btn-sm, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .btn-group-sm > .minicart-icon.btn::before[class*="btn-"]:not(.title) {
            padding-left: calc(20px + 20px); }
            html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title).btn-sm:before, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .btn-group-sm > .minicart-icon.btn::before[class*="btn-"]:not(.title):before {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: color;
              font-weight: normal;
              position: absolute;
              transform: translateX(-32px); }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title) [class*="icon-"],
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title) [class^="icon-"],
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title) .fa {
            display: none; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-quantity {
        color: #FFFFFF;
        background-color: #A3080F; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .store-selector .store-locator-header-button {
        color: #000000; }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .nav-item > .nav-link,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .nav-item > .nav-link::before,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .user > .nav-link::before,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .user > .nav-link::after,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .support > .nav-link,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .support > .nav-link::after,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .country-selector > .dropdown-toggle::after,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .fa-search::before,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .site-search input {
          color: #000000; } }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .site-search .search-field::placeholder {
          color: #000000; } }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar .menu-group .nav.navbar-nav > .nav-item:not(:hover) > .nav-link {
          color: #000000; }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar .menu-group .nav.navbar-nav > .nav-item:not(:hover) > .nav-link::before {
            background-color: #000000; } }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar .menu-group .nav.navbar-nav > .nav-item:hover > .nav-link {
          color: #000000; } }
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light {
      color: #FFFFFF; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar-toggler:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 18px;
        color: #FFFFFF;
        font-weight: normal; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title) {
        padding-left: calc(var(--button-padding-x) + 20px); }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #FFFFFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title).btn-sm, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .btn-group-sm > .navbar-toggler.btn[class*="btn-"]:not(.title) {
          padding-left: calc(20px + 20px); }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title).btn-sm:before, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .btn-group-sm > .navbar-toggler.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #FFFFFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title) [class*="icon-"],
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title) [class^="icon-"],
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title) .fa {
          display: none; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .brand a {
        filter: brightness(0) invert(1); }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before {
        color: #FFFFFF; }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before:before {
          content: "";
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: color;
          font-weight: normal; }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title) {
          padding-left: calc(var(--button-padding-x) + 20px); }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 20px;
            color: color;
            font-weight: normal;
            position: absolute;
            transform: translateX(-30px); }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title).btn-sm, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .btn-group-sm > .minicart-icon.btn::before[class*="btn-"]:not(.title) {
            padding-left: calc(20px + 20px); }
            html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title).btn-sm:before, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .btn-group-sm > .minicart-icon.btn::before[class*="btn-"]:not(.title):before {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: color;
              font-weight: normal;
              position: absolute;
              transform: translateX(-32px); }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title) [class*="icon-"],
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title) [class^="icon-"],
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title) .fa {
            display: none; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-quantity {
        color: #FFFFFF;
        background-color: #000000; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .store-selector .store-locator-header-button {
        color: #FFFFFF; }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .nav-item > .nav-link,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .nav-item > .nav-link::before,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .user > .nav-link::before,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .user > .nav-link::after,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .support > .nav-link,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .support > .nav-link::after,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .country-selector > .dropdown-toggle::after,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .fa-search::before,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .site-search input {
          color: #FFFFFF; } }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .site-search .search-field::placeholder {
          color: #FFFFFF; } }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar .menu-group .nav.navbar-nav > .nav-item:not(:hover) > .nav-link {
          color: #FFFFFF; }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar .menu-group .nav.navbar-nav > .nav-item:not(:hover) > .nav-link::before {
            background-color: #FFFFFF; } }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar .menu-group .nav.navbar-nav > .nav-item:hover > .nav-link {
          color: #FFFFFF; } }
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav:not(.fixed) {
      background: transparent;
      border-bottom: transparent;
      transition: background-color 200ms linear, border-color 200ms linear;
      width: 100%;
      position: absolute; }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav:not(.fixed) .main-menu {
          background-color: transparent;
          transition: background-color 200ms linear; } }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav:not(.fixed) .header {
        transition: color 200ms linear;
        background: transparent; }
        @media (min-width: 769px) {
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav:not(.fixed) .header .navbar .menu-group .nav.navbar-nav > .nav-item:not(:hover) .nav-link {
            transition: color 200ms ease-out; } }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav:not(.fixed) .header .site-search {
          border-color: transparent; }

.site-search {
  position: relative;
  width: 100%; }
  .site-search .search-field {
    border-top: none; }
  .site-search .fa-close,
  .site-search .fa-search {
    position: absolute;
    top: 12px;
    border: none;
    right: 20px;
    padding: 0;
    background-color: transparent; }
  .site-search .fa-close:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: var(--primary-nav-color, var(--brand-primary));
    font-weight: normal; }
  .site-search .fa-close[class*="btn-"]:not(.title) {
    padding-left: calc(var(--button-padding-x) + 20px); }
    .site-search .fa-close[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: var(--primary-nav-color, var(--brand-primary));
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .site-search .fa-close[class*="btn-"]:not(.title).btn-sm, .site-search .btn-group-sm > .fa-close.btn[class*="btn-"]:not(.title) {
      padding-left: calc(20px + 20px); }
      .site-search .fa-close[class*="btn-"]:not(.title).btn-sm:before, .site-search .btn-group-sm > .fa-close.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: var(--primary-nav-color, var(--brand-primary));
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .site-search .fa-close[class*="btn-"]:not(.title) [class*="icon-"],
    .site-search .fa-close[class*="btn-"]:not(.title) [class^="icon-"],
    .site-search .fa-close[class*="btn-"]:not(.title) .fa {
      display: none; }
  .site-search .fa-search:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: var(--primary-nav-color, var(--brand-primary));
    font-weight: normal; }
  .site-search .fa-search[class*="btn-"]:not(.title) {
    padding-left: calc(var(--button-padding-x) + 20px); }
    .site-search .fa-search[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: var(--primary-nav-color, var(--brand-primary));
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .site-search .fa-search[class*="btn-"]:not(.title).btn-sm, .site-search .btn-group-sm > .fa-search.btn[class*="btn-"]:not(.title) {
      padding-left: calc(20px + 20px); }
      .site-search .fa-search[class*="btn-"]:not(.title).btn-sm:before, .site-search .btn-group-sm > .fa-search.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: var(--primary-nav-color, var(--brand-primary));
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .site-search .fa-search[class*="btn-"]:not(.title) [class*="icon-"],
    .site-search .fa-search[class*="btn-"]:not(.title) [class^="icon-"],
    .site-search .fa-search[class*="btn-"]:not(.title) .fa {
      display: none; }
  .site-search .reset-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    right: 20px;
    padding: 0 5px;
    background-color: var(--header-background-color);
    z-index: 1; }
    .site-search .reset-button:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: var(--primary-nav-color, var(--brand-primary));
      font-weight: normal; }
    .site-search .reset-button[class*="btn-"]:not(.title) {
      padding-left: calc(var(--button-padding-x) + 20px); }
      .site-search .reset-button[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: var(--primary-nav-color, var(--brand-primary));
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .site-search .reset-button[class*="btn-"]:not(.title).btn-sm, .site-search .btn-group-sm > .reset-button.btn[class*="btn-"]:not(.title) {
        padding-left: calc(20px + 20px); }
        .site-search .reset-button[class*="btn-"]:not(.title).btn-sm:before, .site-search .btn-group-sm > .reset-button.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: var(--primary-nav-color, var(--brand-primary));
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .site-search .reset-button[class*="btn-"]:not(.title) [class*="icon-"],
      .site-search .reset-button[class*="btn-"]:not(.title) [class^="icon-"],
      .site-search .reset-button[class*="btn-"]:not(.title) .fa {
        display: none; }
  .site-search input {
    height: calc(16px + 12px + 14px + (1px*2));
    padding-right: 40px;
    appearance: none; }
    @media (min-width: 1024px) {
      .site-search input {
        height: 100%; } }
    .site-search input:hover {
      box-shadow: none; }
  @media (min-width: 1024px) {
    .site-search {
      float: right;
      display: block;
      height: 64px;
      border-width: 0 1px 0 1px;
      border-color: #C5C5C5;
      border-style: solid; }
      .site-search form {
        float: right; }
      .site-search .search-field {
        font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
        text-decoration: none;
        text-transform: var(--primary-nav-text-transform);
        font-size: 14px;
        font-weight: var(--primary-nav-font-weight);
        line-height: 20px;
        letter-spacing: 0.03em;
        border: none;
        text-align: left;
        color: var(--primary-nav-color, var(--brand-primary));
        padding-top: calc((64px - 20px) / 2);
        padding-bottom: calc((64px - 20px) / 2);
        padding-right: 40px;
        padding-left: 20px;
        transition: width 200ms ease-out;
        width: 125px;
        background: transparent;
        text-decoration: none; }
        .site-search .search-field:hover {
          text-decoration: none; }
        .site-search .search-field-secondary {
          font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
          font-size: 14px;
          font-weight: var(--secondary-nav-font-weight);
          line-height: 20px;
          text-transform: var(--secondary-nav-text-transform);
          color: var(--secondary-nav-color, var(--brand-primary)); }
          .site-search .search-field-secondary:hover {
            color: var(--secondary-nav-color-hover, var(--brand-primary)); }
        .site-search .search-field-tertiary {
          color: var(--tertiary-nav-color, var(--brand-secondary));
          font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
          font-size: 14px;
          font-weight: var(--tertiary-nav-font-weight);
          text-transform: var(--tertiary-nav-text-transform); }
          .site-search .search-field-tertiary:hover {
            color: var(--tertiary-nav-color-hover, var(--brand-primary)); }
        .site-search .search-field::placeholder {
          color: var(--primary-nav-color, var(--brand-primary)); }
        .site-search .search-field:focus {
          box-shadow: none; }
        .site-search .search-field:hover {
          text-decoration: none; }
      .site-search .fa-close,
      .site-search .fa-search {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }
      .site-search .fa-close::before,
      .site-search .fa-search::before,
      .site-search .reset-button::before {
        font-size: 22px; } }
  @media (min-width: 1440px) {
    .site-search .search-field:focus {
      width: 200px; } }

@media (min-width: 1024px) {
  .search-icon-only .site-search {
    border-color: transparent;
    border-style: solid;
    border-width: 0 1px 0 1px;
    transition: border 200ms ease-out;
    width: auto; }
    .search-icon-only .site-search .search-field {
      border-left: none;
      border-right: none;
      width: 0; }
    .search-icon-only .site-search.expanded {
      border-color: #C5C5C5; }
      .search-icon-only .site-search.expanded .search-field {
        width: 125px; }
      .search-icon-only .site-search.expanded button {
        color: var(--brand-secondary); }
  .logo-center .search-icon-only .site-search {
    height: 64px;
    border-bottom: 0;
    border-top: 0; } }

@media (min-width: 1440px) {
  .search-icon-only .site-search.expanded .search-field {
    width: 200px; } }

.suggestions-wrapper {
  position: relative; }
  .suggestions-wrapper .suggestions {
    list-style-type: none;
    display: block;
    background-color: #FFFFFF;
    top: 0;
    right: 0;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    position: absolute;
    width: 350px; }
  .suggestions-wrapper .suggestions-header {
    font-family: var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif);
    font-size: 15px;
    margin-bottom: 5px;
    color: #000000;
    text-transform: none;
    padding-top: 16px;
    background: transparent; }
    .suggestions-wrapper .suggestions-header:not(:first-child) {
      border-top: 1px solid #C5C5C5; }
  .suggestions-wrapper .suggestions-items {
    font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
    font-size: var(--base-font-size);
    padding-bottom: 16px; }
  .suggestions-wrapper .suggestions-item a {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding-top: 3px;
    padding-bottom: 3px; }
  .suggestions-wrapper .suggestions-item .swatch-thumbnail {
    width: 30px;
    height: 30px;
    background-color: #FFFFFF;
    background-size: cover;
    display: block;
    position: relative;
    text-align: center;
    border-radius: 0;
    margin-right: 16px; }
    .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value {
      transition: box-shadow 200ms ease-out; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value:hover:not(.unselectable) {
        box-shadow: inset 0px -2px 0px #767676; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.selected {
        box-shadow: inset 0px -2px 0px #000000; }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.selected:after {
          content: "";
          font-family: "icomoon";
          display: inline-block;
          font-size: 7px;
          color: #FFFFFF;
          font-weight: normal; }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title) {
          padding-right: calc(var(--button-padding-x) + 20px); }
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 20px;
            color: #FFFFFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title).btn-sm, .suggestions-wrapper .suggestions-item .btn-group-sm > .swatch-thumbnail.color-value.selected.btn[class*="btn-"]:not(.title) {
            padding-right: calc(20px + 20px); }
            .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title).btn-sm:after, .suggestions-wrapper .suggestions-item .btn-group-sm > .swatch-thumbnail.color-value.selected.btn[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title) .fa {
            display: none; }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.selected::after {
          position: absolute;
          top: -4.66667px;
          right: -4.66667px;
          display: none;
          background: rgba(0, 0, 0, 0.7);
          width: 14px;
          height: 14px;
          line-height: 14px;
          padding-left: 1px;
          text-align: center;
          border-radius: 50%;
          z-index: 1; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.unselectable:before, .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.unselectable:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%) rotate(45deg);
        height: calc(100% + 15px);
        width: 1px; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.unselectable:after {
        background-color: #000000; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.unselectable:before {
        background-color: #FFFFFF;
        margin-right: 1px; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.unselectable, .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.unavailable {
        opacity: 0.5;
        pointer-events: none;
        pointer-events: auto;
        position: relative; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.unselectable {
        cursor: not-allowed; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.out-of-stock {
        cursor: pointer;
        opacity: 0.5;
        pointer-events: none; }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.out-of-stock:before, .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.out-of-stock:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%) rotate(45deg);
          height: calc(100% + 15px);
          width: 1px; }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.out-of-stock:after {
          background-color: #BEBEBE; }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.out-of-stock:before {
          background-color: #FFFFFF;
          margin-right: 1px; }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.out-of-stock.selected:after {
          display: block; }
    .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value {
      transition: border 200ms ease-out, box-shadow 200ms ease-out; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value:not(.unselectable):hover {
        border-color: #767676;
        box-shadow: none; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected, .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover {
        background: #FFFFFF;
        color: #000000;
        border-color: #000000;
        box-shadow: none; }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:after, .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover:after {
          content: "";
          font-family: "icomoon";
          display: inline-block;
          font-size: 8px;
          color: #FFFFFF;
          font-weight: normal; }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title), .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) {
          padding-right: calc(var(--button-padding-x) + 20px); }
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title):after, .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 20px;
            color: #FFFFFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm, .suggestions-wrapper .suggestions-item .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm, .suggestions-wrapper .suggestions-item .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
            padding-right: calc(20px + 20px); }
            .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm:after, .suggestions-wrapper .suggestions-item .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm:after, .suggestions-wrapper .suggestions-item .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title) .fa, .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class*="icon-"],
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class^="icon-"],
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) .fa {
            display: none; }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected::after, .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover::after {
          position: absolute;
          top: -5.33333px;
          right: -5.33333px;
          background: var(--brand-primary);
          width: 16px;
          height: 16px;
          line-height: 16px;
          padding-left: 1px;
          text-align: center;
          border-radius: 50%;
          display: none; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.unselectable, .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.unavailable {
        opacity: 0.5;
        pointer-events: none;
        pointer-events: auto;
        position: relative; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.unselectable {
        cursor: not-allowed; }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.unselectable:before, .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.unselectable:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%) rotate(45deg);
          height: calc(100% + 15px);
          width: 1px; }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.unselectable:after {
          background-color: #BEBEBE; }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.unselectable:before {
          background-color: #FFFFFF;
          margin-right: 1px; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.out-of-stock {
        cursor: pointer;
        opacity: 0.5;
        pointer-events: none; }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.out-of-stock:before, .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.out-of-stock:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%) rotate(45deg);
          height: calc(100% + 15px);
          width: 1px; }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.out-of-stock:after {
          background-color: #BEBEBE; }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.out-of-stock:before {
          background-color: #FFFFFF;
          margin-right: 1px; }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.out-of-stock.selected:after {
          display: block; }
  .suggestions-wrapper .suggestions-item .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .suggestions-wrapper .suggestions-item .category-parent {
    color: #BEBEBE; }
  .suggestions-wrapper .suggestions-item.selected {
    background-color: #C5C5C5; }

.hero {
  position: relative;
  overflow: hidden;
  display: flex; }
  .hero .hero-container {
    display: flex;
    padding: 16px;
    height: 100%;
    width: 100%; }
    @media (min-width: 769px) {
      .hero .hero-container {
        padding: 16px; } }
    @media (min-width: 1024px) {
      .hero .hero-container {
        padding: 50px; } }
    @media (min-width: 1440px) {
      .full-bleed .hero .hero-container {
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto; } }
    .hero .hero-container .hero-background-link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
      .hero .hero-container .hero-background-link .video-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
        .hero .hero-container .hero-background-link .video-container .video-player {
          display: none; }
  .hero .embedded-component {
    width: 100%; }
    .hero .embedded-component:empty {
      display: none; }
    .hero .embedded-component .experience-region:empty {
      display: none; }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .hero {
      height: 0; }
      .hero.no-image {
        height: auto; } }
  .hero.short {
    min-height: 150px; }
    @media (min-width: 769px) {
      .hero.short {
        min-height: 80px; } }
    @media (min-width: 1024px) {
      .hero.short {
        min-height: 80px; } }
  .hero.medium {
    min-height: 250px; }
    @media (min-width: 769px) {
      .hero.medium {
        min-height: 300px; } }
    @media (min-width: 1024px) {
      .hero.medium {
        min-height: 350px; } }
  .hero.tall {
    min-height: 600px; }
    @media (min-width: 769px) {
      .hero.tall {
        min-height: 650px; } }
    @media (min-width: 1024px) {
      .hero.tall {
        min-height: 700px; } }
  .hero.screen-height {
    min-height: 100vh; }
    .fixed-header ~ div .hero.screen-height {
      min-height: calc(100vh - 50px); }
      @media (min-width: 769px) {
        .fixed-header ~ div .hero.screen-height {
          min-height: calc(100vh - 70px); } }
      @media (min-width: 1024px) {
        .fixed-header ~ div .hero.screen-height {
          min-height: calc(100vh - 64px); } }
    .experience-main > .experience-component:first-child .hero.screen-height {
      min-height: calc(100vh - 50px - 1px - 20px - 10px - 10px); }
      .multi-brand .experience-main > .experience-component:first-child .hero.screen-height {
        min-height: calc(100vh - 50px - 1px - 20px - 10px - 10px - 30px); }
      @media (min-width: 769px) {
        .experience-main > .experience-component:first-child .hero.screen-height {
          min-height: calc(100vh - 70px - 1px - 20px - 10px - 10px); }
          .multi-brand .experience-main > .experience-component:first-child .hero.screen-height {
            min-height: calc(100vh - 70px - 1px - 20px - 10px - 10px- 40px); } }
      @media (min-width: 1024px) {
        .experience-main > .experience-component:first-child .hero.screen-height {
          min-height: calc(100vh - 64px - 1px - 20px - 10px - 10px); }
          .multi-brand .experience-main > .experience-component:first-child .hero.screen-height {
            min-height: calc(100vh - 64px - 1px - 20px - 10px - 10px - 40px); } }
    .transparent-header ~ div .experience-main > .experience-component:first-child .hero.screen-height {
      min-height: calc(100vh - 20px - 10px - 10px); }
      .multi-brand .transparent-header ~ div .experience-main > .experience-component:first-child .hero.screen-height {
        min-height: calc(100vh - 20px - 10px - 10px - 30px); }
      @media (min-width: 769px) {
        .transparent-header ~ div .experience-main > .experience-component:first-child .hero.screen-height {
          min-height: calc(100vh - 20px - 10px - 10px); }
          .multi-brand .transparent-header ~ div .experience-main > .experience-component:first-child .hero.screen-height {
            min-height: calc(100vh - 20px - 10px - 10px - 40px); } }
      @media (min-width: 1024px) {
        .transparent-header ~ div .experience-main > .experience-component:first-child .hero.screen-height {
          min-height: calc(100vh - 20px - 10px - 10px); }
          .multi-brand .transparent-header ~ div .experience-main > .experience-component:first-child .hero.screen-height {
            min-height: calc(100vh - 20px - 10px - 10px - 40px); } }

.hero-media {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .full-bleed .hero-media {
    width: calc(100vw + 2px); }
  .hero-media.hero-media-tablet, .hero-media.hero-media-desktop {
    display: none; }
  @media (min-width: 769px) {
    .hero-media.hero-media-mobile, .hero-media.hero-media-desktop {
      display: none; }
    .hero-media.hero-media-tablet {
      display: block; } }
  @media (min-width: 1024px) {
    .hero-media.hero-media-mobile, .hero-media.hero-media-tablet {
      display: none; }
    .hero-media.hero-media-desktop {
      display: block; } }
  .hero-media.video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    right: auto;
    transform: none;
    transition: opacity 200ms ease-out; }
    .hero-media.video-container .video-aspect-ratio {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      width: 0;
      height: 0;
      min-height: 100%;
      min-width: 100%; }
      .hero-media.video-container .video-aspect-ratio.aspect-ratio-16-9 {
        padding-top: calc(9 / 16 * 100%);
        padding-left: calc(16 / 9 * 100vh); }
      .hero-media.video-container .video-aspect-ratio.aspect-ratio-9-16 {
        padding-top: calc(16 / 9 * 100%);
        padding-left: calc(9 / 16 * 100vh); }
      .hero-media.video-container .video-aspect-ratio.aspect-ratio-4-3 {
        padding-top: calc(3 / 4 * 100%);
        padding-left: calc(4 / 3 * 100vh); }
      .hero-media.video-container .video-aspect-ratio.aspect-ratio-3-4 {
        padding-top: calc(4 / 3 * 100%);
        padding-left: calc(3 / 4 * 100vh); }
      .hero-media.video-container .video-aspect-ratio.aspect-ratio-1-1 {
        padding-top: calc(1 / 1 * 100%);
        padding-left: calc(1 / 1 * 100vh); }
      .hero-media.video-container .video-aspect-ratio .video-player {
        position: static;
        pointer-events: none; }
      .hero-media.video-container .video-aspect-ratio iframe,
      .hero-media.video-container .video-aspect-ratio video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0; }
        .hero-media.video-container .video-aspect-ratio iframe.video-player,
        .hero-media.video-container .video-aspect-ratio video.video-player {
          position: absolute; }
    .hero-media.video-container ~ .replay-video {
      z-index: 1;
      position: absolute;
      opacity: 0;
      transition: opacity 200ms ease-out; }
      .align-items-start .hero-media.video-container ~ .replay-video,
      .align-items-center .hero-media.video-container ~ .replay-video {
        bottom: 16px; }
      .align-items-end .hero-media.video-container ~ .replay-video {
        top: 16px; }
        .transparent-header ~ div .align-items-end .hero-media.video-container ~ .replay-video {
          top: 66px; }
          @media (min-width: 769px) {
            .transparent-header ~ div .align-items-end .hero-media.video-container ~ .replay-video {
              top: 86px; } }
          @media (min-width: 1024px) {
            .transparent-header ~ div .align-items-end .hero-media.video-container ~ .replay-video {
              top: 80px; } }
      .justify-content-md-start .hero-media.video-container ~ .replay-video {
        right: 16px; }
      .justify-content-md-end .hero-media.video-container ~ .replay-video,
      .justify-content-md-center .hero-media.video-container ~ .replay-video {
        left: 16px; }
    .hero-media.video-container.video-stopped {
      opacity: 0; }
      .hero-media.video-container.video-stopped ~ .replay-video {
        transition: opacity 200ms ease-out 600ms;
        opacity: 1; }

.hero-text {
  overflow: visible;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%; }
  .text-in-card .hero-text {
    padding: 16px;
    border-width: 1px;
    border-style: solid; }
    @media (min-width: 769px) {
      .text-in-card .hero-text {
        padding: 16px; } }
    @media (min-width: 1024px) {
      .text-in-card .hero-text {
        padding: 16px; } }
    .text-in-card .hero-text:not([class*='border--']) {
      border-color: transparent; }
    .text-in-card .hero-text.semitransparent:not([class*='background--']).dark-theme {
      background: rgba(0, 0, 0, 0.7); }
    .text-in-card .hero-text.semitransparent:not([class*='background--']).light-theme {
      background: rgba(255, 255, 255, 0.7); }
    .text-in-card .hero-text.opaque:not([class*='background--']).dark-theme {
      background: #000000; }
    .text-in-card .hero-text.opaque:not([class*='background--']).light-theme {
      background: #FFFFFF; }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .hero-text.text-center {
      align-items: center; }
    .hero-text.text-right {
      align-items: flex-end; } }
  @media (min-width: 769px) {
    .hero-text.width-1-4 {
      max-width: 25%; }
    .hero-text.width-1-3 {
      max-width: 33.3333%; }
    .hero-text.width-1-2 {
      max-width: 50%; }
    .hero-text.width-2-3 {
      max-width: 66.6666%; }
    .hero-text.width-3-4 {
      max-width: 75%; }
    .hero-text.width-1 {
      max-width: 100%; } }
  .hero-text .hero-subtitle > *,
  .hero-text .hero-title > *,
  .hero-text .hero-body > * {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    color: inherit;
    margin-bottom: 0; }
  .hero-text .hero-subtitle:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
    font-family: var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif);
    font-size: calc(var(--base-font-size) - 2px);
    text-transform: none; }
  .hero-text .hero-title:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
    font-family: var(--h1-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
    font-size: var(--h1-mobile-size);
    line-height: 1.14;
    font-weight: 700;
    letter-spacing: 0.01em;
    text-transform: none;
    font-style: normal; }
    @media (min-width: 769px) {
      .hero-text .hero-title:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
        font-size: var(--h1-tablet-size);
        line-height: 1.14;
        font-weight: 700;
        letter-spacing: 0.01em;
        text-transform: none;
        font-style: normal; } }
    @media (min-width: 1024px) {
      .hero-text .hero-title:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
        font-size: var(--h1-desktop-size);
        line-height: 1.11;
        font-weight: 700;
        letter-spacing: 0.01em;
        text-transform: none;
        font-style: normal; } }
  .hero-text .hero-body ul {
    list-style: disc;
    list-style-position: outside;
    margin-left: 16px; }
  @media (min-width: 544px) {
    .hero-text .hero-body ul {
      columns: 2; } }
  .hero-text .hero-subtitle + .hero-title,
  .hero-text .hero-title + .hero-body,
  .hero-text .hero-embedded-component + .hero-body,
  .hero-text .hero-body + .hero-buttons .hero-button,
  .hero-text .hero-embedded-component .experience-region > * {
    margin-top: 16px; }
  .hero-text .hero-buttons {
    font-size: 0; }
  .hero-text .hero-button {
    margin-top: 16px;
    display: inline-block;
    position: relative; }
  .hero-text.text-center .hero-button:not(:only-child) {
    margin-left: 8px;
    margin-right: 8px; }
  .hero-text.text-left .hero-button:not(:only-child) {
    margin-right: 16px; }
  .hero-text.text-right .hero-button:not(:only-child) {
    margin-left: 16px; }

.hero-slider-container .hero-slot.container {
  padding-left: 0;
  padding-right: 0; }

@media (max-width: 768.98px) {
  .hero.half-and-half {
    height: auto; }
    .hero.half-and-half .hero-container {
      flex-flow: column !important; }
    .hero.half-and-half .hero-media {
      transform: translateX(50%);
      top: 0;
      position: relative;
      width: calc(100% + 32px);
      margin: -16px 0 16px -32px; }
    .hero.half-and-half.short {
      min-height: 0; }
      .hero.half-and-half.short .hero-media {
        height: 150px; }
    .hero.half-and-half.medium {
      min-height: 0; }
      .hero.half-and-half.medium .hero-media {
        height: 250px; }
    .hero.half-and-half.tall, .hero.half-and-half.screen-height {
      min-height: 0; }
      .hero.half-and-half.tall .hero-media, .hero.half-and-half.screen-height .hero-media {
        height: 600px; }
    .hero.half-and-half [class*="justify-content-md"] .hero-media.video-container {
      position: absolute;
      margin-left: -50%;
      margin-top: 0; } }

@media (min-width: 769px) and (max-width: 1023.98px) {
  .hero.half-and-half .hero-container:not(.flex-md-column) .hero-media:not(.video-container) {
    transform: translateY(-50%); }
  .hero.half-and-half .hero-container:not(.flex-md-column) .hero-media.width-1-4 {
    max-width: 75%; }
  .hero.half-and-half .hero-container:not(.flex-md-column) .hero-media.width-1-3 {
    max-width: 66.66666%; }
  .hero.half-and-half .hero-container:not(.flex-md-column) .hero-media.width-1-2 {
    max-width: 50%; }
  .hero.half-and-half .hero-container:not(.flex-md-column) .hero-media.width-2-3 {
    max-width: 33.3333%; }
  .hero.half-and-half .hero-container:not(.flex-md-column) .hero-media.width-3-4 {
    max-width: 25%; }
  .hero.half-and-half .hero-container:not(.flex-md-column) .hero-media.width-1 {
    max-width: 50%; }
  .hero.half-and-half .hero-container:not(.flex-md-column) .hero-text.width-1 {
    max-width: 50%; }
  .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-start .hero-media {
    right: 0;
    left: auto; }
    .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-start .hero-media.width-1-4.video-container {
      transform: translateX(25%); }
      .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-start .hero-media.width-1-4.video-container .video-aspect-ratio {
        right: calc(50% + (25%/2)); }
    .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-start .hero-media.width-1-3.video-container {
      transform: translateX(calc(33.3333%)); }
      .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-start .hero-media.width-1-3.video-container .video-aspect-ratio {
        right: calc(50% + (33.3333%/2)); }
    .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-start .hero-media.width-1-2.video-container, .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-start .hero-media.width-1.video-container {
      transform: translateX(50%); }
      .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-start .hero-media.width-1-2.video-container .video-aspect-ratio, .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-start .hero-media.width-1.video-container .video-aspect-ratio {
        right: calc(50% + (50%/2)); }
    .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-start .hero-media.width-2-3.video-container {
      transform: translateX(66.6666%); }
      .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-start .hero-media.width-2-3.video-container .video-aspect-ratio {
        right: calc(50% + (66.6666%/2)); }
    .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-start .hero-media.width-3-4.video-container {
      transform: translateX(75%); }
      .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-start .hero-media.width-3-4.video-container .video-aspect-ratio {
        right: calc(50% + (75%/2)); }
  .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-start .hero-text {
    padding-right: 16px; } }
  @media (min-width: 769px) and (max-width: 1023.98px) and (min-width: 769px) {
    .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-start .hero-text {
      padding-right: 16px; } }
  @media (min-width: 769px) and (max-width: 1023.98px) and (min-width: 1024px) {
    .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-start .hero-text {
      padding-right: 50px; } }

@media (min-width: 769px) and (max-width: 1023.98px) {
  .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-end .hero-media {
    left: 0;
    right: auto; }
    .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-end .hero-media.width-1-4.video-container {
      transform: translateX(-25%); }
      .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-end .hero-media.width-1-4.video-container .video-aspect-ratio {
        right: calc(50% - (25%/2)); }
    .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-end .hero-media.width-1-3.video-container {
      transform: translateX(calc(-33.3333%)); }
      .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-end .hero-media.width-1-3.video-container .video-aspect-ratio {
        right: calc(50% - (33.3333%/2)); }
    .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-end .hero-media.width-1-2.video-container, .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-end .hero-media.width-1.video-container {
      transform: translateX(-50%); }
      .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-end .hero-media.width-1-2.video-container .video-aspect-ratio, .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-end .hero-media.width-1.video-container .video-aspect-ratio {
        right: calc(50% - (50%/2)); }
    .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-end .hero-media.width-2-3.video-container {
      transform: translateX(-66.6666%); }
      .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-end .hero-media.width-2-3.video-container .video-aspect-ratio {
        right: calc(50% - (66.6666%/2)); }
    .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-end .hero-media.width-3-4.video-container {
      transform: translateX(-75%); }
      .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-end .hero-media.width-3-4.video-container .video-aspect-ratio {
        right: calc(50% - (75%/2)); }
  .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-end .hero-text {
    padding-left: 16px; } }
  @media (min-width: 769px) and (max-width: 1023.98px) and (min-width: 769px) {
    .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-end .hero-text {
      padding-left: 16px; } }
  @media (min-width: 769px) and (max-width: 1023.98px) and (min-width: 1024px) {
    .hero.half-and-half .hero-container:not(.flex-md-column).justify-content-md-end .hero-text {
      padding-left: 50px; } }

@media (min-width: 1024px) {
  .hero.half-and-half .hero-media:not(.video-container) {
    transform: translateY(-50%); }
  .hero.half-and-half .hero-media.width-1-4 {
    max-width: 75%; }
  .hero.half-and-half .hero-media.width-1-3 {
    max-width: 66.66666%; }
  .hero.half-and-half .hero-media.width-1-2 {
    max-width: 50%; }
  .hero.half-and-half .hero-media.width-2-3 {
    max-width: 33.3333%; }
  .hero.half-and-half .hero-media.width-3-4 {
    max-width: 25%; }
  .hero.half-and-half .hero-media.width-1 {
    max-width: 50%; }
  .hero.half-and-half .hero-text.width-1 {
    max-width: 50%; }
  .hero.half-and-half.justify-content-md-start .hero-media {
    right: 0;
    left: auto; }
    .hero.half-and-half.justify-content-md-start .hero-media.width-1-4.video-container {
      transform: translateX(25%); }
      .hero.half-and-half.justify-content-md-start .hero-media.width-1-4.video-container .video-aspect-ratio {
        right: calc(50% + (25%/2)); }
    .hero.half-and-half.justify-content-md-start .hero-media.width-1-3.video-container {
      transform: translateX(calc(33.3333%)); }
      .hero.half-and-half.justify-content-md-start .hero-media.width-1-3.video-container .video-aspect-ratio {
        right: calc(50% + (33.3333%/2)); }
    .hero.half-and-half.justify-content-md-start .hero-media.width-1-2.video-container, .hero.half-and-half.justify-content-md-start .hero-media.width-1.video-container {
      transform: translateX(50%); }
      .hero.half-and-half.justify-content-md-start .hero-media.width-1-2.video-container .video-aspect-ratio, .hero.half-and-half.justify-content-md-start .hero-media.width-1.video-container .video-aspect-ratio {
        right: calc(50% + (50%/2)); }
    .hero.half-and-half.justify-content-md-start .hero-media.width-2-3.video-container {
      transform: translateX(66.6666%); }
      .hero.half-and-half.justify-content-md-start .hero-media.width-2-3.video-container .video-aspect-ratio {
        right: calc(50% + (66.6666%/2)); }
    .hero.half-and-half.justify-content-md-start .hero-media.width-3-4.video-container {
      transform: translateX(75%); }
      .hero.half-and-half.justify-content-md-start .hero-media.width-3-4.video-container .video-aspect-ratio {
        right: calc(50% + (75%/2)); }
  .hero.half-and-half.justify-content-md-start .hero-text {
    padding-right: 16px; } }
  @media (min-width: 1024px) and (min-width: 769px) {
    .hero.half-and-half.justify-content-md-start .hero-text {
      padding-right: 16px; } }
  @media (min-width: 1024px) and (min-width: 1024px) {
    .hero.half-and-half.justify-content-md-start .hero-text {
      padding-right: 50px; } }

@media (min-width: 1024px) {
  .hero.half-and-half.justify-content-md-end .hero-media {
    left: 0;
    right: auto; }
    .hero.half-and-half.justify-content-md-end .hero-media.width-1-4.video-container {
      transform: translateX(-25%); }
      .hero.half-and-half.justify-content-md-end .hero-media.width-1-4.video-container .video-aspect-ratio {
        right: calc(50% - (25%/2)); }
    .hero.half-and-half.justify-content-md-end .hero-media.width-1-3.video-container {
      transform: translateX(calc(-33.3333%)); }
      .hero.half-and-half.justify-content-md-end .hero-media.width-1-3.video-container .video-aspect-ratio {
        right: calc(50% - (33.3333%/2)); }
    .hero.half-and-half.justify-content-md-end .hero-media.width-1-2.video-container, .hero.half-and-half.justify-content-md-end .hero-media.width-1.video-container {
      transform: translateX(-50%); }
      .hero.half-and-half.justify-content-md-end .hero-media.width-1-2.video-container .video-aspect-ratio, .hero.half-and-half.justify-content-md-end .hero-media.width-1.video-container .video-aspect-ratio {
        right: calc(50% - (50%/2)); }
    .hero.half-and-half.justify-content-md-end .hero-media.width-2-3.video-container {
      transform: translateX(-66.6666%); }
      .hero.half-and-half.justify-content-md-end .hero-media.width-2-3.video-container .video-aspect-ratio {
        right: calc(50% - (66.6666%/2)); }
    .hero.half-and-half.justify-content-md-end .hero-media.width-3-4.video-container {
      transform: translateX(-75%); }
      .hero.half-and-half.justify-content-md-end .hero-media.width-3-4.video-container .video-aspect-ratio {
        right: calc(50% - (75%/2)); }
  .hero.half-and-half.justify-content-md-end .hero-text {
    padding-left: 16px; } }
  @media (min-width: 1024px) and (min-width: 769px) {
    .hero.half-and-half.justify-content-md-end .hero-text {
      padding-left: 16px; } }
  @media (min-width: 1024px) and (min-width: 1024px) {
    .hero.half-and-half.justify-content-md-end .hero-text {
      padding-left: 50px; } }

@media (max-width: 768.98px) {
  .hero .flex-column .hero-media {
    transform: translateX(50%);
    top: 0;
    position: relative;
    width: calc(100% + 32px);
    margin: -16px 0 16px -32px; }
  .flex-column .hero-media.video-container {
    margin-left: -50%;
    position: absolute;
    margin-top: 0;
    margin-bottom: 0; }
  .hero.short .flex-column {
    min-height: 0; }
    .hero.short .flex-column .hero-media {
      height: 150px; }
  .hero.medium .flex-column {
    min-height: 0; }
    .hero.medium .flex-column .hero-media {
      height: 250px; }
  .hero.tall .flex-column,
  .hero.screen-height .flex-column {
    min-height: 0; }
    .hero.tall .flex-column .hero-media,
    .hero.screen-height .flex-column .hero-media {
      height: 600px; }
  .hero.half-and-half .flex-column[class*="justify-content-md"] .hero-media {
    left: auto;
    right: 50%;
    max-width: calc(100% + 32px); }
  .hero.half-and-half .flex-column[class*="justify-content-md"] .hero-text {
    padding-left: 0;
    padding-right: 0; } }

@media (min-width: 769px) and (max-width: 1023.98px) {
  .hero .flex-md-column .hero-media {
    transform: translateX(50%);
    top: 0;
    position: relative;
    width: calc(100% + 32px);
    margin: -16px 0 16px -32px; }
  .flex-md-column .hero-media.video-container {
    margin-left: -50%;
    position: absolute;
    margin-top: 0;
    margin-bottom: 0; }
  .hero.short .flex-md-column {
    min-height: 0; }
    .hero.short .flex-md-column .hero-media {
      height: 150px; }
  .hero.medium .flex-md-column {
    min-height: 0; }
    .hero.medium .flex-md-column .hero-media {
      height: 250px; }
  .hero.tall .flex-md-column,
  .hero.screen-height .flex-md-column {
    min-height: 0; }
    .hero.tall .flex-md-column .hero-media,
    .hero.screen-height .flex-md-column .hero-media {
      height: 600px; }
  .hero.half-and-half .flex-md-column[class*="justify-content-md"] .hero-media {
    left: auto;
    right: 50%;
    max-width: calc(100% + 32px); }
  .hero.half-and-half .flex-md-column[class*="justify-content-md"] .hero-text {
    padding-left: 0;
    padding-right: 0; } }

.experience-component .hero-button .video-container::before,
.experience-component .hero-button .video-container .video-player,
.experience-component .button .video-container::before,
.experience-component .button .video-container .video-player,
.experience-component .image-text-block-button .video-container::before,
.experience-component .image-text-block-button .video-container .video-player {
  display: none; }

.image-text-block {
  display: block;
  position: relative;
  text-align: center;
  text-decoration: none; }
  .image-text-block:hover {
    text-decoration: none; }
  @media (min-width: 769px) {
    .image-text-block.zoom .image-wrapper {
      will-change: transform;
      transition: transform 200ms ease-out; }
    .image-text-block.zoom:hover .image-wrapper {
      transform: scale(1.2); } }
  .image-text-block .image-cropper {
    border-radius: var(--border-radius);
    overflow: hidden;
    margin: auto;
    width: 100%;
    max-width: 100%;
    max-height: 100%; }
  .image-text-block .image-wrapper {
    width: 100%;
    position: relative; }
    .image-text-block .image-wrapper .background-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: block; }
    .image-text-block .image-wrapper .background-image-tablet,
    .image-text-block .image-wrapper .background-image-desktop {
      display: none; }
    @media (min-width: 769px) {
      .image-text-block .image-wrapper .background-image-mobile,
      .image-text-block .image-wrapper .background-image-desktop {
        display: none; }
      .image-text-block .image-wrapper .background-image-tablet {
        display: block; } }
    @media (min-width: 1024px) {
      .image-text-block .image-wrapper .background-image-mobile,
      .image-text-block .image-wrapper .background-image-tablet {
        display: none; }
      .image-text-block .image-wrapper .background-image-desktop {
        display: block; } }
    .image-text-block .image-wrapper .video-container .video-aspect-ratio {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      padding-top: calc(9 / 16 * 100%);
      padding-left: calc(16 / 9 * 100vh);
      width: 0;
      height: 0;
      min-height: 100%;
      min-width: 100%; }
      .image-text-block .image-wrapper .video-container .video-aspect-ratio .video-player {
        position: static;
        pointer-events: none; }
      .image-text-block .image-wrapper .video-container .video-aspect-ratio iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0; }
        .image-text-block .image-wrapper .video-container .video-aspect-ratio iframe.video-player {
          position: absolute; }
  .image-text-block .image-text-block-text .primary-text > *,
  .image-text-block .image-text-block-text .secondary-text > *,
  .image-text-block .image-text-block-text .image-text-block-subtitle > * {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    color: inherit;
    margin-bottom: 0; }
  .image-text-block .image-text-block-text .image-text-block-subtitle:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
    font-size: calc(var(--base-font-size) - 2px); }
  .image-text-block .image-text-block-text .primary-text:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
    font-size: calc(var(--base-font-size) + 1px); }
  .image-text-block .image-text-block-text .secondary-text:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
    font-size: calc(var(--base-font-size)); }
  .image-text-block .image-text-block-text .image-text-block-subtitle + .primary-text {
    margin-top: 5px; }
  .image-text-block .image-text-block-text .secondary-text + .image-text-block-button {
    margin-top: 16px; }
  .image-text-block .image-text-block-text .image-text-block-button {
    text-shadow: none; }
  .image-text-block.text-placement-below .image-text-block-text {
    margin-top: 16px; }
  .image-text-block.text-placement-inside::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: var(--border-radius); }
  .image-text-block.text-placement-inside .image-text-block-text {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    color: #FFFFFF;
    z-index: 1;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
    width: calc(100% - 16px); }
    @media (min-width: 1024px) {
      .image-text-block.text-placement-inside .image-text-block-text {
        width: calc(100% - 32px); } }
  .image-text-block.aspect-ratio-square .image-wrapper::after {
    display: block;
    content: "";
    clear: both; }
  .image-text-block.aspect-ratio-square .image-wrapper::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(1 / 1 * 100%); }
  .image-text-block.aspect-ratio-landscape .image-wrapper::after {
    display: block;
    content: "";
    clear: both; }
  .image-text-block.aspect-ratio-landscape .image-wrapper::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(1 / 1.5 * 100%); }
  .image-text-block.aspect-ratio-portrait .image-wrapper::after {
    display: block;
    content: "";
    clear: both; }
  .image-text-block.aspect-ratio-portrait .image-wrapper::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(1.5 / 1 * 100%); }

footer {
  background-color: var(--footer-background-color, var(--header-background-color));
  border-top: 1px solid var(--base-border-color);
  padding-bottom: var(--footer-padding-y);
  padding-top: var(--footer-padding-y);
  margin-top: var(--footer-margin-top); }
  footer ul {
    list-style: none;
    padding-left: 0;
    padding-top: 12px; }
  footer .title,
  footer .email-description {
    position: relative;
    color: var(--footer-header-text-color, var(--primary-nav-color, var(--brand-primary)));
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
    text-transform: var(--primary-nav-text-transform); }
  footer .content {
    font-size: 14px; }
    footer .content li {
      height: 34px; }
      footer .content li a {
        text-decoration: none; }
  footer .store .btn {
    margin-bottom: 10px;
    margin-top: 20px; }
  footer .store .content {
    display: none; }
  @media (min-width: 769px) {
    footer .store {
      border: none; }
      footer .store .btn {
        margin-top: 5px; }
      footer .store .content {
        display: block; } }
  footer .store .social::after {
    display: block;
    content: "";
    clear: both; }
  footer .back-to-top {
    margin: 5px;
    padding: 10px 0 0 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none; }
    footer .back-to-top .fa-stack {
      width: 40px;
      height: 40px;
      line-height: 40px; }
    footer .back-to-top .fa-arrow-up {
      width: 100%;
      height: 100%; }
      footer .back-to-top .fa-arrow-up:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #FFFFFF;
        font-weight: normal; }
      footer .back-to-top .fa-arrow-up[class*="btn-"]:not(.title) {
        padding-left: calc(var(--button-padding-x) + 20px); }
        footer .back-to-top .fa-arrow-up[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #FFFFFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        footer .back-to-top .fa-arrow-up[class*="btn-"]:not(.title).btn-sm, footer .back-to-top .btn-group-sm > .fa-arrow-up.btn[class*="btn-"]:not(.title) {
          padding-left: calc(20px + 20px); }
          footer .back-to-top .fa-arrow-up[class*="btn-"]:not(.title).btn-sm:before, footer .back-to-top .btn-group-sm > .fa-arrow-up.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #FFFFFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        footer .back-to-top .fa-arrow-up[class*="btn-"]:not(.title) [class*="icon-"],
        footer .back-to-top .fa-arrow-up[class*="btn-"]:not(.title) [class^="icon-"],
        footer .back-to-top .fa-arrow-up[class*="btn-"]:not(.title) .fa {
          display: none; }
    footer .back-to-top .fa-circle {
      background-color: var(--footer-link-color, var(--secondary-nav-color, var(--brand-secondary)));
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      width: 100%;
      height: 100%;
      border-radius: 50%; }
  footer .copyright,
  footer .postscript {
    color: #767676;
    font-size: 11px;
    mix-blend-mode: multiply; }
    footer .copyright a,
    footer .postscript a {
      color: #767676;
      text-decoration: none; }
  footer hr {
    border-color: transparent;
    margin: 72px 0 10px 0; }
  footer a, footer .text-link {
    color: var(--footer-link-color, var(--secondary-nav-color, var(--brand-secondary))); }
    footer a:hover, footer .text-link:hover {
      color: var(--footer-link-color-hover, var(--secondary-nav-color-hover, var(--brand-primary))); }

.footer-item.collapsible-sm {
  padding-left: 0;
  padding-right: 0; }
  .footer-item.collapsible-sm ~ .collapsible-sm {
    margin-top: 0; }
  .footer-item.collapsible-sm .content {
    margin-bottom: 0; }
  .footer-item.collapsible-sm .title {
    border-bottom: 1px solid #C5C5C5;
    padding: 20px 40px 20px 0; }
    .footer-item.collapsible-sm .title::after {
      color: var(--footer-header-text-color, var(--primary-nav-color, var(--brand-primary)));
      font-size: 16px;
      right: 0; }
  .footer-item.collapsible-sm button {
    text-align: left;
    display: block;
    width: 100%; }
    .footer-item.collapsible-sm button:focus {
      z-index: 1; }
  @media (min-width: 769px) {
    .footer-item.collapsible-sm .title {
      border-bottom: 0;
      padding: 0; }
    .footer-item.collapsible-sm button {
      cursor: text; } }

@media (min-width: 769px) {
  .footer-item.collapsible-sm {
    border: none;
    padding-left: 16px;
    padding-right: 16px; }
    .footer-item.collapsible-sm .content {
      padding-left: 0;
      padding-right: 0; } }

.email-signup-form {
  margin-top: 5px; }
  .email-signup-form .email-success,
  .email-signup-form .email-description {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em; }
  .email-signup-form .email-input input {
    color: var(--footer-link-color, var(--secondary-nav-color, var(--brand-secondary))); }
  .email-signup-form .btn:after {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 16px;
    color: var(--brand-primary);
    font-weight: normal; }
  .email-signup-form .btn[class*="btn-"]:not(.title) {
    padding-right: calc(var(--button-padding-x) + 20px); }
    .email-signup-form .btn[class*="btn-"]:not(.title):after {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: var(--brand-primary);
      font-weight: normal;
      position: absolute;
      transform: translateX(10px); }
    .email-signup-form .btn[class*="btn-"]:not(.title).btn-sm, .email-signup-form .btn-group-sm > .btn[class*="btn-"]:not(.title) {
      padding-right: calc(20px + 20px); }
      .email-signup-form .btn[class*="btn-"]:not(.title).btn-sm:after, .email-signup-form .btn-group-sm > .btn[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: var(--brand-primary);
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
    .email-signup-form .btn[class*="btn-"]:not(.title) [class*="icon-"],
    .email-signup-form .btn[class*="btn-"]:not(.title) [class^="icon-"],
    .email-signup-form .btn[class*="btn-"]:not(.title) .fa {
      display: none; }
  .email-signup-form .btn::after {
    transform: rotate(90deg); }
  .email-signup-form .email-success,
  .email-signup-form .email-error {
    display: none; }
  .email-signup-form.submit-success .email-success {
    display: block; }
  .email-signup-form.submit-success .email-description,
  .email-signup-form.submit-success .email-input {
    display: none; }
  .email-signup-form.submit-error .email-error {
    display: block; }

.row .form-group {
  margin-top: calc(24px - 4px); }

.form-group {
  margin: 0; }
  .form-group ~ .form-group,
  .form-group ~ .btn-block {
    margin-top: 24px; }
  .form-group.required .form-control-label::before {
    content: "* ";
    color: #A3080F; }
  .form-group label {
    font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
    font-weight: normal;
    font-size: var(--base-font-size);
    line-height: 1.4;
    margin-bottom: 6px;
    text-transform: none;
    letter-spacing: 0.1em;
    color: var(--base-font-color); }
  .form-group .form-text,
  .form-group .invalid-feedback {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.05em;
    margin-top: 4px;
    display: block; }
  .form-group .form-text {
    color: #767676; }
  .form-group .invalid-feedback {
    color: #A3080F;
    display: none; }
  .form-group .was-validated .form-control:invalid {
    display: block; }

.form-control, select.form-control {
  outline: none;
  border: 1px solid #C5C5C5;
  box-shadow: none;
  color: var(--base-font-color);
  background-color: #FFFFFF;
  border-radius: var(--border-radius);
  font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.1em;
  transition: border-color 200ms ease-out, box-shadow 200ms ease-out;
  padding: 12px 20px 14px 20px;
  height: calc(16px + 12px + 14px + (1px*2));
  background-clip: border-box; }
  @media (min-width: 769px) {
    .form-control, select.form-control {
      font-size: var(--base-font-size); } }
  .form-control:hover, select.form-control:hover {
    border-color: var(--brand-secondary);
    box-shadow: 0 0 0 1px var(--brand-secondary); }
  .form-control:focus,
  .form-control.focus .form-control:focus:hover, select.form-control:focus,
  select.form-control.focus .form-control:focus:hover,
  .form-control.focus select.form-control:focus:hover,
  select.form-control.focus select.form-control:focus:hover {
    outline: none;
    border-color: var(--brand-tertiary);
    box-shadow: none; }
  .form-control:disabled, .form-control.disabled, select.form-control:disabled, select.form-control.disabled {
    opacity: 0.5;
    pointer-events: none;
    background-color: #FFFFFF; }
  .form-control.form-control-sm, select.form-control.form-control-sm {
    font-size: 11px;
    line-height: 14px;
    padding: 8px 10px 6px 10px;
    height: calc(14px + 8px + 6px + (1px*2)); }
  .form-control.is-invalid, select.form-control.is-invalid {
    background-image: none;
    border-color: #A3080F;
    padding-right: 20px; }
    .form-control.is-invalid:focus, select.form-control.is-invalid:focus {
      border-color: #A3080F;
      box-shadow: none; }
  .form-control::placeholder, select.form-control::placeholder {
    color: #767676; }

.custom-control {
  min-height: 0; }

.custom-radio,
.custom-checkbox {
  user-select: none;
  padding-left: calc(20px + 10px); }
  .custom-radio .custom-control-label,
  .custom-checkbox .custom-control-label {
    font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
    font-weight: normal;
    font-size: var(--base-font-size);
    text-transform: none;
    letter-spacing: 0.1em;
    vertical-align: middle;
    transition: box-shadow 200ms ease-out; }
    .custom-radio .custom-control-label:hover:before,
    .custom-checkbox .custom-control-label:hover:before {
      box-shadow: 0 0 0 1px #BEBEBE; }
    .custom-radio .custom-control-label:before, .custom-radio .custom-control-label:after,
    .custom-checkbox .custom-control-label:before,
    .custom-checkbox .custom-control-label:after {
      top: 0;
      left: calc(-20px - 10px); }
  .custom-radio .custom-control-input:checked ~ .custom-control-label:before,
  .custom-radio .custom-control-input:checked:disabled ~ .custom-control-label:before,
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label:before,
  .custom-checkbox .custom-control-input:checked:disabled ~ .custom-control-label:before {
    background-color: var(--brand-primary);
    border-color: #000000; }
  .custom-radio .custom-control-input:disabled ~ .custom-control-label,
  .custom-checkbox .custom-control-input:disabled ~ .custom-control-label {
    color: inherit;
    opacity: 0.5; }
    .custom-radio .custom-control-input:disabled ~ .custom-control-label:before,
    .custom-checkbox .custom-control-input:disabled ~ .custom-control-label:before {
      box-shadow: none; }
  .custom-radio .custom-control-input.is-invalid ~ .custom-control-label:before,
  .custom-checkbox .custom-control-input.is-invalid ~ .custom-control-label:before {
    background-color: #fcc7ca;
    border-color: #A3080F; }
  .custom-radio .custom-control-input:focus ~ .custom-control-label:before,
  .custom-checkbox .custom-control-input:focus ~ .custom-control-label:before {
    box-shadow: none;
    border-color: #000000; }

.custom-checkbox .custom-control-label:before {
  content: "";
  width: 20px;
  height: 20px;
  border: 1px solid #BEBEBE;
  background-color: #FFFFFF;
  border-radius: var(--border-radius); }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  content: "";
  width: 20px;
  height: 20px;
  background-color: var(--brand-primary);
  border: 1px solid #000000;
  border-radius: var(--border-radius); }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  content: "";
  width: 20px;
  height: 20px;
  line-height: calc(20px + 2px);
  background: none;
  font-family: "icomoon";
  font-size: 13.33333px;
  text-align: center;
  color: #FFFFFF; }

.custom-checkbox + .custom-checkbox {
  margin-top: 3px; }

.custom-radio .custom-control-label:before {
  content: "";
  width: 20px;
  height: 20px;
  border: 1px solid #BEBEBE;
  border-radius: 50%;
  background-color: #FFFFFF; }

.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #FFFFFF;
  border: 1px solid #000000; }

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  content: "";
  background: none;
  width: 14px;
  height: 14px;
  top: calc(6px/ 2);
  left: calc(-30px + 6px/ 2);
  background-color: var(--brand-primary);
  border-radius: 50%; }

.custom-radio + .custom-radio {
  margin-top: 3px; }

.custom-select, select.form-control,
select {
  background-image: url(../images/icons/icon-arrow-down.svg);
  background-size: 12px;
  padding-right: 37px; }
  .custom-select.form-control-sm,
  select.form-control-sm {
    background-size: 10px;
    padding-right: 25px; }
  .custom-select.is-invalid,
  select.is-invalid {
    background-image: url(../images/icons/icon-arrow-down.svg);
    background-size: 12px;
    padding-right: 37px; }
    .custom-select.is-invalid.form-control-sm,
    select.is-invalid.form-control-sm {
      background-size: 10px;
      padding-right: 25px; }

select {
  overflow: hidden;
  max-width: 100vw; }

.quantity-stepper {
  display: flex;
  padding: 0; }
  .quantity-stepper input,
  .quantity-stepper button {
    border: none;
    padding: 0; }
  .quantity-stepper input {
    width: calc(100% - 20px - 20px - 20px - 6px);
    text-align: center;
    height: 100%;
    padding-bottom: 14px;
    padding-top: 12px; }
    .quantity-stepper input:focus {
      outline: none; }
  .quantity-stepper button {
    font-size: 10px;
    padding-top: 12px;
    padding-bottom: 14px; }
    .quantity-stepper button:before {
      font-weight: bold; }
    .quantity-stepper button.icon-minus {
      padding-left: 20px;
      padding-right: 3px; }
    .quantity-stepper button.icon-plus {
      padding-right: 20px;
      padding-left: 3px; }
  .quantity-stepper.form-control-sm {
    padding: 0; }
    .quantity-stepper.form-control-sm input {
      width: calc(100% - 16px - 10px - 10px - 6px);
      padding-top: 8px;
      padding-bottom: 6px; }
    .quantity-stepper.form-control-sm button {
      font-size: 8px;
      padding-top: 8px;
      padding-bottom: 6px; }
      .quantity-stepper.form-control-sm button.icon-minus {
        padding-left: 10px;
        padding-right: 2px; }
      .quantity-stepper.form-control-sm button.icon-plus {
        padding-right: 10px;
        padding-left: 2px; }

.inline-btn {
  background-color: transparent;
  border-color: var(--brand-secondary);
  border-radius: 0;
  border-style: solid;
  border-width: 0 0 1px 0; }
  .inline-btn:hover, .inline-btn:focus {
    border-color: #000000;
    box-shadow: none; }
  .inline-btn input {
    background-color: transparent;
    border-color: transparent;
    border-radius: 0;
    color: var(--base-font-color);
    padding-left: 0; }
    .inline-btn input:hover {
      border: none;
      box-shadow: none; }
    .inline-btn input:focus {
      background-color: transparent;
      outline: none; }
    .inline-btn input::placeholder {
      color: #767676; }
  .inline-btn .btn {
    border: none;
    padding: 0 5px 0 10px; }

.show-password {
  float: right; }
  .show-password input {
    margin-right: 8px; }

.validator-container {
  width: 100%;
  padding: 16px 16px 0 16px; }
  .validator-container .password-validation-helper {
    margin: 0 auto;
    padding: 0; }
    .validator-container .password-validation-helper li {
      font-family: inherit;
      list-style-type: none;
      padding: calc(16px/3) calc(16px/2);
      display: flex;
      flex-flow: wrap;
      align-items: center;
      color: #000000; }
      .validator-container .password-validation-helper li:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 13px;
        color: #BEBEBE;
        font-weight: normal; }
      .validator-container .password-validation-helper li[class*="btn-"]:not(.title) {
        padding-left: calc(var(--button-padding-x) + 20px); }
        .validator-container .password-validation-helper li[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #BEBEBE;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        .validator-container .password-validation-helper li[class*="btn-"]:not(.title).btn-sm, .validator-container .password-validation-helper .btn-group-sm > li.btn[class*="btn-"]:not(.title) {
          padding-left: calc(20px + 20px); }
          .validator-container .password-validation-helper li[class*="btn-"]:not(.title).btn-sm:before, .validator-container .password-validation-helper .btn-group-sm > li.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #BEBEBE;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .validator-container .password-validation-helper li[class*="btn-"]:not(.title) [class*="icon-"],
        .validator-container .password-validation-helper li[class*="btn-"]:not(.title) [class^="icon-"],
        .validator-container .password-validation-helper li[class*="btn-"]:not(.title) .fa {
          display: none; }
      .validator-container .password-validation-helper li:before {
        width: 32px; }
      .validator-container .password-validation-helper li.valid {
        color: #C5C5C5; }
        .validator-container .password-validation-helper li.valid:before {
          content: "";
          font-family: "icomoon";
          display: inline-block;
          font-size: 13px;
          color: #008827;
          font-weight: normal; }
        .validator-container .password-validation-helper li.valid[class*="btn-"]:not(.title) {
          padding-left: calc(var(--button-padding-x) + 20px); }
          .validator-container .password-validation-helper li.valid[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 20px;
            color: #008827;
            font-weight: normal;
            position: absolute;
            transform: translateX(-30px); }
          .validator-container .password-validation-helper li.valid[class*="btn-"]:not(.title).btn-sm, .validator-container .password-validation-helper .btn-group-sm > li.valid.btn[class*="btn-"]:not(.title) {
            padding-left: calc(20px + 20px); }
            .validator-container .password-validation-helper li.valid[class*="btn-"]:not(.title).btn-sm:before, .validator-container .password-validation-helper .btn-group-sm > li.valid.btn[class*="btn-"]:not(.title):before {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #008827;
              font-weight: normal;
              position: absolute;
              transform: translateX(-32px); }
          .validator-container .password-validation-helper li.valid[class*="btn-"]:not(.title) [class*="icon-"],
          .validator-container .password-validation-helper li.valid[class*="btn-"]:not(.title) [class^="icon-"],
          .validator-container .password-validation-helper li.valid[class*="btn-"]:not(.title) .fa {
            display: none; }
      .validator-container .password-validation-helper li.error:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 13px;
        color: #A3080F;
        font-weight: normal; }
      .validator-container .password-validation-helper li.error[class*="btn-"]:not(.title) {
        padding-left: calc(var(--button-padding-x) + 20px); }
        .validator-container .password-validation-helper li.error[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #A3080F;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        .validator-container .password-validation-helper li.error[class*="btn-"]:not(.title).btn-sm, .validator-container .password-validation-helper .btn-group-sm > li.error.btn[class*="btn-"]:not(.title) {
          padding-left: calc(20px + 20px); }
          .validator-container .password-validation-helper li.error[class*="btn-"]:not(.title).btn-sm:before, .validator-container .password-validation-helper .btn-group-sm > li.error.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #A3080F;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .validator-container .password-validation-helper li.error[class*="btn-"]:not(.title) [class*="icon-"],
        .validator-container .password-validation-helper li.error[class*="btn-"]:not(.title) [class^="icon-"],
        .validator-container .password-validation-helper li.error[class*="btn-"]:not(.title) .fa {
          display: none; }
  .order-confirm-create-account .validator-container {
    padding-left: 0;
    padding-right: 0; }
    .order-confirm-create-account .validator-container .password-validation-helper li {
      padding-left: 0;
      padding-right: 0; }

/*
* PAGE DESIGNER LAYOUT GRID SPACING
*/
[class*='experience-commerce_layouts'] + [class*='experience-commerce_layouts'] > div:first-child {
  margin-top: 32px; }

@media (max-width: 768.98px) {
  [class*='experience-commerce_layouts'] .region[class*='col-md'] + .region[class*='col-12']:not(.order-1), [class*='experience-commerce_layouts'] .region[class*='col-md'].order-2[class*='col-12'] {
    margin-top: 32px; }
  [class*='experience-commerce_layouts'] .region[class*='col-md'] + .region[class*='col-6']:not(:nth-child(1)):not(:nth-child(2)) {
    margin-top: 32px; }
  [class*='experience-commerce_layouts'] .region[class*='col-md'] + .region[class*='col-4']:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)) {
    margin-top: 32px; } }

[class*='experience-commerce_layouts'] .region[class*='col-md-6'] .experience-component:not(.experience-commerce_assets-html):not([class*='experience-commerce_layouts']),
[class*='experience-commerce_layouts'] .region[class*='col-md-6'] [class*='experience-commerce_assets'] > div, [class*='experience-commerce_layouts'] .region.align-self-stretch .experience-component:not(.experience-commerce_assets-html):not([class*='experience-commerce_layouts']),
[class*='experience-commerce_layouts'] .region.align-self-stretch [class*='experience-commerce_assets'] > div {
  height: auto; }
  [class*='experience-commerce_layouts'] .region[class*='col-md-6'] .experience-component:not(.experience-commerce_assets-html):not([class*='experience-commerce_layouts']):only-child,
  [class*='experience-commerce_layouts'] .region[class*='col-md-6'] [class*='experience-commerce_assets'] > div:only-child, [class*='experience-commerce_layouts'] .region.align-self-stretch .experience-component:not(.experience-commerce_assets-html):not([class*='experience-commerce_layouts']):only-child,
  [class*='experience-commerce_layouts'] .region.align-self-stretch [class*='experience-commerce_assets'] > div:only-child {
    height: 100%; }
  [class*='experience-commerce_layouts'] .region[class*='col-md-6'] .experience-component:not(.experience-commerce_assets-html):not([class*='experience-commerce_layouts']) + .experience-component:not(.experience-commerce_assets-html):not([class*='experience-commerce_layouts']),
  [class*='experience-commerce_layouts'] .region[class*='col-md-6'] .experience-component:not(.experience-commerce_assets-html):not([class*='experience-commerce_layouts']) [class*='experience-commerce_assets'] > div,
  [class*='experience-commerce_layouts'] .region[class*='col-md-6'] [class*='experience-commerce_assets'] > div + .experience-component:not(.experience-commerce_assets-html):not([class*='experience-commerce_layouts']),
  [class*='experience-commerce_layouts'] .region[class*='col-md-6'] [class*='experience-commerce_assets'] > div [class*='experience-commerce_assets'] > div, [class*='experience-commerce_layouts'] .region.align-self-stretch .experience-component:not(.experience-commerce_assets-html):not([class*='experience-commerce_layouts']) + .experience-component:not(.experience-commerce_assets-html):not([class*='experience-commerce_layouts']),
  [class*='experience-commerce_layouts'] .region.align-self-stretch .experience-component:not(.experience-commerce_assets-html):not([class*='experience-commerce_layouts']) [class*='experience-commerce_assets'] > div,
  [class*='experience-commerce_layouts'] .region.align-self-stretch [class*='experience-commerce_assets'] > div + .experience-component:not(.experience-commerce_assets-html):not([class*='experience-commerce_layouts']),
  [class*='experience-commerce_layouts'] .region.align-self-stretch [class*='experience-commerce_assets'] > div [class*='experience-commerce_assets'] > div {
    height: auto; }
    [class*='experience-commerce_layouts'] .region[class*='col-md-6'] .experience-component:not(.experience-commerce_assets-html):not([class*='experience-commerce_layouts']) + .experience-component:not(.experience-commerce_assets-html):not([class*='experience-commerce_layouts']):only-child,
    [class*='experience-commerce_layouts'] .region[class*='col-md-6'] .experience-component:not(.experience-commerce_assets-html):not([class*='experience-commerce_layouts']) [class*='experience-commerce_assets'] > div:only-child,
    [class*='experience-commerce_layouts'] .region[class*='col-md-6'] [class*='experience-commerce_assets'] > div + .experience-component:not(.experience-commerce_assets-html):not([class*='experience-commerce_layouts']):only-child,
    [class*='experience-commerce_layouts'] .region[class*='col-md-6'] [class*='experience-commerce_assets'] > div [class*='experience-commerce_assets'] > div:only-child, [class*='experience-commerce_layouts'] .region.align-self-stretch .experience-component:not(.experience-commerce_assets-html):not([class*='experience-commerce_layouts']) + .experience-component:not(.experience-commerce_assets-html):not([class*='experience-commerce_layouts']):only-child,
    [class*='experience-commerce_layouts'] .region.align-self-stretch .experience-component:not(.experience-commerce_assets-html):not([class*='experience-commerce_layouts']) [class*='experience-commerce_assets'] > div:only-child,
    [class*='experience-commerce_layouts'] .region.align-self-stretch [class*='experience-commerce_assets'] > div + .experience-component:not(.experience-commerce_assets-html):not([class*='experience-commerce_layouts']):only-child,
    [class*='experience-commerce_layouts'] .region.align-self-stretch [class*='experience-commerce_assets'] > div [class*='experience-commerce_assets'] > div:only-child {
      height: 100%; }

[class*='experience-commerce_layouts'] .no-gutters .row {
  padding-right: 16px;
  padding-left: 16px; }
  @media (min-width: 1440px) {
    [class*='experience-commerce_layouts'] .no-gutters .row {
      padding-right: 24px;
      padding-left: 24px; } }
  [class*='experience-commerce_layouts'] .no-gutters .row > [class*=" col-"] {
    padding-right: 0;
    padding-left: 0; }

[class*='experience-commerce_layouts'] .has-background-image {
  position: relative; }
  [class*='experience-commerce_layouts'] .has-background-image > .container {
    position: relative; }
    [class*='experience-commerce_layouts'] .has-background-image > .container .column-background {
      width: calc(100% - 32px); }
      @media (min-width: 1440px) {
        [class*='experience-commerce_layouts'] .has-background-image > .container .column-background {
          width: calc(100% - 48px); } }
  [class*='experience-commerce_layouts'] .has-background-image .column-background {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; }
    .full-bleed [class*='experience-commerce_layouts'] .has-background-image .column-background {
      width: calc(100vw + 2px); }
    [class*='experience-commerce_layouts'] .has-background-image .column-background.column-background-tablet, [class*='experience-commerce_layouts'] .has-background-image .column-background.column-background-desktop {
      display: none; }
    @media (min-width: 769px) {
      [class*='experience-commerce_layouts'] .has-background-image .column-background.column-background-mobile, [class*='experience-commerce_layouts'] .has-background-image .column-background.column-background-desktop {
        display: none; }
      [class*='experience-commerce_layouts'] .has-background-image .column-background.column-background-tablet {
        display: block; } }
    @media (min-width: 1024px) {
      [class*='experience-commerce_layouts'] .has-background-image .column-background.column-background-mobile, [class*='experience-commerce_layouts'] .has-background-image .column-background.column-background-tablet {
        display: none; }
      [class*='experience-commerce_layouts'] .has-background-image .column-background.column-background-desktop {
        display: block; } }

/*
* LAYOUT GRID ASPECT RATIOS
*/
.region-square,
.region-landscape,
.region-portrait {
  position: relative; }
  .region-square .experience-component,
  .region-landscape .experience-component,
  .region-portrait .experience-component {
    position: absolute;
    top: 0;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
    max-width: calc(100% - 32px); }
    @media (min-width: 1440px) {
      .region-square .experience-component,
      .region-landscape .experience-component,
      .region-portrait .experience-component {
        max-width: calc(100% - 48px); } }

.region-square::after {
  display: block;
  content: "";
  clear: both; }

.region-square::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: calc(1 / 1 * 100%); }

.region-landscape::after {
  display: block;
  content: "";
  clear: both; }

.region-landscape::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: calc(1 / 1.5 * 100%); }

.region-portrait::after {
  display: block;
  content: "";
  clear: both; }

.region-portrait::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: calc(1.5 / 1 * 100%); }

div.sf-interactions-proxyHoverContainer a {
  overflow: visible; }

.hover-switch--hover {
  display: none; }

.hover-switch:hover .hover-switch--load {
  display: none; }

.hover-switch:hover .hover-switch--hover {
  display: block; }

.product-grid .experience-region,
.product-grid .experience-component,
.product-grid .chromeless {
  height: 100%; }

.product-grid .hero {
  min-height: calc(100% - 45px);
  margin-bottom: 45px; }

.folder-nav-layout {
  padding: 0; }
  @media (max-width: 768.98px) {
    .folder-nav-layout .folder-nav-menu {
      padding: 16px; }
    .folder-nav-layout .folder-based-navigation .card .card-body a.active {
      color: var(--brand-primary);
      font-weight: bold; }
    .folder-nav-layout .folder-based-navigation .card .card-body a + .card {
      margin-top: 16px; } }
  @media (min-width: 769px) {
    .folder-nav-layout .folder-nav-menu {
      width: 250px;
      max-width: 250px;
      background: #F2F2F2; }
    .folder-nav-layout .folder-nav-content {
      width: calc(100% - 250px);
      max-width: calc(100% - 250px); }
    .folder-nav-layout .folder-based-navigation {
      position: sticky;
      top: calc(70px + 16px);
      padding-top: 16px;
      padding-left: 16px;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: calc(100vh - (70px + 20px + 10px + 10px)); }
      .multi-brand .folder-nav-layout .folder-based-navigation {
        max-height: calc(100vh - (70px + 20px + 10px + 10px + 40px)); }
      .folder-nav-layout .folder-based-navigation .card {
        margin-bottom: 0;
        border: none; }
        .folder-nav-layout .folder-based-navigation .card .card-header {
          background: transparent; }
        .folder-nav-layout .folder-based-navigation .card .card-title {
          font-family: var(--h5-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
          font-size: var(--h5-mobile-size);
          line-height: 1.25;
          font-weight: 400;
          letter-spacing: 0.01em;
          text-transform: none;
          font-style: normal;
          padding: 0;
          margin-bottom: 3px;
          cursor: text; } }
      @media (min-width: 769px) and (min-width: 769px) {
        .folder-nav-layout .folder-based-navigation .card .card-title {
          font-size: var(--h5-tablet-size);
          line-height: 1.25;
          font-weight: 400;
          letter-spacing: 0.01em;
          text-transform: none;
          font-style: normal; } }
      @media (min-width: 769px) and (min-width: 1024px) {
        .folder-nav-layout .folder-based-navigation .card .card-title {
          font-size: var(--h5-desktop-size);
          line-height: 1.2;
          font-weight: 400;
          letter-spacing: 0.01em;
          text-transform: none;
          font-style: normal; } }
  @media (min-width: 769px) {
        .folder-nav-layout .folder-based-navigation .card .card-body {
          padding: 0;
          margin-bottom: 16px;
          background: transparent; }
          .folder-nav-layout .folder-based-navigation .card .card-body a {
            padding: 2px 0 2px 5px; }
            .folder-nav-layout .folder-based-navigation .card .card-body a.active {
              color: var(--brand-primary);
              font-weight: bold; }
            .folder-nav-layout .folder-based-navigation .card .card-body a + .card {
              margin-top: 16px; }
          .folder-nav-layout .folder-based-navigation .card .card-body .card {
            padding-left: 16px;
            border-left: 1px solid var(--base-border-color); } }
  @media (min-width: 1024px) {
    .folder-nav-layout .folder-based-navigation {
      top: calc(64px + 16px);
      max-height: calc(100vh - (64px + 20px + 10px + 10px)); }
      .multi-brand .folder-nav-layout .folder-based-navigation {
        max-height: calc(100vh - (64px + 20px + 10px + 10px + 40px)); } }

ul.styled-list,
ol.styled-list,
.styled-list ul,
.styled-list ol {
  margin-left: 2px;
  padding-left: 16px; }
  ul.styled-list li,
  ol.styled-list li,
  .styled-list ul li,
  .styled-list ol li {
    margin-bottom: var(--base-font-size)/2;
    padding-left: 4px; }
    ul.styled-list li::marker,
    ol.styled-list li::marker,
    .styled-list ul li::marker,
    .styled-list ol li::marker {
      color: #767676; }

ol.styled-list,
.styled-list ol {
  counter-reset: ol-items; }
  ol.styled-list li,
  .styled-list ol li {
    display: list-item;
    counter-increment: ol-items; }
    ol.styled-list li::marker,
    .styled-list ol li::marker {
      display: list-item;
      content: counter(ol-items) " ";
      font-family: var(--secondary-font), helvetica, arial, sans-serif;
      font-size: var(--base-font-size);
      font-style: normal;
      font-weight: normal; }

.minicart {
  position: relative; }
  .minicart .popover {
    top: 100%;
    left: auto;
    right: -1px;
    min-width: 350px;
    max-width: 350px;
    min-height: 350px;
    display: none; }
    .minicart .popover.show {
      display: block; }
  .minicart .minicart-label {
    font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
    font-weight: normal;
    font-size: var(--base-font-size);
    line-height: 1.4;
    margin-bottom: 6px;
    text-transform: none;
    letter-spacing: 0.1em;
    display: inline; }
  .minicart .cart {
    padding-top: 8px;
    background-color: #FFFFFF; }
  .minicart .remove-btn {
    font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
    border: none;
    border-radius: 50%;
    font-size: 0;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0 20px 20px 20px;
    cursor: pointer;
    z-index: 1;
    background-color: #FFFFFF;
    padding: 4px 6px;
    height: 22px;
    width: 22px;
    margin: 5px;
    left: 0;
    right: auto; }
    .minicart .remove-btn:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 11px;
      color: inherit;
      font-weight: normal; }
    .minicart .remove-btn[class*="btn-"]:not(.title) {
      padding-left: calc(var(--button-padding-x) + 20px); }
      .minicart .remove-btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .minicart .remove-btn[class*="btn-"]:not(.title).btn-sm, .minicart .btn-group-sm > .remove-btn.btn[class*="btn-"]:not(.title) {
        padding-left: calc(20px + 20px); }
        .minicart .remove-btn[class*="btn-"]:not(.title).btn-sm:before, .minicart .btn-group-sm > .remove-btn.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .minicart .remove-btn[class*="btn-"]:not(.title) [class*="icon-"],
      .minicart .remove-btn[class*="btn-"]:not(.title) [class^="icon-"],
      .minicart .remove-btn[class*="btn-"]:not(.title) .fa {
        display: none; }
  .minicart .product-summary {
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 16px;
    padding-top: 16px;
    width: 100%; }
    .minicart .product-summary .line-item-name {
      width: calc(100% - 11px - 16px/2); }
  .minicart .minicart-item + .minicart-item {
    margin-top: 8px;
    padding-top: 16px;
    border-top: 1px solid #C5C5C5;
    border-radius: var(--border-radius); }
  .minicart .minicart-item:first-child .product-line-item {
    margin-top: 0; }
  .minicart .product-line-item {
    position: relative; }
  .minicart .item-price-qty .col {
    flex-direction: column;
    align-items: flex-start; }
  .minicart .quantity,
  .minicart .quantity-stepper {
    width: 100%;
    max-width: 90px; }
  .minicart .quantity {
    min-width: 50px; }
  .minicart .line-item-promo > div {
    padding: 0 0 8px; }
  .minicart .minicart-footer {
    padding-top: 16px;
    border-top: 1px solid #C5C5C5;
    padding-left: 0;
    padding-right: 0; }
  .minicart .estimated-total {
    font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
    font-weight: bold;
    text-transform: none; }
    .minicart .estimated-total p {
      letter-spacing: 0.1em;
      font-size: calc(var(--base-font-size) - 2px); }
      @media (min-width: 1024px) {
        .minicart .estimated-total p {
          font-size: calc(var(--base-font-size) - 1px); } }
  .minicart .single-approaching-discount {
    color: #008827;
    padding-top: 8px; }

.modal {
  z-index: 1050; }
  .modal .modal-content {
    background-color: transparent;
    border: none;
    border-radius: var(--border-radius);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); }
  .modal .modal-header {
    padding: 10px 20px 9px;
    background-color: var(--brand-secondary);
    color: #FFFFFF;
    border-bottom-color: transparent;
    border-top-left-radius: calc(var(--border-radius) - 1px);
    border-top-right-radius: calc(var(--border-radius) - 1px); }
    .modal .modal-header .modal-title {
      font-family: var(--h4-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
      font-size: var(--h4-mobile-size);
      line-height: 1.25;
      font-weight: 400;
      letter-spacing: 0.01em;
      text-transform: none;
      font-style: normal; }
      @media (min-width: 769px) {
        .modal .modal-header .modal-title {
          font-size: var(--h4-tablet-size);
          line-height: 1.25;
          font-weight: 400;
          letter-spacing: 0.01em;
          text-transform: none;
          font-style: normal; } }
      @media (min-width: 1024px) {
        .modal .modal-header .modal-title {
          font-size: var(--h4-desktop-size);
          line-height: 1.2;
          font-weight: 400;
          letter-spacing: 0.01em;
          text-transform: none;
          font-style: normal; } }
    .modal .modal-header .close {
      line-height: 75%;
      margin: 0;
      padding: 0;
      text-shadow: none;
      opacity: 1; }
      .modal .modal-header .close:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 14px;
        color: #FFFFFF;
        font-weight: normal; }
      .modal .modal-header .close[class*="btn-"]:not(.title) {
        padding-left: calc(var(--button-padding-x) + 20px); }
        .modal .modal-header .close[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #FFFFFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        .modal .modal-header .close[class*="btn-"]:not(.title).btn-sm, .modal .modal-header .btn-group-sm > .close.btn[class*="btn-"]:not(.title) {
          padding-left: calc(20px + 20px); }
          .modal .modal-header .close[class*="btn-"]:not(.title).btn-sm:before, .modal .modal-header .btn-group-sm > .close.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #FFFFFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .modal .modal-header .close[class*="btn-"]:not(.title) [class*="icon-"],
        .modal .modal-header .close[class*="btn-"]:not(.title) [class^="icon-"],
        .modal .modal-header .close[class*="btn-"]:not(.title) .fa {
          display: none; }
      .modal .modal-header .close [aria-hidden=true] {
        display: none; }
    .modal .modal-header a {
      color: #FFFFFF; }
  .modal .modal-body {
    flex: 0 0 auto;
    padding: 20px;
    color: var(--base-font-color);
    background-color: #FFFFFF; }
  .modal .modal-footer {
    padding: 0 20px 20px;
    color: var(--base-font-color);
    background-color: #FFFFFF;
    border-top-color: transparent;
    border-bottom-left-radius: calc(var(--border-radius) - 1px);
    border-bottom-right-radius: calc(var(--border-radius) - 1px); }
    .modal .modal-footer .button-wrapper .btn + .btn {
      margin-left: 16px; }
    .modal .modal-footer > .btn + .btn {
      margin-left: 16px; }
  .modal.show ~ .modal.show {
    z-index: 1052; }

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.6); }
  .modal-backdrop.show {
    opacity: 1;
    z-index: 1000; }
    .modal-backdrop.show ~ .modal-backdrop.show {
      z-index: 1051; }
    .modal-backdrop.show + .modal.show {
      z-index: 1052; }

.modal-dialog {
  max-width: none;
  margin: 8px;
  max-width: 500px; }
  @media (min-width: 544px) {
    .modal-dialog {
      margin: 32px auto; } }

.modal-sm {
  max-width: none;
  margin: 8px;
  max-width: 500px; }
  @media (min-width: 544px) {
    .modal-sm {
      margin: 32px auto; } }
  @media (min-width: 544px) {
    .modal-sm {
      max-width: 300px; } }

.modal-lg {
  max-width: none;
  margin: 8px; }
  @media (min-width: 1024px) {
    .modal-lg {
      max-width: 900px;
      margin: 32px auto; } }

.modal-xl {
  max-width: none;
  margin: 8px; }
  @media (min-width: 1024px) {
    .modal-xl {
      max-width: 900px;
      margin: 32px auto; } }
  @media (min-width: 1440px) {
    .modal-xl {
      max-width: 1140px; } }

.modal-scrollbody {
  overflow: hidden; }
  .modal-scrollbody .modal-body {
    overflow-x: hidden;
    overflow-y: auto; }

.notify {
  position: fixed;
  top: 0; }

.page-header {
  padding-top: 16px;
  padding-bottom: 16px; }
  @media (min-width: 769px) {
    .page-header {
      padding-top: 44px;
      padding-bottom: 44px; } }
  .page-header.blog-detail-header .page-header-title {
    font-family: var(--h2-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
    font-size: var(--h2-mobile-size);
    line-height: 1.14;
    font-weight: 700;
    letter-spacing: 0.01em;
    text-transform: none;
    font-style: normal; }
    @media (min-width: 769px) {
      .page-header.blog-detail-header .page-header-title {
        font-size: var(--h2-tablet-size);
        line-height: 1.17;
        font-weight: 700;
        letter-spacing: 0.01em;
        text-transform: none;
        font-style: normal; } }
    @media (min-width: 1024px) {
      .page-header.blog-detail-header .page-header-title {
        font-size: var(--h2-desktop-size);
        line-height: 1.15;
        font-weight: 700;
        letter-spacing: 0.01em;
        text-transform: none;
        font-style: normal; } }
  .page-header.search-results-header .page-header-title {
    font-family: var(--h2-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
    font-size: var(--h2-mobile-size);
    line-height: 1.14;
    font-weight: 700;
    letter-spacing: 0.01em;
    text-transform: none;
    font-style: normal;
    margin-top: 32px; }
    @media (min-width: 769px) {
      .page-header.search-results-header .page-header-title {
        font-size: var(--h2-tablet-size);
        line-height: 1.17;
        font-weight: 700;
        letter-spacing: 0.01em;
        text-transform: none;
        font-style: normal; } }
    @media (min-width: 1024px) {
      .page-header.search-results-header .page-header-title {
        font-size: var(--h2-desktop-size);
        line-height: 1.15;
        font-weight: 700;
        letter-spacing: 0.01em;
        text-transform: none;
        font-style: normal; } }

.hero-slider-container + .page-header .page-header-title, .hero-slot + .page-header .page-header-title {
  display: none; }

.photo-hotspots {
  position: relative; }
  .photo-hotspots .hotspot {
    position: absolute;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    margin-top: -10px;
    margin-left: -10px;
    box-shadow: none;
    transition: all 200ms ease-out;
    text-decoration: none; }
    .photo-hotspots .hotspot:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 10px;
      color: #FFFFFF;
      font-weight: normal; }
    .photo-hotspots .hotspot[class*="btn-"]:not(.title) {
      padding-left: calc(var(--button-padding-x) + 20px); }
      .photo-hotspots .hotspot[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #FFFFFF;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .photo-hotspots .hotspot[class*="btn-"]:not(.title).btn-sm, .photo-hotspots .btn-group-sm > .hotspot.btn[class*="btn-"]:not(.title) {
        padding-left: calc(20px + 20px); }
        .photo-hotspots .hotspot[class*="btn-"]:not(.title).btn-sm:before, .photo-hotspots .btn-group-sm > .hotspot.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFFFFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .photo-hotspots .hotspot[class*="btn-"]:not(.title) [class*="icon-"],
      .photo-hotspots .hotspot[class*="btn-"]:not(.title) [class^="icon-"],
      .photo-hotspots .hotspot[class*="btn-"]:not(.title) .fa {
        display: none; }
    .photo-hotspots .hotspot::before {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      width: 10px; }
    .photo-hotspots .hotspot .hotspot-border-inner,
    .photo-hotspots .hotspot .hotspot-border-outer {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      border-radius: 50%;
      transition: all 200ms ease-out; }
    .photo-hotspots .hotspot .hotspot-border-inner {
      font-size: 10px;
      line-height: 20px;
      width: 26px;
      height: 26px;
      border: 3px solid transparent;
      z-index: 1; }
    .photo-hotspots .hotspot .hotspot-border-outer {
      content: '';
      width: 32px;
      height: 32px;
      border: 3px solid #FFFFFF; }
    .photo-hotspots .hotspot:hover {
      width: 40px;
      height: 40px;
      margin-top: -20px;
      margin-left: -20px; }
      .photo-hotspots .hotspot:hover .hotspot-border-inner {
        line-height: 40px;
        width: 46px;
        height: 46px;
        border: 3px solid transparent; }
      .photo-hotspots .hotspot:hover .hotspot-border-outer {
        width: 52px;
        height: 52px;
        border: 3px solid #FFFFFF; }
    .photo-hotspots .hotspot.dark {
      background: rgba(0, 0, 0, 0.3); }
      .photo-hotspots .hotspot.dark::before {
        color: #000000; }
      .photo-hotspots .hotspot.dark .hotspot-border-inner {
        border-color: transparent; }
      .photo-hotspots .hotspot.dark .hotspot-border-outer {
        border-color: #000000; }
      .photo-hotspots .hotspot.dark:hover .hotspot-border-inner {
        border-color: transparent; }
      .photo-hotspots .hotspot.dark:hover .hotspot-border-outer {
        border-color: #000000; }

.photo-hotspots-tooltip.tooltip {
  z-index: 999; }
  .photo-hotspots-tooltip.tooltip .tooltip-inner {
    padding: 0;
    max-width: 300px; }

.modal.show ~ .photo-hotspots-tooltip.tooltip {
  z-index: 1051; }

.photo-hotspots-tooltip-text {
  text-align: left;
  padding: 8px; }
  .photo-hotspots-tooltip-text > * {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    color: inherit;
    margin-bottom: 0; }
  .photo-hotspots-tooltip-text + .secondary-text {
    padding-top: 0; }
  .photo-hotspots-tooltip-text + .photo-hotspots-tooltip-product {
    border-top: 1px solid var(--base-border-color); }

.photo-hotspots-tooltip-product {
  text-decoration: none; }
  .photo-hotspots-tooltip-product:hover {
    text-decoration: none; }
  .photo-hotspots-tooltip-product .product-image {
    width: 75px;
    height: 75px;
    background-size: cover;
    background-repeat: no-repeat; }
  .photo-hotspots-tooltip-product .product-attributes {
    max-width: calc(300px - 75px - 5px);
    padding: 8px;
    text-align: left; }
  .photo-hotspots-tooltip-product .product-name {
    font-size: var(--base-font-size);
    color: #000000;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .photo-hotspots-tooltip-product .product-price {
    font-size: var(--base-font-size);
    color: #767676;
    margin-top: 4px; }
    .photo-hotspots-tooltip-product .product-price .strike-through {
      margin-right: 5px; }
    .photo-hotspots-tooltip-product .product-price .sales {
      color: #A3080F; }
  .photo-hotspots-tooltip-product .product-message {
    display: block;
    font-size: 10px;
    text-transform: uppercase;
    color: #767676;
    margin-top: 4px; }

.popover {
  font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
  font-size: 12px;
  background-color: #FFFFFF;
  border-color: transparent;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius);
  max-width: 300px; }
  .popover .popover-header {
    font-family: var(--h5-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
    font-size: var(--h5-mobile-size);
    line-height: 1.25;
    font-weight: 400;
    letter-spacing: 0.01em;
    text-transform: none;
    font-style: normal;
    background-color: #767676;
    color: #FFFFFF;
    border-top-left-radius: calc(var(--border-radius) - 1px);
    border-top-right-radius: calc(var(--border-radius) - 1px); }
    @media (min-width: 769px) {
      .popover .popover-header {
        font-size: var(--h5-tablet-size);
        line-height: 1.25;
        font-weight: 400;
        letter-spacing: 0.01em;
        text-transform: none;
        font-style: normal; } }
    @media (min-width: 1024px) {
      .popover .popover-header {
        font-size: var(--h5-desktop-size);
        line-height: 1.2;
        font-weight: 400;
        letter-spacing: 0.01em;
        text-transform: none;
        font-style: normal; } }
  .popover .popover-body {
    padding: 16px;
    font-size: 12px; }
    .popover .popover-body p {
      font-size: 12px; }
    .popover .popover-body ul:only-child {
      margin-bottom: 0; }
  .popover.bs-popover-bottom .popover-header:before, .popover.bs-popover-auto[x-placement^="bottom"] .popover-header:before {
    display: none; }
  .popover.bs-popover-bottom .arrow:after, .popover.bs-popover-auto[x-placement^="bottom"] .arrow:after {
    border-bottom-color: transparent; }

.product-detail .price,
.quick-view-dialog .price {
  color: #000000; }
  .product-detail .price .strike-through,
  .quick-view-dialog .price .strike-through {
    text-decoration: line-through;
    color: #767676; }
  .product-detail .price del ~ .sales,
  .quick-view-dialog .price del ~ .sales {
    float: left;
    margin-right: 16px; }
  .product-detail .price .tiered,
  .quick-view-dialog .price .tiered {
    color: #000000; }
    .product-detail .price .tiered table .table-header,
    .quick-view-dialog .price .tiered table .table-header {
      display: none; }
    .product-detail .price .tiered td,
    .product-detail .price .tiered span.price,
    .quick-view-dialog .price .tiered td,
    .quick-view-dialog .price .tiered span.price {
      font-size: paragraph-font-size(small);
      font-weight: bold; }
    .product-detail .price .tiered td,
    .quick-view-dialog .price .tiered td {
      padding: 8px; }
      .product-detail .price .tiered td.quantity,
      .quick-view-dialog .price .tiered td.quantity {
        font-weight: normal;
        text-align: right; }
      .product-detail .price .tiered td.value,
      .quick-view-dialog .price .tiered td.value {
        text-align: left; }
    .product-detail .price .tiered .table-header,
    .quick-view-dialog .price .tiered .table-header {
      padding: 8px; }
    .product-detail .price .tiered .column-header,
    .quick-view-dialog .price .tiered .column-header {
      padding: 8px;
      font-weight: normal; }
    .product-detail .price .tiered .column-header.quantity,
    .quick-view-dialog .price .tiered .column-header.quantity {
      text-align: right; }

.product-info .product-line-item + .product-line-item,
.product-info .product-line-item + .bonus-product-line-item,
.product-info .product-line-item + .shipment-block,
.product-info .bonus-product-line-item + .product-line-item,
.product-info .bonus-product-line-item + .bonus-product-line-item,
.product-info .bonus-product-line-item + .shipment-block,
.product-info .multi-shipping + .product-line-item,
.product-info .multi-shipping + .bonus-product-line-item,
.product-info .multi-shipping + .shipment-block,
.product-info .shipment-block + .product-line-item,
.product-info .shipment-block + .bonus-product-line-item,
.product-info .shipment-block + .shipment-block,
.product-summary .product-line-item + .product-line-item,
.product-summary .product-line-item + .bonus-product-line-item,
.product-summary .product-line-item + .shipment-block,
.product-summary .bonus-product-line-item + .product-line-item,
.product-summary .bonus-product-line-item + .bonus-product-line-item,
.product-summary .bonus-product-line-item + .shipment-block,
.product-summary .multi-shipping + .product-line-item,
.product-summary .multi-shipping + .bonus-product-line-item,
.product-summary .multi-shipping + .shipment-block,
.product-summary .shipment-block + .product-line-item,
.product-summary .shipment-block + .bonus-product-line-item,
.product-summary .shipment-block + .shipment-block {
  margin-top: 8px;
  padding-top: 16px;
  border-top: 1px solid #C5C5C5;
  border-radius: var(--border-radius); }

.product-info .bonus-product-line-item + .product-line-item,
.product-info .bonus-product-line-item + .bonus-product-line-item,
.product-summary .bonus-product-line-item + .product-line-item,
.product-summary .bonus-product-line-item + .bonus-product-line-item {
  border-top: none; }

.product-info .shipment-block + .shipment-block,
.product-summary .shipment-block + .shipment-block {
  margin-top: 24px;
  padding-top: 24px; }

.order-product-summary .product-info, .order-product-summary
.product-summary, .order-product-summary
.multi-shipping {
  padding: 0;
  margin: 0; }
  .order-product-summary .product-info h3:not(:first-child), .order-product-summary
  .product-summary h3:not(:first-child), .order-product-summary
  .multi-shipping h3:not(:first-child) {
    margin-top: 16px; }

.product-info .product-summary-block > .card,
.product-summary .product-summary-block > .card,
.multi-shipping .product-summary-block > .card {
  border: none; }
  .product-info .product-summary-block > .card .card-body,
  .product-summary .product-summary-block > .card .card-body,
  .multi-shipping .product-summary-block > .card .card-body {
    padding: 0; }

.product-info .item-image,
.product-summary .item-image,
.multi-shipping .item-image {
  width: 100px;
  margin-right: 16px;
  flex-grow: 0;
  flex-shrink: 0; }
  .product-info .item-image img.product-image,
  .product-summary .item-image img.product-image,
  .multi-shipping .item-image img.product-image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto; }

.product-info .line-item-name,
.product-summary .line-item-name,
.multi-shipping .line-item-name {
  display: block;
  font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
  letter-spacing: 0.1em;
  font-size: calc(var(--base-font-size));
  text-transform: none;
  font-weight: normal;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 16px); }
  @media (min-width: 1024px) {
    .product-info .line-item-name,
    .product-summary .line-item-name,
    .multi-shipping .line-item-name {
      font-size: calc(var(--base-font-size)); } }
  @media (min-width: 769px) {
    .product-info .line-item-name,
    .product-summary .line-item-name,
    .multi-shipping .line-item-name {
      width: calc(100% - 24px); } }

.product-info .item-attributes,
.product-summary .item-attributes,
.multi-shipping .item-attributes {
  color: #767676;
  padding: 0 0 8px;
  width: calc(100% - 100px); }

.product-info .item-attributes,
.product-info .item-attributes p,
.product-info .item-price-qty,
.product-info .line-item-availability,
.product-summary .item-attributes,
.product-summary .item-attributes p,
.product-summary .item-price-qty,
.product-summary .line-item-availability,
.multi-shipping .item-attributes,
.multi-shipping .item-attributes p,
.multi-shipping .item-price-qty,
.multi-shipping .line-item-availability {
  font-size: var(--base-font-size);
  margin: 0; }

.product-info .line-item-availability .line-item-attributes,
.product-summary .line-item-availability .line-item-attributes,
.multi-shipping .line-item-availability .line-item-attributes {
  display: inline; }
  .product-info .line-item-availability .line-item-attributes:first-child:after,
  .product-summary .line-item-availability .line-item-attributes:first-child:after,
  .multi-shipping .line-item-availability .line-item-attributes:first-child:after {
    content: ': '; }

.product-info .line-item-pricing-info,
.product-summary .line-item-pricing-info,
.multi-shipping .line-item-pricing-info {
  font-size: var(--base-font-size);
  margin-bottom: 0; }

.product-info .item-price-qty,
.product-info .product-card-footer,
.product-summary .item-price-qty,
.product-summary .product-card-footer,
.multi-shipping .item-price-qty,
.multi-shipping .product-card-footer {
  padding-top: 8px;
  padding-bottom: 8px; }
  .product-info .item-price-qty.row > .col,
  .product-info .product-card-footer.row > .col,
  .product-summary .item-price-qty.row > .col,
  .product-summary .product-card-footer.row > .col,
  .multi-shipping .item-price-qty.row > .col,
  .multi-shipping .product-card-footer.row > .col {
    padding: 0; }
  .product-info .item-price-qty .line-item-unit-price + .line-item-quantity,
  .product-info .item-price-qty .col-4 + .line-item-quantity,
  .product-info .product-card-footer .line-item-unit-price + .line-item-quantity,
  .product-info .product-card-footer .col-4 + .line-item-quantity,
  .product-summary .item-price-qty .line-item-unit-price + .line-item-quantity,
  .product-summary .item-price-qty .col-4 + .line-item-quantity,
  .product-summary .product-card-footer .line-item-unit-price + .line-item-quantity,
  .product-summary .product-card-footer .col-4 + .line-item-quantity,
  .multi-shipping .item-price-qty .line-item-unit-price + .line-item-quantity,
  .multi-shipping .item-price-qty .col-4 + .line-item-quantity,
  .multi-shipping .product-card-footer .line-item-unit-price + .line-item-quantity,
  .multi-shipping .product-card-footer .col-4 + .line-item-quantity {
    align-items: center;
    text-align: center; }
  .product-info .item-price-qty .line-item-total-price,
  .product-info .product-card-footer .line-item-total-price,
  .product-summary .item-price-qty .line-item-total-price,
  .product-summary .product-card-footer .line-item-total-price,
  .multi-shipping .item-price-qty .line-item-total-price,
  .multi-shipping .product-card-footer .line-item-total-price {
    align-items: flex-end;
    text-align: right; }

.product-info .quantity-label,
.product-summary .quantity-label,
.multi-shipping .quantity-label {
  display: none; }

.product-info del,
.product-info .strike-through,
.product-summary del,
.product-summary .strike-through,
.multi-shipping del,
.multi-shipping .strike-through {
  text-decoration: line-through;
  color: #767676; }
  .product-info del .value,
  .product-info .strike-through .value,
  .product-summary del .value,
  .product-summary .strike-through .value,
  .multi-shipping del .value,
  .multi-shipping .strike-through .value {
    margin-right: 4px; }

.product-info .bonus-line-item-msg,
.product-summary .bonus-line-item-msg,
.multi-shipping .bonus-line-item-msg {
  margin-top: 8px; }

.product-info .nested-line-item,
.product-summary .nested-line-item,
.multi-shipping .nested-line-item {
  margin: 0;
  border: 1px solid #C5C5C5;
  padding: 8px; }
  .product-info .nested-line-item ~ .nested-line-item,
  .product-summary .nested-line-item ~ .nested-line-item,
  .multi-shipping .nested-line-item ~ .nested-line-item {
    border-top: 0; }

.product-info .bonus-line-item-msg,
.product-info .unit-price-label,
.product-info .qty-card-quantity-label,
.product-info .quantity-label,
.product-info .line-item-total-price-label,
.product-summary .bonus-line-item-msg,
.product-summary .unit-price-label,
.product-summary .qty-card-quantity-label,
.product-summary .quantity-label,
.product-summary .line-item-total-price-label,
.multi-shipping .bonus-line-item-msg,
.multi-shipping .unit-price-label,
.multi-shipping .qty-card-quantity-label,
.multi-shipping .quantity-label,
.multi-shipping .line-item-total-price-label {
  font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
  font-weight: normal;
  font-size: var(--base-font-size);
  line-height: 1.4;
  margin-bottom: 6px;
  text-transform: none;
  letter-spacing: 0.1em; }

.product-info .non-adjusted-price,
.product-summary .non-adjusted-price,
.multi-shipping .non-adjusted-price {
  display: none; }

.product-info .line-item-promo,
.product-summary .line-item-promo,
.multi-shipping .line-item-promo {
  color: #008827;
  font-size: var(--base-font-size); }

.product-tile {
  position: relative; }
  .product-grid .product-tile {
    margin-bottom: 45px; }
  .product-tile .image-container {
    position: relative;
    margin-bottom: 10px; }
  .product-tile .tile-image {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .product-tile .product-tile-image-link {
    display: block; }
  .product-tile .quick-action-btns {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: calc(100% - 20px);
    align-items: flex-end; }
    .product-tile .quick-action-btns.icons {
      flex-direction: row; }
    @media (min-width: 1024px) {
      .product-tile .quick-action-btns {
        flex-direction: row; } }
  .product-tile .quickview {
    text-decoration: none;
    align-content: flex-end; }
    .product-tile .quickview .quickview-icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      background-color: rgba(255, 255, 255, 0.7);
      box-shadow: none;
      border-radius: 50%;
      position: relative;
      right: 0px; }
      .product-tile .quickview .quickview-icon:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.7);
        font-weight: normal; }
      .product-tile .quickview .quickview-icon[class*="btn-"]:not(.title) {
        padding-left: calc(var(--button-padding-x) + 20px); }
        .product-tile .quickview .quickview-icon[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: rgba(0, 0, 0, 0.7);
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        .product-tile .quickview .quickview-icon[class*="btn-"]:not(.title).btn-sm, .product-tile .quickview .btn-group-sm > .quickview-icon.btn[class*="btn-"]:not(.title) {
          padding-left: calc(20px + 20px); }
          .product-tile .quickview .quickview-icon[class*="btn-"]:not(.title).btn-sm:before, .product-tile .quickview .btn-group-sm > .quickview-icon.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: rgba(0, 0, 0, 0.7);
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .product-tile .quickview .quickview-icon[class*="btn-"]:not(.title) [class*="icon-"],
        .product-tile .quickview .quickview-icon[class*="btn-"]:not(.title) [class^="icon-"],
        .product-tile .quickview .quickview-icon[class*="btn-"]:not(.title) .fa {
          display: none; }
      .product-tile .quickview .quickview-icon::before {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%); }
    .product-tile .quickview.btn {
      flex: 1 1 50%;
      opacity: 0; }
  .product-tile .quick-add-to-cart,
  .product-tile .single-variant-quick-add-to-cart {
    text-decoration: none; }
    .product-tile .quick-add-to-cart .icon-add-to-cart,
    .product-tile .single-variant-quick-add-to-cart .icon-add-to-cart {
      width: 30px;
      height: 30px;
      line-height: 30px;
      background-color: rgba(255, 255, 255, 0.7);
      box-shadow: none;
      border-radius: 50%; }
      .product-tile .quick-add-to-cart .icon-add-to-cart:before,
      .product-tile .single-variant-quick-add-to-cart .icon-add-to-cart:before {
        font-size: 20px;
        margin-left: 0.425em; }
      .product-tile .quick-add-to-cart .icon-add-to-cart .path2:before,
      .product-tile .single-variant-quick-add-to-cart .icon-add-to-cart .path2:before {
        font-size: 30px;
        margin-left: -0.9em; }
      .product-tile .quick-add-to-cart .icon-add-to-cart .path3:before,
      .product-tile .single-variant-quick-add-to-cart .icon-add-to-cart .path3:before {
        font-size: 40px;
        margin-left: -1.015em;
        bottom: -0.06em;
        position: relative; }
    .product-tile .quick-add-to-cart.btn,
    .product-tile .single-variant-quick-add-to-cart.btn {
      flex: 1 1 50%;
      opacity: 0;
      padding-left: 0;
      padding-right: 0; }
      .product-tile .quick-add-to-cart.btn:not(.d-none) + .quickview,
      .product-tile .single-variant-quick-add-to-cart.btn:not(.d-none) + .quickview {
        margin-left: 16px; }
    @media (min-width: 1024px) {
      .product-tile .quick-add-to-cart:not(.d-none) + .quickview,
      .product-tile .single-variant-quick-add-to-cart:not(.d-none) + .quickview {
        margin-left: 16px; } }
  .product-tile .tile-body {
    padding-top: 5px;
    padding-bottom: 16px;
    position: relative;
    display: flex;
    flex-flow: column; }
    .product-tile .tile-body :last-child {
      margin-bottom: 0; }
    .product-tile .tile-body.align-items-center {
      text-align: center; }
    .product-tile .tile-body.align-items-end {
      text-align: right; }
  .product-tile .color-swatches {
    margin-bottom: 10px;
    font-size: 0; }
    .product-tile .color-swatches .swatches::after {
      display: block;
      content: "";
      clear: both; }
    .product-tile .color-swatches .swatch-link {
      display: block;
      float: left;
      text-decoration: none; }
      .product-tile .color-swatches .swatch-link:first-child .swatch-circle {
        margin-left: 3px; }
    .product-tile .color-swatches .swatch-circle {
      width: var(--product-tile-swatch-size);
      height: var(--product-tile-swatch-size);
      background-color: #FFFFFF;
      background-size: cover;
      display: block;
      position: relative;
      text-align: center;
      border-radius: 50%;
      margin-right: 8px;
      margin-bottom: 8px; }
      .product-tile .color-swatches .swatch-circle.color-value {
        transition: box-shadow 200ms ease-out; }
        .product-tile .color-swatches .swatch-circle.color-value:hover {
          box-shadow: 0 0 0 calc(3px - 1px) #FFFFFF, 0 0 0 3px #BEBEBE; }
        .product-tile .color-swatches .swatch-circle.color-value.selected {
          box-shadow: 0 0 0 calc(3px - 1px) #FFFFFF, 0 0 0 3px #000000; }
          .product-tile .color-swatches .swatch-circle.color-value.selected:after {
            content: "";
            font-family: "icomoon";
            display: inline-block;
            font-size: 7px;
            color: #FFFFFF;
            font-weight: normal; }
          .product-tile .color-swatches .swatch-circle.color-value.selected[class*="btn-"]:not(.title) {
            padding-right: calc(var(--button-padding-x) + 20px); }
            .product-tile .color-swatches .swatch-circle.color-value.selected[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 20px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
            .product-tile .color-swatches .swatch-circle.color-value.selected[class*="btn-"]:not(.title).btn-sm, .product-tile .color-swatches .btn-group-sm > .swatch-circle.color-value.selected.btn[class*="btn-"]:not(.title) {
              padding-right: calc(20px + 20px); }
              .product-tile .color-swatches .swatch-circle.color-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-tile .color-swatches .btn-group-sm > .swatch-circle.color-value.selected.btn[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 22px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
            .product-tile .color-swatches .swatch-circle.color-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
            .product-tile .color-swatches .swatch-circle.color-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
            .product-tile .color-swatches .swatch-circle.color-value.selected[class*="btn-"]:not(.title) .fa {
              display: none; }
          .product-tile .color-swatches .swatch-circle.color-value.selected::after {
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            display: none;
            background: rgba(0, 0, 0, 0.7);
            width: 14px;
            height: 14px;
            line-height: 14px;
            padding-left: 1px;
            text-align: center;
            border-radius: 50%;
            z-index: 1; }
        .product-tile .color-swatches .swatch-circle.color-value.unselectable:before, .product-tile .color-swatches .swatch-circle.color-value.unselectable:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%) rotate(45deg);
          height: 100%;
          width: 1px; }
        .product-tile .color-swatches .swatch-circle.color-value.unselectable:after {
          background-color: #000000; }
        .product-tile .color-swatches .swatch-circle.color-value.unselectable:before {
          background-color: #FFFFFF;
          margin-right: 1px; }
        .product-tile .color-swatches .swatch-circle.color-value.unselectable, .product-tile .color-swatches .swatch-circle.color-value.unavailable {
          opacity: 0.5;
          pointer-events: none;
          pointer-events: auto;
          position: relative; }
        .product-tile .color-swatches .swatch-circle.color-value.unselectable {
          cursor: not-allowed; }
        .product-tile .color-swatches .swatch-circle.color-value.out-of-stock {
          cursor: pointer;
          opacity: 0.5;
          pointer-events: none; }
          .product-tile .color-swatches .swatch-circle.color-value.out-of-stock:before, .product-tile .color-swatches .swatch-circle.color-value.out-of-stock:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%) rotate(45deg);
            height: calc(100% + 15px);
            width: 1px; }
          .product-tile .color-swatches .swatch-circle.color-value.out-of-stock:after {
            background-color: #BEBEBE; }
          .product-tile .color-swatches .swatch-circle.color-value.out-of-stock:before {
            background-color: #FFFFFF;
            margin-right: 1px; }
          .product-tile .color-swatches .swatch-circle.color-value.out-of-stock.selected:after {
            display: block; }
      .product-tile .color-swatches .swatch-circle.non-color-swatch-value {
        transition: border 200ms ease-out, box-shadow 200ms ease-out; }
        .product-tile .color-swatches .swatch-circle.non-color-swatch-value:not(.unselectable):hover {
          border-color: #767676;
          box-shadow: none; }
        .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover {
          background: #FFFFFF;
          color: #000000;
          border-color: #000000;
          box-shadow: none; }
          .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:after, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover:after {
            content: "";
            font-family: "icomoon";
            display: inline-block;
            font-size: 8px;
            color: #FFFFFF;
            font-weight: normal; }
          .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title), .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) {
            padding-right: calc(var(--button-padding-x) + 20px); }
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title):after, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 20px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm, .product-tile .color-swatches .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm, .product-tile .color-swatches .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
              padding-right: calc(20px + 20px); }
              .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-tile .color-swatches .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm:after, .product-tile .color-swatches .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 22px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title) .fa, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class*="icon-"],
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class^="icon-"],
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) .fa {
              display: none; }
          .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected::after, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover::after {
            position: absolute;
            top: -5.33333px;
            right: -5.33333px;
            background: var(--brand-primary);
            width: 16px;
            height: 16px;
            line-height: 16px;
            padding-left: 1px;
            text-align: center;
            border-radius: 50%;
            display: none; }
        .product-tile .color-swatches .swatch-circle.non-color-swatch-value.unselectable, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.unavailable {
          opacity: 0.5;
          pointer-events: none;
          pointer-events: auto;
          position: relative; }
        .product-tile .color-swatches .swatch-circle.non-color-swatch-value.unselectable {
          cursor: not-allowed; }
          .product-tile .color-swatches .swatch-circle.non-color-swatch-value.unselectable:before, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.unselectable:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%) rotate(45deg);
            height: calc(100% + 15px);
            width: 1px; }
          .product-tile .color-swatches .swatch-circle.non-color-swatch-value.unselectable:after {
            background-color: #BEBEBE; }
          .product-tile .color-swatches .swatch-circle.non-color-swatch-value.unselectable:before {
            background-color: #FFFFFF;
            margin-right: 1px; }
        .product-tile .color-swatches .swatch-circle.non-color-swatch-value.out-of-stock {
          cursor: pointer;
          opacity: 0.5;
          pointer-events: none; }
          .product-tile .color-swatches .swatch-circle.non-color-swatch-value.out-of-stock:before, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.out-of-stock:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%) rotate(45deg);
            height: calc(100% + 15px);
            width: 1px; }
          .product-tile .color-swatches .swatch-circle.non-color-swatch-value.out-of-stock:after {
            background-color: #BEBEBE; }
          .product-tile .color-swatches .swatch-circle.non-color-swatch-value.out-of-stock:before {
            background-color: #FFFFFF;
            margin-right: 1px; }
          .product-tile .color-swatches .swatch-circle.non-color-swatch-value.out-of-stock.selected:after {
            display: block; }
      .product-tile .color-swatches .swatch-circle.selected {
        box-shadow: 0 0 0 calc(3px - 1px) #FFFFFF, 0 0 0 3px #000000; }
      .product-tile .color-swatches .swatch-circle.unselectable, .product-tile .color-swatches .swatch-circle.unavailable {
        opacity: 0.5;
        pointer-events: none;
        pointer-events: auto;
        position: relative; }
      .product-tile .color-swatches .swatch-circle.unselectable {
        cursor: not-allowed; }
        .product-tile .color-swatches .swatch-circle.unselectable:before, .product-tile .color-swatches .swatch-circle.unselectable:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%) rotate(45deg);
          height: 100%;
          width: 1px; }
        .product-tile .color-swatches .swatch-circle.unselectable:after {
          background-color: #BEBEBE; }
        .product-tile .color-swatches .swatch-circle.unselectable:before {
          background-color: #FFFFFF;
          margin-right: 1px; }
    .product-tile .color-swatches .swatch-thumbnail {
      width: var(--product-tile-thumbnail-size);
      height: var(--product-tile-thumbnail-size);
      background-color: #FFFFFF;
      background-size: cover;
      display: block;
      position: relative;
      text-align: center;
      border-radius: 0;
      margin-right: 1px;
      margin-bottom: 1px; }
      .product-tile .color-swatches .swatch-thumbnail.color-value {
        transition: box-shadow 200ms ease-out; }
        .product-tile .color-swatches .swatch-thumbnail.color-value:hover:not(.unselectable) {
          box-shadow: inset 0px -2px 0px #767676; }
        .product-tile .color-swatches .swatch-thumbnail.color-value.selected {
          box-shadow: inset 0px -2px 0px #000000; }
          .product-tile .color-swatches .swatch-thumbnail.color-value.selected:after {
            content: "";
            font-family: "icomoon";
            display: inline-block;
            font-size: 7px;
            color: #FFFFFF;
            font-weight: normal; }
          .product-tile .color-swatches .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title) {
            padding-right: calc(var(--button-padding-x) + 20px); }
            .product-tile .color-swatches .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 20px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
            .product-tile .color-swatches .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title).btn-sm, .product-tile .color-swatches .btn-group-sm > .swatch-thumbnail.color-value.selected.btn[class*="btn-"]:not(.title) {
              padding-right: calc(20px + 20px); }
              .product-tile .color-swatches .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-tile .color-swatches .btn-group-sm > .swatch-thumbnail.color-value.selected.btn[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 22px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
            .product-tile .color-swatches .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
            .product-tile .color-swatches .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
            .product-tile .color-swatches .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title) .fa {
              display: none; }
          .product-tile .color-swatches .swatch-thumbnail.color-value.selected::after {
            position: absolute;
            top: -4.66667px;
            right: -4.66667px;
            display: none;
            background: rgba(0, 0, 0, 0.7);
            width: 14px;
            height: 14px;
            line-height: 14px;
            padding-left: 1px;
            text-align: center;
            border-radius: 50%;
            z-index: 1; }
        .product-tile .color-swatches .swatch-thumbnail.color-value.unselectable:before, .product-tile .color-swatches .swatch-thumbnail.color-value.unselectable:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%) rotate(45deg);
          height: calc(100% + 15px);
          width: 1px; }
        .product-tile .color-swatches .swatch-thumbnail.color-value.unselectable:after {
          background-color: #000000; }
        .product-tile .color-swatches .swatch-thumbnail.color-value.unselectable:before {
          background-color: #FFFFFF;
          margin-right: 1px; }
        .product-tile .color-swatches .swatch-thumbnail.color-value.unselectable, .product-tile .color-swatches .swatch-thumbnail.color-value.unavailable {
          opacity: 0.5;
          pointer-events: none;
          pointer-events: auto;
          position: relative; }
        .product-tile .color-swatches .swatch-thumbnail.color-value.unselectable {
          cursor: not-allowed; }
        .product-tile .color-swatches .swatch-thumbnail.color-value.out-of-stock {
          cursor: pointer;
          opacity: 0.5;
          pointer-events: none; }
          .product-tile .color-swatches .swatch-thumbnail.color-value.out-of-stock:before, .product-tile .color-swatches .swatch-thumbnail.color-value.out-of-stock:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%) rotate(45deg);
            height: calc(100% + 15px);
            width: 1px; }
          .product-tile .color-swatches .swatch-thumbnail.color-value.out-of-stock:after {
            background-color: #BEBEBE; }
          .product-tile .color-swatches .swatch-thumbnail.color-value.out-of-stock:before {
            background-color: #FFFFFF;
            margin-right: 1px; }
          .product-tile .color-swatches .swatch-thumbnail.color-value.out-of-stock.selected:after {
            display: block; }
      .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value {
        transition: border 200ms ease-out, box-shadow 200ms ease-out; }
        .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value:not(.unselectable):hover {
          border-color: #767676;
          box-shadow: none; }
        .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected, .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover {
          background: #FFFFFF;
          color: #000000;
          border-color: #000000;
          box-shadow: none; }
          .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:after, .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover:after {
            content: "";
            font-family: "icomoon";
            display: inline-block;
            font-size: 8px;
            color: #FFFFFF;
            font-weight: normal; }
          .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title), .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) {
            padding-right: calc(var(--button-padding-x) + 20px); }
            .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title):after, .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 20px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
            .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm, .product-tile .color-swatches .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm, .product-tile .color-swatches .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
              padding-right: calc(20px + 20px); }
              .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-tile .color-swatches .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm:after, .product-tile .color-swatches .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 22px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
            .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
            .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
            .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title) .fa, .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class*="icon-"],
            .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class^="icon-"],
            .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) .fa {
              display: none; }
          .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected::after, .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.selected:hover::after {
            position: absolute;
            top: -5.33333px;
            right: -5.33333px;
            background: var(--brand-primary);
            width: 16px;
            height: 16px;
            line-height: 16px;
            padding-left: 1px;
            text-align: center;
            border-radius: 50%;
            display: none; }
        .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.unselectable, .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.unavailable {
          opacity: 0.5;
          pointer-events: none;
          pointer-events: auto;
          position: relative; }
        .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.unselectable {
          cursor: not-allowed; }
          .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.unselectable:before, .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.unselectable:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%) rotate(45deg);
            height: calc(100% + 15px);
            width: 1px; }
          .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.unselectable:after {
            background-color: #BEBEBE; }
          .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.unselectable:before {
            background-color: #FFFFFF;
            margin-right: 1px; }
        .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.out-of-stock {
          cursor: pointer;
          opacity: 0.5;
          pointer-events: none; }
          .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.out-of-stock:before, .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.out-of-stock:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%) rotate(45deg);
            height: calc(100% + 15px);
            width: 1px; }
          .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.out-of-stock:after {
            background-color: #BEBEBE; }
          .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.out-of-stock:before {
            background-color: #FFFFFF;
            margin-right: 1px; }
          .product-tile .color-swatches .swatch-thumbnail.non-color-swatch-value.out-of-stock.selected:after {
            display: block; }
      .product-tile .color-swatches .swatch-thumbnail.selected {
        box-shadow: inset 0px -2px 0px #000000; }
      .product-tile .color-swatches .swatch-thumbnail.unselectable {
        cursor: not-allowed; }
        .product-tile .color-swatches .swatch-thumbnail.unselectable:before, .product-tile .color-swatches .swatch-thumbnail.unselectable:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%) rotate(45deg);
          height: calc(100% + 15px);
          width: 1px; }
        .product-tile .color-swatches .swatch-thumbnail.unselectable:after {
          background-color: #BEBEBE; }
        .product-tile .color-swatches .swatch-thumbnail.unselectable:before {
          background-color: #FFFFFF;
          margin-right: 1px; }
    .product-tile .color-swatches .swatch-color-count {
      font-size: var(--base-font-size);
      line-height: var(--product-tile-swatch-size);
      letter-spacing: 0.1em;
      margin-left: 5px;
      white-space: nowrap;
      display: inline-block;
      vertical-align: middle;
      color: var(--brand-secondary); }
      @media (min-width: 1024px) {
        .product-tile .color-swatches .swatch-color-count {
          position: absolute;
          right: 0;
          top: 0;
          transform: translateY(180%);
          line-height: 1.4; } }
  .product-tile .pdp-link {
    margin-bottom: 2px; }
    .product-tile .pdp-link .link {
      display: block;
      font-family: var(--product-name-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
      font-size: var(--product-name-font-size-mobile);
      font-weight: normal;
      color: var(--base-font-color);
      text-decoration: none; }
      @media (min-width: 1024px) {
        .product-tile .pdp-link .link {
          font-size: var(--product-name-font-size-desktop);
          line-height: 1.4; } }
      .product-tile .pdp-link .link:hover {
        color: var(--base-font-color);
        text-decoration: none; }
  .product-tile .price {
    margin-bottom: 10px;
    font-size: var(--product-tile-price-size);
    color: var(--product-tile-price-color); }
    .product-tile .price::after {
      display: block;
      content: "";
      clear: both; }
    @media (min-width: 1024px) {
      .product-tile .price {
        font-size: var(--product-tile-price-size);
        line-height: 1.4; } }
    .product-tile .price del,
    .product-tile .price .strike-through {
      color: #767676; }
    .product-tile .price del ~ .sales {
      float: left;
      margin-right: 16px;
      color: var(--product-tile-sale-color); }
  .product-tile .promotion {
    margin-bottom: 10px; }
  .product-tile .ratings {
    margin-bottom: 20px;
    font-size: var(--product-tile-star-size);
    color: var(--product-tile-star-color);
    flex-basis: 100%;
    max-width: 100%; }
    .product-tile .ratings .fa-star:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: inherit;
      color: inherit;
      font-weight: normal; }
    .product-tile .ratings .fa-star[class*="btn-"]:not(.title) {
      padding-left: calc(var(--button-padding-x) + 20px); }
      .product-tile .ratings .fa-star[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .product-tile .ratings .fa-star[class*="btn-"]:not(.title).btn-sm, .product-tile .ratings .btn-group-sm > .fa-star.btn[class*="btn-"]:not(.title) {
        padding-left: calc(20px + 20px); }
        .product-tile .ratings .fa-star[class*="btn-"]:not(.title).btn-sm:before, .product-tile .ratings .btn-group-sm > .fa-star.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .product-tile .ratings .fa-star[class*="btn-"]:not(.title) [class*="icon-"],
      .product-tile .ratings .fa-star[class*="btn-"]:not(.title) [class^="icon-"],
      .product-tile .ratings .fa-star[class*="btn-"]:not(.title) .fa {
        display: none; }
    .product-tile .ratings .fa-star-half-o:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: inherit;
      color: inherit;
      font-weight: normal; }
    .product-tile .ratings .fa-star-half-o[class*="btn-"]:not(.title) {
      padding-left: calc(var(--button-padding-x) + 20px); }
      .product-tile .ratings .fa-star-half-o[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .product-tile .ratings .fa-star-half-o[class*="btn-"]:not(.title).btn-sm, .product-tile .ratings .btn-group-sm > .fa-star-half-o.btn[class*="btn-"]:not(.title) {
        padding-left: calc(20px + 20px); }
        .product-tile .ratings .fa-star-half-o[class*="btn-"]:not(.title).btn-sm:before, .product-tile .ratings .btn-group-sm > .fa-star-half-o.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .product-tile .ratings .fa-star-half-o[class*="btn-"]:not(.title) [class*="icon-"],
      .product-tile .ratings .fa-star-half-o[class*="btn-"]:not(.title) [class^="icon-"],
      .product-tile .ratings .fa-star-half-o[class*="btn-"]:not(.title) .fa {
        display: none; }
    .product-tile .ratings .fa-star-o:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: inherit;
      color: inherit;
      font-weight: normal; }
    .product-tile .ratings .fa-star-o[class*="btn-"]:not(.title) {
      padding-left: calc(var(--button-padding-x) + 20px); }
      .product-tile .ratings .fa-star-o[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .product-tile .ratings .fa-star-o[class*="btn-"]:not(.title).btn-sm, .product-tile .ratings .btn-group-sm > .fa-star-o.btn[class*="btn-"]:not(.title) {
        padding-left: calc(20px + 20px); }
        .product-tile .ratings .fa-star-o[class*="btn-"]:not(.title).btn-sm:before, .product-tile .ratings .btn-group-sm > .fa-star-o.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .product-tile .ratings .fa-star-o[class*="btn-"]:not(.title) [class*="icon-"],
      .product-tile .ratings .fa-star-o[class*="btn-"]:not(.title) [class^="icon-"],
      .product-tile .ratings .fa-star-o[class*="btn-"]:not(.title) .fa {
        display: none; }
  @media (min-width: 1024px) {
    .product-tile.on-hover .color-swatches .swatches {
      opacity: 0; }
    .product-tile.on-hover:hover .color-swatches .swatches {
      opacity: 1; } }
  @media (min-width: 769px) {
    .product-tile .show-secondary-image:hover .product-tile-image {
      display: none; }
    .product-tile .show-secondary-image:hover .product-tile-image-secondary {
      display: block; } }
  .product-tile .show-secondary-image .product-tile-image {
    display: block; }
  .product-tile .show-secondary-image .product-tile-image-secondary {
    display: none; }
  .product-tile.bottom-border:hover .product-tile-image-container:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    box-shadow: inset 0px -0 0px #000000; }
  .product-tile.zoom-image .image-container {
    overflow: hidden; }
  .product-tile.zoom-image .product-tile-image-link {
    transition: transform 600ms ease-in-out; }
  .product-tile.zoom-image:hover .product-tile-image-link {
    transform: scale(1.035); }
  .product-tile.underline-title:hover .tile-body .pdp-link .link {
    text-decoration: underline; }
  .product-tile:hover .quick-action-btns .btn {
    opacity: 1; }

.product-tile .pdp-link .part-number {
  color: #767676; }

.quick-view-dialog,
.choose-bonus-product-dialog {
  max-width: none;
  margin: 8px; }
  @media (min-width: 1024px) {
    .quick-view-dialog,
    .choose-bonus-product-dialog {
      max-width: 900px;
      margin: 32px auto; } }
  .quick-view-dialog .modal-body,
  .choose-bonus-product-dialog .modal-body {
    overflow-x: hidden;
    overflow-y: auto; }
  .quick-view-dialog .product-quickview,
  .choose-bonus-product-dialog .product-quickview {
    padding: 0; }
    .quick-view-dialog .product-quickview .availability,
    .choose-bonus-product-dialog .product-quickview .availability {
      margin-left: 0;
      margin-right: 0; }
    .quick-view-dialog .product-quickview .product-availability,
    .choose-bonus-product-dialog .product-quickview .product-availability {
      display: none; }
    .quick-view-dialog .product-quickview .pdp-in-store-inventory,
    .choose-bonus-product-dialog .product-quickview .pdp-in-store-inventory {
      margin-top: 16px;
      width: 100%; }
  .quick-view-dialog .product-detail,
  .choose-bonus-product-dialog .product-detail {
    margin-top: 0;
    margin-bottom: 0; }
    .quick-view-dialog .product-detail .product-name,
    .choose-bonus-product-dialog .product-detail .product-name {
      margin-bottom: 0; }
  .quick-view-dialog .primary-images,
  .choose-bonus-product-dialog .primary-images {
    margin: 0 0 16px; }
    @media (min-width: 769px) {
      .quick-view-dialog .primary-images,
      .choose-bonus-product-dialog .primary-images {
        margin-bottom: 0; } }
  .quick-view-dialog .selectable-bonus-product-line-item,
  .choose-bonus-product-dialog .selectable-bonus-product-line-item {
    margin-top: 8px;
    margin-bottom: 8px;
    padding-top: 8px;
    padding-bottom: 8px; }
  .quick-view-dialog .beenSelected,
  .choose-bonus-product-dialog .beenSelected {
    background-color: #C5C5C5; }
  .quick-view-dialog .availability-container,
  .choose-bonus-product-dialog .availability-container {
    padding: 0; }
  .quick-view-dialog .modal-footer,
  .choose-bonus-product-dialog .modal-footer {
    padding-top: 16px;
    border-top: 1px solid var(--base-border-color); }
    .quick-view-dialog .modal-footer .prices,
    .choose-bonus-product-dialog .modal-footer .prices {
      text-align: center;
      margin-bottom: 8px; }
      .quick-view-dialog .modal-footer .prices .price,
      .quick-view-dialog .modal-footer .prices .sales,
      .choose-bonus-product-dialog .modal-footer .prices .price,
      .choose-bonus-product-dialog .modal-footer .prices .sales {
        font-size: 20px;
        font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif; }
    .quick-view-dialog .modal-footer label,
    .quick-view-dialog .modal-footer .non-input-label,
    .choose-bonus-product-dialog .modal-footer label,
    .choose-bonus-product-dialog .modal-footer .non-input-label {
      font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
      font-weight: normal;
      font-size: var(--base-font-size);
      line-height: 1.4;
      margin-bottom: 6px;
      text-transform: none;
      letter-spacing: 0.1em; }
  .quick-view-dialog .set-items,
  .choose-bonus-product-dialog .set-items {
    padding: 0; }
    .quick-view-dialog .set-items .set-item-product-name,
    .choose-bonus-product-dialog .set-items .set-item-product-name {
      font-family: var(--h4-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
      font-size: var(--h4-mobile-size);
      line-height: 1.25;
      font-weight: 400;
      letter-spacing: 0.01em;
      text-transform: none;
      font-style: normal;
      margin-bottom: 8px; }
      @media (min-width: 769px) {
        .quick-view-dialog .set-items .set-item-product-name,
        .choose-bonus-product-dialog .set-items .set-item-product-name {
          font-size: var(--h4-tablet-size);
          line-height: 1.25;
          font-weight: 400;
          letter-spacing: 0.01em;
          text-transform: none;
          font-style: normal; } }
      @media (min-width: 1024px) {
        .quick-view-dialog .set-items .set-item-product-name,
        .choose-bonus-product-dialog .set-items .set-item-product-name {
          font-size: var(--h4-desktop-size);
          line-height: 1.2;
          font-weight: 400;
          letter-spacing: 0.01em;
          text-transform: none;
          font-style: normal; } }
    .quick-view-dialog .set-items .product-availability,
    .choose-bonus-product-dialog .set-items .product-availability {
      display: block; }
      .quick-view-dialog .set-items .product-availability .availability-container,
      .choose-bonus-product-dialog .set-items .product-availability .availability-container {
        padding-left: 0; }
  .quick-view-dialog .product-set-contains,
  .choose-bonus-product-dialog .product-set-contains {
    font-family: var(--h5-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
    font-size: var(--h5-mobile-size);
    line-height: 1.25;
    font-weight: 400;
    letter-spacing: 0.01em;
    text-transform: none;
    font-style: normal;
    color: #000000; }
    @media (min-width: 769px) {
      .quick-view-dialog .product-set-contains,
      .choose-bonus-product-dialog .product-set-contains {
        font-size: var(--h5-tablet-size);
        line-height: 1.25;
        font-weight: 400;
        letter-spacing: 0.01em;
        text-transform: none;
        font-style: normal; } }
    @media (min-width: 1024px) {
      .quick-view-dialog .product-set-contains,
      .choose-bonus-product-dialog .product-set-contains {
        font-size: var(--h5-desktop-size);
        line-height: 1.2;
        font-weight: 400;
        letter-spacing: 0.01em;
        text-transform: none;
        font-style: normal; } }
  .quick-view-dialog .bonus-product-item,
  .choose-bonus-product-dialog .bonus-product-item {
    padding: 0; }
    .quick-view-dialog .bonus-product-item ~ .bonus-product-item,
    .choose-bonus-product-dialog .bonus-product-item ~ .bonus-product-item {
      margin-top: 16px;
      padding-top: 16px;
      border-top: 1px solid var(--base-border-color); }
    .quick-view-dialog .bonus-product-item .product-quickview + hr,
    .choose-bonus-product-dialog .bonus-product-item .product-quickview + hr {
      display: none; }
    .quick-view-dialog .bonus-product-item .bonus-quantity,
    .quick-view-dialog .bonus-product-item .bonus-option,
    .quick-view-dialog .bonus-product-item .select-bonus-product,
    .quick-view-dialog .bonus-product-item .bonus-product-price:not(:empty),
    .choose-bonus-product-dialog .bonus-product-item .bonus-quantity,
    .choose-bonus-product-dialog .bonus-product-item .bonus-option,
    .choose-bonus-product-dialog .bonus-product-item .select-bonus-product,
    .choose-bonus-product-dialog .bonus-product-item .bonus-product-price:not(:empty) {
      margin-top: 16px; }
    .quick-view-dialog .bonus-product-item .attribute label,
    .choose-bonus-product-dialog .bonus-product-item .attribute label {
      font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
      font-weight: normal;
      font-size: var(--base-font-size);
      line-height: 1.4;
      margin-bottom: 6px;
      text-transform: none;
      letter-spacing: 0.1em; }
    .quick-view-dialog .bonus-product-item .quantity,
    .choose-bonus-product-dialog .bonus-product-item .quantity {
      margin-bottom: 0; }
      .quick-view-dialog .bonus-product-item .quantity label,
      .choose-bonus-product-dialog .bonus-product-item .quantity label {
        display: block;
        margin-right: 0; }
        .quick-view-dialog .bonus-product-item .quantity label:after,
        .choose-bonus-product-dialog .bonus-product-item .quantity label:after {
          display: none; }
  .quick-view-dialog .selected-bonus-products ~ .col-sm-6,
  .choose-bonus-product-dialog .selected-bonus-products ~ .col-sm-6 {
    text-align: right; }
  .quick-view-dialog .selected-bonus-products .bonus-summary,
  .choose-bonus-product-dialog .selected-bonus-products .bonus-summary {
    font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
    font-weight: normal;
    font-size: var(--base-font-size);
    line-height: 1.4;
    margin-bottom: 6px;
    text-transform: none;
    letter-spacing: 0.1em; }
    .quick-view-dialog .selected-bonus-products .bonus-summary.alert-danger,
    .choose-bonus-product-dialog .selected-bonus-products .bonus-summary.alert-danger {
      background: transparent;
      border: none; }
    .quick-view-dialog .selected-bonus-products .bonus-summary .pre-cart-products,
    .choose-bonus-product-dialog .selected-bonus-products .bonus-summary .pre-cart-products {
      font-weight: bold; }
  .quick-view-dialog .selected-bonus-products .bonus-summary-products-container,
  .choose-bonus-product-dialog .selected-bonus-products .bonus-summary-products-container {
    position: relative;
    padding: 0 20px 0 20px;
    border: 1px solid var(--base-border-color);
    font-size: var(--base-font-size);
    line-height: 16px;
    letter-spacing: 0.1em;
    min-height: calc(16px + 12px + 14px + (1px*2)); }
  .quick-view-dialog .selected-bonus-products .selected-pid,
  .choose-bonus-product-dialog .selected-bonus-products .selected-pid {
    position: relative;
    line-height: calc(16px + 12px + 14px + (1px*2)); }
    .quick-view-dialog .selected-bonus-products .selected-pid .bonus-product-name,
    .choose-bonus-product-dialog .selected-bonus-products .selected-pid .bonus-product-name {
      padding: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .quick-view-dialog .selected-bonus-products .selected-pid + .selected-pid::before,
    .choose-bonus-product-dialog .selected-bonus-products .selected-pid + .selected-pid::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: var(--base-border-color); }
    .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product,
    .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product {
      font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
      border: none;
      border-radius: 50%;
      font-size: 0;
      position: absolute;
      top: 0;
      right: 0;
      margin: 0 20px 20px 20px;
      cursor: pointer;
      z-index: 1;
      background-color: #FFFFFF;
      padding: 4px 6px;
      height: 22px;
      width: 22px;
      background-color: transparent;
      margin: 0;
      padding: 0; }
      .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product:before,
      .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 11px;
        color: inherit;
        font-weight: normal; }
      .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title),
      .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title) {
        padding-left: calc(var(--button-padding-x) + 20px); }
        .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title):before,
        .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title).btn-sm, .quick-view-dialog .selected-bonus-products .selected-pid .btn-group-sm > .remove-bonus-product.btn[class*="btn-"]:not(.title),
        .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title).btn-sm,
        .choose-bonus-product-dialog .selected-bonus-products .selected-pid .btn-group-sm > .remove-bonus-product.btn[class*="btn-"]:not(.title) {
          padding-left: calc(20px + 20px); }
          .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title).btn-sm:before, .quick-view-dialog .selected-bonus-products .selected-pid .btn-group-sm > .remove-bonus-product.btn[class*="btn-"]:not(.title):before,
          .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title).btn-sm:before,
          .choose-bonus-product-dialog .selected-bonus-products .selected-pid .btn-group-sm > .remove-bonus-product.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: inherit;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title) [class*="icon-"],
        .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title) [class^="icon-"],
        .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title) .fa,
        .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title) [class*="icon-"],
        .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title) [class^="icon-"],
        .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title) .fa {
          display: none; }

.popover[role="tooltip"] .arrow {
  display: none; }

.popover[role="tooltip"] .product-quick-add-to-cart {
  padding: 0;
  margin: 0; }
  .popover[role="tooltip"] .product-quick-add-to-cart .attributes .swatch + .swatch {
    margin-top: 16px; }
  .popover[role="tooltip"] .product-quick-add-to-cart .attribute {
    margin-top: 0; }
    .popover[role="tooltip"] .product-quick-add-to-cart .attribute .non-input-label {
      text-align: center; }
    .popover[role="tooltip"] .product-quick-add-to-cart .attribute [class*='-attribute'] {
      padding: 0 5px; }
    .popover[role="tooltip"] .product-quick-add-to-cart .attribute .swatch-tile-container {
      margin: 5px 0; }
  .popover[role="tooltip"] .product-quick-add-to-cart .availability .availability-container {
    margin-top: 16px; }

.slider-title {
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px; }
  .slider-title:not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
    font-family: var(--h3-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
    font-size: var(--h3-mobile-size);
    line-height: 1.2;
    font-weight: 400;
    letter-spacing: 0.01em;
    text-transform: none;
    font-style: normal; }
    @media (min-width: 769px) {
      .slider-title:not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
        font-size: var(--h3-tablet-size);
        line-height: 1.2;
        font-weight: 400;
        letter-spacing: 0.01em;
        text-transform: none;
        font-style: normal; } }
    @media (min-width: 1024px) {
      .slider-title:not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
        font-size: var(--h3-desktop-size);
        line-height: 1.17;
        font-weight: 400;
        letter-spacing: 0.01em;
        text-transform: none;
        font-style: normal; } }

.slider-container {
  position: relative;
  padding-left: 60px;
  padding-right: 60px;
  letter-spacing: 0; }
  @media (max-width: 768.98px) {
    .slider-container:not(.slider-bullets-inside):not(.slider-bullets-hidden) {
      margin-bottom: 32px; } }
  @media (min-width: 769px) and (max-width: 1023.98px) {
    .slider-container:not(.slider-bullets-inside-md):not(.slider-bullets-hidden-md) {
      margin-bottom: 32px; } }
  @media (min-width: 1024px) {
    .slider-container:not(.slider-bullets-inside-lg):not(.slider-bullets-hidden-lg) {
      margin-bottom: 32px; } }
  .slider-container .slider {
    opacity: 0; }
    .slider-container .slider.tns-slider {
      opacity: 1; }
    .slider-container .slider.tns-gallery .tns-slide-active {
      z-index: 1; }
  .slider-container .slide {
    transform: translateZ(0); }
  .slider-container .tns-controls button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    user-select: none;
    z-index: 2;
    width: 60px;
    height: 100%;
    background-color: transparent;
    border: none;
    display: flex;
    text-align: center;
    transition: background-color 200ms ease-out;
    font-size: 0;
    padding: 0; }
    .slider-container .tns-controls button:before {
      width: 100%;
      align-self: center;
      transition: color 200ms ease-out; }
    .slider-container .tns-controls button:focus {
      outline: none; }
    .slider-container .tns-controls button:hover {
      background-color: transparent; }
      .slider-container .tns-controls button:hover:before {
        color: #000000; }
  .slider-container [data-controls='prev'] {
    left: 0; }
    .slider-container [data-controls='prev']:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 24px;
      color: rgba(0, 0, 0, 0.5);
      font-weight: normal; }
    .slider-container [data-controls='prev'][class*="btn-"]:not(.title) {
      padding-left: calc(var(--button-padding-x) + 20px); }
      .slider-container [data-controls='prev'][class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.5);
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .slider-container [data-controls='prev'][class*="btn-"]:not(.title).btn-sm, .slider-container .btn-group-sm > .btn[data-controls='prev'][class*="btn-"]:not(.title) {
        padding-left: calc(20px + 20px); }
        .slider-container [data-controls='prev'][class*="btn-"]:not(.title).btn-sm:before, .slider-container .btn-group-sm > .btn[data-controls='prev'][class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: rgba(0, 0, 0, 0.5);
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .slider-container [data-controls='prev'][class*="btn-"]:not(.title) [class*="icon-"],
      .slider-container [data-controls='prev'][class*="btn-"]:not(.title) [class^="icon-"],
      .slider-container [data-controls='prev'][class*="btn-"]:not(.title) .fa {
        display: none; }
  .slider-container [data-controls='next'] {
    right: 0; }
    .slider-container [data-controls='next']:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 24px;
      color: rgba(0, 0, 0, 0.5);
      font-weight: normal; }
    .slider-container [data-controls='next'][class*="btn-"]:not(.title) {
      padding-left: calc(var(--button-padding-x) + 20px); }
      .slider-container [data-controls='next'][class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.5);
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .slider-container [data-controls='next'][class*="btn-"]:not(.title).btn-sm, .slider-container .btn-group-sm > .btn[data-controls='next'][class*="btn-"]:not(.title) {
        padding-left: calc(20px + 20px); }
        .slider-container [data-controls='next'][class*="btn-"]:not(.title).btn-sm:before, .slider-container .btn-group-sm > .btn[data-controls='next'][class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: rgba(0, 0, 0, 0.5);
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .slider-container [data-controls='next'][class*="btn-"]:not(.title) [class*="icon-"],
      .slider-container [data-controls='next'][class*="btn-"]:not(.title) [class^="icon-"],
      .slider-container [data-controls='next'][class*="btn-"]:not(.title) .fa {
        display: none; }
  .slider-container .tns-nav {
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    display: flex;
    bottom: 0;
    z-index: 2;
    bottom: -32px; }
    .slider-container .tns-nav button {
      display: block;
      background: rgba(0, 0, 0, 0.5);
      border: none;
      width: 12px;
      height: 12px;
      margin: 10px 5px 10px 5px;
      padding: 0;
      cursor: pointer;
      transition: transform 200ms ease-out; }
      .slider-container .tns-nav button:focus {
        outline: none; }
      .slider-container .tns-nav button.tns-nav-active {
        background: #000000;
        border: none;
        transform: scale(1); }
    .slider-container .tns-nav.with-pagination-btn button {
      order: 1; }
    .slider-container .tns-nav.with-pagination-btn .with-pagination {
      order: 0; }
  .slider-container.pagination-style-dots .tns-nav {
    align-items: center; }
    .slider-container.pagination-style-dots .tns-nav button {
      border-radius: 50%; }
  .slider-container.pagination-style-shortLines .tns-nav {
    align-items: flex-end; }
    .slider-container.pagination-style-shortLines .tns-nav button {
      background-color: var(--brand-tertiary);
      border: none;
      width: 20px;
      height: 2px; }
      .slider-container.pagination-style-shortLines .tns-nav button.tns-nav-active, .slider-container.pagination-style-shortLines .tns-nav button:hover:not(.tns-nav-active) {
        background-color: var(--brand-primary);
        height: 4px;
        transition: background-color 200ms ease-in-out, height 200ms ease-in-out; }
  .slider-container.light .tns-controls button {
    background-color: transparent; }
    .slider-container.light .tns-controls button::before {
      color: rgba(255, 255, 255, 0.7); }
    .slider-container.light .tns-controls button:hover {
      background-color: transparent; }
      .slider-container.light .tns-controls button:hover::before {
        color: #FFFFFF; }
  .slider-container.light .tns-nav button {
    background: rgba(255, 255, 255, 0.5);
    border: none; }
    .slider-container.light .tns-nav button.tns-nav-active {
      background: #FFFFFF;
      border: none; }
  .slider-container.light .tns-nav-controller::before {
    color: #FFFFFF; }
  .slider-container.light .tns-nav-controller.pause::before {
    color: #FFFFFF; }
  .slider-container.light.pagination-style-shortLines .tns-nav button {
    background-color: rgba(255, 255, 255, 0.5); }
    .slider-container.light.pagination-style-shortLines .tns-nav button.tns-nav-active, .slider-container.light.pagination-style-shortLines .tns-nav button:hover:not(.tns-nav-active) {
      background-color: #FFFFFF; }
  .slider-container [data-action='stop'],
  .slider-container [data-action='start'] {
    display: none; }
  @media (max-width: 768.98px) {
    .slider-container.slider-buttons-hidden {
      padding-left: 0;
      padding-right: 0; }
      .slider-container.slider-buttons-hidden .tns-controls {
        display: none; }
    .slider-container.slider-bullets-hidden .tns-nav {
      display: none; }
    .slider-container.slider-buttons-inside {
      padding-left: 0;
      padding-right: 0; }
    .slider-container.slider-bullets-inside .tns-nav {
      bottom: 0; }
    .slider-container.next-slide-1 .tns-inner {
      padding-right: 1%; }
    .slider-container.next-slide-1.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-1 .tns-inner {
      padding-left: 1%; }
    .slider-container.prev-slide-1.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-2 .tns-inner {
      padding-right: 2%; }
    .slider-container.next-slide-2.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-2 .tns-inner {
      padding-left: 2%; }
    .slider-container.prev-slide-2.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-3 .tns-inner {
      padding-right: 3%; }
    .slider-container.next-slide-3.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-3 .tns-inner {
      padding-left: 3%; }
    .slider-container.prev-slide-3.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-4 .tns-inner {
      padding-right: 4%; }
    .slider-container.next-slide-4.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-4 .tns-inner {
      padding-left: 4%; }
    .slider-container.prev-slide-4.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-5 .tns-inner {
      padding-right: 5%; }
    .slider-container.next-slide-5.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-5 .tns-inner {
      padding-left: 5%; }
    .slider-container.prev-slide-5.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-6 .tns-inner {
      padding-right: 6%; }
    .slider-container.next-slide-6.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-6 .tns-inner {
      padding-left: 6%; }
    .slider-container.prev-slide-6.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-7 .tns-inner {
      padding-right: 7%; }
    .slider-container.next-slide-7.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-7 .tns-inner {
      padding-left: 7%; }
    .slider-container.prev-slide-7.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-8 .tns-inner {
      padding-right: 8%; }
    .slider-container.next-slide-8.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-8 .tns-inner {
      padding-left: 8%; }
    .slider-container.prev-slide-8.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-9 .tns-inner {
      padding-right: 9%; }
    .slider-container.next-slide-9.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-9 .tns-inner {
      padding-left: 9%; }
    .slider-container.prev-slide-9.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-10 .tns-inner {
      padding-right: 10%; }
    .slider-container.next-slide-10.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-10 .tns-inner {
      padding-left: 10%; }
    .slider-container.prev-slide-10.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-11 .tns-inner {
      padding-right: 11%; }
    .slider-container.next-slide-11.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-11 .tns-inner {
      padding-left: 11%; }
    .slider-container.prev-slide-11.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-12 .tns-inner {
      padding-right: 12%; }
    .slider-container.next-slide-12.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-12 .tns-inner {
      padding-left: 12%; }
    .slider-container.prev-slide-12.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-13 .tns-inner {
      padding-right: 13%; }
    .slider-container.next-slide-13.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-13 .tns-inner {
      padding-left: 13%; }
    .slider-container.prev-slide-13.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-14 .tns-inner {
      padding-right: 14%; }
    .slider-container.next-slide-14.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-14 .tns-inner {
      padding-left: 14%; }
    .slider-container.prev-slide-14.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-15 .tns-inner {
      padding-right: 15%; }
    .slider-container.next-slide-15.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-15 .tns-inner {
      padding-left: 15%; }
    .slider-container.prev-slide-15.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-16 .tns-inner {
      padding-right: 16%; }
    .slider-container.next-slide-16.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-16 .tns-inner {
      padding-left: 16%; }
    .slider-container.prev-slide-16.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-17 .tns-inner {
      padding-right: 17%; }
    .slider-container.next-slide-17.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-17 .tns-inner {
      padding-left: 17%; }
    .slider-container.prev-slide-17.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-18 .tns-inner {
      padding-right: 18%; }
    .slider-container.next-slide-18.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-18 .tns-inner {
      padding-left: 18%; }
    .slider-container.prev-slide-18.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-19 .tns-inner {
      padding-right: 19%; }
    .slider-container.next-slide-19.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-19 .tns-inner {
      padding-left: 19%; }
    .slider-container.prev-slide-19.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-20 .tns-inner {
      padding-right: 20%; }
    .slider-container.next-slide-20.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-20 .tns-inner {
      padding-left: 20%; }
    .slider-container.prev-slide-20.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-21 .tns-inner {
      padding-right: 21%; }
    .slider-container.next-slide-21.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-21 .tns-inner {
      padding-left: 21%; }
    .slider-container.prev-slide-21.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-22 .tns-inner {
      padding-right: 22%; }
    .slider-container.next-slide-22.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-22 .tns-inner {
      padding-left: 22%; }
    .slider-container.prev-slide-22.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-23 .tns-inner {
      padding-right: 23%; }
    .slider-container.next-slide-23.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-23 .tns-inner {
      padding-left: 23%; }
    .slider-container.prev-slide-23.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-24 .tns-inner {
      padding-right: 24%; }
    .slider-container.next-slide-24.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-24 .tns-inner {
      padding-left: 24%; }
    .slider-container.prev-slide-24.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-25 .tns-inner {
      padding-right: 25%; }
    .slider-container.next-slide-25.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-25 .tns-inner {
      padding-left: 25%; }
    .slider-container.prev-slide-25.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-26 .tns-inner {
      padding-right: 26%; }
    .slider-container.next-slide-26.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-26 .tns-inner {
      padding-left: 26%; }
    .slider-container.prev-slide-26.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-27 .tns-inner {
      padding-right: 27%; }
    .slider-container.next-slide-27.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-27 .tns-inner {
      padding-left: 27%; }
    .slider-container.prev-slide-27.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-28 .tns-inner {
      padding-right: 28%; }
    .slider-container.next-slide-28.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-28 .tns-inner {
      padding-left: 28%; }
    .slider-container.prev-slide-28.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-29 .tns-inner {
      padding-right: 29%; }
    .slider-container.next-slide-29.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-29 .tns-inner {
      padding-left: 29%; }
    .slider-container.prev-slide-29.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-30 .tns-inner {
      padding-right: 30%; }
    .slider-container.next-slide-30.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-30 .tns-inner {
      padding-left: 30%; }
    .slider-container.prev-slide-30.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-31 .tns-inner {
      padding-right: 31%; }
    .slider-container.next-slide-31.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-31 .tns-inner {
      padding-left: 31%; }
    .slider-container.prev-slide-31.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-32 .tns-inner {
      padding-right: 32%; }
    .slider-container.next-slide-32.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-32 .tns-inner {
      padding-left: 32%; }
    .slider-container.prev-slide-32.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-33 .tns-inner {
      padding-right: 33%; }
    .slider-container.next-slide-33.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-33 .tns-inner {
      padding-left: 33%; }
    .slider-container.prev-slide-33.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-34 .tns-inner {
      padding-right: 34%; }
    .slider-container.next-slide-34.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-34 .tns-inner {
      padding-left: 34%; }
    .slider-container.prev-slide-34.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-35 .tns-inner {
      padding-right: 35%; }
    .slider-container.next-slide-35.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-35 .tns-inner {
      padding-left: 35%; }
    .slider-container.prev-slide-35.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-36 .tns-inner {
      padding-right: 36%; }
    .slider-container.next-slide-36.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-36 .tns-inner {
      padding-left: 36%; }
    .slider-container.prev-slide-36.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-37 .tns-inner {
      padding-right: 37%; }
    .slider-container.next-slide-37.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-37 .tns-inner {
      padding-left: 37%; }
    .slider-container.prev-slide-37.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-38 .tns-inner {
      padding-right: 38%; }
    .slider-container.next-slide-38.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-38 .tns-inner {
      padding-left: 38%; }
    .slider-container.prev-slide-38.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-39 .tns-inner {
      padding-right: 39%; }
    .slider-container.next-slide-39.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-39 .tns-inner {
      padding-left: 39%; }
    .slider-container.prev-slide-39.slider-buttons-hidden {
      margin-left: -16px; }
    .slider-container.next-slide-40 .tns-inner {
      padding-right: 40%; }
    .slider-container.next-slide-40.slider-buttons-hidden {
      margin-right: -16px; }
    .slider-container.prev-slide-40 .tns-inner {
      padding-left: 40%; }
    .slider-container.prev-slide-40.slider-buttons-hidden {
      margin-left: -16px; } }
  @media (min-width: 769px) and (max-width: 1023.98px) {
    .slider-container.slider-buttons-hidden-md {
      padding-left: 0;
      padding-right: 0; }
      .slider-container.slider-buttons-hidden-md .tns-controls {
        display: none; }
    .slider-container.slider-bullets-hidden-md {
      margin-bottom: 0; }
      .slider-container.slider-bullets-hidden-md .tns-nav {
        display: none; }
    .slider-container.slider-buttons-inside-md {
      padding-left: 0;
      padding-right: 0; }
    .slider-container.slider-bullets-inside-md {
      margin-bottom: 0; }
      .slider-container.slider-bullets-inside-md .tns-nav {
        bottom: 0; }
    .slider-container.next-slide-md-1 .tns-inner {
      padding-right: 1%; }
    .slider-container.prev-slide-md-1 .tns-inner {
      padding-left: 1%; }
    .slider-container.next-slide-md-2 .tns-inner {
      padding-right: 2%; }
    .slider-container.prev-slide-md-2 .tns-inner {
      padding-left: 2%; }
    .slider-container.next-slide-md-3 .tns-inner {
      padding-right: 3%; }
    .slider-container.prev-slide-md-3 .tns-inner {
      padding-left: 3%; }
    .slider-container.next-slide-md-4 .tns-inner {
      padding-right: 4%; }
    .slider-container.prev-slide-md-4 .tns-inner {
      padding-left: 4%; }
    .slider-container.next-slide-md-5 .tns-inner {
      padding-right: 5%; }
    .slider-container.prev-slide-md-5 .tns-inner {
      padding-left: 5%; }
    .slider-container.next-slide-md-6 .tns-inner {
      padding-right: 6%; }
    .slider-container.prev-slide-md-6 .tns-inner {
      padding-left: 6%; }
    .slider-container.next-slide-md-7 .tns-inner {
      padding-right: 7%; }
    .slider-container.prev-slide-md-7 .tns-inner {
      padding-left: 7%; }
    .slider-container.next-slide-md-8 .tns-inner {
      padding-right: 8%; }
    .slider-container.prev-slide-md-8 .tns-inner {
      padding-left: 8%; }
    .slider-container.next-slide-md-9 .tns-inner {
      padding-right: 9%; }
    .slider-container.prev-slide-md-9 .tns-inner {
      padding-left: 9%; }
    .slider-container.next-slide-md-10 .tns-inner {
      padding-right: 10%; }
    .slider-container.prev-slide-md-10 .tns-inner {
      padding-left: 10%; }
    .slider-container.next-slide-md-11 .tns-inner {
      padding-right: 11%; }
    .slider-container.prev-slide-md-11 .tns-inner {
      padding-left: 11%; }
    .slider-container.next-slide-md-12 .tns-inner {
      padding-right: 12%; }
    .slider-container.prev-slide-md-12 .tns-inner {
      padding-left: 12%; }
    .slider-container.next-slide-md-13 .tns-inner {
      padding-right: 13%; }
    .slider-container.prev-slide-md-13 .tns-inner {
      padding-left: 13%; }
    .slider-container.next-slide-md-14 .tns-inner {
      padding-right: 14%; }
    .slider-container.prev-slide-md-14 .tns-inner {
      padding-left: 14%; }
    .slider-container.next-slide-md-15 .tns-inner {
      padding-right: 15%; }
    .slider-container.prev-slide-md-15 .tns-inner {
      padding-left: 15%; }
    .slider-container.next-slide-md-16 .tns-inner {
      padding-right: 16%; }
    .slider-container.prev-slide-md-16 .tns-inner {
      padding-left: 16%; }
    .slider-container.next-slide-md-17 .tns-inner {
      padding-right: 17%; }
    .slider-container.prev-slide-md-17 .tns-inner {
      padding-left: 17%; }
    .slider-container.next-slide-md-18 .tns-inner {
      padding-right: 18%; }
    .slider-container.prev-slide-md-18 .tns-inner {
      padding-left: 18%; }
    .slider-container.next-slide-md-19 .tns-inner {
      padding-right: 19%; }
    .slider-container.prev-slide-md-19 .tns-inner {
      padding-left: 19%; }
    .slider-container.next-slide-md-20 .tns-inner {
      padding-right: 20%; }
    .slider-container.prev-slide-md-20 .tns-inner {
      padding-left: 20%; }
    .slider-container.next-slide-md-21 .tns-inner {
      padding-right: 21%; }
    .slider-container.prev-slide-md-21 .tns-inner {
      padding-left: 21%; }
    .slider-container.next-slide-md-22 .tns-inner {
      padding-right: 22%; }
    .slider-container.prev-slide-md-22 .tns-inner {
      padding-left: 22%; }
    .slider-container.next-slide-md-23 .tns-inner {
      padding-right: 23%; }
    .slider-container.prev-slide-md-23 .tns-inner {
      padding-left: 23%; }
    .slider-container.next-slide-md-24 .tns-inner {
      padding-right: 24%; }
    .slider-container.prev-slide-md-24 .tns-inner {
      padding-left: 24%; }
    .slider-container.next-slide-md-25 .tns-inner {
      padding-right: 25%; }
    .slider-container.prev-slide-md-25 .tns-inner {
      padding-left: 25%; }
    .slider-container.next-slide-md-26 .tns-inner {
      padding-right: 26%; }
    .slider-container.prev-slide-md-26 .tns-inner {
      padding-left: 26%; }
    .slider-container.next-slide-md-27 .tns-inner {
      padding-right: 27%; }
    .slider-container.prev-slide-md-27 .tns-inner {
      padding-left: 27%; }
    .slider-container.next-slide-md-28 .tns-inner {
      padding-right: 28%; }
    .slider-container.prev-slide-md-28 .tns-inner {
      padding-left: 28%; }
    .slider-container.next-slide-md-29 .tns-inner {
      padding-right: 29%; }
    .slider-container.prev-slide-md-29 .tns-inner {
      padding-left: 29%; }
    .slider-container.next-slide-md-30 .tns-inner {
      padding-right: 30%; }
    .slider-container.prev-slide-md-30 .tns-inner {
      padding-left: 30%; }
    .slider-container.next-slide-md-31 .tns-inner {
      padding-right: 31%; }
    .slider-container.prev-slide-md-31 .tns-inner {
      padding-left: 31%; }
    .slider-container.next-slide-md-32 .tns-inner {
      padding-right: 32%; }
    .slider-container.prev-slide-md-32 .tns-inner {
      padding-left: 32%; }
    .slider-container.next-slide-md-33 .tns-inner {
      padding-right: 33%; }
    .slider-container.prev-slide-md-33 .tns-inner {
      padding-left: 33%; }
    .slider-container.next-slide-md-34 .tns-inner {
      padding-right: 34%; }
    .slider-container.prev-slide-md-34 .tns-inner {
      padding-left: 34%; }
    .slider-container.next-slide-md-35 .tns-inner {
      padding-right: 35%; }
    .slider-container.prev-slide-md-35 .tns-inner {
      padding-left: 35%; }
    .slider-container.next-slide-md-36 .tns-inner {
      padding-right: 36%; }
    .slider-container.prev-slide-md-36 .tns-inner {
      padding-left: 36%; }
    .slider-container.next-slide-md-37 .tns-inner {
      padding-right: 37%; }
    .slider-container.prev-slide-md-37 .tns-inner {
      padding-left: 37%; }
    .slider-container.next-slide-md-38 .tns-inner {
      padding-right: 38%; }
    .slider-container.prev-slide-md-38 .tns-inner {
      padding-left: 38%; }
    .slider-container.next-slide-md-39 .tns-inner {
      padding-right: 39%; }
    .slider-container.prev-slide-md-39 .tns-inner {
      padding-left: 39%; }
    .slider-container.next-slide-md-40 .tns-inner {
      padding-right: 40%; }
    .slider-container.prev-slide-md-40 .tns-inner {
      padding-left: 40%; } }
  @media (min-width: 1024px) {
    .slider-container.slider-buttons-hidden-lg {
      padding-left: 0;
      padding-right: 0; }
      .slider-container.slider-buttons-hidden-lg .tns-controls {
        display: none; }
    .slider-container.slider-bullets-hidden-lg {
      margin-bottom: 0; }
      .slider-container.slider-bullets-hidden-lg .tns-nav {
        display: none; }
    .slider-container.slider-buttons-inside-lg {
      padding-left: 0;
      padding-right: 0; }
    .slider-container.slider-bullets-inside-lg {
      margin-bottom: 0; }
      .slider-container.slider-bullets-inside-lg .tns-nav {
        bottom: 0; }
    .slider-container.next-slide-lg-1 .tns-inner {
      padding-right: 1%; }
    .slider-container.prev-slide-lg-1 .tns-inner {
      padding-left: 1%; }
    .slider-container.next-slide-lg-2 .tns-inner {
      padding-right: 2%; }
    .slider-container.prev-slide-lg-2 .tns-inner {
      padding-left: 2%; }
    .slider-container.next-slide-lg-3 .tns-inner {
      padding-right: 3%; }
    .slider-container.prev-slide-lg-3 .tns-inner {
      padding-left: 3%; }
    .slider-container.next-slide-lg-4 .tns-inner {
      padding-right: 4%; }
    .slider-container.prev-slide-lg-4 .tns-inner {
      padding-left: 4%; }
    .slider-container.next-slide-lg-5 .tns-inner {
      padding-right: 5%; }
    .slider-container.prev-slide-lg-5 .tns-inner {
      padding-left: 5%; }
    .slider-container.next-slide-lg-6 .tns-inner {
      padding-right: 6%; }
    .slider-container.prev-slide-lg-6 .tns-inner {
      padding-left: 6%; }
    .slider-container.next-slide-lg-7 .tns-inner {
      padding-right: 7%; }
    .slider-container.prev-slide-lg-7 .tns-inner {
      padding-left: 7%; }
    .slider-container.next-slide-lg-8 .tns-inner {
      padding-right: 8%; }
    .slider-container.prev-slide-lg-8 .tns-inner {
      padding-left: 8%; }
    .slider-container.next-slide-lg-9 .tns-inner {
      padding-right: 9%; }
    .slider-container.prev-slide-lg-9 .tns-inner {
      padding-left: 9%; }
    .slider-container.next-slide-lg-10 .tns-inner {
      padding-right: 10%; }
    .slider-container.prev-slide-lg-10 .tns-inner {
      padding-left: 10%; }
    .slider-container.next-slide-lg-11 .tns-inner {
      padding-right: 11%; }
    .slider-container.prev-slide-lg-11 .tns-inner {
      padding-left: 11%; }
    .slider-container.next-slide-lg-12 .tns-inner {
      padding-right: 12%; }
    .slider-container.prev-slide-lg-12 .tns-inner {
      padding-left: 12%; }
    .slider-container.next-slide-lg-13 .tns-inner {
      padding-right: 13%; }
    .slider-container.prev-slide-lg-13 .tns-inner {
      padding-left: 13%; }
    .slider-container.next-slide-lg-14 .tns-inner {
      padding-right: 14%; }
    .slider-container.prev-slide-lg-14 .tns-inner {
      padding-left: 14%; }
    .slider-container.next-slide-lg-15 .tns-inner {
      padding-right: 15%; }
    .slider-container.prev-slide-lg-15 .tns-inner {
      padding-left: 15%; }
    .slider-container.next-slide-lg-16 .tns-inner {
      padding-right: 16%; }
    .slider-container.prev-slide-lg-16 .tns-inner {
      padding-left: 16%; }
    .slider-container.next-slide-lg-17 .tns-inner {
      padding-right: 17%; }
    .slider-container.prev-slide-lg-17 .tns-inner {
      padding-left: 17%; }
    .slider-container.next-slide-lg-18 .tns-inner {
      padding-right: 18%; }
    .slider-container.prev-slide-lg-18 .tns-inner {
      padding-left: 18%; }
    .slider-container.next-slide-lg-19 .tns-inner {
      padding-right: 19%; }
    .slider-container.prev-slide-lg-19 .tns-inner {
      padding-left: 19%; }
    .slider-container.next-slide-lg-20 .tns-inner {
      padding-right: 20%; }
    .slider-container.prev-slide-lg-20 .tns-inner {
      padding-left: 20%; }
    .slider-container.next-slide-lg-21 .tns-inner {
      padding-right: 21%; }
    .slider-container.prev-slide-lg-21 .tns-inner {
      padding-left: 21%; }
    .slider-container.next-slide-lg-22 .tns-inner {
      padding-right: 22%; }
    .slider-container.prev-slide-lg-22 .tns-inner {
      padding-left: 22%; }
    .slider-container.next-slide-lg-23 .tns-inner {
      padding-right: 23%; }
    .slider-container.prev-slide-lg-23 .tns-inner {
      padding-left: 23%; }
    .slider-container.next-slide-lg-24 .tns-inner {
      padding-right: 24%; }
    .slider-container.prev-slide-lg-24 .tns-inner {
      padding-left: 24%; }
    .slider-container.next-slide-lg-25 .tns-inner {
      padding-right: 25%; }
    .slider-container.prev-slide-lg-25 .tns-inner {
      padding-left: 25%; }
    .slider-container.next-slide-lg-26 .tns-inner {
      padding-right: 26%; }
    .slider-container.prev-slide-lg-26 .tns-inner {
      padding-left: 26%; }
    .slider-container.next-slide-lg-27 .tns-inner {
      padding-right: 27%; }
    .slider-container.prev-slide-lg-27 .tns-inner {
      padding-left: 27%; }
    .slider-container.next-slide-lg-28 .tns-inner {
      padding-right: 28%; }
    .slider-container.prev-slide-lg-28 .tns-inner {
      padding-left: 28%; }
    .slider-container.next-slide-lg-29 .tns-inner {
      padding-right: 29%; }
    .slider-container.prev-slide-lg-29 .tns-inner {
      padding-left: 29%; }
    .slider-container.next-slide-lg-30 .tns-inner {
      padding-right: 30%; }
    .slider-container.prev-slide-lg-30 .tns-inner {
      padding-left: 30%; }
    .slider-container.next-slide-lg-31 .tns-inner {
      padding-right: 31%; }
    .slider-container.prev-slide-lg-31 .tns-inner {
      padding-left: 31%; }
    .slider-container.next-slide-lg-32 .tns-inner {
      padding-right: 32%; }
    .slider-container.prev-slide-lg-32 .tns-inner {
      padding-left: 32%; }
    .slider-container.next-slide-lg-33 .tns-inner {
      padding-right: 33%; }
    .slider-container.prev-slide-lg-33 .tns-inner {
      padding-left: 33%; }
    .slider-container.next-slide-lg-34 .tns-inner {
      padding-right: 34%; }
    .slider-container.prev-slide-lg-34 .tns-inner {
      padding-left: 34%; }
    .slider-container.next-slide-lg-35 .tns-inner {
      padding-right: 35%; }
    .slider-container.prev-slide-lg-35 .tns-inner {
      padding-left: 35%; }
    .slider-container.next-slide-lg-36 .tns-inner {
      padding-right: 36%; }
    .slider-container.prev-slide-lg-36 .tns-inner {
      padding-left: 36%; }
    .slider-container.next-slide-lg-37 .tns-inner {
      padding-right: 37%; }
    .slider-container.prev-slide-lg-37 .tns-inner {
      padding-left: 37%; }
    .slider-container.next-slide-lg-38 .tns-inner {
      padding-right: 38%; }
    .slider-container.prev-slide-lg-38 .tns-inner {
      padding-left: 38%; }
    .slider-container.next-slide-lg-39 .tns-inner {
      padding-right: 39%; }
    .slider-container.prev-slide-lg-39 .tns-inner {
      padding-left: 39%; }
    .slider-container.next-slide-lg-40 .tns-inner {
      padding-right: 40%; }
    .slider-container.prev-slide-lg-40 .tns-inner {
      padding-left: 40%; } }

.tns-nav-controller {
  cursor: pointer; }
  .tns-nav-controller:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 25px;
    color: #C5C5C5;
    font-weight: normal; }
  .tns-nav-controller[class*="btn-"]:not(.title) {
    padding-left: calc(var(--button-padding-x) + 20px); }
    .tns-nav-controller[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: #C5C5C5;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .tns-nav-controller[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .tns-nav-controller.btn[class*="btn-"]:not(.title) {
      padding-left: calc(20px + 20px); }
      .tns-nav-controller[class*="btn-"]:not(.title).btn-sm:before, .btn-group-sm > .tns-nav-controller.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #C5C5C5;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .tns-nav-controller[class*="btn-"]:not(.title) [class*="icon-"],
    .tns-nav-controller[class*="btn-"]:not(.title) [class^="icon-"],
    .tns-nav-controller[class*="btn-"]:not(.title) .fa {
      display: none; }
  .tns-nav-controller.pause:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 25px;
    color: var(--brand-primary);
    font-weight: normal; }
  .tns-nav-controller.pause[class*="btn-"]:not(.title) {
    padding-left: calc(var(--button-padding-x) + 20px); }
    .tns-nav-controller.pause[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: var(--brand-primary);
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .tns-nav-controller.pause[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .tns-nav-controller.pause.btn[class*="btn-"]:not(.title) {
      padding-left: calc(20px + 20px); }
      .tns-nav-controller.pause[class*="btn-"]:not(.title).btn-sm:before, .btn-group-sm > .tns-nav-controller.pause.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: var(--brand-primary);
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .tns-nav-controller.pause[class*="btn-"]:not(.title) [class*="icon-"],
    .tns-nav-controller.pause[class*="btn-"]:not(.title) [class^="icon-"],
    .tns-nav-controller.pause[class*="btn-"]:not(.title) .fa {
      display: none; }
  .tns-nav-controller:not(.with-pagination) {
    position: absolute;
    padding: 10px;
    z-index: 2; }
  .tns-nav-controller.top-right {
    top: 0;
    right: 0; }
  .tns-nav-controller.top-left {
    top: 0;
    left: 0; }
  .tns-nav-controller.bottom-right {
    bottom: 0;
    right: 0; }
  .tns-nav-controller.bottom-left {
    bottom: 0;
    left: 0; }
  .tns-nav-controller.with-pagination {
    padding-right: 5px; }

.slider-thumbnails {
  padding-left: 28px;
  padding-right: 28px;
  user-select: none; }
  .slider-thumbnails .slider {
    overflow: auto;
    text-align: center;
    padding-bottom: 50px;
    margin-bottom: -50px; }
  .slider-thumbnails .slide {
    min-width: 63px;
    max-width: 63px;
    flex-basis: 63px; }
    @media (min-width: 769px) {
      .slider-thumbnails .slide {
        min-width: 63px;
        max-width: 63px;
        flex-basis: 63px; } }
    @media (min-width: 1024px) {
      .slider-thumbnails .slide {
        min-width: 63px;
        max-width: 63px;
        flex-basis: 63px; } }
    .slider-thumbnails .slide > * {
      width: 100%; }
    .slider-thumbnails .slide::after {
      content: '';
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-style: solid;
      border-width: 0;
      border-color: transparent;
      transition: border 200ms ease-out; }
    .slider-thumbnails .slide.tns-nav-active::after {
      border-width: 0 0 2px 0;
      border-color: #000000; }
  @media (max-width: 768.98px) {
    .slider-thumbnails.slider-thumbnail-dots .slide {
      min-width: 12px;
      max-width: 12px;
      height: 12px;
      margin: 10px 5px 10px 5px; }
      .slider-thumbnails.slider-thumbnail-dots .slide:focus {
        outline: none; }
      .slider-thumbnails.slider-thumbnail-dots .slide:after {
        background: rgba(0, 0, 0, 0.5);
        border: none;
        border-radius: 50%; }
      .slider-thumbnails.slider-thumbnail-dots .slide.tns-nav-active::after {
        background: #000000;
        border: none;
        transform: scale(1); }
      .slider-thumbnails.slider-thumbnail-dots .slide img {
        display: none !important; } }
  .primary-images-thumbnails-dots .slider-thumbnails.slider-thumbnail-dots .slide {
    min-width: 12px;
    max-width: 12px;
    height: 12px;
    margin: 10px 5px 10px 5px; }
    .primary-images-thumbnails-dots .slider-thumbnails.slider-thumbnail-dots .slide:focus {
      outline: none; }
    .primary-images-thumbnails-dots .slider-thumbnails.slider-thumbnail-dots .slide:after {
      background: rgba(0, 0, 0, 0.5);
      border: none;
      border-radius: 50%; }
    .primary-images-thumbnails-dots .slider-thumbnails.slider-thumbnail-dots .slide.tns-nav-active::after {
      background: #000000;
      border: none;
      transform: scale(1); }
    .primary-images-thumbnails-dots .slider-thumbnails.slider-thumbnail-dots .slide img {
      display: none !important; }
  .slider-thumbnails.slider-thumbnail-dots .slider-thumbnail-controls {
    display: none; }
    .primary-images-thumbnails .slider-thumbnails.slider-thumbnail-dots .slider-thumbnail-controls {
      display: block; }
  .slider-thumbnails .tns-horizontal > .tns-item {
    vertical-align: middle; }
  .slider-thumbnails .slider-thumbnail-controls .prev,
  .slider-thumbnails .slider-thumbnail-controls .next {
    position: absolute;
    top: 0;
    height: 100%;
    width: 11px;
    cursor: pointer;
    user-select: none;
    background-color: #FFFFFF;
    transition: opacity 200ms ease-out; }
    .slider-thumbnails .slider-thumbnail-controls .prev::before,
    .slider-thumbnails .slider-thumbnail-controls .next::before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
    .slider-thumbnails .slider-thumbnail-controls .prev:hover,
    .slider-thumbnails .slider-thumbnail-controls .next:hover {
      background-color: #FFFFFF; }
      .slider-thumbnails .slider-thumbnail-controls .prev:hover::before,
      .slider-thumbnails .slider-thumbnail-controls .next:hover::before {
        color: #000000; }
    .slider-thumbnails .slider-thumbnail-controls .prev[aria-disabled=true],
    .slider-thumbnails .slider-thumbnail-controls .next[aria-disabled=true] {
      opacity: 0; }
  .slider-thumbnails .slider-thumbnail-controls .prev {
    left: 0; }
    .slider-thumbnails .slider-thumbnail-controls .prev:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: #000000;
      font-weight: normal; }
    .slider-thumbnails .slider-thumbnail-controls .prev[class*="btn-"]:not(.title) {
      padding-left: calc(var(--button-padding-x) + 20px); }
      .slider-thumbnails .slider-thumbnail-controls .prev[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #000000;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .slider-thumbnails .slider-thumbnail-controls .prev[class*="btn-"]:not(.title).btn-sm, .slider-thumbnails .slider-thumbnail-controls .btn-group-sm > .prev.btn[class*="btn-"]:not(.title) {
        padding-left: calc(20px + 20px); }
        .slider-thumbnails .slider-thumbnail-controls .prev[class*="btn-"]:not(.title).btn-sm:before, .slider-thumbnails .slider-thumbnail-controls .btn-group-sm > .prev.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #000000;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .slider-thumbnails .slider-thumbnail-controls .prev[class*="btn-"]:not(.title) [class*="icon-"],
      .slider-thumbnails .slider-thumbnail-controls .prev[class*="btn-"]:not(.title) [class^="icon-"],
      .slider-thumbnails .slider-thumbnail-controls .prev[class*="btn-"]:not(.title) .fa {
        display: none; }
  .slider-thumbnails .slider-thumbnail-controls .next {
    right: 0; }
    .slider-thumbnails .slider-thumbnail-controls .next:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: #000000;
      font-weight: normal; }
    .slider-thumbnails .slider-thumbnail-controls .next[class*="btn-"]:not(.title) {
      padding-left: calc(var(--button-padding-x) + 20px); }
      .slider-thumbnails .slider-thumbnail-controls .next[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #000000;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .slider-thumbnails .slider-thumbnail-controls .next[class*="btn-"]:not(.title).btn-sm, .slider-thumbnails .slider-thumbnail-controls .btn-group-sm > .next.btn[class*="btn-"]:not(.title) {
        padding-left: calc(20px + 20px); }
        .slider-thumbnails .slider-thumbnail-controls .next[class*="btn-"]:not(.title).btn-sm:before, .slider-thumbnails .slider-thumbnail-controls .btn-group-sm > .next.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #000000;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .slider-thumbnails .slider-thumbnail-controls .next[class*="btn-"]:not(.title) [class*="icon-"],
      .slider-thumbnails .slider-thumbnail-controls .next[class*="btn-"]:not(.title) [class^="icon-"],
      .slider-thumbnails .slider-thumbnail-controls .next[class*="btn-"]:not(.title) .fa {
        display: none; }
  .slider-thumbnails .tns-horizontal.tns-no-subpixel {
    white-space: nowrap; }
    .slider-thumbnails .tns-horizontal.tns-no-subpixel > .tns-item {
      float: none;
      display: inline-block;
      margin: 0;
      margin-left: 0 !important; }
  .slider-thumbnails .video-container .video-thumbnail-button::before {
    font-size: 26px; }
  @media (max-width: 768.98px) {
    .slider-thumbnails.slider-thumbnails-vertical {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical .tns-outer,
      .slider-thumbnails.slider-thumbnails-vertical .tns-ovh {
        max-height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical .tns-inner {
        width: calc(100% + 50px);
        margin-right: -50px !important;
        overflow: auto;
        max-height: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical .slider {
        padding-bottom: 0;
        margin-bottom: 0;
        width: 100% !important; }
      .slider-thumbnails.slider-thumbnails-vertical .slide {
        display: block;
        width: 100% !important;
        padding-right: 0 !important; }
        .slider-thumbnails.slider-thumbnails-vertical .slide + .slide {
          margin-top: 1px; }
      .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .next {
        left: 0;
        text-align: center;
        height: 11px;
        width: 63px; } }
    @media (max-width: 768.98px) and (min-width: 769px) {
      .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .next {
        width: 63px; } }
    @media (max-width: 768.98px) and (min-width: 1024px) {
      .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .next {
        width: 63px; } }
  @media (max-width: 768.98px) {
        .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .prev::before,
        .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .next::before {
          transform: rotate(90deg);
          top: auto;
          bottom: 0;
          left: calc(50% - 6px);
          width: 11px;
          height: 12px; }
      .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .prev {
        top: 0; }
      .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .next {
        top: auto;
        bottom: 0; } }
  @media (min-width: 769px) and (max-width: 1023.98px) {
    .slider-thumbnails.slider-thumbnails-vertical-md {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical-md .tns-outer,
      .slider-thumbnails.slider-thumbnails-vertical-md .tns-ovh {
        max-height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical-md .tns-inner {
        width: calc(100% + 50px);
        margin-right: -50px !important;
        overflow: auto;
        max-height: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical-md .slider {
        padding-bottom: 0;
        margin-bottom: 0;
        width: 100% !important; }
      .slider-thumbnails.slider-thumbnails-vertical-md .slide {
        display: block;
        width: 100% !important;
        padding-right: 0 !important; }
        .slider-thumbnails.slider-thumbnails-vertical-md .slide + .slide {
          margin-top: 1px; }
      .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .next {
        left: 0;
        text-align: center;
        height: 11px;
        width: 63px; } }
    @media (min-width: 769px) and (max-width: 1023.98px) and (min-width: 769px) {
      .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .next {
        width: 63px; } }
    @media (min-width: 769px) and (max-width: 1023.98px) and (min-width: 1024px) {
      .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .next {
        width: 63px; } }
  @media (min-width: 769px) and (max-width: 1023.98px) {
        .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .prev::before,
        .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .next::before {
          transform: rotate(90deg);
          top: auto;
          bottom: 0;
          left: calc(50% - 6px);
          width: 11px;
          height: 12px; }
      .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .prev {
        top: 0; }
      .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .next {
        top: auto;
        bottom: 0; } }
  @media (min-width: 1024px) {
    .slider-thumbnails.slider-thumbnails-vertical-lg {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .tns-outer,
      .slider-thumbnails.slider-thumbnails-vertical-lg .tns-ovh {
        max-height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .tns-inner {
        width: calc(100% + 50px);
        margin-right: -50px !important;
        overflow: auto;
        max-height: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider {
        padding-bottom: 0;
        margin-bottom: 0;
        width: 100% !important; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .slide {
        display: block;
        width: 100% !important;
        padding-right: 0 !important; }
        .slider-thumbnails.slider-thumbnails-vertical-lg .slide + .slide {
          margin-top: 1px; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .next {
        left: 0;
        text-align: center;
        height: 11px;
        width: 63px; } }
    @media (min-width: 1024px) and (min-width: 769px) {
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .next {
        width: 63px; } }
    @media (min-width: 1024px) and (min-width: 1024px) {
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .next {
        width: 63px; } }
  @media (min-width: 1024px) {
        .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .prev::before,
        .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .next::before {
          transform: rotate(90deg);
          top: auto;
          bottom: 0;
          left: calc(50% - 6px);
          width: 11px;
          height: 12px; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .prev {
        top: 0; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .next {
        top: auto;
        bottom: 0; } }

.social-links {
  list-style: none;
  display: inline-flex;
  flex-flow: wrap;
  padding-left: 0px;
  margin-bottom: 0px; }
  .social-links li {
    margin: 20px 10px; }
  .social-links a {
    font-size: 40px;
    line-height: 40px;
    text-decoration: none; }
    .social-links a:hover {
      text-decoration: none; }
  .social-links .fa-linkedin-square:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-links .fa-linkedin-square[class*="btn-"]:not(.title) {
    padding-left: calc(var(--button-padding-x) + 20px); }
    .social-links .fa-linkedin-square[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-links .fa-linkedin-square[class*="btn-"]:not(.title).btn-sm, .social-links .btn-group-sm > .fa-linkedin-square.btn[class*="btn-"]:not(.title) {
      padding-left: calc(20px + 20px); }
      .social-links .fa-linkedin-square[class*="btn-"]:not(.title).btn-sm:before, .social-links .btn-group-sm > .fa-linkedin-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-linkedin-square[class*="btn-"]:not(.title) [class*="icon-"],
    .social-links .fa-linkedin-square[class*="btn-"]:not(.title) [class^="icon-"],
    .social-links .fa-linkedin-square[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-links .fa-facebook-square:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-links .fa-facebook-square[class*="btn-"]:not(.title) {
    padding-left: calc(var(--button-padding-x) + 20px); }
    .social-links .fa-facebook-square[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-links .fa-facebook-square[class*="btn-"]:not(.title).btn-sm, .social-links .btn-group-sm > .fa-facebook-square.btn[class*="btn-"]:not(.title) {
      padding-left: calc(20px + 20px); }
      .social-links .fa-facebook-square[class*="btn-"]:not(.title).btn-sm:before, .social-links .btn-group-sm > .fa-facebook-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-facebook-square[class*="btn-"]:not(.title) [class*="icon-"],
    .social-links .fa-facebook-square[class*="btn-"]:not(.title) [class^="icon-"],
    .social-links .fa-facebook-square[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-links .fa-twitter-square:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-links .fa-twitter-square[class*="btn-"]:not(.title) {
    padding-left: calc(var(--button-padding-x) + 20px); }
    .social-links .fa-twitter-square[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-links .fa-twitter-square[class*="btn-"]:not(.title).btn-sm, .social-links .btn-group-sm > .fa-twitter-square.btn[class*="btn-"]:not(.title) {
      padding-left: calc(20px + 20px); }
      .social-links .fa-twitter-square[class*="btn-"]:not(.title).btn-sm:before, .social-links .btn-group-sm > .fa-twitter-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-twitter-square[class*="btn-"]:not(.title) [class*="icon-"],
    .social-links .fa-twitter-square[class*="btn-"]:not(.title) [class^="icon-"],
    .social-links .fa-twitter-square[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-links .fa-youtube-square:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-links .fa-youtube-square[class*="btn-"]:not(.title) {
    padding-left: calc(var(--button-padding-x) + 20px); }
    .social-links .fa-youtube-square[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-links .fa-youtube-square[class*="btn-"]:not(.title).btn-sm, .social-links .btn-group-sm > .fa-youtube-square.btn[class*="btn-"]:not(.title) {
      padding-left: calc(20px + 20px); }
      .social-links .fa-youtube-square[class*="btn-"]:not(.title).btn-sm:before, .social-links .btn-group-sm > .fa-youtube-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-youtube-square[class*="btn-"]:not(.title) [class*="icon-"],
    .social-links .fa-youtube-square[class*="btn-"]:not(.title) [class^="icon-"],
    .social-links .fa-youtube-square[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-links .fa-pinterest-square:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-links .fa-pinterest-square[class*="btn-"]:not(.title) {
    padding-left: calc(var(--button-padding-x) + 20px); }
    .social-links .fa-pinterest-square[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-links .fa-pinterest-square[class*="btn-"]:not(.title).btn-sm, .social-links .btn-group-sm > .fa-pinterest-square.btn[class*="btn-"]:not(.title) {
      padding-left: calc(20px + 20px); }
      .social-links .fa-pinterest-square[class*="btn-"]:not(.title).btn-sm:before, .social-links .btn-group-sm > .fa-pinterest-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-pinterest-square[class*="btn-"]:not(.title) [class*="icon-"],
    .social-links .fa-pinterest-square[class*="btn-"]:not(.title) [class^="icon-"],
    .social-links .fa-pinterest-square[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-links .fa-email-square:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-links .fa-email-square[class*="btn-"]:not(.title) {
    padding-left: calc(var(--button-padding-x) + 20px); }
    .social-links .fa-email-square[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-links .fa-email-square[class*="btn-"]:not(.title).btn-sm, .social-links .btn-group-sm > .fa-email-square.btn[class*="btn-"]:not(.title) {
      padding-left: calc(20px + 20px); }
      .social-links .fa-email-square[class*="btn-"]:not(.title).btn-sm:before, .social-links .btn-group-sm > .fa-email-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-email-square[class*="btn-"]:not(.title) [class*="icon-"],
    .social-links .fa-email-square[class*="btn-"]:not(.title) [class^="icon-"],
    .social-links .fa-email-square[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-links .fa-link-square:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-links .fa-link-square[class*="btn-"]:not(.title) {
    padding-left: calc(var(--button-padding-x) + 20px); }
    .social-links .fa-link-square[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-links .fa-link-square[class*="btn-"]:not(.title).btn-sm, .social-links .btn-group-sm > .fa-link-square.btn[class*="btn-"]:not(.title) {
      padding-left: calc(20px + 20px); }
      .social-links .fa-link-square[class*="btn-"]:not(.title).btn-sm:before, .social-links .btn-group-sm > .fa-link-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-link-square[class*="btn-"]:not(.title) [class*="icon-"],
    .social-links .fa-link-square[class*="btn-"]:not(.title) [class^="icon-"],
    .social-links .fa-link-square[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-links .fa-instagram-square:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-links .fa-instagram-square[class*="btn-"]:not(.title) {
    padding-left: calc(var(--button-padding-x) + 20px); }
    .social-links .fa-instagram-square[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-links .fa-instagram-square[class*="btn-"]:not(.title).btn-sm, .social-links .btn-group-sm > .fa-instagram-square.btn[class*="btn-"]:not(.title) {
      padding-left: calc(20px + 20px); }
      .social-links .fa-instagram-square[class*="btn-"]:not(.title).btn-sm:before, .social-links .btn-group-sm > .fa-instagram-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-instagram-square[class*="btn-"]:not(.title) [class*="icon-"],
    .social-links .fa-instagram-square[class*="btn-"]:not(.title) [class^="icon-"],
    .social-links .fa-instagram-square[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-links .share-link {
    font-size: initial; }

.social-icons {
  list-style-type: none;
  padding: 0;
  margin: 0; }
  .social-icons li {
    padding: 0; }
  .social-icons .fa-pinterest:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-icons .fa-pinterest[class*="btn-"]:not(.title) {
    padding-left: calc(var(--button-padding-x) + 20px); }
    .social-icons .fa-pinterest[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-icons .fa-pinterest[class*="btn-"]:not(.title).btn-sm, .social-icons .btn-group-sm > .fa-pinterest.btn[class*="btn-"]:not(.title) {
      padding-left: calc(20px + 20px); }
      .social-icons .fa-pinterest[class*="btn-"]:not(.title).btn-sm:before, .social-icons .btn-group-sm > .fa-pinterest.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-icons .fa-pinterest[class*="btn-"]:not(.title) [class*="icon-"],
    .social-icons .fa-pinterest[class*="btn-"]:not(.title) [class^="icon-"],
    .social-icons .fa-pinterest[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-icons .fa-facebook-official:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-icons .fa-facebook-official[class*="btn-"]:not(.title) {
    padding-left: calc(var(--button-padding-x) + 20px); }
    .social-icons .fa-facebook-official[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-icons .fa-facebook-official[class*="btn-"]:not(.title).btn-sm, .social-icons .btn-group-sm > .fa-facebook-official.btn[class*="btn-"]:not(.title) {
      padding-left: calc(20px + 20px); }
      .social-icons .fa-facebook-official[class*="btn-"]:not(.title).btn-sm:before, .social-icons .btn-group-sm > .fa-facebook-official.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-icons .fa-facebook-official[class*="btn-"]:not(.title) [class*="icon-"],
    .social-icons .fa-facebook-official[class*="btn-"]:not(.title) [class^="icon-"],
    .social-icons .fa-facebook-official[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-icons .fa-twitter:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-icons .fa-twitter[class*="btn-"]:not(.title) {
    padding-left: calc(var(--button-padding-x) + 20px); }
    .social-icons .fa-twitter[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-icons .fa-twitter[class*="btn-"]:not(.title).btn-sm, .social-icons .btn-group-sm > .fa-twitter.btn[class*="btn-"]:not(.title) {
      padding-left: calc(20px + 20px); }
      .social-icons .fa-twitter[class*="btn-"]:not(.title).btn-sm:before, .social-icons .btn-group-sm > .fa-twitter.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-icons .fa-twitter[class*="btn-"]:not(.title) [class*="icon-"],
    .social-icons .fa-twitter[class*="btn-"]:not(.title) [class^="icon-"],
    .social-icons .fa-twitter[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-icons .fa-link:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-icons .fa-link[class*="btn-"]:not(.title) {
    padding-left: calc(var(--button-padding-x) + 20px); }
    .social-icons .fa-link[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-icons .fa-link[class*="btn-"]:not(.title).btn-sm, .social-icons .btn-group-sm > .fa-link.btn[class*="btn-"]:not(.title) {
      padding-left: calc(20px + 20px); }
      .social-icons .fa-link[class*="btn-"]:not(.title).btn-sm:before, .social-icons .btn-group-sm > .fa-link.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-icons .fa-link[class*="btn-"]:not(.title) [class*="icon-"],
    .social-icons .fa-link[class*="btn-"]:not(.title) [class^="icon-"],
    .social-icons .fa-link[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-icons .share-link {
    text-decoration: none;
    display: block; }

.veil {
  position: absolute;
  z-index: 1000;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .veil .underlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: #000000; }
  .veil + .veil {
    display: none; }

.spinner {
  width: 80px;
  height: 80px;
  text-align: center;
  animation: sk-rotate 2s infinite linear;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px; }

.dot1,
.dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #FFFFFF;
  border-radius: 100%;
  animation: sk-bounce 2s infinite ease-in-out; }

.dot2 {
  top: auto;
  bottom: 0;
  animation-delay: -1s; }

@keyframes sk-rotate {
  100% {
    transform: rotate(360deg); } }

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }

.nav-links,
.nav-tabs,
.nav-pills {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative; }
  .nav-links .nav-item,
  .nav-tabs .nav-item,
  .nav-pills .nav-item {
    white-space: nowrap; }
  .nav-links .nav-item:only-child .nav-link,
  .nav-tabs .nav-item:only-child .nav-link,
  .nav-pills .nav-item:only-child .nav-link {
    border: none;
    background: transparent; }

.nav-tabs {
  border-bottom: none;
  padding-bottom: 1px; }
  .nav-tabs .nav-link {
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    border-top: var(--tab-border-top) solid var(--tab-border-color);
    border-right: var(--tab-border-right) solid var(--tab-border-color);
    border-bottom: var(--tab-border-bottom) solid var(--tab-border-color);
    border-left: var(--tab-border-left) solid var(--tab-border-color);
    font: 14px var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
    text-transform: none;
    font-weight: bold;
    text-decoration: none;
    letter-spacing: 0.05em;
    padding: 15px 16px 11px 16px;
    background-color: transparent;
    color: #767676; }
    .nav-tabs .nav-link:hover {
      border-top: none;
      border-right: none;
      border-bottom: var(--tab-border-bottom) solid var(--tab-border-color);
      border-left: none;
      text-decoration: none;
      background-color: transparent;
      color: var(--brand-primary); }
    .nav-tabs .nav-link.active {
      border-top: var(--tab-border-top-active) solid var(--tab-border-color-active);
      border-right: var(--tab-border-right-active) solid var(--tab-border-color-active);
      border-bottom: var(--tab-border-bottom-active) solid var(--tab-border-color-active);
      border-left: var(--tab-border-left-active) solid var(--tab-border-color-active);
      text-decoration: none;
      background-color: transparent;
      color: var(--brand-primary); }

.nav-links .nav-link {
  font: 14px var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
  text-transform: none;
  font-weight: normal;
  text-decoration: none;
  letter-spacing: 0.05em;
  color: var(--brand-primary);
  padding: 8px 16px 8px 16px; }
  .nav-links .nav-link:hover {
    text-decoration: underline;
    color: var(--brand-secondary);
    font-weight: normal;
    text-transform: none; }
  .nav-links .nav-link.active {
    text-decoration: none;
    color: var(--brand-primary);
    font-weight: bold;
    text-transform: none; }

.nav-pills .nav-link {
  font: 14px var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
  text-transform: none;
  font-weight: bold;
  text-decoration: none;
  letter-spacing: 0.05em;
  background-color: transparent;
  color: #767676;
  padding: 8px 16px 8px 16px;
  border-radius: 6px; }
  .nav-pills .nav-link:hover {
    text-decoration: none;
    background-color: transparent;
    color: var(--brand-primary); }
  .nav-pills .nav-link.active {
    text-decoration: none;
    background-color: #007c9e;
    color: #FFFFFF; }

.nav-tabs-container {
  position: relative; }
  .nav-tabs-container.has-overflow::after {
    content: '';
    animation: fadeIn 200ms;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: calc(var(--nav-tabs-height) - 8px);
    background: #FFFFFF;
    mask-image: linear-gradient(to left, black 0%, transparent);
    mask-size: 40px 100%;
    mask-position: top right;
    mask-repeat: no-repeat;
    pointer-events: none; }
  .nav-tabs-container.has-overflow .nav.custom-scrollbar {
    padding-right: 16px; }
    .nav-tabs-container.has-overflow .nav.custom-scrollbar.nav-pills {
      padding-bottom: 8px; }

.info-icon,
.tooltip-icon {
  border: none;
  padding: 0;
  position: relative;
  top: -2px;
  background: inherit;
  display: inline-block;
  cursor: pointer;
  font: inherit; }
  .info-icon:before,
  .tooltip-icon:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 120%;
    color: var(--brand-primary);
    font-weight: normal; }
  .info-icon[class*="btn-"]:not(.title),
  .tooltip-icon[class*="btn-"]:not(.title) {
    padding-left: calc(var(--button-padding-x) + 20px); }
    .info-icon[class*="btn-"]:not(.title):before,
    .tooltip-icon[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: var(--brand-primary);
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .info-icon[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .info-icon.btn[class*="btn-"]:not(.title),
    .tooltip-icon[class*="btn-"]:not(.title).btn-sm,
    .btn-group-sm > .tooltip-icon.btn[class*="btn-"]:not(.title) {
      padding-left: calc(20px + 20px); }
      .info-icon[class*="btn-"]:not(.title).btn-sm:before, .btn-group-sm > .info-icon.btn[class*="btn-"]:not(.title):before,
      .tooltip-icon[class*="btn-"]:not(.title).btn-sm:before,
      .btn-group-sm > .tooltip-icon.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: var(--brand-primary);
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .info-icon[class*="btn-"]:not(.title) [class*="icon-"],
    .info-icon[class*="btn-"]:not(.title) [class^="icon-"],
    .info-icon[class*="btn-"]:not(.title) .fa,
    .tooltip-icon[class*="btn-"]:not(.title) [class*="icon-"],
    .tooltip-icon[class*="btn-"]:not(.title) [class^="icon-"],
    .tooltip-icon[class*="btn-"]:not(.title) .fa {
      display: none; }
  .info-icon .icon,
  .tooltip-icon .icon {
    display: none; }
  .info-icon:hover:before, .info-icon[aria-describedby*="tooltip"]:before,
  .tooltip-icon:hover:before,
  .tooltip-icon[aria-describedby*="tooltip"]:before {
    color: var(--brand-secondary); }

.tooltip.bs-tooltip-right .arrow:before, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow:before {
  border-right-color: #FFFFFF; }

.tooltip.bs-tooltip-left .arrow:before, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow:before {
  border-left-color: #FFFFFF; }

.tooltip.bs-tooltip-top .arrow:before, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-top-color: #FFFFFF; }

.tooltip.bs-tooltip-bottom .arrow:before, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  border-bottom-color: #FFFFFF; }

.tooltip .tooltip-inner {
  border-radius: var(--border-radius);
  padding: 12px 25px;
  text-align: center;
  max-width: 200px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); }
  .tooltip .tooltip-inner:not([class*='background--']) {
    background: #FFFFFF; }
  .tooltip .tooltip-inner:not([class*='text--']) {
    color: #000000; }
  .tooltip .tooltip-inner:not([class*='font-size--']) {
    font-size: 10px; }

.tooltip.show {
  opacity: 1; }

.video-container {
  position: relative;
  max-width: 100%;
  min-width: 100%;
  max-height: 100%; }
  .video-container.aspect-ratio-16-9::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-16-9::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(9 / 16 * 100%); }
  .video-container.aspect-ratio-16-9 .video-thumbnail:not(.btn)::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-16-9 .video-thumbnail:not(.btn)::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(9 / 16 * 100%); }
  .video-container.aspect-ratio-9-16::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-9-16::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(16 / 9 * 100%); }
  .video-container.aspect-ratio-9-16 .video-thumbnail:not(.btn)::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-9-16 .video-thumbnail:not(.btn)::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(16 / 9 * 100%); }
  .video-container.aspect-ratio-4-3::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-4-3::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(3 / 4 * 100%); }
  .video-container.aspect-ratio-4-3 .video-thumbnail:not(.btn)::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-4-3 .video-thumbnail:not(.btn)::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(3 / 4 * 100%); }
  .video-container.aspect-ratio-3-4::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-3-4::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(4 / 3 * 100%); }
  .video-container.aspect-ratio-3-4 .video-thumbnail:not(.btn)::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-3-4 .video-thumbnail:not(.btn)::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(4 / 3 * 100%); }
  .video-container.aspect-ratio-1-1::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-1-1::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(1 / 1 * 100%); }
  .video-container.aspect-ratio-1-1 .video-thumbnail:not(.btn)::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-1-1 .video-thumbnail:not(.btn)::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(1 / 1 * 100%); }
  .video-container .video-player,
  .video-container .video-player iframe,
  .video-container video.vjs-tech {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .video-container .video-thumbnail:not(.btn) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    opacity: 1;
    transition: opacity 200ms;
    z-index: 1; }
    .video-container .video-thumbnail:not(.btn).faded {
      opacity: 0;
      transition: opacity 200ms; }
      .video-container .video-thumbnail:not(.btn).faded .video-thumbnail-button:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 50px;
        color: #FFFFFF;
        font-weight: normal; }
      .video-container .video-thumbnail:not(.btn).faded .video-thumbnail-button[class*="btn-"]:not(.title) {
        padding-left: calc(var(--button-padding-x) + 20px); }
        .video-container .video-thumbnail:not(.btn).faded .video-thumbnail-button[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #FFFFFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        .video-container .video-thumbnail:not(.btn).faded .video-thumbnail-button[class*="btn-"]:not(.title).btn-sm, .video-container .video-thumbnail:not(.btn).faded .btn-group-sm > .video-thumbnail-button.btn[class*="btn-"]:not(.title) {
          padding-left: calc(20px + 20px); }
          .video-container .video-thumbnail:not(.btn).faded .video-thumbnail-button[class*="btn-"]:not(.title).btn-sm:before, .video-container .video-thumbnail:not(.btn).faded .btn-group-sm > .video-thumbnail-button.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #FFFFFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .video-container .video-thumbnail:not(.btn).faded .video-thumbnail-button[class*="btn-"]:not(.title) [class*="icon-"],
        .video-container .video-thumbnail:not(.btn).faded .video-thumbnail-button[class*="btn-"]:not(.title) [class^="icon-"],
        .video-container .video-thumbnail:not(.btn).faded .video-thumbnail-button[class*="btn-"]:not(.title) .fa {
          display: none; }
      .video-container .video-thumbnail:not(.btn).faded .video-thumbnail-image {
        opacity: 0;
        pointer-events: none; }
      .video-container .video-thumbnail:not(.btn).faded:hover {
        opacity: 1; }
    .video-container .video-thumbnail:not(.btn):focus .video-thumbnail-button:before, .video-container .video-thumbnail:not(.btn):hover:not(.faded) .video-thumbnail-button:before {
      color: var(--brand-secondary);
      transform: scale(1.1); }
  .video-container.has-controls .video-thumbnail.faded {
    pointer-events: none; }
    .video-container.has-controls .video-thumbnail.faded .video-thumbnail-button {
      opacity: 0; }
  .video-container.has-controls .video-js .vjs-big-play-button {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    left: auto;
    border: none;
    background: transparent;
    width: 50px;
    height: 50px; }
    .video-container.has-controls .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 50px;
      color: #FFFFFF;
      font-weight: normal; }
    .video-container.has-controls .video-js .vjs-big-play-button .vjs-icon-placeholder[class*="btn-"]:not(.title) {
      padding-left: calc(var(--button-padding-x) + 20px); }
      .video-container.has-controls .video-js .vjs-big-play-button .vjs-icon-placeholder[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #FFFFFF;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .video-container.has-controls .video-js .vjs-big-play-button .vjs-icon-placeholder[class*="btn-"]:not(.title).btn-sm, .video-container.has-controls .video-js .vjs-big-play-button .btn-group-sm > .vjs-icon-placeholder.btn[class*="btn-"]:not(.title) {
        padding-left: calc(20px + 20px); }
        .video-container.has-controls .video-js .vjs-big-play-button .vjs-icon-placeholder[class*="btn-"]:not(.title).btn-sm:before, .video-container.has-controls .video-js .vjs-big-play-button .btn-group-sm > .vjs-icon-placeholder.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFFFFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .video-container.has-controls .video-js .vjs-big-play-button .vjs-icon-placeholder[class*="btn-"]:not(.title) [class*="icon-"],
      .video-container.has-controls .video-js .vjs-big-play-button .vjs-icon-placeholder[class*="btn-"]:not(.title) [class^="icon-"],
      .video-container.has-controls .video-js .vjs-big-play-button .vjs-icon-placeholder[class*="btn-"]:not(.title) .fa {
        display: none; }
    .video-container.has-controls .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
      transition: all 200ms ease-in-out; }
  .video-container.has-controls .video-js:hover .vjs-icon-placeholder::before {
    color: var(--brand-secondary);
    transform: scale(1.1); }
  .video-container.has-controls .video-js .vjs-poster {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; }
  .video-container .video-thumbnail-button {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%); }
    .video-container .video-thumbnail-button:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 50px;
      color: #FFFFFF;
      font-weight: normal; }
    .video-container .video-thumbnail-button[class*="btn-"]:not(.title) {
      padding-left: calc(var(--button-padding-x) + 20px); }
      .video-container .video-thumbnail-button[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #FFFFFF;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .video-container .video-thumbnail-button[class*="btn-"]:not(.title).btn-sm, .video-container .btn-group-sm > .video-thumbnail-button.btn[class*="btn-"]:not(.title) {
        padding-left: calc(20px + 20px); }
        .video-container .video-thumbnail-button[class*="btn-"]:not(.title).btn-sm:before, .video-container .btn-group-sm > .video-thumbnail-button.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFFFFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .video-container .video-thumbnail-button[class*="btn-"]:not(.title) [class*="icon-"],
      .video-container .video-thumbnail-button[class*="btn-"]:not(.title) [class^="icon-"],
      .video-container .video-thumbnail-button[class*="btn-"]:not(.title) .fa {
        display: none; }
    .video-container .video-thumbnail-button:before {
      transition: all 200ms ease-in-out; }
  .video-container .video-thumbnail-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; }
    .video-container .video-thumbnail-image.background-image-tablet, .video-container .video-thumbnail-image.background-image-desktop {
      display: none; }
    @media (min-width: 769px) {
      .video-container .video-thumbnail-image.background-image-mobile, .video-container .video-thumbnail-image.background-image-desktop {
        display: none; }
      .video-container .video-thumbnail-image.background-image-tablet {
        display: block; } }
    @media (min-width: 1024px) {
      .video-container .video-thumbnail-image.background-image-mobile, .video-container .video-thumbnail-image.background-image-tablet {
        display: none; }
      .video-container .video-thumbnail-image.background-image-desktop {
        display: block; } }

.video-modal .modal-dialog {
  max-width: none;
  margin: 8px; }
  @media (min-width: 1024px) {
    .video-modal .modal-dialog {
      max-width: 900px;
      margin: 32px auto; } }
  @media (min-width: 1440px) {
    .video-modal .modal-dialog {
      max-width: 1140px; } }

.video-modal .modal-body {
  padding: 0; }

.video-modal .video-player {
  width: 100%; }

.product-list-enhancements-header {
  border-bottom: 1px solid #C5C5C5;
  margin-bottom: 24px;
  display: flex;
  position: relative;
  top: 0;
  z-index: 1;
  background: white;
  padding: 16px 0; }
  .product-list-enhancements-header-name {
    display: flex;
    justify-content: center; }
  .product-list-enhancements-header-back:after {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 14px;
    color: #767676;
    font-weight: normal; }
  .product-list-enhancements-header-back[class*="btn-"]:not(.title) {
    padding-right: calc(var(--button-padding-x) + 20px); }
    .product-list-enhancements-header-back[class*="btn-"]:not(.title):after {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: #767676;
      font-weight: normal;
      position: absolute;
      transform: translateX(10px); }
    .product-list-enhancements-header-back[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .product-list-enhancements-header-back.btn[class*="btn-"]:not(.title) {
      padding-right: calc(20px + 20px); }
      .product-list-enhancements-header-back[class*="btn-"]:not(.title).btn-sm:after, .btn-group-sm > .product-list-enhancements-header-back.btn[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #767676;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
    .product-list-enhancements-header-back[class*="btn-"]:not(.title) [class*="icon-"],
    .product-list-enhancements-header-back[class*="btn-"]:not(.title) [class^="icon-"],
    .product-list-enhancements-header-back[class*="btn-"]:not(.title) .fa {
      display: none; }

.product-list-enhancements-create-list-btn {
  display: flex;
  text-transform: uppercase;
  font-weight: 400;
  justify-content: center;
  align-items: center; }

.product-list-enhancements-create-list-form {
  display: flex;
  flex-direction: column;
  padding: 0 0 30px 0; }
  .product-list-enhancements-create-list-form .form-group {
    margin-top: 0;
    margin-bottom: 20px; }

.product-list-enhancements-toggle-product {
  white-space: nowrap;
  text-decoration: none; }
  .product-list-enhancements-toggle-product:hover {
    text-decoration: none; }
  .product-list-enhancements-toggle-product:not(.move) {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
    z-index: 50; }
    @media (min-width: 544px) {
      .product-list-enhancements-toggle-product:not(.move) {
        top: 10px;
        right: 10px; } }
  .product-list-enhancements-toggle-product .product-list-enhancements-icon {
    width: 30px;
    height: 30px;
    line-height: 30px;
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: none;
    border-radius: 50%; }
    .product-list-enhancements-toggle-product .product-list-enhancements-icon:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.7);
      font-weight: normal; }
    .product-list-enhancements-toggle-product .product-list-enhancements-icon[class*="btn-"]:not(.title) {
      padding-left: calc(var(--button-padding-x) + 20px); }
      .product-list-enhancements-toggle-product .product-list-enhancements-icon[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.7);
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .product-list-enhancements-toggle-product .product-list-enhancements-icon[class*="btn-"]:not(.title).btn-sm, .product-list-enhancements-toggle-product .btn-group-sm > .product-list-enhancements-icon.btn[class*="btn-"]:not(.title) {
        padding-left: calc(20px + 20px); }
        .product-list-enhancements-toggle-product .product-list-enhancements-icon[class*="btn-"]:not(.title).btn-sm:before, .product-list-enhancements-toggle-product .btn-group-sm > .product-list-enhancements-icon.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: rgba(0, 0, 0, 0.7);
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .product-list-enhancements-toggle-product .product-list-enhancements-icon[class*="btn-"]:not(.title) [class*="icon-"],
      .product-list-enhancements-toggle-product .product-list-enhancements-icon[class*="btn-"]:not(.title) [class^="icon-"],
      .product-list-enhancements-toggle-product .product-list-enhancements-icon[class*="btn-"]:not(.title) .fa {
        display: none; }
    .product-list-enhancements-toggle-product .product-list-enhancements-icon.selected:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.7);
      font-weight: normal; }
    .product-list-enhancements-toggle-product .product-list-enhancements-icon.selected[class*="btn-"]:not(.title) {
      padding-left: calc(var(--button-padding-x) + 20px); }
      .product-list-enhancements-toggle-product .product-list-enhancements-icon.selected[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.7);
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .product-list-enhancements-toggle-product .product-list-enhancements-icon.selected[class*="btn-"]:not(.title).btn-sm, .product-list-enhancements-toggle-product .btn-group-sm > .product-list-enhancements-icon.selected.btn[class*="btn-"]:not(.title) {
        padding-left: calc(20px + 20px); }
        .product-list-enhancements-toggle-product .product-list-enhancements-icon.selected[class*="btn-"]:not(.title).btn-sm:before, .product-list-enhancements-toggle-product .btn-group-sm > .product-list-enhancements-icon.selected.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: rgba(0, 0, 0, 0.7);
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .product-list-enhancements-toggle-product .product-list-enhancements-icon.selected[class*="btn-"]:not(.title) [class*="icon-"],
      .product-list-enhancements-toggle-product .product-list-enhancements-icon.selected[class*="btn-"]:not(.title) [class^="icon-"],
      .product-list-enhancements-toggle-product .product-list-enhancements-icon.selected[class*="btn-"]:not(.title) .fa {
        display: none; }
    .product-list-enhancements-toggle-product .product-list-enhancements-icon.before::before {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      padding-top: 1px;
      padding-left: 1px; }

.product-list-enhancements-message-empty {
  display: flex;
  text-align: center; }

.product-list-enhancements-card-list {
  background-color: #FFFFFF; }
  .product-list-enhancements-card-list .card-header {
    background-color: transparent;
    border-bottom: 1px solid #C5C5C5;
    padding: 16px 20px; }
  .product-list-enhancements-card-list-header-link {
    text-decoration: none; }
  .product-list-enhancements-card-list-header-edit, .product-list-enhancements-card-list-header-delete {
    text-decoration: underline; }
    .product-list-enhancements-card-list-header-edit:hover, .product-list-enhancements-card-list-header-edit:focus, .product-list-enhancements-card-list-header-delete:hover, .product-list-enhancements-card-list-header-delete:focus {
      color: #767676; }
  .product-list-enhancements-card-list-name {
    text-transform: capitalize; }
  .product-list-enhancements-card-list-body {
    display: flex;
    gap: 16px;
    flex-wrap: wrap; }
    .card.product-list-enhancements-card-list .product-list-enhancements-card-list-body {
      padding: 20px; }
    .product-list-enhancements-card-list-body-empty {
      gap: 0; }
      .product-list-enhancements-card-list-body-empty-message {
        text-align: center; }
    .product-list-enhancements-card-list-body-image, .product-list-enhancements-card-list-body-image-blank,
    .product-list-enhancements-card-list-body .extra-items-block {
      flex: 0 1 auto;
      width: 100%;
      max-width: calc((100% / 4) - ((56px) /  4)); }
    .product-list-enhancements-card-list-body-image {
      height: auto; }
  .product-list-enhancements-card-list.is-public {
    overflow: hidden; }
    .product-list-enhancements-card-list.is-public:after {
      content: " PUBLIC";
      font-family: "icomoon";
      display: inline-block;
      font-size: 10px;
      color: #FFFFFF;
      font-weight: normal; }
    .product-list-enhancements-card-list.is-public[class*="btn-"]:not(.title) {
      padding-right: calc(var(--button-padding-x) + 20px); }
      .product-list-enhancements-card-list.is-public[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #FFFFFF;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .product-list-enhancements-card-list.is-public[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .product-list-enhancements-card-list.is-public.btn[class*="btn-"]:not(.title) {
        padding-right: calc(20px + 20px); }
        .product-list-enhancements-card-list.is-public[class*="btn-"]:not(.title).btn-sm:after, .btn-group-sm > .product-list-enhancements-card-list.is-public.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFFFFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .product-list-enhancements-card-list.is-public[class*="btn-"]:not(.title) [class*="icon-"],
      .product-list-enhancements-card-list.is-public[class*="btn-"]:not(.title) [class^="icon-"],
      .product-list-enhancements-card-list.is-public[class*="btn-"]:not(.title) .fa {
        display: none; }
    .product-list-enhancements-card-list.is-public:after {
      letter-spacing: 3px;
      font-weight: bold;
      font: bold 10px 'icomoon', helvetica, sans-serif;
      background-color: var(--brand-secondary);
      padding: 5px 10px calc(5px - 1px) 13px;
      position: absolute;
      bottom: 0;
      right: 0;
      border-radius: 5px 0 var(--border-radius) 0;
      margin-right: -65px;
      transition: margin 200ms ease-in-out; }
    .product-list-enhancements-card-list.is-public:hover:after {
      margin-right: 0;
      font: bold  10px 'icomoon', helvetica, sans-serif; }

.product-list-enhancements-card-product {
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  padding: 16px; }
  .product-list-enhancements-card-product-toggle:after {
    position: absolute;
    content: '';
    display: flex;
    height: 20px;
    width: 20px;
    background-color: #FFFFFF;
    border: 1px solid #BEBEBE;
    border-radius: var(--border-radius);
    top: 10px;
    right: 10px;
    justify-content: center;
    align-items: center; }
  .product-list-enhancements-card-product-toggle.selected:after {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 10px;
    color: #FFFFFF;
    font-weight: normal; }
  .product-list-enhancements-card-product-toggle.selected[class*="btn-"]:not(.title) {
    padding-right: calc(var(--button-padding-x) + 20px); }
    .product-list-enhancements-card-product-toggle.selected[class*="btn-"]:not(.title):after {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: #FFFFFF;
      font-weight: normal;
      position: absolute;
      transform: translateX(10px); }
    .product-list-enhancements-card-product-toggle.selected[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .product-list-enhancements-card-product-toggle.selected.btn[class*="btn-"]:not(.title) {
      padding-right: calc(20px + 20px); }
      .product-list-enhancements-card-product-toggle.selected[class*="btn-"]:not(.title).btn-sm:after, .btn-group-sm > .product-list-enhancements-card-product-toggle.selected.btn[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #FFFFFF;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
    .product-list-enhancements-card-product-toggle.selected[class*="btn-"]:not(.title) [class*="icon-"],
    .product-list-enhancements-card-product-toggle.selected[class*="btn-"]:not(.title) [class^="icon-"],
    .product-list-enhancements-card-product-toggle.selected[class*="btn-"]:not(.title) .fa {
      display: none; }
  .product-list-enhancements-card-product-toggle.selected:after {
    background-color: var(--brand-primary);
    border-color: #000000;
    display: flex; }
  .product-list-enhancements-card-product-quantity {
    text-align: center; }
  .product-list-enhancements-card-product-image {
    width: 100%;
    margin-bottom: 16px; }
  .product-list-enhancements-card-product-add, .product-list-enhancements-card-product-remove {
    width: 100%; }
  .product-list-enhancements-card-product-form-quantity {
    position: relative;
    margin-bottom: 16px; }
  .product-list-enhancements-card-product .product-tile .quick-action-btns {
    bottom: unset;
    margin-top: calc( 0px - var(--button-padding-y) - 18px - var(--button-padding-y) - 16px); }
  .product-list-enhancements-card-product .price {
    flex-grow: 1; }
    .product-list-enhancements-card-product .price .price-del {
      order: 2; }
    .product-list-enhancements-card-product .price span del ~ .sales {
      padding-right: 0; }

.product-list-enhancements-modal-delete-list .modal-footer > *,
.product-list-enhancements-modal-delete-product .modal-footer > *,
.product-list-enhancements-modal-delete-products .modal-footer > * {
  flex: auto; }

.product-list-enhancements-edit-list-form .modal-footer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 32px; }
  .product-list-enhancements-edit-list-form .modal-footer > .btn {
    width: 100%; }
    .product-list-enhancements-edit-list-form .modal-footer > .btn + .btn {
      margin-left: 0; }

.product-list-enhancements-modal-share-list .form-group {
  margin-top: 0; }
  .product-list-enhancements-modal-share-list .form-group .form-control {
    border-right: 0px; }

.product-list-enhancements-modal-show-lists {
  z-index: 1050; }
  .product-list-enhancements-modal-show-lists .product-list-enhancements-create-list-btn {
    margin-bottom: 16px; }
  .product-list-enhancements-modal-show-lists .modal-body {
    padding: 0; }
  .product-list-enhancements-modal-show-lists-header {
    position: relative;
    display: flex; }
    .product-list-enhancements-modal-show-lists-header-close:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 14px;
      color: #FFFFFF;
      font-weight: normal; }
    .product-list-enhancements-modal-show-lists-header-close[class*="btn-"]:not(.title) {
      padding-right: calc(var(--button-padding-x) + 20px); }
      .product-list-enhancements-modal-show-lists-header-close[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #FFFFFF;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .product-list-enhancements-modal-show-lists-header-close[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .product-list-enhancements-modal-show-lists-header-close.btn[class*="btn-"]:not(.title) {
        padding-right: calc(20px + 20px); }
        .product-list-enhancements-modal-show-lists-header-close[class*="btn-"]:not(.title).btn-sm:after, .btn-group-sm > .product-list-enhancements-modal-show-lists-header-close.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFFFFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .product-list-enhancements-modal-show-lists-header-close[class*="btn-"]:not(.title) [class*="icon-"],
      .product-list-enhancements-modal-show-lists-header-close[class*="btn-"]:not(.title) [class^="icon-"],
      .product-list-enhancements-modal-show-lists-header-close[class*="btn-"]:not(.title) .fa {
        display: none; }
    .product-list-enhancements-modal-show-lists-header-back:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 14px;
      color: #FFFFFF;
      font-weight: normal; }
    .product-list-enhancements-modal-show-lists-header-back[class*="btn-"]:not(.title) {
      padding-right: calc(var(--button-padding-x) + 20px); }
      .product-list-enhancements-modal-show-lists-header-back[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #FFFFFF;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .product-list-enhancements-modal-show-lists-header-back[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .product-list-enhancements-modal-show-lists-header-back.btn[class*="btn-"]:not(.title) {
        padding-right: calc(20px + 20px); }
        .product-list-enhancements-modal-show-lists-header-back[class*="btn-"]:not(.title).btn-sm:after, .btn-group-sm > .product-list-enhancements-modal-show-lists-header-back.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFFFFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .product-list-enhancements-modal-show-lists-header-back[class*="btn-"]:not(.title) [class*="icon-"],
      .product-list-enhancements-modal-show-lists-header-back[class*="btn-"]:not(.title) [class^="icon-"],
      .product-list-enhancements-modal-show-lists-header-back[class*="btn-"]:not(.title) .fa {
        display: none; }
    .product-list-enhancements-modal-show-lists-header-close, .product-list-enhancements-modal-show-lists-header-back {
      transition: all 200ms; }
      .product-list-enhancements-modal-show-lists-header-close.hide, .product-list-enhancements-modal-show-lists-header-back.hide {
        opacity: 0;
        z-index: -1; }
      .product-list-enhancements-modal-show-lists-header-close.show, .product-list-enhancements-modal-show-lists-header-back.show {
        opacity: 1;
        z-index: 0; }
    .product-list-enhancements-modal-show-lists-header-title {
      flex-grow: 1;
      text-align: center; }
  .product-list-enhancements-modal-show-lists-keep {
    margin-bottom: 16px;
    padding: 0; }
  .product-list-enhancements-modal-show-lists-toggle {
    padding: 0; }
    .product-list-enhancements-modal-show-lists-toggle-list-form {
      padding-top: 32px; }
    .product-list-enhancements-modal-show-lists-toggle-lists-options {
      display: flex;
      flex-direction: column;
      padding: 32px 0; }
    .product-list-enhancements-modal-show-lists-toggle-lists .move,
    .product-list-enhancements-modal-show-lists-toggle-lists .toggle {
      min-height: calc(56px);
      display: flex;
      text-transform: none;
      font-weight: 400;
      align-items: center;
      margin-bottom: 16px; }
      .product-list-enhancements-modal-show-lists-toggle-lists .move:after,
      .product-list-enhancements-modal-show-lists-toggle-lists .toggle:after {
        margin-left: auto; }
      .product-list-enhancements-modal-show-lists-toggle-lists .move.selected,
      .product-list-enhancements-modal-show-lists-toggle-lists .toggle.selected {
        border-color: #C5C5C5; }
        .product-list-enhancements-modal-show-lists-toggle-lists .move.selected div,
        .product-list-enhancements-modal-show-lists-toggle-lists .toggle.selected div {
          color: #BEBEBE; }
      .product-list-enhancements-modal-show-lists-toggle-lists .move img,
      .product-list-enhancements-modal-show-lists-toggle-lists .toggle img {
        width: 40px;
        height: 40px;
        margin-right: 16px; }
      .product-list-enhancements-modal-show-lists-toggle-lists .move div,
      .product-list-enhancements-modal-show-lists-toggle-lists .toggle div {
        display: flex;
        flex-direction: column;
        text-align: left; }
        .product-list-enhancements-modal-show-lists-toggle-lists .move div small,
        .product-list-enhancements-modal-show-lists-toggle-lists .toggle div small {
          color: #BEBEBE;
          font-size: calc(var(--base-font-size) - 2px); }

.product-list-enhancements-overlay-detail-selected {
  transition-property: all;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transform: translateY(-100%);
  background-color: #FFFFFF;
  position: absolute;
  opacity: 0; }
  .product-list-enhancements-overlay-detail-selected.open {
    transition-timing-function: ease-in;
    transform: translateY(0);
    opacity: 1; }

.product-list-enhancements-toast {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  text-align: center;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  position: fixed;
  top: 0;
  padding: 50px 16px 16px;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  z-index: 1100;
  padding: 16px;
  top: 40px; }
  .product-list-enhancements-toast.show {
    display: block; }
  .product-list-enhancements-toast:not(.alert-dismissible) {
    animation: fadeIn 200ms, fadeOut 200ms linear calc(5s - 200ms); }
  .product-list-enhancements-toast.alert-dismissible {
    animation: fadeIn 200ms;
    padding-right: 50px; }
    .product-list-enhancements-toast.alert-dismissible .close {
      padding: 16px; }
  @media (max-width: 768.98px) {
    .product-list-enhancements-toast {
      width: calc(100vw - 32px); } }
  .product-list-enhancements-toast.alert-dismissible {
    padding-right: 0; }
  .product-list-enhancements-toast:focus {
    outline: 2px solid #767676; }
  .product-list-enhancements-toast.hide {
    z-index: -1;
    opacity: 0;
    margin-top: calc(-40px + -16px);
    transition: all 200ms; }
  .product-list-enhancements-toast.show {
    z-index: 1100;
    opacity: 1;
    margin-top: 0px; }
  .product-list-enhancements-toast-content {
    display: flex;
    gap: 10px; }
  .product-list-enhancements-toast-message-wrapper {
    align-items: center;
    display: flex;
    flex: auto; }
    .product-list-enhancements-toast-message-wrapper .message {
      color: #767676;
      display: flex;
      flex: auto;
      gap: 5px; }
      .product-list-enhancements-toast-message-wrapper .message:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 14px;
        color: #767676;
        font-weight: normal; }
      .product-list-enhancements-toast-message-wrapper .message[class*="btn-"]:not(.title) {
        padding-left: calc(var(--button-padding-x) + 20px); }
        .product-list-enhancements-toast-message-wrapper .message[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #767676;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        .product-list-enhancements-toast-message-wrapper .message[class*="btn-"]:not(.title).btn-sm, .product-list-enhancements-toast-message-wrapper .btn-group-sm > .message.btn[class*="btn-"]:not(.title) {
          padding-left: calc(20px + 20px); }
          .product-list-enhancements-toast-message-wrapper .message[class*="btn-"]:not(.title).btn-sm:before, .product-list-enhancements-toast-message-wrapper .btn-group-sm > .message.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #767676;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .product-list-enhancements-toast-message-wrapper .message[class*="btn-"]:not(.title) [class*="icon-"],
        .product-list-enhancements-toast-message-wrapper .message[class*="btn-"]:not(.title) [class^="icon-"],
        .product-list-enhancements-toast-message-wrapper .message[class*="btn-"]:not(.title) .fa {
          display: none; }
      .product-list-enhancements-toast-message-wrapper .message .undo {
        text-decoration: underline; }
        .product-list-enhancements-toast-message-wrapper .message .undo:hover, .product-list-enhancements-toast-message-wrapper .message .undo:focus {
          color: #000000; }
      .product-list-enhancements-toast-message-wrapper .message .manage:before,
      .product-list-enhancements-toast-message-wrapper .message .undo:before {
        content: "|";
        padding-right: 8px;
        text-decoration: none;
        display: inline-block; }
  .product-list-enhancements-toast-dismiss:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 14px;
    color: #767676;
    font-weight: normal; }
  .product-list-enhancements-toast-dismiss[class*="btn-"]:not(.title) {
    padding-left: calc(var(--button-padding-x) + 20px); }
    .product-list-enhancements-toast-dismiss[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: #767676;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .product-list-enhancements-toast-dismiss[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .product-list-enhancements-toast-dismiss.btn[class*="btn-"]:not(.title) {
      padding-left: calc(20px + 20px); }
      .product-list-enhancements-toast-dismiss[class*="btn-"]:not(.title).btn-sm:before, .btn-group-sm > .product-list-enhancements-toast-dismiss.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #767676;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .product-list-enhancements-toast-dismiss[class*="btn-"]:not(.title) [class*="icon-"],
    .product-list-enhancements-toast-dismiss[class*="btn-"]:not(.title) [class^="icon-"],
    .product-list-enhancements-toast-dismiss[class*="btn-"]:not(.title) .fa {
      display: none; }

.product-list-enhancements-landing-pagination {
  text-align: center;
  margin-bottom: 16px; }

.product-list-enhancements-landing .lists {
  gap: 20px 4px; }
  .product-list-enhancements-landing .lists .list-grid-item {
    flex-basis: 100%;
    overflow: hidden; }
    @media (min-width: 769px) {
      .product-list-enhancements-landing .lists .list-grid-item {
        flex-basis: 33%; } }

.product-list-enhancements-landing .list-container {
  min-height: 60vh; }
  .product-list-enhancements-landing .list-container .notes.hide {
    opacity: 0;
    max-height: 0;
    transition: all 200ms;
    padding-left: 20px;
    padding-right: 20px; }
  .product-list-enhancements-landing .list-container .notes.show {
    opacity: 1;
    max-height: 100%;
    padding-top: 16px; }

@media (min-width: 769px) {
  .product-list-enhancements-landing .product-list-enhancements-create-list-btn {
    max-width: 25vw; } }

.product-list-enhancements-landing .message {
  display: flex;
  text-align: center; }

.response .wishlist-search-results .card:hover {
  background-color: #F2F2F2; }
  .response .wishlist-search-results .card:hover .card-header {
    background-color: #F2F2F2; }

.response .wishlist-search-results .view-wishlist {
  cursor: pointer; }

.product-list-enhancements-detail {
  margin-bottom: 60px;
  min-height: 30vh; }
  .product-list-enhancements-detail-nav {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: ease-in; }
    .product-list-enhancements-detail-nav .action-btns {
      transition-property: all;
      transition-duration: 200ms;
      transition-timing-function: ease-in;
      opacity: 0;
      margin-top: 32px; }
    .product-list-enhancements-detail-nav.open {
      transition-timing-function: ease-in; }
      .product-list-enhancements-detail-nav.open .action-btns {
        opacity: 1;
        transition-timing-function: ease-in;
        margin-top: 0; }
  .product-list-enhancements-detail .public-toggle label:before, .product-list-enhancements-detail .public-toggle label:after {
    margin-top: -2px; }
  .product-list-enhancements-detail-name {
    background-color: #FFFFFF; }
  .product-list-enhancements-detail-deselect {
    align-items: center;
    display: flex;
    text-decoration: none;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: ease-in; }
    .product-list-enhancements-detail-deselect:hover {
      text-decoration: underline; }
    .product-list-enhancements-detail-deselect.hide {
      opacity: 0;
      pointer-events: none; }
    .product-list-enhancements-detail-deselect.show {
      transition-timing-function: ease-in;
      opacity: 1;
      pointer-events: auto; }
  .product-list-enhancements-detail-share:disabled {
    pointer-events: auto;
    cursor: unset; }
  .product-list-enhancements-detail .product-list-enhancements-header-name {
    text-transform: capitalize; }
  .product-list-enhancements-detail .product-tile .quickview.btn.show {
    opacity: 1; }

.product-list-enhancements-detail-notes-form-group {
  overflow: hidden; }

.product-list-enhancements-detail-notes-text {
  height: 100px;
  transition-property: all;
  transition-duration: 200ms;
  transition-timing-function: ease-in;
  margin-top: -100px; }
  .product-list-enhancements-detail-notes-text.open {
    transition-timing-function: ease-in;
    margin-top: 0; }

.add-to-wishlist-messages {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  position: fixed;
  top: 0;
  padding: 50px 16px 16px;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  z-index: 1100; }

.wishlist {
  white-space: nowrap;
  text-decoration: none; }
  .wishlist:hover {
    text-decoration: none; }
  .wishlist:not(.move) {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
    z-index: 50; }
    @media (min-width: 544px) {
      .wishlist:not(.move) {
        top: 10px;
        right: 10px; } }
  .wishlist .wishlist-icon {
    width: 30px;
    height: 30px;
    line-height: 30px;
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: none;
    border-radius: 50%; }
    .wishlist .wishlist-icon:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.7);
      font-weight: normal; }
    .wishlist .wishlist-icon[class*="btn-"]:not(.title) {
      padding-left: calc(var(--button-padding-x) + 20px); }
      .wishlist .wishlist-icon[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.7);
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .wishlist .wishlist-icon[class*="btn-"]:not(.title).btn-sm, .wishlist .btn-group-sm > .wishlist-icon.btn[class*="btn-"]:not(.title) {
        padding-left: calc(20px + 20px); }
        .wishlist .wishlist-icon[class*="btn-"]:not(.title).btn-sm:before, .wishlist .btn-group-sm > .wishlist-icon.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: rgba(0, 0, 0, 0.7);
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .wishlist .wishlist-icon[class*="btn-"]:not(.title) [class*="icon-"],
      .wishlist .wishlist-icon[class*="btn-"]:not(.title) [class^="icon-"],
      .wishlist .wishlist-icon[class*="btn-"]:not(.title) .fa {
        display: none; }
    .wishlist .wishlist-icon.selected:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.7);
      font-weight: normal; }
    .wishlist .wishlist-icon.selected[class*="btn-"]:not(.title) {
      padding-left: calc(var(--button-padding-x) + 20px); }
      .wishlist .wishlist-icon.selected[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.7);
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .wishlist .wishlist-icon.selected[class*="btn-"]:not(.title).btn-sm, .wishlist .btn-group-sm > .wishlist-icon.selected.btn[class*="btn-"]:not(.title) {
        padding-left: calc(20px + 20px); }
        .wishlist .wishlist-icon.selected[class*="btn-"]:not(.title).btn-sm:before, .wishlist .btn-group-sm > .wishlist-icon.selected.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: rgba(0, 0, 0, 0.7);
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .wishlist .wishlist-icon.selected[class*="btn-"]:not(.title) [class*="icon-"],
      .wishlist .wishlist-icon.selected[class*="btn-"]:not(.title) [class^="icon-"],
      .wishlist .wishlist-icon.selected[class*="btn-"]:not(.title) .fa {
        display: none; }
    .wishlist .wishlist-icon.before::before {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      padding-top: 1px;
      padding-left: 1px; }

.wishlistItemCards .select-attributes-btn {
  white-space: nowrap; }

.wishlistItemCards .line-item-availability .line-item-attributes {
  white-space: normal; }

.btn-update-wishlist-product {
  width: 100%; }

.wishlist-remove-btn {
  font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
  border: none;
  border-radius: 50%;
  font-size: 0;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0 20px 20px 20px;
  cursor: pointer;
  z-index: 1;
  background-color: #FFFFFF;
  padding: 4px 6px;
  height: 22px;
  width: 22px;
  margin: 5px 0px 0px 20px;
  left: 0; }
  .wishlist-remove-btn:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 11px;
    color: inherit;
    font-weight: normal; }
  .wishlist-remove-btn[class*="btn-"]:not(.title) {
    padding-left: calc(var(--button-padding-x) + 20px); }
    .wishlist-remove-btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .wishlist-remove-btn[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .wishlist-remove-btn.btn[class*="btn-"]:not(.title) {
      padding-left: calc(20px + 20px); }
      .wishlist-remove-btn[class*="btn-"]:not(.title).btn-sm:before, .btn-group-sm > .wishlist-remove-btn.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .wishlist-remove-btn[class*="btn-"]:not(.title) [class*="icon-"],
    .wishlist-remove-btn[class*="btn-"]:not(.title) [class^="icon-"],
    .wishlist-remove-btn[class*="btn-"]:not(.title) .fa {
      display: none; }
  @media (min-width: 1440px) {
    .wishlist-remove-btn {
      margin-left: 28px; } }

.consent-drawer {
  position: fixed;
  display: block;
  align-items: center;
  bottom: 0;
  width: 100%;
  min-height: 85px;
  z-index: 2147483601;
  background-color: #F2F2F2;
  border-top: 1px solid var(--brand-tertiary); }
  @media (min-width: 769px) {
    .consent-drawer {
      display: flex; } }
  .consent-drawer p {
    margin: 4px;
    font-size: 12px;
    line-height: 12px; }
  .consent-drawer .consent-text-wrapper {
    display: block;
    width: 100%;
    padding: 10px 0 10px 14px; }
    @media (min-width: 769px) {
      .consent-drawer .consent-text-wrapper {
        width: auto;
        display: inline-block;
        padding: 16px; } }
  .consent-drawer .consent-button-wrapper {
    display: block;
    width: 100%;
    text-align: center;
    padding-bottom: 10px; }
    .consent-drawer .consent-button-wrapper button {
      width: 50%; }
    @media (min-width: 769px) {
      .consent-drawer .consent-button-wrapper {
        display: inline-block;
        width: auto;
        padding: 0 8px; }
        .consent-drawer .consent-button-wrapper button {
          width: auto; } }

.flex-grid-wrapper .grid-title:not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
  font-family: var(--h1-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
  font-size: var(--h1-mobile-size);
  line-height: 1.14;
  font-weight: 700;
  letter-spacing: 0.01em;
  text-transform: none;
  font-style: normal; }
  @media (min-width: 769px) {
    .flex-grid-wrapper .grid-title:not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
      font-size: var(--h1-tablet-size);
      line-height: 1.14;
      font-weight: 700;
      letter-spacing: 0.01em;
      text-transform: none;
      font-style: normal; } }
  @media (min-width: 1024px) {
    .flex-grid-wrapper .grid-title:not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
      font-size: var(--h1-desktop-size);
      line-height: 1.11;
      font-weight: 700;
      letter-spacing: 0.01em;
      text-transform: none;
      font-style: normal; } }

.flex-grid-wrapper .flex-grid {
  display: flex;
  flex-flow: row wrap; }
  .flex-grid-wrapper .flex-grid .flex-grid-item {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 32px; }
    @media (min-width: 1440px) {
      .flex-grid-wrapper .flex-grid .flex-grid-item {
        margin-bottom: 48px; } }
    .flex-grid-wrapper .flex-grid .flex-grid-item > * {
      width: 100%; }
  .flex-grid-wrapper .flex-grid.no-gutters .row {
    padding-left: 0;
    padding-right: 0; }
  .flex-grid-wrapper .flex-grid.no-gutters .flex-grid-item {
    margin-bottom: 0; }

.login-page {
  padding-top: 16px; }
  .login-page .login-nav-tabs {
    background: #FFFFFF;
    padding: 0;
    border-bottom: none;
    margin: -1px -1px 20px -1px; }
    .login-page .login-nav-tabs .nav-link {
      padding-top: 15px;
      padding-bottom: 11px; }
      .login-page .login-nav-tabs .nav-link:hover {
        color: var(--brand-primary); }
      .login-page .login-nav-tabs .nav-link.active {
        background: #FFFFFF;
        color: var(--brand-primary);
        border-color: #C5C5C5;
        border-width: 1px; }
      .login-page .login-nav-tabs .nav-link:not(.active) {
        border-bottom: 1px solid #C5C5C5;
        color: #767676;
        margin-bottom: 1px; }
  .login-page .login-form-nav .login-oauth {
    margin-top: 24px; }
  .login-page .login-form-nav .remember-me {
    margin-top: 20px; }
    .login-page .login-form-nav .remember-me .custom-control-label {
      margin-bottom: 0; }
  .login-page .login-form-nav .forgot-password {
    margin-top: 20px;
    font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
    font-weight: normal;
    font-size: var(--base-font-size);
    line-height: 1.4;
    margin-bottom: 6px;
    text-transform: none;
    letter-spacing: 0.1em; }
  .login-page .request-password-body {
    margin-bottom: 16px; }
  .login-page .privacy-policy {
    margin-top: 16px; }
  .login-page .login-nav-tabs .nav-link,
  .login-page .card-header.track-order-header {
    font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
    font-size: var(--base-font-size);
    font-weight: normal;
    letter-spacing: 0.1em;
    line-height: 1.4;
    text-decoration: none;
    text-transform: none; }

.account-page {
  padding-top: 16px;
  padding-bottom: 16px; }
  @media (min-width: 1024px) {
    .account-page .col-12 > .card:only-child .card-body > form {
      width: 750px;
      margin-left: auto;
      margin-right: auto; } }
  .account-page .card:not(:last-of-type) {
    margin-bottom: 0; }
    .account-page .card:not(:last-of-type) .card-body,
    .account-page .card:not(:last-of-type) .card-footer {
      border-bottom: 0; }
  .account-page .card-header + .card-footer a {
    color: inherit; }
  .account-page .dashboard-info,
  .account-page dl dt,
  .account-page .dashboard-cards-block-title,
  .account-page .dashboard-info {
    color: var(--base-font-color); }
  .account-page .dashboard-cards-block-title {
    margin-bottom: 16px; }
  .account-page .marketing-email-title {
    color: var(--base-font-color);
    margin-bottom: 16px;
    font-weight: normal; }
  .account-page dl {
    margin-bottom: 24px; }
    .account-page dl dd {
      color: #767676;
      margin-bottom: 0;
      margin-top: 1px; }
    .account-page dl:last-child {
      margin-bottom: 0; }
  .account-page .card-body.card-info-group p,
  .account-page .card-body .card-info-group p {
    color: #767676; }
  .account-page .card-body p {
    color: var(--base-font-color); }
  .account-page .address-card .card-body div {
    color: #767676; }
  .account-page .card-info-group p {
    margin-bottom: 5px; }
    .account-page .card-info-group p:last-child {
      margin-bottom: 0; }
  .account-page .card-info-group .wishlist-row {
    position: relative; }
    .account-page .card-info-group .wishlist-row:not(:last-child) {
      border-bottom: 1px solid #C5C5C5;
      margin-bottom: 20px;
      padding-bottom: 20px; }
  .account-page .tracking-consent {
    color: var(--brand-primary);
    font-weight: normal;
    text-decoration: none;
    cursor: pointer;
    display: inline-block; }
    .account-page .tracking-consent:hover {
      color: var(--brand-secondary);
      text-decoration: underline; }
    .dark-theme .account-page .tracking-consent {
      color: #FFFFFF; }
      .dark-theme .account-page .tracking-consent:hover {
        color: #FFFFFF; }
  .account-page .change-password-form .row,
  .account-page [name=change-password-form] .row,
  .account-page .address-form .row,
  .account-page .payment-form .row {
    margin-top: 24px; }
  .account-page .col-12 > .address-card:only-child .card-body,
  .account-page .col-12 > .payment-card:only-child .card-body {
    position: relative; }
    @media (min-width: 1024px) {
      .account-page .col-12 > .address-card:only-child .card-body,
      .account-page .col-12 > .payment-card:only-child .card-body {
        width: 100%; } }
  .account-page .card-make-default-link,
  .account-page .make-default-payment {
    margin-top: 16px; }
  .account-page .remove-btn {
    font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
    border: none;
    border-radius: 50%;
    font-size: 0;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0 20px 20px 20px;
    cursor: pointer;
    z-index: 1;
    background-color: #FFFFFF;
    padding: 4px 6px;
    height: 22px;
    width: 22px; }
    .account-page .remove-btn:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 11px;
      color: inherit;
      font-weight: normal; }
    .account-page .remove-btn[class*="btn-"]:not(.title) {
      padding-left: calc(var(--button-padding-x) + 20px); }
      .account-page .remove-btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .account-page .remove-btn[class*="btn-"]:not(.title).btn-sm, .account-page .btn-group-sm > .remove-btn.btn[class*="btn-"]:not(.title) {
        padding-left: calc(20px + 20px); }
        .account-page .remove-btn[class*="btn-"]:not(.title).btn-sm:before, .account-page .btn-group-sm > .remove-btn.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .account-page .remove-btn[class*="btn-"]:not(.title) [class*="icon-"],
      .account-page .remove-btn[class*="btn-"]:not(.title) [class^="icon-"],
      .account-page .remove-btn[class*="btn-"]:not(.title) .fa {
        display: none; }
  .account-page .product-summary-block .shipment-block span.ship-to-name,
  .account-page .product-summary-block .shipment-block span.ship-to-address1,
  .account-page .product-summary-block .shipment-block span.ship-to-address2,
  .account-page .product-summary-block .shipment-block span.ship-to-phone,
  .account-page .product-summary-block .shipment-block span.ship-to-city-st-zip {
    display: block; }
  .account-page .product-summary-block .shipment-block h3:not(:first-child) {
    margin-top: 16px; }

.order-card .order-image-col {
  min-width: 120px;
  max-width: 120px; }

.order-card .order-details-col {
  max-width: calc(100% - 120px);
  padding-left: 0; }
  @media (min-width: 544px) {
    .order-card .order-details-col {
      padding-left: 16px; } }

.cart-page .cart-error-messaging.cart-error {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  position: fixed;
  top: 0;
  padding: 50px 16px 16px;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  z-index: 1100; }

.cart-page .valid-cart-error {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  text-align: center; }
  .cart-page .valid-cart-error.show {
    display: block; }
  .cart-page .valid-cart-error:not(.alert-dismissible) {
    animation: fadeIn 200ms, fadeOut 200ms linear calc(5s - 200ms); }
  .cart-page .valid-cart-error.alert-dismissible {
    animation: fadeIn 200ms;
    padding-right: 50px; }
    .cart-page .valid-cart-error.alert-dismissible .close {
      padding: 16px; }

.cart-page .card {
  margin-bottom: -1px; }

.cart-page .cart-header {
  align-items: center;
  margin-bottom: 18px;
  margin-top: 40px; }
  .cart-page .cart-header .number-of-items {
    font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
    font-size: var(--base-font-size);
    font-weight: normal;
    line-height: 1.4;
    letter-spacing: 0.1em;
    margin: 0; }

.cart-page .product-card-footer {
  padding-top: 0; }

.cart-page .product-info .remove-btn {
  font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
  border: none;
  border-radius: 50%;
  font-size: 0;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0 20px 20px 20px;
  cursor: pointer;
  z-index: 1;
  background-color: #FFFFFF;
  padding: 4px 6px;
  height: 22px;
  width: 22px;
  margin: 25px 25px 25px 25px;
  left: 0;
  right: auto; }
  .cart-page .product-info .remove-btn:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 11px;
    color: inherit;
    font-weight: normal; }
  .cart-page .product-info .remove-btn[class*="btn-"]:not(.title) {
    padding-left: calc(var(--button-padding-x) + 20px); }
    .cart-page .product-info .remove-btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .cart-page .product-info .remove-btn[class*="btn-"]:not(.title).btn-sm, .cart-page .product-info .btn-group-sm > .remove-btn.btn[class*="btn-"]:not(.title) {
      padding-left: calc(20px + 20px); }
      .cart-page .product-info .remove-btn[class*="btn-"]:not(.title).btn-sm:before, .cart-page .product-info .btn-group-sm > .remove-btn.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .cart-page .product-info .remove-btn[class*="btn-"]:not(.title) [class*="icon-"],
    .cart-page .product-info .remove-btn[class*="btn-"]:not(.title) [class^="icon-"],
    .cart-page .product-info .remove-btn[class*="btn-"]:not(.title) .fa {
      display: none; }

.cart-page .product-info label {
  font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
  font-weight: normal;
  font-size: var(--base-font-size);
  line-height: 1.4;
  margin-bottom: 6px;
  text-transform: none;
  letter-spacing: 0.1em;
  color: #767676; }

.cart-page .product-info .product-edit,
.cart-page .product-info .line-item-promo {
  margin-top: 5px; }
  .cart-page .product-info .product-edit a,
  .cart-page .product-info .line-item-promo a {
    display: block; }
  .cart-page .product-info .product-edit a + a,
  .cart-page .product-info .line-item-promo a + a {
    margin-top: 5px; }

.cart-page .product-info .bonus-product-button {
  margin: 16px 0 0; }

.cart-page .product-info .item-image {
  width: 100px; }
  @media (min-width: 1024px) {
    .cart-page .product-info .item-image {
      width: 176px; } }

@media (min-width: 1024px) {
  .cart-page .item-attributes {
    width: calc(100% - 176px); } }

.cart-page .line-item-name,
.cart-page .line-item-attributes {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%; }

.cart-page .single-approaching-discount {
  background-color: #FFFFFF;
  border: 1px solid #C5C5C5;
  border-radius: var(--border-radius);
  margin-bottom: 16px;
  padding: 20px 20px 20px 20px;
  color: #008827; }

.cart-page .bonus-line-item-msg {
  margin-top: 16px;
  margin-left: 0; }

.cart-page .bundled-line-item:not(:empty) {
  border-top: 1px solid #C5C5C5;
  padding: 16px 0px; }

.cart-page .bundled-line-item .item-attributes {
  margin-left: 0; }

@media (min-width: 1024px) {
  .cart-page .bundled-line-item .line-item-total-price {
    padding-right: 16px; } }

@media (min-width: 1440px) {
  .cart-page .bundled-line-item .line-item-total-price {
    padding-right: 24px; } }

.cart-page .coupon-price-adjustment {
  margin-top: 16px;
  background-color: #FFFFFF;
  border: 1px solid #C5C5C5;
  border-radius: var(--border-radius);
  padding: 12px 20px 14px 20px;
  font-size: var(--base-font-size);
  line-height: 16px;
  letter-spacing: 0.1em;
  position: relative; }
  .cart-page .coupon-price-adjustment .coupon-applied,
  .cart-page .coupon-price-adjustment .coupon-not-applied {
    letter-spacing: 0.1em;
    font-size: calc(var(--base-font-size) - 2px); }
    @media (min-width: 1024px) {
      .cart-page .coupon-price-adjustment .coupon-applied,
      .cart-page .coupon-price-adjustment .coupon-not-applied {
        font-size: calc(var(--base-font-size) - 1px); } }
  .cart-page .coupon-price-adjustment .coupon-applied {
    color: #008827; }
  .cart-page .coupon-price-adjustment .coupon-not-applied {
    color: #A3080F; }
  .cart-page .coupon-price-adjustment .remove-coupon {
    font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
    border: none;
    border-radius: 50%;
    font-size: 0;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0 20px 20px 20px;
    cursor: pointer;
    z-index: 1;
    background-color: #FFFFFF;
    padding: 4px 6px;
    height: 22px;
    width: 22px;
    padding: 12px 20px 14px 20px; }
    .cart-page .coupon-price-adjustment .remove-coupon:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 11px;
      color: inherit;
      font-weight: normal; }
    .cart-page .coupon-price-adjustment .remove-coupon[class*="btn-"]:not(.title) {
      padding-left: calc(var(--button-padding-x) + 20px); }
      .cart-page .coupon-price-adjustment .remove-coupon[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .cart-page .coupon-price-adjustment .remove-coupon[class*="btn-"]:not(.title).btn-sm, .cart-page .coupon-price-adjustment .btn-group-sm > .remove-coupon.btn[class*="btn-"]:not(.title) {
        padding-left: calc(20px + 20px); }
        .cart-page .coupon-price-adjustment .remove-coupon[class*="btn-"]:not(.title).btn-sm:before, .cart-page .coupon-price-adjustment .btn-group-sm > .remove-coupon.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .cart-page .coupon-price-adjustment .remove-coupon[class*="btn-"]:not(.title) [class*="icon-"],
      .cart-page .coupon-price-adjustment .remove-coupon[class*="btn-"]:not(.title) [class^="icon-"],
      .cart-page .coupon-price-adjustment .remove-coupon[class*="btn-"]:not(.title) .fa {
        display: none; }
  .cart-page .coupon-price-adjustment .coupon-promotion-relationship {
    letter-spacing: 0.1em;
    font-size: calc(var(--base-font-size) - 2px);
    margin-bottom: 0; }
    @media (min-width: 1024px) {
      .cart-page .coupon-price-adjustment .coupon-promotion-relationship {
        font-size: calc(var(--base-font-size) - 1px); } }

.cart-page .coupon-missing-error {
  display: none; }

.cart-page .promotion-information {
  margin-bottom: 8px;
  margin-top: 8px; }
  .cart-page .promotion-information::after {
    display: block;
    content: "";
    clear: both; }

.cart-page .applied-promotion-discount {
  color: #008827;
  float: right; }

.cart-page .totals .card .card-body {
  flex: none; }
  .cart-page .totals .card .card-body:not(:first-child) {
    border-top: 1px solid #C5C5C5; }

.cart-page .totals .shipping-method {
  margin-bottom: 24px; }

.cart-page .totals .estimated-total {
  margin-bottom: 0; }
  .cart-page .totals .estimated-total p {
    font-size: 16px;
    font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
    font-weight: normal;
    text-transform: none; }

.cart-page .checkout-btn:before {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: inherit;
  color: inherit;
  font-weight: normal; }

.cart-page .checkout-btn[class*="btn-"]:not(.title) {
  padding-left: calc(var(--button-padding-x) + 20px); }
  .cart-page .checkout-btn[class*="btn-"]:not(.title):before {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: inherit;
    font-weight: normal;
    position: absolute;
    transform: translateX(-30px); }
  .cart-page .checkout-btn[class*="btn-"]:not(.title).btn-sm, .cart-page .btn-group-sm > .checkout-btn.btn[class*="btn-"]:not(.title) {
    padding-left: calc(20px + 20px); }
    .cart-page .checkout-btn[class*="btn-"]:not(.title).btn-sm:before, .cart-page .btn-group-sm > .checkout-btn.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .cart-page .checkout-btn[class*="btn-"]:not(.title) [class*="icon-"],
  .cart-page .checkout-btn[class*="btn-"]:not(.title) [class^="icon-"],
  .cart-page .checkout-btn[class*="btn-"]:not(.title) .fa {
    display: none; }

@media (max-width: 543.98px) {
  .cart-page .checkout-btn:after {
    content: " | " attr(data-price); } }

@media (max-width: 543.98px) {
  .cart-page .checkout-continue {
    position: fixed;
    bottom: 0;
    border: 0;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.6);
    width: 100%;
    z-index: 500;
    margin-right: -16px;
    margin-left: -16px;
    max-height: calc(100vh - 50px);
    overflow: auto; } }

.cart-page .checkout-continue .js-paymentmethod-mutationobserver .paypal-button-container-mini,
.cart-page .checkout-continue .js-paymentmethod-mutationobserver .paypal-button-container-cart1,
.cart-page .checkout-continue .js-paymentmethod-mutationobserver .v-checkout-wrapper,
.cart-page .checkout-continue .js-paymentmethod-mutationobserver #js-googlepay-container,
.cart-page .checkout-continue .js-paymentmethod-mutationobserver .applePaySpecificityOverride {
  margin-top: 10px;
  margin-bottom: 0;
  width: 100%;
  border-radius: var(--button-border-radius); }
  .cart-page .checkout-continue .js-paymentmethod-mutationobserver .paypal-button-container-mini button,
  .cart-page .checkout-continue .js-paymentmethod-mutationobserver .paypal-button-container-cart1 button,
  .cart-page .checkout-continue .js-paymentmethod-mutationobserver .v-checkout-wrapper button,
  .cart-page .checkout-continue .js-paymentmethod-mutationobserver #js-googlepay-container button,
  .cart-page .checkout-continue .js-paymentmethod-mutationobserver .applePaySpecificityOverride button {
    width: 100%;
    border-radius: var(--button-border-radius); }

.cart-page .checkout-continue .js-paymentmethod-mutationobserver #cart-paypal-button-container {
  margin-top: 10px;
  margin-bottom: 0; }

.cart-page .cart ~ .cart-recommendations {
  border-top: 1px solid var(--base-border-color);
  margin-top: 130px;
  padding-top: 95px; }

.delete-coupon-confirmation-body .coupon-to-remove {
  margin: 16px 0 0;
  font-weight: bold; }

.delete-confirmation-body .product-to-remove {
  margin: 16px 0 0;
  font-weight: bold; }

.cart-page .totals .shipping-method {
  margin-bottom: 0;
  display: none; }

.customer-information-block .btn-link {
  color: var(--brand-primary);
  font-weight: normal;
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  vertical-align: baseline;
  margin-top: 10px;
  border: none; }
  .customer-information-block .btn-link:hover {
    color: var(--brand-secondary);
    text-decoration: underline; }
  .dark-theme .customer-information-block .btn-link {
    color: #FFFFFF; }
    .dark-theme .customer-information-block .btn-link:hover {
      color: #FFFFFF; }

.customer-information-block .submit-customer {
  margin-top: 10px; }

.customer-information-block .btn-block + .btn-block {
  margin-top: 10px; }

.customer-information-block .password-form {
  margin-bottom: 20px; }

.data-checkout-stage {
  /*
    * Show/Hide behavior for various checkout states
    */ }
  .data-checkout-stage .row > .col-12 .text-center {
    margin-bottom: 10px; }
  .data-checkout-stage .row [class*='col-'] .card {
    margin-bottom: 0; }
    .data-checkout-stage .row [class*='col-'] .card ~ .card .card-header {
      border-top: 0; }
    .data-checkout-stage .row [class*='col-'] .card ~ .card.customer-section, .data-checkout-stage .row [class*='col-'] .card ~ .card.customer-summary {
      border-top: 1px solid #C5C5C5; }
    .data-checkout-stage .row [class*='col-'] .card ~ .card.ghost.payment {
      border: solid #C5C5C5;
      border-width: 0 0 1px 0; }
  .data-checkout-stage .card-header .edit-button {
    color: var(--brand-primary);
    font-weight: normal;
    text-decoration: none;
    cursor: pointer;
    color: var(--brand-primary); }
    .data-checkout-stage .card-header .edit-button:hover {
      color: var(--brand-secondary);
      text-decoration: underline; }
    .dark-theme .data-checkout-stage .card-header .edit-button {
      color: #FFFFFF; }
      .dark-theme .data-checkout-stage .card-header .edit-button:hover {
        color: #FFFFFF; }
    .data-checkout-stage .card-header .edit-button:hover {
      color: var(--brand-primary); }
  .data-checkout-stage .shipping-summary .leading-lines,
  .data-checkout-stage .payment-summary .leading-lines {
    margin-bottom: 0; }
  .data-checkout-stage .shipping-summary .summary-details,
  .data-checkout-stage .payment-summary .summary-details {
    padding-bottom: 41px; }
    .data-checkout-stage .shipping-summary .summary-details .address-summary,
    .data-checkout-stage .shipping-summary .summary-details .shipping-phone,
    .data-checkout-stage .shipping-summary .summary-details .shipping-method,
    .data-checkout-stage .shipping-summary .summary-details .gift-message-summary,
    .data-checkout-stage .shipping-summary .summary-details .order-summary-email,
    .data-checkout-stage .shipping-summary .summary-details .order-summary-phone,
    .data-checkout-stage .shipping-summary .summary-details .payment-details,
    .data-checkout-stage .payment-summary .summary-details .address-summary,
    .data-checkout-stage .payment-summary .summary-details .shipping-phone,
    .data-checkout-stage .payment-summary .summary-details .shipping-method,
    .data-checkout-stage .payment-summary .summary-details .gift-message-summary,
    .data-checkout-stage .payment-summary .summary-details .order-summary-email,
    .data-checkout-stage .payment-summary .summary-details .order-summary-phone,
    .data-checkout-stage .payment-summary .summary-details .payment-details {
      color: #767676;
      margin-bottom: 1px; }
  .data-checkout-stage .payment-summary .addressInformation.hideAddressInformationForPayment {
    display: none; }
  .data-checkout-stage .view-address-block h3:not(:first-child),
  .data-checkout-stage .view-address-block .multi-shipping,
  .data-checkout-stage .shipment-block h3:not(:first-child),
  .data-checkout-stage .shipment-block .multi-shipping,
  .data-checkout-stage .product-summary-block h3:not(:first-child),
  .data-checkout-stage .product-summary-block .multi-shipping {
    margin-top: 16px; }
  .data-checkout-stage .view-address-block address,
  .data-checkout-stage .view-address-block .store-details,
  .data-checkout-stage .view-address-block .shipping-header-text + p,
  .data-checkout-stage .shipment-block address,
  .data-checkout-stage .shipment-block .store-details,
  .data-checkout-stage .shipment-block .shipping-header-text + p,
  .data-checkout-stage .product-summary-block address,
  .data-checkout-stage .product-summary-block .store-details,
  .data-checkout-stage .product-summary-block .shipping-header-text + p {
    margin-left: 16px; }
    .data-checkout-stage .view-address-block address address,
    .data-checkout-stage .view-address-block .store-details address,
    .data-checkout-stage .view-address-block .shipping-header-text + p address,
    .data-checkout-stage .shipment-block address address,
    .data-checkout-stage .shipment-block .store-details address,
    .data-checkout-stage .shipment-block .shipping-header-text + p address,
    .data-checkout-stage .product-summary-block address address,
    .data-checkout-stage .product-summary-block .store-details address,
    .data-checkout-stage .product-summary-block .shipping-header-text + p address {
      margin-left: 0; }
  .data-checkout-stage .shipping-method-block h3 {
    margin-bottom: 16px; }
  .data-checkout-stage .shipping-method-block .shipping-method-list .start-lines {
    margin-bottom: 12px; }
    .data-checkout-stage .shipping-method-block .shipping-method-list .start-lines:before {
      left: 54px; }
  .data-checkout-stage .shipping-method-block .shipping-method-list .end-lines {
    margin-bottom: 12px; }
  .data-checkout-stage .shipping-method-block span.display-name {
    padding-right: 0; }
  .data-checkout-stage .shipping-method-block .text-muted.arrival-time {
    padding-top: 3px;
    margin-top: -3px;
    top: -1px;
    padding-left: 3px;
    left: -3px;
    padding-right: 0; }
  .data-checkout-stage .shipping-method-block .shipping-cost {
    float: right; }
  .data-checkout-stage .gift-message-block {
    margin-bottom: 16px; }
    .data-checkout-stage .gift-message-block .gift-message {
      margin-top: 16px; }
  .data-checkout-stage .gift-message-summary {
    margin-bottom: 16px; }
  .data-checkout-stage .shipment-selector-block .btn-show-details,
  .data-checkout-stage .shipment-selector-block .btn-add-new,
  .data-checkout-stage .address-selector-block .btn-show-details,
  .data-checkout-stage .address-selector-block .btn-add-new {
    color: var(--brand-primary);
    font-weight: normal;
    text-decoration: none;
    cursor: pointer;
    margin-top: 24px;
    padding: 0;
    border: none; }
    .data-checkout-stage .shipment-selector-block .btn-show-details:hover,
    .data-checkout-stage .shipment-selector-block .btn-add-new:hover,
    .data-checkout-stage .address-selector-block .btn-show-details:hover,
    .data-checkout-stage .address-selector-block .btn-add-new:hover {
      color: var(--brand-secondary);
      text-decoration: underline; }
    .dark-theme .data-checkout-stage .shipment-selector-block .btn-show-details, .dark-theme
    .data-checkout-stage .shipment-selector-block .btn-add-new, .dark-theme
    .data-checkout-stage .address-selector-block .btn-show-details, .dark-theme
    .data-checkout-stage .address-selector-block .btn-add-new {
      color: #FFFFFF; }
      .dark-theme .data-checkout-stage .shipment-selector-block .btn-show-details:hover, .dark-theme
      .data-checkout-stage .shipment-selector-block .btn-add-new:hover, .dark-theme
      .data-checkout-stage .address-selector-block .btn-show-details:hover, .dark-theme
      .data-checkout-stage .address-selector-block .btn-add-new:hover {
        color: #FFFFFF; }
  .data-checkout-stage .contact-info-block .info-icon {
    font-size: var(--base-font-size); }
  .data-checkout-stage .giftcertificate-information {
    margin-top: 16px; }
    .data-checkout-stage .giftcertificate-information .applybalancebutton {
      margin-top: auto;
      margin-bottom: 4px; }
  .data-checkout-stage .payment-information .payment-options .nav-item {
    margin-bottom: 24px;
    display: flex; }
    .data-checkout-stage .payment-information .payment-options .nav-item:only-child {
      display: none; }
    .data-checkout-stage .payment-information .payment-options .nav-item:not(:only-child) {
      margin-top: calc(24px - 4px); }
    .data-checkout-stage .payment-information .payment-options .nav-item > .nav-link {
      width: 100%; }
  .data-checkout-stage .form-control.shippingZipCode ~ span,
  .data-checkout-stage .form-control.shippingPhoneNumber ~ span,
  .data-checkout-stage .form-control.billingZipCode ~ span {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.05em;
    margin-top: 4px;
    display: block; }
    .data-checkout-stage .form-control.shippingZipCode ~ span:not(.invalid-feedback),
    .data-checkout-stage .form-control.shippingPhoneNumber ~ span:not(.invalid-feedback),
    .data-checkout-stage .form-control.billingZipCode ~ span:not(.invalid-feedback) {
      color: #767676; }
  .data-checkout-stage .credit-card-selection-new .info-icon {
    font-size: var(--base-font-size); }
  .data-checkout-stage .credit-card-selection-new .paypal-content {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto; }
  .data-checkout-stage .credit-card-selection-new .saved-payment-information {
    margin-top: 12px; }
    @media (min-width: 544px) {
      .data-checkout-stage .credit-card-selection-new .saved-payment-information {
        margin-top: 0; } }
  .data-checkout-stage .credit-card-selection-new .card-image {
    max-width: 100%; }
  .data-checkout-stage .credit-card-selection-new .saved-payment-instrument {
    padding-top: 12px;
    padding-bottom: 12px;
    align-items: center; }
  .data-checkout-stage .credit-card-selection-new .add-payment {
    margin-top: 12px; }
  .data-checkout-stage .credit-card-selection-new .cancel-new-payment {
    margin-top: 24px; }
  .data-checkout-stage .credit-card-selection-new .selected-payment {
    background-color: #F2F2F2;
    border: 1px solid var(--brand-primary);
    color: var(--brand-primary); }
  .data-checkout-stage .credit-card-selection-new .save-credit-card {
    margin-top: 5px; }
  .data-checkout-stage .credit-card-form select:invalid {
    color: #767676; }
  .data-checkout-stage .next-step-button {
    margin-top: 24px; }
    @media (max-width: 768.98px) {
      .data-checkout-stage .next-step-button {
        position: fixed;
        bottom: 0;
        padding-right: 0;
        padding-left: 0;
        background-color: rgba(255, 255, 255, 0.8);
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.6);
        z-index: 500; }
        .data-checkout-stage .next-step-button .btn {
          margin-top: 24px;
          margin-bottom: 24px; } }
    .data-checkout-stage .next-step-button .card {
      border: 0; }
  .data-checkout-stage [data-customer-type=guest] .single-shipping .shipment-selector-block,
  .data-checkout-stage option[value=new],
  .data-checkout-stage .single-shipping .shipping-form:not(.in-store-pickup) .multi-ship-action-buttons,
  .data-checkout-stage .single-shipping .view-address-block,
  .data-checkout-stage .multi-ship .single-shipping .shipping-content,
  .data-checkout-stage .multi-ship .shipping-summary .single-shipping,
  .data-checkout-stage .multi-ship .confirm-details .single-shipping,
  .data-checkout-stage .multi-shipping,
  .data-checkout-stage .error-message,
  .data-checkout-stage .checkout-hidden {
    display: none; }
  .data-checkout-stage.multi-ship .multi-shipping,
  .data-checkout-stage span.ship-to-name,
  .data-checkout-stage span.ship-to-address1,
  .data-checkout-stage span.ship-to-address2,
  .data-checkout-stage span.ship-to-phone,
  .data-checkout-stage span.ship-to-city-st-zip {
    display: block; }
  .data-checkout-stage[data-checkout-stage] .card.payment-summary,
  .data-checkout-stage[data-checkout-stage] .shipping-summary,
  .data-checkout-stage[data-checkout-stage] button.place-order,
  .data-checkout-stage[data-checkout-stage] button.submit-payment,
  .data-checkout-stage[data-checkout-stage] button.submit-shipping {
    display: none; }
  .data-checkout-stage[data-checkout-stage] .card.secure-acceptance-iframe,
  .data-checkout-stage[data-checkout-stage] button.sa_redirect,
  .data-checkout-stage[data-checkout-stage] button.alipay, .data-checkout-stage[data-checkout-stage] button.sof, .data-checkout-stage[data-checkout-stage] button.eps, .data-checkout-stage[data-checkout-stage] button.idl, .data-checkout-stage[data-checkout-stage] button.gpy, .data-checkout-stage[data-checkout-stage] button.mch, .data-checkout-stage[data-checkout-stage] button.klarna, .data-checkout-stage[data-checkout-stage] button.credit_card, .data-checkout-stage[data-checkout-stage] button.dw_google_pay,
  .data-checkout-stage[data-checkout-stage] button.sa_iframe,
  .data-checkout-stage[data-checkout-stage] button.sa_silentpost,
  .data-checkout-stage[data-checkout-stage] button.sa_flex {
    display: none; }
  .data-checkout-stage[data-checkout-stage=customer] .shipping-address-block > .row .form-group {
    margin-top: calc(24px - 4px); }
  .data-checkout-stage[data-checkout-stage=customer] .shipping-address-block:not(.d-none) + .shipping-method-block {
    margin-top: 41px; }
  .data-checkout-stage[data-checkout-stage=customer].multi-ship .order-product-summary,
  .data-checkout-stage[data-checkout-stage=customer] .card.ghost.customer {
    display: none; }
  .data-checkout-stage[data-checkout-stage=customer] .card.customer-summary {
    display: none; }
  .data-checkout-stage[data-checkout-stage=customer] .card.payment-form {
    display: none; }
  .data-checkout-stage[data-checkout-stage=customer] button.submit-customer {
    display: block; }
  .data-checkout-stage[data-checkout-stage=customer] .card.ghost.shipping {
    display: none; }
  .data-checkout-stage[data-checkout-stage=customer] button.submit-shipping {
    display: block; }
  .data-checkout-stage[data-checkout-stage=shipping] .card.customer-section {
    display: none; }
  .data-checkout-stage[data-checkout-stage=shipping] button.submit-customer {
    display: none; }
  .data-checkout-stage[data-checkout-stage=shipping] .card.ghost.customer {
    display: none; }
  .data-checkout-stage[data-checkout-stage=shipping].multi-ship .order-product-summary,
  .data-checkout-stage[data-checkout-stage=shipping] .card.payment-form,
  .data-checkout-stage[data-checkout-stage=shipping] .card.ghost,
  .data-checkout-stage[data-checkout-stage=shipping] [data-address-mode=customer] .shipping-address-block,
  .data-checkout-stage[data-checkout-stage=shipping] [data-address-mode=shipment] .shipping-address-form,
  .data-checkout-stage[data-checkout-stage=shipping] [data-address-mode=edit] .shipping-address-block,
  .data-checkout-stage[data-checkout-stage=shipping] [data-address-mode=new] .btn-show-details,
  .data-checkout-stage[data-checkout-stage=shipping] [data-address-mode=new] .btn-add-new {
    display: none; }
  .data-checkout-stage[data-checkout-stage=shipping] button.submit-shipping {
    display: block; }
  .data-checkout-stage[data-checkout-stage=shipping] .shipping-address-block > .row .form-group {
    margin-top: calc(24px - 4px); }
  .data-checkout-stage[data-checkout-stage=shipping] .shipping-address-block:not(.d-none) + .shipping-method-block {
    margin-top: 41px; }
  .data-checkout-stage[data-checkout-stage=shipping] .shipment-selector-block {
    padding-top: 24px; }
  .data-checkout-stage[data-checkout-stage=shipping] .multi-shipping .card .card-header {
    border-top: 0; }
  .data-checkout-stage[data-checkout-stage=shipping] .multi-shipping .card .card-title {
    border-bottom: 0; }
  .data-checkout-stage[data-checkout-stage=payment] button.submit-customer {
    display: none; }
  .data-checkout-stage[data-checkout-stage=payment] .customer-section,
  .data-checkout-stage[data-checkout-stage=payment] .shipping-section,
  .data-checkout-stage[data-checkout-stage=payment] .card.ghost,
  .data-checkout-stage[data-checkout-stage=payment] [data-address-mode=customer] .billing-address,
  .data-checkout-stage[data-checkout-stage=payment] [data-address-mode=shipment] .billing-address,
  .data-checkout-stage[data-checkout-stage=payment] [data-address-mode=edit] .billing-address,
  .data-checkout-stage[data-checkout-stage=payment] [data-address-mode=new] .btn-show-details,
  .data-checkout-stage[data-checkout-stage=payment] [data-address-mode=new] .btn-add-new,
  .data-checkout-stage[data-checkout-stage=payment] [data-address-mode=details] .btn-show-details,
  .data-checkout-stage[data-checkout-stage=payment] [data-address-mode=details] .btn-add-new,
  .data-checkout-stage[data-checkout-stage=payment] button#hideSubmitPayment,
  .data-checkout-stage[data-checkout-stage=payment] .card.secure-acceptance-iframe,
  .data-checkout-stage[data-checkout-stage=payment] button.sa_redirect,
  .data-checkout-stage[data-checkout-stage=payment] button.sa_iframe,
  .data-checkout-stage[data-checkout-stage=payment] button.alipay, .data-checkout-stage[data-checkout-stage=payment] button.sof, .data-checkout-stage[data-checkout-stage=payment] button.eps, .data-checkout-stage[data-checkout-stage=payment] button.idl, .data-checkout-stage[data-checkout-stage=payment] button.gpy, .data-checkout-stage[data-checkout-stage=payment] button.mch, .data-checkout-stage[data-checkout-stage=payment] button.klarna, .data-checkout-stage[data-checkout-stage=payment] button.credit_card, .data-checkout-stage[data-checkout-stage=payment] button.dw_google_pay,
  .data-checkout-stage[data-checkout-stage=payment] button.sa_silentpost,
  .data-checkout-stage[data-checkout-stage=payment] button.sa_flex {
    display: none; }
  .data-checkout-stage[data-checkout-stage=payment] .card.payment-form,
  .data-checkout-stage[data-checkout-stage=payment] .shipping-summary,
  .data-checkout-stage[data-checkout-stage=payment] button.submit-payment,
  .data-checkout-stage[data-checkout-stage=payment] button#showSubmitPayment {
    display: block; }
  .data-checkout-stage[data-checkout-stage=payment] .contact-info-block > .row .form-group,
  .data-checkout-stage[data-checkout-stage=payment] .billing-address > .row .form-group,
  .data-checkout-stage[data-checkout-stage=payment] .billingAddressFields > .row .form-group,
  .data-checkout-stage[data-checkout-stage=payment] .credit-card-form > .row .form-group {
    margin-top: calc(24px - 4px); }
  .data-checkout-stage[data-checkout-stage=placeOrder] button.submit-customer,
  .data-checkout-stage[data-checkout-stage=placeOrder] .customer-section,
  .data-checkout-stage[data-checkout-stage=placeOrder] .shipping-section,
  .data-checkout-stage[data-checkout-stage=placeOrder] .card.payment-form,
  .data-checkout-stage[data-checkout-stage=placeOrder] .card.ghost,
  .data-checkout-stage[data-checkout-stage=placeOrder] button.sasilentpost,
  .data-checkout-stage[data-checkout-stage=placeOrder] .card.secure-acceptance-iframe {
    display: none; }
  .data-checkout-stage[data-checkout-stage=placeOrder] .card.payment-summary,
  .data-checkout-stage[data-checkout-stage=placeOrder] .shipping-summary,
  .data-checkout-stage[data-checkout-stage=placeOrder] button.place-order,
  .data-checkout-stage[data-checkout-stage=placeOrder] .card.secure-acceptance-iframe,
  .data-checkout-stage[data-checkout-stage=placeOrder] button.sa_redirect,
  .data-checkout-stage[data-checkout-stage=placeOrder] button.sa_iframe,
  .data-checkout-stage[data-checkout-stage=placeOrder] button.sa_silentpost,
  .data-checkout-stage[data-checkout-stage=placeOrder] button.alipay, .data-checkout-stage[data-checkout-stage=placeOrder] button.sof, .data-checkout-stage[data-checkout-stage=placeOrder] button.eps, .data-checkout-stage[data-checkout-stage=placeOrder] button.idl, .data-checkout-stage[data-checkout-stage=placeOrder] button.gpy, .data-checkout-stage[data-checkout-stage=placeOrder] button.mch, .data-checkout-stage[data-checkout-stage=placeOrder] button.klarna, .data-checkout-stage[data-checkout-stage=placeOrder] button.credit_card, .data-checkout-stage[data-checkout-stage=placeOrder] button.dw_google_pay,
  .data-checkout-stage[data-checkout-stage=placeOrder] button.sa_flex {
    display: block; }
  .data-checkout-stage[data-checkout-stage=submitted] .shipping-form,
  .data-checkout-stage[data-checkout-stage=submitted] .card.payment-form,
  .data-checkout-stage[data-checkout-stage=submitted] button.submit-customer,
  .data-checkout-stage[data-checkout-stage=submitted] .card.ghost,
  .data-checkout-stage[data-checkout-stage=submitted] .summary-section-label.shipping-addr-label,
  .data-checkout-stage[data-checkout-stage=submitted] button.place-order {
    display: none; }
  .data-checkout-stage[data-checkout-stage=submitted] .card.payment-summary,
  .data-checkout-stage[data-checkout-stage=submitted] .shipping-summary {
    display: block; }

/*
* Shared styles for account order detail and checkout confirmation pages
*/
.data-checkout-stage .order-total-summary .grand-total,
.data-checkout-stage .order-total-summary .grand-total .order-receipt-label,
.data-checkout-stage .order-total-summary .grand-total-sum,
.data-checkout-stage .order-product-summary .grand-total,
.data-checkout-stage .order-product-summary .grand-total .order-receipt-label,
.data-checkout-stage .order-product-summary .grand-total-sum,
.receipt .order-total-summary .grand-total,
.receipt .order-total-summary .grand-total .order-receipt-label,
.receipt .order-total-summary .grand-total-sum,
.receipt .order-product-summary .grand-total,
.receipt .order-product-summary .grand-total .order-receipt-label,
.receipt .order-product-summary .grand-total-sum,
.account-page .order-total-summary .grand-total,
.account-page .order-total-summary .grand-total .order-receipt-label,
.account-page .order-total-summary .grand-total-sum,
.account-page .order-product-summary .grand-total,
.account-page .order-product-summary .grand-total .order-receipt-label,
.account-page .order-product-summary .grand-total-sum {
  font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  text-transform: none; }

.data-checkout-stage .summary-section-label,
.data-checkout-stage .shipping-section h3,
.data-checkout-stage .product-summary-block .shipment-block h3,
.data-checkout-stage .product-summary-block .multi-shipping h3,
.receipt .summary-section-label,
.receipt .shipping-section h3,
.receipt .product-summary-block .shipment-block h3,
.receipt .product-summary-block .multi-shipping h3,
.account-page .summary-section-label,
.account-page .shipping-section h3,
.account-page .product-summary-block .shipment-block h3,
.account-page .product-summary-block .multi-shipping h3 {
  font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
  font-weight: normal;
  font-size: var(--base-font-size);
  line-height: 1.4;
  margin-bottom: 6px;
  text-transform: none;
  letter-spacing: 0.1em;
  display: block;
  color: var(--brand-primary); }

.data-checkout-stage .order-total-summary .grand-total,
.receipt .order-total-summary .grand-total,
.account-page .order-total-summary .grand-total {
  margin-bottom: 5px; }

.data-checkout-stage .order-product-summary .product-summary-block,
.receipt .order-product-summary .product-summary-block,
.account-page .order-product-summary .product-summary-block {
  padding-top: 20px; }

.data-checkout-stage .order-product-summary .card-header,
.receipt .order-product-summary .card-header,
.account-page .order-product-summary .card-header {
  border-bottom: 1px solid #C5C5C5;
  padding: 24px 20px 24px 20px; }

.data-checkout-stage .order-product-summary .leading-lines,
.receipt .order-product-summary .leading-lines,
.account-page .order-product-summary .leading-lines {
  margin-bottom: 0; }

.data-checkout-stage .order-product-summary .product-line-item .line-item-name,
.data-checkout-stage .order-product-summary .product-line-item .line-item-pricing-info,
.receipt .order-product-summary .product-line-item .line-item-name,
.receipt .order-product-summary .product-line-item .line-item-pricing-info,
.account-page .order-product-summary .product-line-item .line-item-name,
.account-page .order-product-summary .product-line-item .line-item-pricing-info {
  text-decoration: none; }

.data-checkout-stage .order-product-summary .product-line-item .item-attributes,
.receipt .order-product-summary .product-line-item .item-attributes,
.account-page .order-product-summary .product-line-item .item-attributes {
  padding: 0; }
  .data-checkout-stage .order-product-summary .product-line-item .item-attributes .line-item-attributes,
  .data-checkout-stage .order-product-summary .product-line-item .item-attributes .item-options,
  .receipt .order-product-summary .product-line-item .item-attributes .line-item-attributes,
  .receipt .order-product-summary .product-line-item .item-attributes .item-options,
  .account-page .order-product-summary .product-line-item .item-attributes .line-item-attributes,
  .account-page .order-product-summary .product-line-item .item-attributes .item-options {
    color: #767676;
    margin-top: 1px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%; }

.data-checkout-stage .order-product-summary .product-line-item .item-price-qty,
.receipt .order-product-summary .product-line-item .item-price-qty,
.account-page .order-product-summary .product-line-item .item-price-qty {
  border-top: none;
  padding-top: 24px; }
  .data-checkout-stage .order-product-summary .product-line-item .item-price-qty > .col,
  .receipt .order-product-summary .product-line-item .item-price-qty > .col,
  .account-page .order-product-summary .product-line-item .item-price-qty > .col {
    padding: 0; }

.data-checkout-stage .order-product-summary .product-line-item .line-item-total-text,
.receipt .order-product-summary .product-line-item .line-item-total-text,
.account-page .order-product-summary .product-line-item .line-item-total-text {
  color: #767676; }

.data-checkout-stage .order-product-summary .product-line-item .line-item-total-price-amount,
.receipt .order-product-summary .product-line-item .line-item-total-price-amount,
.account-page .order-product-summary .product-line-item .line-item-total-price-amount {
  color: #000000; }

.data-checkout-stage .order-product-summary .product-line-item ~ .store-details,
.receipt .order-product-summary .product-line-item ~ .store-details,
.account-page .order-product-summary .product-line-item ~ .store-details {
  margin-left: 16px;
  margin-bottom: 16px; }

.data-checkout-stage .order-product-summary .product-line-item ~ address,
.receipt .order-product-summary .product-line-item ~ address,
.account-page .order-product-summary .product-line-item ~ address {
  margin-left: 16px; }
  .data-checkout-stage .order-product-summary .product-line-item ~ address span,
  .receipt .order-product-summary .product-line-item ~ address span,
  .account-page .order-product-summary .product-line-item ~ address span {
    display: block; }

.data-checkout-stage .confirm-details .summary-section-label,
.receipt .confirm-details .summary-section-label,
.account-page .confirm-details .summary-section-label {
  font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
  font-weight: normal;
  font-size: var(--base-font-size);
  line-height: 1.4;
  margin-bottom: 6px;
  text-transform: none;
  letter-spacing: 0.1em;
  display: block;
  color: var(--brand-primary); }
  .data-checkout-stage .confirm-details .summary-section-label:not(.order-number-label),
  .receipt .confirm-details .summary-section-label:not(.order-number-label),
  .account-page .confirm-details .summary-section-label:not(.order-number-label) {
    margin-top: 24px; }

.data-checkout-stage .confirm-details .shipping-method-arrival-time,
.receipt .confirm-details .shipping-method-arrival-time,
.account-page .confirm-details .shipping-method-arrival-time {
  letter-spacing: 0.1em;
  font-size: calc(var(--base-font-size) - 2px);
  padding-top: 3px;
  margin-top: -3px;
  top: -1px;
  padding-left: 3px;
  left: -3px; }
  @media (min-width: 1024px) {
    .data-checkout-stage .confirm-details .shipping-method-arrival-time,
    .receipt .confirm-details .shipping-method-arrival-time,
    .account-page .confirm-details .shipping-method-arrival-time {
      font-size: calc(var(--base-font-size) - 1px); } }

.data-checkout-stage .confirm-details .summary-details,
.data-checkout-stage .confirm-details .shipping-method,
.data-checkout-stage .confirm-details .pricing,
.receipt .confirm-details .summary-details,
.receipt .confirm-details .shipping-method,
.receipt .confirm-details .pricing,
.account-page .confirm-details .summary-details,
.account-page .confirm-details .shipping-method,
.account-page .confirm-details .pricing {
  color: #767676; }

.data-checkout-stage .confirm-details .card-body > .summary-details:last-child,
.receipt .confirm-details .card-body > .summary-details:last-child,
.account-page .confirm-details .card-body > .summary-details:last-child {
  padding-bottom: 41px; }

.receipt .confirm-details,
.account-page .confirm-details {
  margin-bottom: 0; }

.receipt .checkout-order-total-summary .order-total-summary {
  border-top: none; }

.pac-container:empty {
  box-shadow: none;
  border-top: 0; }

.contact-us-landing-page {
  padding-top: 16px;
  padding-bottom: 16px; }
  @media (min-width: 1024px) {
    .contact-us-landing-page .col-12 > .card:only-child .card-body > form {
      width: 750px; } }
  .contact-us-landing-page .card {
    margin-top: 16px; }
  .contact-us-landing-page .subscribe-contact-us {
    margin-top: 10px; }

.contact-us-signup-message {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  position: fixed;
  top: 0;
  padding: 50px 16px 16px;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  z-index: 1100; }

.contact-us-signup-alert {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  text-align: center;
  padding: 20px 30px; }
  .contact-us-signup-alert.show {
    display: block; }
  .contact-us-signup-alert:not(.alert-dismissible) {
    animation: fadeIn 200ms, fadeOut 200ms linear calc(5s - 200ms); }
  .contact-us-signup-alert.alert-dismissible {
    animation: fadeIn 200ms;
    padding-right: 50px; }
    .contact-us-signup-alert.alert-dismissible .close {
      padding: 16px; }

.product-detail {
  margin-bottom: 16px; }
  .product-detail .primary-images .slider-container {
    margin-bottom: 0; }
  .product-detail .primary-images .primary-images-main.product-has-thumbnails {
    margin-bottom: 1px; }
  @media (min-width: 1024px) {
    .product-detail .primary-images > .row {
      margin-left: 0;
      margin-right: 0; }
    .product-detail .primary-images .primary-images-thumbnails {
      flex-basis: 63px;
      max-width: 63px;
      padding-left: 0;
      padding-right: 0; }
    .product-detail .primary-images .primary-images-main {
      padding-left: 0;
      padding-right: 0; }
      .product-detail .primary-images .primary-images-main.product-has-thumbnails {
        flex-basis: calc(100% - 63px - 1px);
        max-width: calc(100% - 63px - 1px);
        margin-left: 1px;
        margin-bottom: 0; } }
  @media (min-width: 544px) and (max-width: 768.98px) {
    .product-detail .primary-images + .col-sm-6 {
      max-width: 100%;
      flex-basis: 100%; } }
  @media (min-width: 769px) {
    .product-detail .row > .product-data {
      padding-left: 16px; } }
  @media (min-width: 1024px) {
    .product-detail .row > .product-data {
      padding-left: 40px; } }
  @media (min-width: 1440px) {
    .product-detail .row > .product-data {
      padding-left: 80px; } }
  .product-detail .product-name {
    font-family: var(--h3-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
    font-size: var(--h3-mobile-size);
    line-height: 1.2;
    font-weight: 400;
    letter-spacing: 0.01em;
    text-transform: none;
    font-style: normal;
    color: #000000; }
    @media (min-width: 769px) {
      .product-detail .product-name {
        font-size: var(--h3-tablet-size);
        line-height: 1.2;
        font-weight: 400;
        letter-spacing: 0.01em;
        text-transform: none;
        font-style: normal; } }
    @media (min-width: 1024px) {
      .product-detail .product-name {
        font-size: var(--h3-desktop-size);
        line-height: 1.17;
        font-weight: 400;
        letter-spacing: 0.01em;
        text-transform: none;
        font-style: normal; } }
    .product-detail .product-name a {
      text-decoration: none; }
  .product-detail .attribute,
  .product-detail .product-option {
    margin-top: 24px; }
    @media (min-width: 769px) {
      .product-detail .attribute,
      .product-detail .product-option {
        margin-top: 24px; } }
    @media (min-width: 1024px) {
      .product-detail .attribute,
      .product-detail .product-option {
        margin-top: 44px; } }
    .product-detail .attribute label,
    .product-detail .product-option label {
      display: block; }
  .product-detail .qty-cart-container {
    margin-top: 16px;
    margin-bottom: 16px; }
  .product-detail .quantity {
    display: flex;
    align-items: center;
    margin-bottom: 16px; }
    .product-detail .quantity label {
      font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
      font-weight: normal;
      font-size: var(--base-font-size);
      line-height: 1.4;
      margin-bottom: 6px;
      text-transform: none;
      letter-spacing: 0.1em;
      margin-right: 10px; }
      .product-detail .quantity label:after {
        content: ':'; }
  @media (min-width: 544px) {
    .product-detail .quantity-stepper,
    .product-detail .quantity-select {
      width: calc(100% - 10px); } }
  .product-detail .simple-quantity {
    margin-top: 16px; }
  .product-detail .availability-container {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    margin-top: 24px; }
    @media (min-width: 769px) {
      .product-detail .availability-container {
        margin-top: 24px; } }
    @media (min-width: 1024px) {
      .product-detail .availability-container {
        margin-top: 44px; } }
  .product-detail .swatch-circle {
    width: var(--pdp-color-swatch-size);
    height: var(--pdp-color-swatch-size);
    background-color: #FFFFFF;
    background-size: cover;
    display: block;
    position: relative;
    text-align: center;
    border-radius: 50%;
    margin: 10px 20px 10px 0; }
    .product-detail .swatch-circle.color-value {
      transition: box-shadow 200ms ease-out; }
      .product-detail .swatch-circle.color-value:hover {
        box-shadow: 0 0 0 calc(3px - 1px) #FFFFFF, 0 0 0 3px #BEBEBE; }
      .product-detail .swatch-circle.color-value.selected {
        box-shadow: 0 0 0 calc(3px - 1px) #FFFFFF, 0 0 0 3px #000000; }
        .product-detail .swatch-circle.color-value.selected:after {
          content: "";
          font-family: "icomoon";
          display: inline-block;
          font-size: 7px;
          color: #FFFFFF;
          font-weight: normal; }
        .product-detail .swatch-circle.color-value.selected[class*="btn-"]:not(.title) {
          padding-right: calc(var(--button-padding-x) + 20px); }
          .product-detail .swatch-circle.color-value.selected[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 20px;
            color: #FFFFFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
          .product-detail .swatch-circle.color-value.selected[class*="btn-"]:not(.title).btn-sm, .product-detail .btn-group-sm > .swatch-circle.color-value.selected.btn[class*="btn-"]:not(.title) {
            padding-right: calc(20px + 20px); }
            .product-detail .swatch-circle.color-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-detail .btn-group-sm > .swatch-circle.color-value.selected.btn[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
          .product-detail .swatch-circle.color-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
          .product-detail .swatch-circle.color-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
          .product-detail .swatch-circle.color-value.selected[class*="btn-"]:not(.title) .fa {
            display: none; }
        .product-detail .swatch-circle.color-value.selected::after {
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%);
          display: none;
          background: rgba(0, 0, 0, 0.7);
          width: 14px;
          height: 14px;
          line-height: 14px;
          padding-left: 1px;
          text-align: center;
          border-radius: 50%;
          z-index: 1; }
      .product-detail .swatch-circle.color-value.unselectable:before, .product-detail .swatch-circle.color-value.unselectable:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%) rotate(45deg);
        height: 100%;
        width: 1px; }
      .product-detail .swatch-circle.color-value.unselectable:after {
        background-color: #000000; }
      .product-detail .swatch-circle.color-value.unselectable:before {
        background-color: #FFFFFF;
        margin-right: 1px; }
      .product-detail .swatch-circle.color-value.unselectable, .product-detail .swatch-circle.color-value.unavailable {
        opacity: 0.5;
        pointer-events: none;
        pointer-events: auto;
        position: relative; }
      .product-detail .swatch-circle.color-value.unselectable {
        cursor: not-allowed; }
      .product-detail .swatch-circle.color-value.out-of-stock {
        cursor: pointer;
        opacity: 0.5;
        pointer-events: none; }
        .product-detail .swatch-circle.color-value.out-of-stock:before, .product-detail .swatch-circle.color-value.out-of-stock:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%) rotate(45deg);
          height: calc(100% + 15px);
          width: 1px; }
        .product-detail .swatch-circle.color-value.out-of-stock:after {
          background-color: #BEBEBE; }
        .product-detail .swatch-circle.color-value.out-of-stock:before {
          background-color: #FFFFFF;
          margin-right: 1px; }
        .product-detail .swatch-circle.color-value.out-of-stock.selected:after {
          display: block; }
    .product-detail .swatch-circle.non-color-swatch-value {
      transition: border 200ms ease-out, box-shadow 200ms ease-out; }
      .product-detail .swatch-circle.non-color-swatch-value:not(.unselectable):hover {
        border-color: #767676;
        box-shadow: none; }
      .product-detail .swatch-circle.non-color-swatch-value.selected, .product-detail .swatch-circle.non-color-swatch-value.selected:hover {
        background: #FFFFFF;
        color: #000000;
        border-color: #000000;
        box-shadow: none; }
        .product-detail .swatch-circle.non-color-swatch-value.selected:after, .product-detail .swatch-circle.non-color-swatch-value.selected:hover:after {
          content: "";
          font-family: "icomoon";
          display: inline-block;
          font-size: 8px;
          color: #FFFFFF;
          font-weight: normal; }
        .product-detail .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title), .product-detail .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) {
          padding-right: calc(var(--button-padding-x) + 20px); }
          .product-detail .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title):after, .product-detail .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 20px;
            color: #FFFFFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
          .product-detail .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm, .product-detail .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .product-detail .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm, .product-detail .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
            padding-right: calc(20px + 20px); }
            .product-detail .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-detail .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .product-detail .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm:after, .product-detail .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
          .product-detail .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
          .product-detail .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
          .product-detail .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title) .fa, .product-detail .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class*="icon-"],
          .product-detail .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class^="icon-"],
          .product-detail .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) .fa {
            display: none; }
        .product-detail .swatch-circle.non-color-swatch-value.selected::after, .product-detail .swatch-circle.non-color-swatch-value.selected:hover::after {
          position: absolute;
          top: -5.33333px;
          right: -5.33333px;
          background: var(--brand-primary);
          width: 16px;
          height: 16px;
          line-height: 16px;
          padding-left: 1px;
          text-align: center;
          border-radius: 50%;
          display: none; }
      .product-detail .swatch-circle.non-color-swatch-value.unselectable, .product-detail .swatch-circle.non-color-swatch-value.unavailable {
        opacity: 0.5;
        pointer-events: none;
        pointer-events: auto;
        position: relative; }
      .product-detail .swatch-circle.non-color-swatch-value.unselectable {
        cursor: not-allowed; }
        .product-detail .swatch-circle.non-color-swatch-value.unselectable:before, .product-detail .swatch-circle.non-color-swatch-value.unselectable:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%) rotate(45deg);
          height: calc(100% + 15px);
          width: 1px; }
        .product-detail .swatch-circle.non-color-swatch-value.unselectable:after {
          background-color: #BEBEBE; }
        .product-detail .swatch-circle.non-color-swatch-value.unselectable:before {
          background-color: #FFFFFF;
          margin-right: 1px; }
      .product-detail .swatch-circle.non-color-swatch-value.out-of-stock {
        cursor: pointer;
        opacity: 0.5;
        pointer-events: none; }
        .product-detail .swatch-circle.non-color-swatch-value.out-of-stock:before, .product-detail .swatch-circle.non-color-swatch-value.out-of-stock:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%) rotate(45deg);
          height: calc(100% + 15px);
          width: 1px; }
        .product-detail .swatch-circle.non-color-swatch-value.out-of-stock:after {
          background-color: #BEBEBE; }
        .product-detail .swatch-circle.non-color-swatch-value.out-of-stock:before {
          background-color: #FFFFFF;
          margin-right: 1px; }
        .product-detail .swatch-circle.non-color-swatch-value.out-of-stock.selected:after {
          display: block; }
  .product-detail .swatch-thumbnail {
    width: var(--pdp-color-swatch-thumbnail-size);
    height: var(--pdp-color-swatch-thumbnail-size);
    background-color: #FFFFFF;
    background-size: cover;
    display: block;
    position: relative;
    text-align: center;
    border-radius: 0;
    margin: 0.5px 1px 0.5px 0; }
    .product-detail .swatch-thumbnail.color-value {
      transition: box-shadow 200ms ease-out; }
      .product-detail .swatch-thumbnail.color-value:hover:not(.unselectable) {
        box-shadow: inset 0px -2px 0px #767676; }
      .product-detail .swatch-thumbnail.color-value.selected {
        box-shadow: inset 0px -2px 0px #000000; }
        .product-detail .swatch-thumbnail.color-value.selected:after {
          content: "";
          font-family: "icomoon";
          display: inline-block;
          font-size: 7px;
          color: #FFFFFF;
          font-weight: normal; }
        .product-detail .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title) {
          padding-right: calc(var(--button-padding-x) + 20px); }
          .product-detail .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 20px;
            color: #FFFFFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
          .product-detail .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title).btn-sm, .product-detail .btn-group-sm > .swatch-thumbnail.color-value.selected.btn[class*="btn-"]:not(.title) {
            padding-right: calc(20px + 20px); }
            .product-detail .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-detail .btn-group-sm > .swatch-thumbnail.color-value.selected.btn[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
          .product-detail .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
          .product-detail .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
          .product-detail .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title) .fa {
            display: none; }
        .product-detail .swatch-thumbnail.color-value.selected::after {
          position: absolute;
          top: -4.66667px;
          right: -4.66667px;
          display: none;
          background: rgba(0, 0, 0, 0.7);
          width: 14px;
          height: 14px;
          line-height: 14px;
          padding-left: 1px;
          text-align: center;
          border-radius: 50%;
          z-index: 1; }
      .product-detail .swatch-thumbnail.color-value.unselectable:before, .product-detail .swatch-thumbnail.color-value.unselectable:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%) rotate(45deg);
        height: calc(100% + 15px);
        width: 1px; }
      .product-detail .swatch-thumbnail.color-value.unselectable:after {
        background-color: #000000; }
      .product-detail .swatch-thumbnail.color-value.unselectable:before {
        background-color: #FFFFFF;
        margin-right: 1px; }
      .product-detail .swatch-thumbnail.color-value.unselectable, .product-detail .swatch-thumbnail.color-value.unavailable {
        opacity: 0.5;
        pointer-events: none;
        pointer-events: auto;
        position: relative; }
      .product-detail .swatch-thumbnail.color-value.unselectable {
        cursor: not-allowed; }
      .product-detail .swatch-thumbnail.color-value.out-of-stock {
        cursor: pointer;
        opacity: 0.5;
        pointer-events: none; }
        .product-detail .swatch-thumbnail.color-value.out-of-stock:before, .product-detail .swatch-thumbnail.color-value.out-of-stock:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%) rotate(45deg);
          height: calc(100% + 15px);
          width: 1px; }
        .product-detail .swatch-thumbnail.color-value.out-of-stock:after {
          background-color: #BEBEBE; }
        .product-detail .swatch-thumbnail.color-value.out-of-stock:before {
          background-color: #FFFFFF;
          margin-right: 1px; }
        .product-detail .swatch-thumbnail.color-value.out-of-stock.selected:after {
          display: block; }
    .product-detail .swatch-thumbnail.non-color-swatch-value {
      transition: border 200ms ease-out, box-shadow 200ms ease-out; }
      .product-detail .swatch-thumbnail.non-color-swatch-value:not(.unselectable):hover {
        border-color: #767676;
        box-shadow: none; }
      .product-detail .swatch-thumbnail.non-color-swatch-value.selected, .product-detail .swatch-thumbnail.non-color-swatch-value.selected:hover {
        background: #FFFFFF;
        color: #000000;
        border-color: #000000;
        box-shadow: none; }
        .product-detail .swatch-thumbnail.non-color-swatch-value.selected:after, .product-detail .swatch-thumbnail.non-color-swatch-value.selected:hover:after {
          content: "";
          font-family: "icomoon";
          display: inline-block;
          font-size: 8px;
          color: #FFFFFF;
          font-weight: normal; }
        .product-detail .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title), .product-detail .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) {
          padding-right: calc(var(--button-padding-x) + 20px); }
          .product-detail .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title):after, .product-detail .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 20px;
            color: #FFFFFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
          .product-detail .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm, .product-detail .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .product-detail .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm, .product-detail .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
            padding-right: calc(20px + 20px); }
            .product-detail .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-detail .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .product-detail .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm:after, .product-detail .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: #FFFFFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
          .product-detail .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
          .product-detail .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
          .product-detail .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title) .fa, .product-detail .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class*="icon-"],
          .product-detail .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class^="icon-"],
          .product-detail .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) .fa {
            display: none; }
        .product-detail .swatch-thumbnail.non-color-swatch-value.selected::after, .product-detail .swatch-thumbnail.non-color-swatch-value.selected:hover::after {
          position: absolute;
          top: -5.33333px;
          right: -5.33333px;
          background: var(--brand-primary);
          width: 16px;
          height: 16px;
          line-height: 16px;
          padding-left: 1px;
          text-align: center;
          border-radius: 50%;
          display: none; }
      .product-detail .swatch-thumbnail.non-color-swatch-value.unselectable, .product-detail .swatch-thumbnail.non-color-swatch-value.unavailable {
        opacity: 0.5;
        pointer-events: none;
        pointer-events: auto;
        position: relative; }
      .product-detail .swatch-thumbnail.non-color-swatch-value.unselectable {
        cursor: not-allowed; }
        .product-detail .swatch-thumbnail.non-color-swatch-value.unselectable:before, .product-detail .swatch-thumbnail.non-color-swatch-value.unselectable:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%) rotate(45deg);
          height: calc(100% + 15px);
          width: 1px; }
        .product-detail .swatch-thumbnail.non-color-swatch-value.unselectable:after {
          background-color: #BEBEBE; }
        .product-detail .swatch-thumbnail.non-color-swatch-value.unselectable:before {
          background-color: #FFFFFF;
          margin-right: 1px; }
      .product-detail .swatch-thumbnail.non-color-swatch-value.out-of-stock {
        cursor: pointer;
        opacity: 0.5;
        pointer-events: none; }
        .product-detail .swatch-thumbnail.non-color-swatch-value.out-of-stock:before, .product-detail .swatch-thumbnail.non-color-swatch-value.out-of-stock:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%) rotate(45deg);
          height: calc(100% + 15px);
          width: 1px; }
        .product-detail .swatch-thumbnail.non-color-swatch-value.out-of-stock:after {
          background-color: #BEBEBE; }
        .product-detail .swatch-thumbnail.non-color-swatch-value.out-of-stock:before {
          background-color: #FFFFFF;
          margin-right: 1px; }
        .product-detail .swatch-thumbnail.non-color-swatch-value.out-of-stock.selected:after {
          display: block; }
  .product-detail [disabled] .swatch,
  .product-detail .swatch.unselectable {
    cursor: not-allowed; }
    .product-detail [disabled] .swatch.color-value.selected::after,
    .product-detail .swatch.unselectable.color-value.selected::after {
      background-color: #BEBEBE; }
  .product-detail label.color ~ a:hover {
    text-decoration: none; }
  .product-detail .non-color-attribute-swatches::after {
    display: block;
    content: "";
    clear: both; }
  .product-detail .non-color-attribute-swatches .swatch-tile-container {
    float: left;
    display: block;
    margin: 4px 8px 4px 0; }
    .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch {
      background: none;
      display: block;
      position: relative;
      padding: 0;
      border: none; }
      .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value {
        position: relative;
        color: #000000;
        background: #FFFFFF;
        border: 1px solid #BEBEBE;
        border-radius: 0;
        padding: 0 9px;
        display: block;
        min-width: var(--pdp-non-color-swatch-size);
        line-height: calc(var(--pdp-non-color-swatch-size) - 1px*2); }
        .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.color-value {
          transition: box-shadow 200ms ease-out; }
          .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.color-value:hover {
            box-shadow: 0 0 0 calc(3px - 1px) #FFFFFF, 0 0 0 3px #BEBEBE; }
          .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.color-value.selected {
            box-shadow: 0 0 0 calc(3px - 1px) #FFFFFF, 0 0 0 3px #000000; }
            .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.color-value.selected:after {
              content: "";
              font-family: "icomoon";
              display: inline-block;
              font-size: 7px;
              color: #FFFFFF;
              font-weight: normal; }
            .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.color-value.selected[class*="btn-"]:not(.title) {
              padding-right: calc(var(--button-padding-x) + 20px); }
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.color-value.selected[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 20px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.color-value.selected[class*="btn-"]:not(.title).btn-sm, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .btn-group-sm > .swatch-value.color-value.selected.btn[class*="btn-"]:not(.title) {
                padding-right: calc(20px + 20px); }
                .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.color-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .btn-group-sm > .swatch-value.color-value.selected.btn[class*="btn-"]:not(.title):after {
                  font-family: "icomoon";
                  display: inline-block;
                  font-size: 22px;
                  color: #FFFFFF;
                  font-weight: normal;
                  position: absolute;
                  transform: translateX(10px); }
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.color-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.color-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.color-value.selected[class*="btn-"]:not(.title) .fa {
                display: none; }
            .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.color-value.selected::after {
              position: absolute;
              top: 50%;
              right: 50%;
              transform: translate(50%, -50%);
              display: none;
              background: rgba(0, 0, 0, 0.7);
              width: 14px;
              height: 14px;
              line-height: 14px;
              padding-left: 1px;
              text-align: center;
              border-radius: 50%;
              z-index: 1; }
          .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.color-value.unselectable:before, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.color-value.unselectable:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%) rotate(45deg);
            height: 100%;
            width: 1px; }
          .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.color-value.unselectable:after {
            background-color: #000000; }
          .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.color-value.unselectable:before {
            background-color: #FFFFFF;
            margin-right: 1px; }
          .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.color-value.unselectable, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.color-value.unavailable {
            opacity: 0.5;
            pointer-events: none;
            pointer-events: auto;
            position: relative; }
          .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.color-value.unselectable {
            cursor: not-allowed; }
          .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.color-value.out-of-stock {
            cursor: pointer;
            opacity: 0.5;
            pointer-events: none; }
            .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.color-value.out-of-stock:before, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.color-value.out-of-stock:after {
              content: "";
              position: absolute;
              top: 50%;
              right: 50%;
              transform: translate(50%, -50%) rotate(45deg);
              height: calc(100% + 15px);
              width: 1px; }
            .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.color-value.out-of-stock:after {
              background-color: #BEBEBE; }
            .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.color-value.out-of-stock:before {
              background-color: #FFFFFF;
              margin-right: 1px; }
            .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.color-value.out-of-stock.selected:after {
              display: block; }
        .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value {
          transition: border 200ms ease-out, box-shadow 200ms ease-out; }
          .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value:not(.unselectable):hover {
            border-color: #767676;
            box-shadow: none; }
          .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected:hover {
            background: #FFFFFF;
            color: #000000;
            border-color: #000000;
            box-shadow: none; }
            .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected:after, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected:hover:after {
              content: "";
              font-family: "icomoon";
              display: inline-block;
              font-size: 8px;
              color: #FFFFFF;
              font-weight: normal; }
            .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title), .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) {
              padding-right: calc(var(--button-padding-x) + 20px); }
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title):after, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 20px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .btn-group-sm > .swatch-value.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .btn-group-sm > .swatch-value.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
                padding-right: calc(20px + 20px); }
                .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .btn-group-sm > .swatch-value.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm:after, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .btn-group-sm > .swatch-value.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
                  font-family: "icomoon";
                  display: inline-block;
                  font-size: 22px;
                  color: #FFFFFF;
                  font-weight: normal;
                  position: absolute;
                  transform: translateX(10px); }
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title) .fa, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class*="icon-"],
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class^="icon-"],
              .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) .fa {
                display: none; }
            .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected::after, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.selected:hover::after {
              position: absolute;
              top: -5.33333px;
              right: -5.33333px;
              background: var(--brand-primary);
              width: 16px;
              height: 16px;
              line-height: 16px;
              padding-left: 1px;
              text-align: center;
              border-radius: 50%;
              display: none; }
          .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.unselectable, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.unavailable {
            opacity: 0.5;
            pointer-events: none;
            pointer-events: auto;
            position: relative; }
          .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.unselectable {
            cursor: not-allowed; }
            .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.unselectable:before, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.unselectable:after {
              content: "";
              position: absolute;
              top: 50%;
              right: 50%;
              transform: translate(50%, -50%) rotate(45deg);
              height: calc(100% + 15px);
              width: 1px; }
            .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.unselectable:after {
              background-color: #BEBEBE; }
            .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.unselectable:before {
              background-color: #FFFFFF;
              margin-right: 1px; }
          .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.out-of-stock {
            cursor: pointer;
            opacity: 0.5;
            pointer-events: none; }
            .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.out-of-stock:before, .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.out-of-stock:after {
              content: "";
              position: absolute;
              top: 50%;
              right: 50%;
              transform: translate(50%, -50%) rotate(45deg);
              height: calc(100% + 15px);
              width: 1px; }
            .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.out-of-stock:after {
              background-color: #BEBEBE; }
            .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.out-of-stock:before {
              background-color: #FFFFFF;
              margin-right: 1px; }
            .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .swatch-value.non-color-swatch-value.out-of-stock.selected:after {
              display: block; }
      .product-detail .non-color-attribute-swatches .swatch-tile-container .swatch .description {
        display: block;
        text-align: center;
        font-size: 12px;
        color: #BEBEBE;
        font-weight: normal; }
  .product-detail .social-container {
    padding-top: 24px; }
    .product-detail .social-container .social-icons {
      align-items: center; }
      .product-detail .social-container .social-icons li:not(:last-child) {
        margin-right: 30px; }
      .product-detail .social-container .social-icons .share-icons {
        font-size: 30px;
        color: var(--brand-primary); }
        .product-detail .social-container .social-icons .share-icons:hover {
          color: var(--brand-secondary); }
  .product-detail .product-number-rating {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 16px;
    clear: left; }
  .product-detail .product-number {
    color: #767676;
    font-size: 13px; }
  .product-detail .ratings {
    margin-left: 32px; }
    .product-detail .ratings .fa-star:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 14px;
      color: var(--product-tile-star-color);
      font-weight: normal; }
    .product-detail .ratings .fa-star[class*="btn-"]:not(.title) {
      padding-left: calc(var(--button-padding-x) + 20px); }
      .product-detail .ratings .fa-star[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: var(--product-tile-star-color);
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .product-detail .ratings .fa-star[class*="btn-"]:not(.title).btn-sm, .product-detail .ratings .btn-group-sm > .fa-star.btn[class*="btn-"]:not(.title) {
        padding-left: calc(20px + 20px); }
        .product-detail .ratings .fa-star[class*="btn-"]:not(.title).btn-sm:before, .product-detail .ratings .btn-group-sm > .fa-star.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: var(--product-tile-star-color);
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .product-detail .ratings .fa-star[class*="btn-"]:not(.title) [class*="icon-"],
      .product-detail .ratings .fa-star[class*="btn-"]:not(.title) [class^="icon-"],
      .product-detail .ratings .fa-star[class*="btn-"]:not(.title) .fa {
        display: none; }
    .product-detail .ratings .fa-star-half-o:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 14px;
      color: var(--product-tile-star-color);
      font-weight: normal; }
    .product-detail .ratings .fa-star-half-o[class*="btn-"]:not(.title) {
      padding-left: calc(var(--button-padding-x) + 20px); }
      .product-detail .ratings .fa-star-half-o[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: var(--product-tile-star-color);
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .product-detail .ratings .fa-star-half-o[class*="btn-"]:not(.title).btn-sm, .product-detail .ratings .btn-group-sm > .fa-star-half-o.btn[class*="btn-"]:not(.title) {
        padding-left: calc(20px + 20px); }
        .product-detail .ratings .fa-star-half-o[class*="btn-"]:not(.title).btn-sm:before, .product-detail .ratings .btn-group-sm > .fa-star-half-o.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: var(--product-tile-star-color);
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .product-detail .ratings .fa-star-half-o[class*="btn-"]:not(.title) [class*="icon-"],
      .product-detail .ratings .fa-star-half-o[class*="btn-"]:not(.title) [class^="icon-"],
      .product-detail .ratings .fa-star-half-o[class*="btn-"]:not(.title) .fa {
        display: none; }
    .product-detail .ratings .fa-star-o:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 14px;
      color: var(--product-tile-star-color);
      font-weight: normal; }
    .product-detail .ratings .fa-star-o[class*="btn-"]:not(.title) {
      padding-left: calc(var(--button-padding-x) + 20px); }
      .product-detail .ratings .fa-star-o[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: var(--product-tile-star-color);
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .product-detail .ratings .fa-star-o[class*="btn-"]:not(.title).btn-sm, .product-detail .ratings .btn-group-sm > .fa-star-o.btn[class*="btn-"]:not(.title) {
        padding-left: calc(20px + 20px); }
        .product-detail .ratings .fa-star-o[class*="btn-"]:not(.title).btn-sm:before, .product-detail .ratings .btn-group-sm > .fa-star-o.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: var(--product-tile-star-color);
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .product-detail .ratings .fa-star-o[class*="btn-"]:not(.title) [class*="icon-"],
      .product-detail .ratings .fa-star-o[class*="btn-"]:not(.title) [class^="icon-"],
      .product-detail .ratings .fa-star-o[class*="btn-"]:not(.title) .fa {
        display: none; }
  .product-detail .promotions {
    margin-top: 16px; }
    .product-detail .promotions .promotion {
      color: var(--base-font-color); }
  .product-detail .prices {
    margin-top: 16px;
    margin-bottom: 16px; }
    @media (min-width: 544px) {
      .product-detail .prices {
        margin-bottom: 8px; } }
    @media (min-width: 769px) {
      .product-detail .prices {
        margin-top: 0; } }
    .product-detail .prices .price {
      font-size: 20px;
      font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif; }
  .product-detail .size-chart {
    font-size: calc(var(--base-font-size) - 2px); }
    .product-detail .size-chart h2 {
      font-family: var(--h4-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
      font-size: var(--h4-mobile-size);
      line-height: 1.25;
      font-weight: 400;
      letter-spacing: 0.01em;
      text-transform: none;
      font-style: normal; }
      @media (min-width: 769px) {
        .product-detail .size-chart h2 {
          font-size: var(--h4-tablet-size);
          line-height: 1.25;
          font-weight: 400;
          letter-spacing: 0.01em;
          text-transform: none;
          font-style: normal; } }
      @media (min-width: 1024px) {
        .product-detail .size-chart h2 {
          font-size: var(--h4-desktop-size);
          line-height: 1.2;
          font-weight: 400;
          letter-spacing: 0.01em;
          text-transform: none;
          font-style: normal; } }
    .product-detail .size-chart .sizinginformation .nav-tabs .nav-link.active {
      background-color: #FFFFFF; }
    .product-detail .size-chart .sizinginformation .tab-content {
      border: 1px solid var(--tab-border-color);
      margin-bottom: 16px; }
      .product-detail .size-chart .sizinginformation .tab-content .sizechart {
        margin-top: -1px; }
      .product-detail .size-chart .sizinginformation .tab-content .sizecharttable {
        margin-bottom: 0; }
  .product-detail .attribute .size-chart {
    position: absolute;
    right: 16px; }
  .product-detail .color-attribute {
    display: block;
    float: left; }
  .product-detail label,
  .product-detail .non-input-label {
    font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
    font-weight: normal;
    font-size: var(--base-font-size);
    line-height: 1.4;
    margin-bottom: 6px;
    text-transform: none;
    letter-spacing: 0.1em;
    display: block; }
  .product-detail .set-item, .product-detail.set-item {
    padding: 0; }
    .product-detail .set-item .attribute.quantity, .product-detail.set-item .attribute.quantity {
      align-self: flex-end;
      margin-bottom: 4px; }
    .product-detail .set-item .size-chart, .product-detail.set-item .size-chart {
      margin-top: calc(24px - 4px); }
      @media (min-width: 769px) {
        .product-detail .set-item .size-chart, .product-detail.set-item .size-chart {
          margin-top: calc(24px - 4px); } }
      @media (min-width: 1024px) {
        .product-detail .set-item .size-chart, .product-detail.set-item .size-chart {
          margin-top: calc(44px - 4px); } }
  .product-detail .set-items {
    padding-top: 44px; }
    @media (min-width: 1024px) {
      .product-detail .set-items.product-has-thumbnails {
        margin-left: calc(63px - 1px); } }
    .product-detail .set-items .product-set-item-main-image {
      margin-bottom: 16px; }
  .product-detail .product-set-contains {
    font-family: var(--h5-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
    font-size: var(--h5-mobile-size);
    line-height: 1.25;
    font-weight: 400;
    letter-spacing: 0.01em;
    text-transform: none;
    font-style: normal;
    color: #000000;
    border-top: var(--base-border-color) solid 1px;
    border-bottom: var(--base-border-color) solid 1px;
    margin: 24px 0;
    padding: 24px 0;
    background-color: transparent; }
    @media (min-width: 769px) {
      .product-detail .product-set-contains {
        font-size: var(--h5-tablet-size);
        line-height: 1.25;
        font-weight: 400;
        letter-spacing: 0.01em;
        text-transform: none;
        font-style: normal; } }
    @media (min-width: 1024px) {
      .product-detail .product-set-contains {
        font-size: var(--h5-desktop-size);
        line-height: 1.2;
        font-weight: 400;
        letter-spacing: 0.01em;
        text-transform: none;
        font-style: normal; } }
    .product-detail .product-set-contains + .product-set-item-detail {
      border-top: 0;
      margin-top: 0; }
  .product-detail .product-set-item-detail {
    border-top: var(--base-border-color) solid 1px;
    margin-top: 28px;
    padding-top: 44px; }
    .product-detail .product-set-item-detail .set-item-product-name {
      font-family: var(--h4-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
      font-size: var(--h4-mobile-size);
      line-height: 1.25;
      font-weight: 400;
      letter-spacing: 0.01em;
      text-transform: none;
      font-style: normal;
      color: #000000; }
      @media (min-width: 769px) {
        .product-detail .product-set-item-detail .set-item-product-name {
          font-size: var(--h4-tablet-size);
          line-height: 1.25;
          font-weight: 400;
          letter-spacing: 0.01em;
          text-transform: none;
          font-style: normal; } }
      @media (min-width: 1024px) {
        .product-detail .product-set-item-detail .set-item-product-name {
          font-size: var(--h4-desktop-size);
          line-height: 1.2;
          font-weight: 400;
          letter-spacing: 0.01em;
          text-transform: none;
          font-style: normal; } }
      .product-detail .product-set-item-detail .set-item-product-name a {
        text-decoration: none; }
    .product-detail .product-set-item-detail .set-item-headline-and-description {
      display: block; }
    .product-detail .product-set-item-detail .set-item-price .price {
      font-family: var(--h4-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
      font-size: var(--h4-mobile-size);
      line-height: 1.25;
      font-weight: 400;
      letter-spacing: 0.01em;
      text-transform: none;
      font-style: normal;
      color: #000000; }
      @media (min-width: 769px) {
        .product-detail .product-set-item-detail .set-item-price .price {
          font-size: var(--h4-tablet-size);
          line-height: 1.25;
          font-weight: 400;
          letter-spacing: 0.01em;
          text-transform: none;
          font-style: normal; } }
      @media (min-width: 1024px) {
        .product-detail .product-set-item-detail .set-item-price .price {
          font-size: var(--h4-desktop-size);
          line-height: 1.2;
          font-weight: 400;
          letter-spacing: 0.01em;
          text-transform: none;
          font-style: normal; } }
    .product-detail .product-set-item-detail .mini-attributes .attribute,
    .product-detail .product-set-item-detail .mini-attributes .availability-container,
    .product-detail .product-set-item-detail .mini-attributes .qty-cart-container {
      margin-top: 24px; }
    .product-detail .product-set-item-detail .mini-attributes .qty-cart-container {
      margin-bottom: 0; }
    @media (min-width: 1440px) {
      .product-detail .product-set-item-detail .mini-attributes .attribute .size-chart {
        padding-right: 8px; } }
  .product-detail .product-bundle .addtocart-sticky-bar .add-to-cart.btn.btn-primary {
    width: 100%; }
  .product-detail .product-bundle .bundle-footer .qty-cart-container .quantity.d-none + .prices-add-to-cart-actions {
    flex: 0 0 100%;
    max-width: 100%; }
  .product-detail .bundle-items {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 32px 16px; }
    .product-detail .bundle-items .bundle-item {
      padding: 16px;
      border-top: 1px solid var(--base-border-color); }
      .product-detail .bundle-items .bundle-item .zoomImg {
        display: none !important; }
      .product-detail .bundle-items .bundle-item .product-name {
        font-family: var(--h3-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
        font-size: var(--h3-mobile-size);
        line-height: 1.2;
        font-weight: 400;
        letter-spacing: 0.01em;
        text-transform: none;
        font-style: normal; }
        @media (min-width: 769px) {
          .product-detail .bundle-items .bundle-item .product-name {
            font-size: var(--h3-tablet-size);
            line-height: 1.2;
            font-weight: 400;
            letter-spacing: 0.01em;
            text-transform: none;
            font-style: normal; } }
        @media (min-width: 1024px) {
          .product-detail .bundle-items .bundle-item .product-name {
            font-size: var(--h3-desktop-size);
            line-height: 1.17;
            font-weight: 400;
            letter-spacing: 0.01em;
            text-transform: none;
            font-style: normal; } }
      .product-detail .bundle-items .bundle-item .product-number-rating {
        flex-direction: column; }
        .product-detail .bundle-items .bundle-item .product-number-rating .ratings {
          margin-left: 0; }
      .product-detail .bundle-items .bundle-item .product-number-rating,
      .product-detail .bundle-items .bundle-item .ratings,
      .product-detail .bundle-items .bundle-item .availability-container,
      .product-detail .bundle-items .bundle-item .attribute,
      .product-detail .bundle-items .bundle-item .product-option {
        margin-top: 8px; }
        @media (min-width: 769px) {
          .product-detail .bundle-items .bundle-item .product-number-rating,
          .product-detail .bundle-items .bundle-item .ratings,
          .product-detail .bundle-items .bundle-item .availability-container,
          .product-detail .bundle-items .bundle-item .attribute,
          .product-detail .bundle-items .bundle-item .product-option {
            margin-top: 8px; } }
        @media (min-width: 1024px) {
          .product-detail .bundle-items .bundle-item .product-number-rating,
          .product-detail .bundle-items .bundle-item .ratings,
          .product-detail .bundle-items .bundle-item .availability-container,
          .product-detail .bundle-items .bundle-item .attribute,
          .product-detail .bundle-items .bundle-item .product-option {
            margin-top: 8px; } }
      .product-detail .bundle-items .bundle-item .product-option {
        padding-left: 0; }

.tabs {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 16px; }
  @media (min-width: 769px) {
    .tabs {
      padding-top: 30px; } }
  @media (min-width: 1024px) {
    .tabs {
      padding-top: 60px; } }
  .tabs .nav-link {
    font-family: var(--h6-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
    font-size: var(--h6-mobile-size);
    line-height: 1.4;
    font-weight: normal;
    letter-spacing: 0.1em;
    text-transform: none;
    font-style: normal; }
    @media (min-width: 769px) {
      .tabs .nav-link {
        font-size: var(--h6-tablet-size);
        line-height: 1.4;
        font-weight: normal;
        letter-spacing: 0.1em;
        text-transform: none;
        font-style: normal; } }
    @media (min-width: 1024px) {
      .tabs .nav-link {
        font-size: var(--h6-desktop-size);
        line-height: 1.4;
        font-weight: normal;
        letter-spacing: 0.1em;
        text-transform: none;
        font-style: normal; } }
  @media (min-width: 1024px) {
    .tabs.product-has-thumbnails {
      padding-left: 63px; } }

.main-content-group .main-content-item {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 16px; }
  @media (min-width: 769px) {
    .main-content-group .main-content-item {
      padding-top: 30px; } }
  @media (min-width: 1024px) {
    .main-content-group .main-content-item {
      padding-top: 60px; } }

@media (min-width: 1024px) {
  .main-content-group.product-has-thumbnails {
    padding-left: 63px; } }

.main-content-group .card .card-header .title {
  font-family: var(--h6-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
  font-size: var(--h6-mobile-size);
  line-height: 1.4;
  font-weight: normal;
  letter-spacing: 0.1em;
  text-transform: none;
  font-style: normal; }
  @media (min-width: 769px) {
    .main-content-group .card .card-header .title {
      font-size: var(--h6-tablet-size);
      line-height: 1.4;
      font-weight: normal;
      letter-spacing: 0.1em;
      text-transform: none;
      font-style: normal; } }
  @media (min-width: 1024px) {
    .main-content-group .card .card-header .title {
      font-size: var(--h6-desktop-size);
      line-height: 1.4;
      font-weight: normal;
      letter-spacing: 0.1em;
      text-transform: none;
      font-style: normal; } }

.main-content-group .card .card-body {
  padding-bottom: 16px; }
  @media (min-width: 769px) {
    .main-content-group .card .card-body {
      padding-bottom: 30px; } }
  @media (min-width: 1024px) {
    .main-content-group .card .card-body {
      padding-bottom: 60px; } }

.main-content-group .main-attributes {
  margin-bottom: 16px; }

.main-content-group .product-attributes .attribute-name {
  font-family: var(--h6-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
  font-size: var(--h6-mobile-size);
  line-height: 1.4;
  font-weight: normal;
  letter-spacing: 0.1em;
  text-transform: none;
  font-style: normal; }
  @media (min-width: 769px) {
    .main-content-group .product-attributes .attribute-name {
      font-size: var(--h6-tablet-size);
      line-height: 1.4;
      font-weight: normal;
      letter-spacing: 0.1em;
      text-transform: none;
      font-style: normal; } }
  @media (min-width: 1024px) {
    .main-content-group .product-attributes .attribute-name {
      font-size: var(--h6-desktop-size);
      line-height: 1.4;
      font-weight: normal;
      letter-spacing: 0.1em;
      text-transform: none;
      font-style: normal; } }

@media (max-width: 768.98px) {
  .main-content-group .product-attributes + .collapsible-content.collapsible-sm.border-top,
  .main-content-group .collapsible-content.collapsible-sm.border-top {
    border-top: 1px solid var(--base-border-color); } }

.main-content-group .product-attributes + .collapsible-content.collapsible-xl.border-top,
.main-content-group .collapsible-content.collapsible-xl.border-top {
  border-top: 1px solid var(--base-border-color); }

.cart-and-ipay {
  text-align: center; }
  .cart-and-ipay.row:not(.no-gutters) {
    margin-left: 0;
    margin-right: 0; }
    .cart-and-ipay.row:not(.no-gutters) > .col-sm-12 {
      padding-left: 0;
      padding-right: 0; }
  .cart-and-ipay .btn {
    width: 100%; }
  @media (min-width: 544px) {
    .cart-and-ipay {
      padding-bottom: 0; } }

.add-to-cart-messages {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  position: fixed;
  top: 0;
  padding: 50px 16px 16px;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  z-index: 1100; }

.add-to-basket-alert {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  text-align: center; }
  .add-to-basket-alert.show {
    display: block; }
  .add-to-basket-alert:not(.alert-dismissible) {
    animation: fadeIn 200ms, fadeOut 200ms linear calc(5s - 200ms); }
  .add-to-basket-alert.alert-dismissible {
    animation: fadeIn 200ms;
    padding-right: 50px; }
    .add-to-basket-alert.alert-dismissible .close {
      padding: 16px; }

.jumplink-anchor {
  position: absolute;
  top: -150px; }

.addtocart-sticky-bar {
  pointer-events: none;
  display: block;
  position: fixed;
  top: 0;
  background: #FFFFFF;
  width: 100%;
  left: 0;
  z-index: 200;
  padding: 10px 0;
  opacity: 1;
  transform: translateY(-100%);
  transition: transform 200ms linear;
  overflow: hidden;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2); }
  .addtocart-sticky-bar .name,
  .addtocart-sticky-bar .links {
    margin: auto; }
  .addtocart-sticky-bar h1.product-name {
    font-family: var(--h4-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
    font-size: var(--h4-mobile-size);
    line-height: 1.25;
    font-weight: 400;
    letter-spacing: 0.01em;
    text-transform: none;
    font-style: normal;
    color: #000000;
    margin: 0; }
    @media (min-width: 769px) {
      .addtocart-sticky-bar h1.product-name {
        font-size: var(--h4-tablet-size);
        line-height: 1.25;
        font-weight: 400;
        letter-spacing: 0.01em;
        text-transform: none;
        font-style: normal; } }
    @media (min-width: 1024px) {
      .addtocart-sticky-bar h1.product-name {
        font-size: var(--h4-desktop-size);
        line-height: 1.2;
        font-weight: 400;
        letter-spacing: 0.01em;
        text-transform: none;
        font-style: normal; } }
  .addtocart-sticky-bar a.jumplink {
    margin: 0 15px;
    position: relative;
    z-index: 10; }
    .addtocart-sticky-bar a.jumplink.selected, .addtocart-sticky-bar a.jumplink:hover {
      text-decoration: none; }
  .addtocart-sticky-bar .sticky-attribute {
    float: left; }
    .addtocart-sticky-bar .sticky-attribute + .sticky-attribute {
      margin-left: 16px; }
  .addtocart-sticky-bar .attribute {
    margin-top: 0; }
    .addtocart-sticky-bar .attribute label,
    .addtocart-sticky-bar .attribute .non-input-label,
    .addtocart-sticky-bar .attribute .size-chart {
      display: none; }
    .addtocart-sticky-bar .attribute .swatch-circle {
      width: var(--pdp-color-swatch-size);
      height: var(--pdp-color-swatch-size);
      margin: 5px 10px 5px 0; }
    .addtocart-sticky-bar .attribute .swatch-thumbnail {
      width: calc(14px + 8px + 6px + (1px*2));
      height: calc(14px + 8px + 6px + (1px*2));
      margin: 0.5px 1px 0.5px 0; }
    .addtocart-sticky-bar .attribute .custom-select, .addtocart-sticky-bar .attribute select.form-control,
    .addtocart-sticky-bar .attribute select {
      font-size: 11px;
      line-height: 14px;
      padding: 8px 25px 6px 10px;
      height: calc(14px + 8px + 6px + (1px*2)); }
  .addtocart-sticky-bar .cart-and-ipay {
    text-align: right;
    padding-bottom: 0; }
    .addtocart-sticky-bar .cart-and-ipay .add-to-cart.btn.btn-primary {
      font-size: 14px;
      line-height: 18px;
      padding: 5px 20px 5px 20px;
      letter-spacing: 0.05em;
      width: auto; }
  .sticky-header .addtocart-sticky-bar {
    transition: transform 400ms ease-out; }
  .sticky-header.scroll-direction-up .showstickybar .addtocart-sticky-bar {
    transform: translateY(var(--header-height));
    transition: transform 400ms ease-out 50ms; }

.showstickybar .addtocart-sticky-bar {
  pointer-events: auto;
  transform: translateY(0); }

.search-nav {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-bottom: 1px solid #BEBEBE; }
  .search-nav.hide-search-tabs {
    display: none; }
  .search-nav .nav-tabs-wrapper {
    padding: 0;
    width: 100%; }
    .search-nav .nav-tabs-wrapper .nav-link.active {
      background-color: #FFFFFF; }
    @media (min-width: 544px) {
      .search-nav .nav-tabs-wrapper {
        width: auto; } }

.search-results .show-more,
.search-results .show-more-content {
  padding: 16px 0;
  clear: both; }

.search-results .search-keywords {
  font-weight: bold; }

.search-results .category-item {
  margin-bottom: 16px; }

.search-results .tab-pane.active:focus {
  outline: none; }

.grid-header,
.content-grid-header {
  margin-bottom: 16px; }
  @media (min-width: 1024px) {
    .grid-header,
    .content-grid-header {
      margin-bottom: 0; } }
  .grid-header .breadcrumb-container > .row,
  .content-grid-header .breadcrumb-container > .row {
    align-items: center;
    height: 100%; }
  @media (min-width: 1024px) {
    .grid-header .breadcrumb,
    .content-grid-header .breadcrumb {
      margin-bottom: 0; } }
  .grid-header .result-count p,
  .content-grid-header .result-count p {
    margin-bottom: 16px; }
    @media (min-width: 544px) {
      .grid-header .result-count p,
      .content-grid-header .result-count p {
        margin-bottom: 0; } }
  .grid-header .filter-results,
  .grid-header select,
  .content-grid-header .filter-results,
  .content-grid-header select {
    width: 100%; }

.filter-header {
  margin-left: -16px;
  margin-right: -16px; }

.header-bar {
  background-color: var(--brand-secondary);
  border-bottom: 1px solid transparent;
  padding: 20px; }
  .header-bar button.close {
    line-height: 75%;
    margin: 0;
    padding: 0;
    text-shadow: none;
    opacity: 1; }
    .header-bar button.close:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 16px;
      color: #FFFFFF;
      font-weight: normal; }
    .header-bar button.close[class*="btn-"]:not(.title) {
      padding-right: calc(var(--button-padding-x) + 20px); }
      .header-bar button.close[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #FFFFFF;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .header-bar button.close[class*="btn-"]:not(.title).btn-sm, .header-bar .btn-group-sm > button.close.btn[class*="btn-"]:not(.title) {
        padding-right: calc(20px + 20px); }
        .header-bar button.close[class*="btn-"]:not(.title).btn-sm:after, .header-bar .btn-group-sm > button.close.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFFFFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .header-bar button.close[class*="btn-"]:not(.title) [class*="icon-"],
      .header-bar button.close[class*="btn-"]:not(.title) [class^="icon-"],
      .header-bar button.close[class*="btn-"]:not(.title) .fa {
        display: none; }
    .header-bar button.close span {
      display: none; }
  .header-bar .result-count {
    font-family: var(--h4-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
    font-size: var(--h4-mobile-size);
    line-height: 1.25;
    font-weight: 400;
    letter-spacing: 0.01em;
    text-transform: none;
    font-style: normal;
    color: #FFFFFF; }
    @media (min-width: 769px) {
      .header-bar .result-count {
        font-size: var(--h4-tablet-size);
        line-height: 1.25;
        font-weight: 400;
        letter-spacing: 0.01em;
        text-transform: none;
        font-style: normal; } }
    @media (min-width: 1024px) {
      .header-bar .result-count {
        font-size: var(--h4-desktop-size);
        line-height: 1.2;
        font-weight: 400;
        letter-spacing: 0.01em;
        text-transform: none;
        font-style: normal; } }

.filter-bar ul {
  padding-left: 16px;
  margin: 0; }
  @media (min-width: 1440px) {
    .filter-bar ul {
      padding-left: 24px; } }

.filter-bar li {
  list-style: none;
  float: left;
  margin-right: 5px;
  margin-bottom: 16px; }
  .filter-bar li:not(:first-child) {
    margin-left: 5px; }
  .filter-bar li.filter-value {
    position: relative; }
    .filter-bar li.filter-value button {
      color: #000000;
      background: #FFFFFF;
      font-size: 13px;
      font-weight: normal;
      line-height: 1;
      border: 1px solid #C5C5C5;
      border-radius: var(--border-radius);
      padding-top: 8px;
      padding-right: 25px;
      padding-bottom: 7px;
      padding-left: 10px; }
      .filter-bar li.filter-value button:after {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 10px;
        color: inherit;
        font-weight: normal; }
      .filter-bar li.filter-value button[class*="btn-"]:not(.title) {
        padding-right: calc(var(--button-padding-x) + 20px); }
        .filter-bar li.filter-value button[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
        .filter-bar li.filter-value button[class*="btn-"]:not(.title).btn-sm, .filter-bar li.filter-value .btn-group-sm > button.btn[class*="btn-"]:not(.title) {
          padding-right: calc(20px + 20px); }
          .filter-bar li.filter-value button[class*="btn-"]:not(.title).btn-sm:after, .filter-bar li.filter-value .btn-group-sm > button.btn[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: inherit;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .filter-bar li.filter-value button[class*="btn-"]:not(.title) [class*="icon-"],
        .filter-bar li.filter-value button[class*="btn-"]:not(.title) [class^="icon-"],
        .filter-bar li.filter-value button[class*="btn-"]:not(.title) .fa {
          display: none; }
      .filter-bar li.filter-value button:after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px; }
      .filter-bar li.filter-value button:hover {
        color: black; }

.filter-bar .result-count {
  padding: 8px 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px; }
  .filter-bar .result-count:not(:only-child) {
    margin-right: 16px; }
    @media (min-width: 1440px) {
      .filter-bar .result-count:not(:only-child) {
        margin-right: 24px; } }
  .filter-bar .result-count p {
    margin-bottom: 0; }

.refinement-bar {
  overflow: auto; }
  .refinement-bar-horizontal {
    overflow: visible; }
  @media (min-width: 1024px) {
    .refinement-bar:not([class*="refinement-bar-horizontal"]) {
      margin-top: 16px; }
      .refinement-bar:not([class*="refinement-bar-horizontal"]) + div {
        margin-top: 16px; } }
  .refinement-bar ul {
    padding: 0;
    margin: 0;
    line-height: 1; }
  .refinement-bar .secondary-bar {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 16px;
    margin-bottom: 16px; }
    .refinement-bar .secondary-bar li {
      padding: 0; }
    @media (min-width: 1024px) {
      .refinement-bar .secondary-bar {
        margin-top: 0; } }
    .refinement-bar .secondary-bar .secondary-bar-links {
      text-decoration: none;
      line-height: 1.4;
      padding: 8px 0;
      display: block;
      width: 100%; }
    .refinement-bar .secondary-bar li:last-child .secondary-bar-links {
      text-align: right; }
  @media (max-width: 1023.98px) {
    .refinement-bar {
      position: fixed;
      transform: translateX(-100%);
      transition: transform 200ms ease-out;
      top: 0;
      left: 0;
      display: block;
      width: 350px;
      height: calc(100vh - 70px);
      background-color: #FFFFFF;
      z-index: 250;
      overflow-x: hidden;
      overflow-y: auto;
      padding-bottom: 200px; }
      .refinement-bar.in {
        transform: translateX(0); } }
  @media (max-width: 768.98px) {
    .refinement-bar {
      height: calc(100vh - 50px); } }
  @media (max-width: 543.98px) {
    .refinement-bar {
      width: calc(100% - 50px);
      max-width: 350px; } }

.refinements > .card:last-child {
  border-bottom: none; }

.refinements .values.content {
  font-size: 0;
  display: flex;
  flex-wrap: wrap; }
  .refinements .values.content li {
    padding-left: 0;
    padding-bottom: 20px;
    display: block;
    max-width: 100%; }
    .refinements .values.content li.col-sm-4 {
      width: 100%;
      flex-basis: 100%; }
  .refinements .values.content button {
    border: none;
    background: none;
    padding: 0;
    text-align: left; }
    .refinements .values.content button:hover i.fa {
      box-shadow: 0 0 0 1px #BEBEBE; }
    .refinements .values.content button:hover .swatch-circle {
      box-shadow: 0 0 0 calc(3px - 1px) #FFFFFF, 0 0 0 3px #BEBEBE; }
    .refinements .values.content button span {
      font-size: var(--base-font-size);
      display: inline-block;
      vertical-align: middle; }
      .refinements .values.content button span:not(.color-value) {
        margin-right: 12px; }
      .refinements .values.content button span.unselectable.swatch-circle:hover {
        box-shadow: none; }
      .refinements .values.content button span.non-color-swatch-value {
        position: relative;
        color: #000000;
        background: #FFFFFF;
        border: 1px solid #BEBEBE;
        border-radius: 0;
        padding: 0 9px;
        display: block;
        min-width: 44px;
        line-height: calc(44px - 1px*2);
        text-align: center;
        margin-right: 10px; }
        .refinements .values.content button span.non-color-swatch-value.color-value {
          transition: box-shadow 200ms ease-out; }
          .refinements .values.content button span.non-color-swatch-value.color-value:hover {
            box-shadow: 0 0 0 calc(3px - 1px) #FFFFFF, 0 0 0 3px #BEBEBE; }
          .refinements .values.content button span.non-color-swatch-value.color-value.selected {
            box-shadow: 0 0 0 calc(3px - 1px) #FFFFFF, 0 0 0 3px #000000; }
            .refinements .values.content button span.non-color-swatch-value.color-value.selected:after {
              content: "";
              font-family: "icomoon";
              display: inline-block;
              font-size: 7px;
              color: #FFFFFF;
              font-weight: normal; }
            .refinements .values.content button span.non-color-swatch-value.color-value.selected[class*="btn-"]:not(.title) {
              padding-right: calc(var(--button-padding-x) + 20px); }
              .refinements .values.content button span.non-color-swatch-value.color-value.selected[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 20px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
              .refinements .values.content button span.non-color-swatch-value.color-value.selected[class*="btn-"]:not(.title).btn-sm, .refinements .values.content button .btn-group-sm > span.non-color-swatch-value.color-value.selected.btn[class*="btn-"]:not(.title) {
                padding-right: calc(20px + 20px); }
                .refinements .values.content button span.non-color-swatch-value.color-value.selected[class*="btn-"]:not(.title).btn-sm:after, .refinements .values.content button .btn-group-sm > span.non-color-swatch-value.color-value.selected.btn[class*="btn-"]:not(.title):after {
                  font-family: "icomoon";
                  display: inline-block;
                  font-size: 22px;
                  color: #FFFFFF;
                  font-weight: normal;
                  position: absolute;
                  transform: translateX(10px); }
              .refinements .values.content button span.non-color-swatch-value.color-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
              .refinements .values.content button span.non-color-swatch-value.color-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
              .refinements .values.content button span.non-color-swatch-value.color-value.selected[class*="btn-"]:not(.title) .fa {
                display: none; }
            .refinements .values.content button span.non-color-swatch-value.color-value.selected::after {
              position: absolute;
              top: 50%;
              right: 50%;
              transform: translate(50%, -50%);
              display: none;
              background: rgba(0, 0, 0, 0.7);
              width: 14px;
              height: 14px;
              line-height: 14px;
              padding-left: 1px;
              text-align: center;
              border-radius: 50%;
              z-index: 1; }
          .refinements .values.content button span.non-color-swatch-value.color-value.unselectable:before, .refinements .values.content button span.non-color-swatch-value.color-value.unselectable:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%) rotate(45deg);
            height: 100%;
            width: 1px; }
          .refinements .values.content button span.non-color-swatch-value.color-value.unselectable:after {
            background-color: #000000; }
          .refinements .values.content button span.non-color-swatch-value.color-value.unselectable:before {
            background-color: #FFFFFF;
            margin-right: 1px; }
          .refinements .values.content button span.non-color-swatch-value.color-value.unselectable, .refinements .values.content button span.non-color-swatch-value.color-value.unavailable {
            opacity: 0.5;
            pointer-events: none;
            pointer-events: auto;
            position: relative; }
          .refinements .values.content button span.non-color-swatch-value.color-value.unselectable {
            cursor: not-allowed; }
          .refinements .values.content button span.non-color-swatch-value.color-value.out-of-stock {
            cursor: pointer;
            opacity: 0.5;
            pointer-events: none; }
            .refinements .values.content button span.non-color-swatch-value.color-value.out-of-stock:before, .refinements .values.content button span.non-color-swatch-value.color-value.out-of-stock:after {
              content: "";
              position: absolute;
              top: 50%;
              right: 50%;
              transform: translate(50%, -50%) rotate(45deg);
              height: calc(100% + 15px);
              width: 1px; }
            .refinements .values.content button span.non-color-swatch-value.color-value.out-of-stock:after {
              background-color: #BEBEBE; }
            .refinements .values.content button span.non-color-swatch-value.color-value.out-of-stock:before {
              background-color: #FFFFFF;
              margin-right: 1px; }
            .refinements .values.content button span.non-color-swatch-value.color-value.out-of-stock.selected:after {
              display: block; }
        .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value {
          transition: border 200ms ease-out, box-shadow 200ms ease-out; }
          .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value:not(.unselectable):hover {
            border-color: #767676;
            box-shadow: none; }
          .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected, .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover {
            background: #FFFFFF;
            color: #000000;
            border-color: #000000;
            box-shadow: none; }
            .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:after, .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover:after {
              content: "";
              font-family: "icomoon";
              display: inline-block;
              font-size: 8px;
              color: #FFFFFF;
              font-weight: normal; }
            .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title), .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) {
              padding-right: calc(var(--button-padding-x) + 20px); }
              .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title):after, .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 20px;
                color: #FFFFFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
              .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm, .refinements .values.content button .btn-group-sm > span.non-color-swatch-value.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm, .refinements .values.content button .btn-group-sm > span.non-color-swatch-value.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
                padding-right: calc(20px + 20px); }
                .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm:after, .refinements .values.content button .btn-group-sm > span.non-color-swatch-value.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm:after, .refinements .values.content button .btn-group-sm > span.non-color-swatch-value.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
                  font-family: "icomoon";
                  display: inline-block;
                  font-size: 22px;
                  color: #FFFFFF;
                  font-weight: normal;
                  position: absolute;
                  transform: translateX(10px); }
              .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
              .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
              .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title) .fa, .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class*="icon-"],
              .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class^="icon-"],
              .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) .fa {
                display: none; }
            .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected::after, .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover::after {
              position: absolute;
              top: -5.33333px;
              right: -5.33333px;
              background: var(--brand-primary);
              width: 16px;
              height: 16px;
              line-height: 16px;
              padding-left: 1px;
              text-align: center;
              border-radius: 50%;
              display: none; }
          .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.unselectable, .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.unavailable {
            opacity: 0.5;
            pointer-events: none;
            pointer-events: auto;
            position: relative; }
          .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.unselectable {
            cursor: not-allowed; }
            .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.unselectable:before, .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.unselectable:after {
              content: "";
              position: absolute;
              top: 50%;
              right: 50%;
              transform: translate(50%, -50%) rotate(45deg);
              height: calc(100% + 15px);
              width: 1px; }
            .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.unselectable:after {
              background-color: #BEBEBE; }
            .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.unselectable:before {
              background-color: #FFFFFF;
              margin-right: 1px; }
          .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.out-of-stock {
            cursor: pointer;
            opacity: 0.5;
            pointer-events: none; }
            .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.out-of-stock:before, .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.out-of-stock:after {
              content: "";
              position: absolute;
              top: 50%;
              right: 50%;
              transform: translate(50%, -50%) rotate(45deg);
              height: calc(100% + 15px);
              width: 1px; }
            .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.out-of-stock:after {
              background-color: #BEBEBE; }
            .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.out-of-stock:before {
              background-color: #FFFFFF;
              margin-right: 1px; }
            .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.out-of-stock.selected:after {
              display: block; }
    .refinements .values.content button i.fa {
      position: relative;
      vertical-align: middle;
      margin-right: 12px;
      transition: box-shadow 200ms ease-out; }
    .refinements .values.content button i.fa-circle-o {
      content: "";
      width: 20px;
      height: 20px;
      border: 1px solid #BEBEBE;
      border-radius: 50%;
      background-color: #FFFFFF; }
    .refinements .values.content button i.fa-check-circle {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #FFFFFF;
      border: 1px solid #000000; }
      .refinements .values.content button i.fa-check-circle:after {
        content: "";
        background: none;
        width: 14px;
        height: 14px;
        top: calc(6px/ 2);
        left: calc(-30px + 6px/ 2);
        background-color: var(--brand-primary);
        border-radius: 50%;
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        left: auto; }
    .refinements .values.content button i.fa-square-o {
      content: "";
      width: 20px;
      height: 20px;
      border: 1px solid #BEBEBE;
      background-color: #FFFFFF;
      border-radius: var(--border-radius); }
    .refinements .values.content button i.fa-check-square {
      content: "";
      width: 20px;
      height: 20px;
      background-color: var(--brand-primary);
      border: 1px solid #000000;
      border-radius: var(--border-radius); }
      .refinements .values.content button i.fa-check-square:after {
        content: "";
        width: 20px;
        height: 20px;
        line-height: calc(20px + 2px);
        background: none;
        font-family: "icomoon";
        font-size: 13.33333px;
        text-align: center;
        color: #FFFFFF;
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        left: auto; }
  .refinements .values.content .swatches-vertical {
    flex-basis: 50%; }
  .refinements .values.content .swatches-grid {
    padding-bottom: 10px; }

.refinements .refinement-category .values.content {
  flex-direction: column; }

.refinements .refinement-category .values li {
  display: block; }

.refinements .refinement-category .values .values {
  margin-left: 30px;
  margin-top: 3px; }
  .refinements .refinement-category .values .values li {
    padding-top: 20px;
    padding-bottom: 0; }

.refinements .swatch-color-text {
  max-width: calc(100% - 20px - 12px);
  min-width: 0;
  padding-left: 12px; }

@media (min-width: 1024px) {
  .refinement-bar-horizontal .horizontal-filter-btn.custom-select, .refinement-bar-horizontal select.horizontal-filter-btn.form-control,
  .refinement-bar-horizontal select.horizontal-filter-btn {
    width: auto; }
  .horizontal-multipledropdowns-filter-refinement.card.aside {
    height: calc(16px + 12px + 14px + (1px*2));
    border-top: 1px solid var(--base-border-color);
    border-bottom: 1px solid var(--base-border-color);
    border-left: 1px solid var(--base-border-color);
    border-right: 1px solid var(--base-border-color);
    min-width: 150px;
    margin-right: 10px;
    margin-bottom: 0; }
    .horizontal-multipledropdowns-filter-refinement.card.aside.refinement-category .values.content {
      margin-left: 0;
      margin-top: 0; }
      .horizontal-multipledropdowns-filter-refinement.card.aside.refinement-category .values.content.values li {
        padding-top: 0; }
  .refinements .horizontal-multipledropdowns-filter-dropdown-menu .refinement-category .values.content button {
    padding-bottom: 20px; }
  .horizontal-multipledropdowns-filter-dropdown-menu {
    min-width: 300px;
    margin-top: 0;
    padding: 16px; }
    .horizontal-multipledropdowns-filter-dropdown-menu .card-body {
      min-height: 0; }
    .horizontal-multipledropdowns-filter-dropdown-menu .values.content {
      margin-left: 0;
      margin-top: 0;
      min-height: 0;
      max-width: 300px; }
    .horizontal-multipledropdowns-filter-dropdown-menu.show {
      position: absolute;
      transform: translate3d(0px, 43px, 0px);
      top: 0px;
      left: 0px;
      will-change: transform;
      display: block; }
  .horizontal-multipledropdowns-filter-btn {
    border: none;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 12px;
    padding-right: 10px; }
    .horizontal-multipledropdowns-filter-btn:after {
      float: right; }
  .refinement-bar-horizontal-one-dropdown .desktop-search-refinements {
    position: relative; }
    .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements:not(.sticky-filters) .refinements-container {
      padding: 0; }
    .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper {
      display: none; }
      .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-filters {
        border-top: 1px solid var(--base-border-color);
        border-bottom: 1px solid var(--base-border-color);
        border-right: 1px solid var(--base-border-color);
        border-left: 1px solid var(--base-border-color);
        margin-top: 15px; }
      .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter {
        display: flex;
        border-top: 1px solid var(--base-border-color);
        border-bottom: 1px solid var(--base-border-color);
        border-right: 1px solid var(--base-border-color);
        border-left: 1px solid var(--base-border-color);
        max-width: 1392px;
        margin-top: 15px; }
        .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .horizontal-onedropdown-filter-refinement {
          width: 33%;
          padding-top: 15px;
          padding-bottom: 15px;
          border: none; }
        .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown {
          display: flex; }
          .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown:before {
            content: "";
            font-family: "icomoon";
            display: inline-block;
            font-size: inherit;
            color: inherit;
            font-weight: normal; }
          .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown[class*="btn-"]:not(.title) {
            padding-left: calc(var(--button-padding-x) + 20px); }
            .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown[class*="btn-"]:not(.title):before {
              font-family: "icomoon";
              display: inline-block;
              font-size: 20px;
              color: inherit;
              font-weight: normal;
              position: absolute;
              transform: translateX(-30px); }
            .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown[class*="btn-"]:not(.title).btn-sm, .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .btn-group-sm > .close-one-refinements-dropdown.btn[class*="btn-"]:not(.title) {
              padding-left: calc(20px + 20px); }
              .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown[class*="btn-"]:not(.title).btn-sm:before, .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .btn-group-sm > .close-one-refinements-dropdown.btn[class*="btn-"]:not(.title):before {
                font-family: "icomoon";
                display: inline-block;
                font-size: 22px;
                color: inherit;
                font-weight: normal;
                position: absolute;
                transform: translateX(-32px); }
            .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown[class*="btn-"]:not(.title) [class*="icon-"],
            .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown[class*="btn-"]:not(.title) [class^="icon-"],
            .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown[class*="btn-"]:not(.title) .fa {
              display: none; }
    .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements.sticky-filters .horizontal-onedropdown-sortBy {
      position: relative; }
  .horizontal-onedropdown-filter-btn.title {
    width: 150px;
    background-color: transparent;
    padding-right: 10px;
    padding-left: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-top: 1px solid var(--base-border-color);
    border-bottom: 1px solid var(--base-border-color);
    border-right: 1px solid var(--base-border-color);
    border-left: 1px solid var(--base-border-color);
    text-align: left; }
  .collapse-filters-wrapper .horizontal-onedropdown-filter-btn.title::after,
  .collapse-filters-wrapper.active .horizontal-onedropdown-filter-btn.title::after {
    content: '';
    display: none; }
  .horizontal-onedropdown-filter select[name="sort-order"] {
    position: absolute;
    right: 0;
    width: 150px; }
  .horizontal-onedropdown-filter-group {
    width: 100%; }
    .horizontal-onedropdown-filter-group .horizontal-onedropdown-filter-btn {
      background-image: none;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .horizontal-onedropdown-filter-group .horizontal-onedropdown-filter-btn .horizontal-onedropdown-filter-btn-arrow {
        background-image: url(../images/icons/icon-arrow-down.svg);
        background-size: 12px;
        width: 12px;
        height: 12px; }
    .horizontal-onedropdown-filter-group.active .horizontal-onedropdown-filter-btn-arrow {
      transform: scaleY(-1); } }

.horizontal-filter-refinements-bar {
  padding-top: 16px;
  padding-bottom: 16px; }
  @media (min-width: 1024px) {
    .horizontal-filter-refinements-bar .refinements-container {
      padding: 0; } }
  @media (min-width: 1024px) {
    .horizontal-filter-refinements-bar .refinements-container select[name="sort-order"] {
      font-size: var(--button-font-size); } }
  @media (min-width: 1440px) {
    .horizontal-filter-refinements-bar.sticky-filters .refinements-container {
      padding: 0 24px; } }

.sticky-filters {
  position: fixed;
  left: 0;
  z-index: 300;
  background: #FFFFFF;
  width: 100%;
  border-bottom: 1px solid var(--base-border-color);
  padding-left: 16px;
  padding-right: 16px; }
  .sticky-header .sticky-filters {
    transition: transform 400ms ease-out; }
  .sticky-header.scroll-direction-up .sticky-filters {
    transform: translateY(var(--header-height));
    transition: transform 400ms ease-out 50ms; }

.refinements .values.content button span, .refinements .values.content button a:hover, .refinements .values.content button .text-link:hover {
  display: inline;
  text-decoration: none; }

.store-locator-container .btn-storelocator-search {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  border-width: 1px;
  border-style: solid;
  font-family: var(--button-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif);
  text-transform: var(--button-text-transform);
  transition: all 200ms ease-out;
  text-decoration: none;
  border-radius: var(--button-border-radius);
  text-align: center;
  font-weight: var(--button-font-weight);
  font-size: var(--button-font-size);
  letter-spacing: 0.1em;
  line-height: 18px;
  padding: var(--button-padding-y) var(--button-padding-x) var(--button-padding-y) var(--button-padding-x);
  font-family: var(--button-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif);
  text-transform: var(--button-text-transform);
  color: var(--button-primary-color);
  background-color: var(--button-primary-background);
  border-width: var(--button-primary-border-width);
  border-radius: var(--button-border-radius);
  border-color: var(--button-primary-border-color);
  text-decoration: none;
  text-align: center;
  font-weight: var(--button-font-weight);
  padding: var(--button-padding-y) var(--button-padding-x) var(--button-padding-y) var(--button-padding-x); }
  .store-locator-container .btn-storelocator-search:hover, .store-locator-container .btn-storelocator-search:focus, .store-locator-container .btn-storelocator-search[aria-describedby*="popover"] {
    color: var(--button-primary-color-hover);
    background-color: var(--button-primary-background-hover);
    border-color: var(--button-primary-border-color-hover);
    text-decoration: none; }
  .dark-theme .store-locator-container .btn-storelocator-search {
    color: var(--button-primary-color); }
    .dark-theme .store-locator-container .btn-storelocator-search:hover {
      color: var(--button-primary-color-hover); }
  .store-locator-container .btn-storelocator-search[class*="icon-"], .store-locator-container .btn-storelocator-search[class^="icon-"] {
    padding-left: calc(var(--button-padding-x) * 2); }
    .store-locator-container .btn-storelocator-search[class*="icon-"]:before, .store-locator-container .btn-storelocator-search[class^="icon-"]:before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }

.store-locator-container .detect-location-spacer {
  margin-bottom: 16px; }

.store-locator-container .search-form {
  width: 100%; }

.store-locator-container .results-card > .card-body {
  padding-right: 0;
  padding-bottom: 0; }

.store-locator-container .filter-results {
  margin-bottom: 24px;
  padding-right: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .store-locator-container .filter-results .btn {
    margin-bottom: 5px;
    margin-right: 5px; }

@media (min-width: 769px) {
  .store-locator-container .results {
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 0;
    max-height: 350px; } }

.store-locator-container .results-card > .card-header label {
  font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
  font-weight: normal;
  font-size: var(--base-font-size);
  line-height: 1.4;
  margin-bottom: 6px;
  text-transform: none;
  letter-spacing: 0.1em; }

.store-locator-container .results .store-result {
  display: flex;
  margin-bottom: 24px; }
  .store-locator-container .results .store-result .map-marker {
    flex-shrink: 0; }

.store-details .store-name a {
  display: block;
  margin-bottom: 5px; }

.store-details .store-name p {
  font-style: italic;
  font-weight: normal;
  margin-bottom: 5px; }

.store-details .store-map {
  display: block; }

.store-details .store-map,
.store-details .store-hours {
  margin-bottom: 5px; }

.store-details ul,
.store-details address {
  margin-bottom: 0; }

.data-checkout-stage .shipping-section .store-details .store-name a,
.product-summary-block .shipment-block .store-details .store-name a,
.product-summary-block .multi-shipping .store-details .store-name a,
.cart-page .line-item-store .store-details .store-name a {
  font-size: inherit;
  letter-spacing: 0.1em; }

.cart-page .line-item-store .store-details {
  margin-top: 5px; }

.map-marker {
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  position: relative;
  letter-spacing: 0;
  margin-right: 16px; }
  .map-marker span {
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    color: #FFFFFF;
    top: 0;
    width: 32px; }

.gm-style .gm-style-iw,
.gm-style .gm-style-iw-c {
  border-color: transparent;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  max-width: 300px; }
  .gm-style .gm-style-iw .store-details,
  .gm-style .gm-style-iw-c .store-details {
    margin-left: 0;
    font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
    font-size: 12px;
    line-height: 1.5; }
    .gm-style .gm-style-iw .store-details address,
    .gm-style .gm-style-iw-c .store-details address {
      line-height: 1.75; }
    .gm-style .gm-style-iw .store-details .store-hours,
    .gm-style .gm-style-iw-c .store-details .store-hours {
      margin-bottom: 5px; }
  .gm-style .gm-style-iw .title,
  .gm-style .gm-style-iw-c .title {
    font-size: 18px; }

.store-locator-no-results {
  padding-right: 20px;
  display: none; }

.store-locator-no-apiKey {
  display: none; }

.map-canvas {
  height: 250px;
  display: block; }
  @media (min-width: 769px) {
    .map-canvas {
      height: 450px; } }

.store-detail-page .store-name {
  font-family: var(--h3-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
  font-size: var(--h3-mobile-size);
  line-height: 1.2;
  font-weight: 400;
  letter-spacing: 0.01em;
  text-transform: none;
  font-style: normal;
  color: #000000; }
  @media (min-width: 769px) {
    .store-detail-page .store-name {
      font-size: var(--h3-tablet-size);
      line-height: 1.2;
      font-weight: 400;
      letter-spacing: 0.01em;
      text-transform: none;
      font-style: normal; } }
  @media (min-width: 1024px) {
    .store-detail-page .store-name {
      font-size: var(--h3-desktop-size);
      line-height: 1.17;
      font-weight: 400;
      letter-spacing: 0.01em;
      text-transform: none;
      font-style: normal; } }

.store-detail-page .store-attribute + .store-attribute {
  margin-top: 8px; }
  .store-detail-page .store-attribute + .store-attribute.store-additional {
    margin-top: 30px; }
  .store-detail-page .store-attribute + .store-attribute.store-image {
    margin-top: 30px; }

.store-detail-page .store-attribute label {
  font-weight: bold; }

.store-detail-page .store-map-link {
  display: block;
  margin-top: 16px; }

.styleguide .main__section > .row {
  background: #FFFFFF; }
  .styleguide .main__section > .row .brand-logo {
    height: 50px;
    max-width: var(--brand-logo-mobile-size);
    background-image: var(--brand-logo-mobile); }
    @media (min-width: 769px) {
      .styleguide .main__section > .row .brand-logo {
        height: 100px;
        max-width: var(--brand-logo-desktop-size);
        background-image: var(--brand-logo-desktop); } }
    @media (min-width: 1024px) {
      .styleguide .main__section > .row .brand-logo {
        max-width: var(--brand-logo-desktop-size);
        background-image: var(--brand-logo-desktop); } }
  .styleguide .main__section > .row .brand-favicon {
    height: 32px;
    width: 32px;
    background-image: var(--brand-favicon);
    background-size: contain;
    background-repeat: no-repeat; }
  .styleguide .main__section > .row .sg__header1, .styleguide .main__section > .row .sg__header2, .styleguide .main__section > .row .sg__header3, .styleguide .main__section > .row .sg__header4 {
    color: rgba(var(--base-font-color), 0.75); }
  .styleguide .main__section > .row .sg__n1 {
    font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
    text-decoration: none;
    text-transform: var(--primary-nav-text-transform);
    font-size: 14px;
    font-weight: var(--primary-nav-font-weight);
    line-height: 20px;
    letter-spacing: 0.03em; }
    .styleguide .main__section > .row .sg__n1:hover {
      text-decoration: none; }
    .styleguide .main__section > .row .sg__n1-secondary {
      font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
      font-size: 14px;
      font-weight: var(--secondary-nav-font-weight);
      line-height: 20px;
      text-transform: var(--secondary-nav-text-transform);
      color: var(--secondary-nav-color, var(--brand-primary)); }
      .styleguide .main__section > .row .sg__n1-secondary:hover {
        color: var(--secondary-nav-color-hover, var(--brand-primary)); }
    .styleguide .main__section > .row .sg__n1-tertiary {
      color: var(--tertiary-nav-color, var(--brand-secondary));
      font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
      font-size: 14px;
      font-weight: var(--tertiary-nav-font-weight);
      text-transform: var(--tertiary-nav-text-transform); }
      .styleguide .main__section > .row .sg__n1-tertiary:hover {
        color: var(--tertiary-nav-color-hover, var(--brand-primary)); }
  .styleguide .main__section > .row .sg__print-icon:after {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .styleguide .main__section > .row .sg__print-icon[class*="btn-"]:not(.title) {
    padding-right: calc(var(--button-padding-x) + 20px); }
    .styleguide .main__section > .row .sg__print-icon[class*="btn-"]:not(.title):after {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(10px); }
    .styleguide .main__section > .row .sg__print-icon[class*="btn-"]:not(.title).btn-sm, .styleguide .main__section > .row .btn-group-sm > .sg__print-icon.btn[class*="btn-"]:not(.title) {
      padding-right: calc(20px + 20px); }
      .styleguide .main__section > .row .sg__print-icon[class*="btn-"]:not(.title).btn-sm:after, .styleguide .main__section > .row .btn-group-sm > .sg__print-icon.btn[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
    .styleguide .main__section > .row .sg__print-icon[class*="btn-"]:not(.title) [class*="icon-"],
    .styleguide .main__section > .row .sg__print-icon[class*="btn-"]:not(.title) [class^="icon-"],
    .styleguide .main__section > .row .sg__print-icon[class*="btn-"]:not(.title) .fa {
      display: none; }
  .styleguide .main__section > .row .sg__gold-star-icon:after {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 50px;
    color: gold;
    font-weight: normal; }
  .styleguide .main__section > .row .sg__gold-star-icon[class*="btn-"]:not(.title) {
    padding-right: calc(var(--button-padding-x) + 20px); }
    .styleguide .main__section > .row .sg__gold-star-icon[class*="btn-"]:not(.title):after {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: gold;
      font-weight: normal;
      position: absolute;
      transform: translateX(10px); }
    .styleguide .main__section > .row .sg__gold-star-icon[class*="btn-"]:not(.title).btn-sm, .styleguide .main__section > .row .btn-group-sm > .sg__gold-star-icon.btn[class*="btn-"]:not(.title) {
      padding-right: calc(20px + 20px); }
      .styleguide .main__section > .row .sg__gold-star-icon[class*="btn-"]:not(.title).btn-sm:after, .styleguide .main__section > .row .btn-group-sm > .sg__gold-star-icon.btn[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: gold;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
    .styleguide .main__section > .row .sg__gold-star-icon[class*="btn-"]:not(.title) [class*="icon-"],
    .styleguide .main__section > .row .sg__gold-star-icon[class*="btn-"]:not(.title) [class^="icon-"],
    .styleguide .main__section > .row .sg__gold-star-icon[class*="btn-"]:not(.title) .fa {
      display: none; }
  .styleguide .main__section > .row .sg__custom-button {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-width: 1px;
    border-style: solid;
    font-family: var(--button-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif);
    text-transform: var(--button-text-transform);
    transition: all 200ms ease-out;
    text-decoration: none;
    border-radius: var(--button-border-radius);
    text-align: center;
    font-weight: var(--button-font-weight);
    font-size: var(--button-font-size);
    letter-spacing: 0.1em;
    line-height: 18px;
    padding: var(--button-padding-y) var(--button-padding-x) var(--button-padding-y) var(--button-padding-x);
    background: #008827;
    color: #FFFFFF;
    border-color: #008827; }
    .styleguide .main__section > .row .sg__custom-button:hover, .styleguide .main__section > .row .sg__custom-button:focus, .styleguide .main__section > .row .sg__custom-button[aria-describedby*="popover"] {
      background: #006f20;
      border-color: #006f20; }
    .styleguide .main__section > .row .sg__custom-button[class*="icon-"], .styleguide .main__section > .row .sg__custom-button[class^="icon-"] {
      padding-left: calc(var(--button-padding-x) * 2); }
      .styleguide .main__section > .row .sg__custom-button[class*="icon-"]:before, .styleguide .main__section > .row .sg__custom-button[class^="icon-"]:before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
  .styleguide .main__section > .row [class*="font"]:after {
    content: attr(variable); }
  .styleguide .main__section > .row .color-value.background--blue::after {
    content: "#007c9e"; }
  .styleguide .main__section > .row .color-value.background--indigo::after {
    content: "#6610f2"; }
  .styleguide .main__section > .row .color-value.background--purple::after {
    content: "#6f42c1"; }
  .styleguide .main__section > .row .color-value.background--pink::after {
    content: "#e83e8c"; }
  .styleguide .main__section > .row .color-value.background--red::after {
    content: "#A3080F"; }
  .styleguide .main__section > .row .color-value.background--orange::after {
    content: "#fd7e14"; }
  .styleguide .main__section > .row .color-value.background--yellow::after {
    content: "#FFCC00"; }
  .styleguide .main__section > .row .color-value.background--green::after {
    content: "#008827"; }
  .styleguide .main__section > .row .color-value.background--teal::after {
    content: "#20c997"; }
  .styleguide .main__section > .row .color-value.background--cyan::after {
    content: "#17a2b8"; }
  .styleguide .main__section > .row .color-value.background--white::after {
    content: "#FFFFFF"; }
  .styleguide .main__section > .row .color-value.background--gray::after {
    content: "#6c757d"; }
  .styleguide .main__section > .row .color-value.background--gray-dark::after {
    content: "#343a40"; }
  .styleguide .main__section > .row .color-value.background--white::after {
    content: "#FFFFFF"; }
  .styleguide .main__section > .row .color-value.background--off-white::after {
    content: "#F2F2F2"; }
  .styleguide .main__section > .row .color-value.background--light-gray::after {
    content: "#C5C5C5"; }
  .styleguide .main__section > .row .color-value.background--gray::after {
    content: "#BEBEBE"; }
  .styleguide .main__section > .row .color-value.background--dark-gray::after {
    content: "#767676"; }
  .styleguide .main__section > .row .color-value.background--black::after {
    content: "#000000"; }
  .styleguide .main__section > .row .color-value.background--green::after {
    content: "#008827"; }
  .styleguide .main__section > .row .color-value.background--red::after {
    content: "#A3080F"; }
  .styleguide .main__section > .row .color-value.background--yellow::after {
    content: "#FFCC00"; }
  .styleguide .main__section > .row .color-value.background--blue::after {
    content: "#007c9e"; }
  .styleguide .main__section > .row .color-value.background--success::after {
    content: "#008827"; }
  .styleguide .main__section > .row .color-value.background--danger::after {
    content: "#A3080F"; }
  .styleguide .main__section > .row .color-value.background--warning::after {
    content: "#FFCC00"; }
  .styleguide .main__section > .row .color-value.background--info::after {
    content: "#007c9e"; }
  .styleguide .main__section > .row .color-value[class*="background--brand-"]:after {
    content: attr(variable); }
  .styleguide .main__section > .row .grid-columns__breakpoint-xs::after {
    content: "0 - 768px"; }
  .styleguide .main__section > .row .grid-columns__breakpoint-md::after {
    content: "769px - 1439px"; }
  .styleguide .main__section > .row .grid-columns__breakpoint-xl::after {
    content: "1440px +"; }
  .styleguide .main__section > .row .grid-columns__gutter-size-xs::after {
    content: "32px"; }
  .styleguide .main__section > .row .grid-columns__gutter-size-md::after {
    content: "32px"; }
  .styleguide .main__section > .row .grid-columns__gutter-size-xl::after {
    content: "48px"; }
  .styleguide .main__section > .row .grid-columns__xs {
    padding-right: 16px;
    padding-left: 16px; }
    .styleguide .main__section > .row .grid-columns__xs .row {
      margin-right: -16px;
      margin-left: -16px; }
    .styleguide .main__section > .row .grid-columns__xs .col {
      padding-right: 16px;
      padding-left: 16px; }
  .styleguide .main__section > .row .grid-columns__md {
    padding-right: 16px;
    padding-left: 16px; }
    .styleguide .main__section > .row .grid-columns__md .row {
      margin-right: -16px;
      margin-left: -16px; }
    .styleguide .main__section > .row .grid-columns__md .col {
      padding-right: 16px;
      padding-left: 16px; }
  .styleguide .main__section > .row .grid-columns__xl {
    padding-right: 24px;
    padding-left: 24px; }
    .styleguide .main__section > .row .grid-columns__xl .row {
      margin-right: -24px;
      margin-left: -24px; }
    .styleguide .main__section > .row .grid-columns__xl .col {
      padding-right: 24px;
      padding-left: 24px; }

.styleguide .main .section--typography .bp--mobile::after {
  content: "0 - 768px"; }

.styleguide .main .section--typography .bp--tablet::after {
  content: "769px - 1023px"; }

.styleguide .main .section--typography .bp--mobile-tablet::after {
  content: "0 - 1023px"; }

.styleguide .main .section--typography .bp--desktop::after {
  content: "1024px +"; }

.styleguide .main .section--typography .h1--mobile .h1 {
  font-size: var(--h1-mobile-size);
  line-height: 1.14;
  font-weight: 700;
  letter-spacing: 0.01em;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h1--tablet .h1 {
  font-size: var(--h1-tablet-size);
  line-height: 1.14;
  font-weight: 700;
  letter-spacing: 0.01em;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h1--desktop .h1 {
  font-size: var(--h1-desktop-size);
  line-height: 1.11;
  font-weight: 700;
  letter-spacing: 0.01em;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h2--mobile .h2 {
  font-size: var(--h2-mobile-size);
  line-height: 1.17;
  font-weight: 700;
  letter-spacing: 0.01em;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h2--tablet .h2 {
  font-size: var(--h2-tablet-size);
  line-height: 1.17;
  font-weight: 700;
  letter-spacing: 0.01em;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h2--desktop .h2 {
  font-size: var(--h2-desktop-size);
  line-height: 1.15;
  font-weight: 700;
  letter-spacing: 0.01em;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h3--mobile .h3 {
  font-size: var(--h3-mobile-size);
  line-height: 1.2;
  font-weight: 400;
  letter-spacing: 0.01em;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h3--tablet .h3 {
  font-size: var(--h3-tablet-size);
  line-height: 1.2;
  font-weight: 400;
  letter-spacing: 0.01em;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h3--desktop .h3 {
  font-size: var(--h3-desktop-size);
  line-height: 1.17;
  font-weight: 400;
  letter-spacing: 0.01em;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h4--mobile .h4 {
  font-size: var(--h4-mobile-size);
  line-height: 1.25;
  font-weight: 400;
  letter-spacing: 0.01em;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h4--tablet .h4 {
  font-size: var(--h4-tablet-size);
  line-height: 1.25;
  font-weight: 400;
  letter-spacing: 0.01em;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h4--desktop .h4 {
  font-size: var(--h4-desktop-size);
  line-height: 1.2;
  font-weight: 400;
  letter-spacing: 0.01em;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h5--mobile .h5 {
  font-size: var(--h5-mobile-size);
  line-height: 1.25;
  font-weight: 400;
  letter-spacing: 0.01em;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h5--tablet .h5 {
  font-size: var(--h5-tablet-size);
  line-height: 1.25;
  font-weight: 400;
  letter-spacing: 0.01em;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h5--desktop .h5 {
  font-size: var(--h5-desktop-size);
  line-height: 1.2;
  font-weight: 400;
  letter-spacing: 0.01em;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h6--mobile .h6 {
  font-size: var(--h6-mobile-size);
  line-height: 1.4;
  font-weight: normal;
  letter-spacing: 0.1em;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h6--tablet .h6 {
  font-size: var(--h6-tablet-size);
  line-height: 1.4;
  font-weight: normal;
  letter-spacing: 0.1em;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h6--desktop .h6 {
  font-size: var(--h6-desktop-size);
  line-height: 1.4;
  font-weight: normal;
  letter-spacing: 0.1em;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .b1--mobile .b1 {
  font-size: var(--b1-mobile-size);
  line-height: 1.19;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .b1--tablet .b1 {
  font-size: var(--b1-tablet-size);
  line-height: 1.19;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .b1--desktop .b1 {
  font-size: var(--b1-desktop-size);
  line-height: 1.19;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .b2--mobile .b2 {
  font-size: var(--b2-mobile-size);
  line-height: 1.19;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .b2--tablet .b2 {
  font-size: var(--b2-tablet-size);
  line-height: 1.19;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .b2--desktop .b2 {
  font-size: var(--b2-desktop-size);
  line-height: 1.19;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .b3--mobile .b3 {
  font-size: var(--b3-mobile-size);
  line-height: 1.19;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .b3--tablet .b3 {
  font-size: var(--b3-tablet-size);
  line-height: 1.19;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .b3--desktop .b3 {
  font-size: var(--b3-desktop-size);
  line-height: 1.19;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .b4--mobile .b4 {
  font-size: var(--b4-mobile-size);
  line-height: 1.19;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .b4--tablet .b4 {
  font-size: var(--b4-tablet-size);
  line-height: 1.19;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .b4--desktop .b4 {
  font-size: var(--b4-desktop-size);
  line-height: 1.19;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .p--mobile .p--small {
  font-size: calc(var(--base-font-size) - 2px);
  line-height: 1.4; }

.styleguide .main .section--typography .p--mobile .p {
  font-size: calc(var(--base-font-size));
  line-height: 1.4; }

.styleguide .main .section--typography .p--mobile .p--large {
  font-size: calc(var(--base-font-size) + 1px);
  line-height: 1.4; }

.styleguide .main .section--typography .p--desktop .p--small {
  font-size: calc(var(--base-font-size) - 1px);
  line-height: 1.4; }

.styleguide .main .section--typography .p--desktop .p {
  font-size: calc(var(--base-font-size));
  line-height: 1.4; }

.styleguide .main .section--typography .p--desktop .p--large {
  font-size: calc(var(--base-font-size) + 2px);
  line-height: 1.4; }

.parts-finder-signup-message {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  position: fixed;
  top: 0;
  padding: 50px 16px 16px;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  z-index: 1100; }

.parts-finder-signup-alert {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  text-align: center;
  padding: 20px 30px; }
  .parts-finder-signup-alert.show {
    display: block; }
  .parts-finder-signup-alert:not(.alert-dismissible) {
    animation: fadeIn 200ms, fadeOut 200ms linear calc(5s - 200ms); }
  .parts-finder-signup-alert.alert-dismissible {
    animation: fadeIn 200ms;
    padding-right: 50px; }
    .parts-finder-signup-alert.alert-dismissible .close {
      padding: 16px; }

/* ALL FILES KEPT LOCAL, PREVENTS ACCIDENTIAL BASE STYLES INCLUSION */
.hide-gift-certificates-applied {
  display: none; }

.gift-certificates-applied-total {
  color: #A3080F; }

.salesforce-payments-errors {
  font-size: 0.8em;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  border: 1px solid lightblue;
  border-radius: var(--border-radius);
  background: #fee url(../../images/exclamation.svg) 0.5rem 0.5rem/1rem no-repeat; }

.salesforce-payments-errors:empty {
  display: none; }

.salesforce-paymentrequest-element-errors {
  margin-top: 0.75rem; }

.salesforce-paymentrequest-element[disabled] {
  display: none; }

.salesforce-paymentrequest-element:empty {
  display: none; }

.salesforce-paymentrequest-element div:not(:first-child) {
  margin-top: 1em;
  padding: 0; }

.salesforce-secure-payment {
  margin-left: 1.2rem;
  font-size: 0.8em;
  padding: 0.3rem 0.5rem 0.5rem 1.5rem;
  background: url(../../images/padlock.svg) no-repeat; }

.salesforce-paymentrequest-element-skeletons {
  display: none; }

.salesforce-paypal-messaging {
  height: 4rem;
  padding: 1.1rem 0 0; }

.salesforce-paypal-messaging-hidden {
  display: none; }

.commercepayments.cart-and-ipay .add-to-cart {
  width: 100%;
  display: block; }

@media (max-width: 543.98px) {
  .commercepayments.cart-and-ipay .add-to-cart {
    width: 100%;
    margin: 0; } }

.salesforce-buynow-element {
  width: 100%; }

.salesforce-buynow-element:not(:empty) {
  margin-top: 1em; }

.salesforce-buynow-element-errors {
  margin-top: 0.75rem;
  text-align: left; }

.salesforce-buynow-element[disabled] {
  display: none; }

.salesforce-buynow-element div:not(:first-child) {
  margin-top: 1em; }

.commercepayments.cart-and-ipay .add-to-cart {
  width: 100%;
  display: block; }

@media (max-width: 543.98px) {
  .commercepayments.cart-and-ipay .add-to-cart {
    width: 100%;
    margin: 0; } }

.salesforce-buynow-element {
  width: 100%; }

.salesforce-buynow-element:not(:empty) {
  margin-top: 1em; }

.salesforce-buynow-element-errors {
  margin-top: 0.75rem;
  text-align: left; }

.salesforce-buynow-element[disabled] {
  display: none; }

.salesforce-buynow-element div:not(:first-child) {
  margin-top: 1em; }

.commercepayments .card.payment-method .card-header {
  border-bottom: 0; }

.commercepayments .card.payment-method .card-body {
  padding: 0; }

.salesforce-payments-element-errors {
  margin-bottom: 0.75rem; }

.salesforce-payments-errors:empty {
  display: none; }

.salesforce-payments-element .sfpp-payment-method-choice,
.salesforce-payments-element .sfpp-payment-method-choice .sfpp-payment-method-body,
.sfpp-payment-method-ideal-bank-element,
.sfpp-payment-method-eps-bank-element,
.sfpp-payment-method-iban-element {
  border-color: rgba(0, 0, 0, 0.125); }

.sfpp-payment-method-card-number-label::before,
.sfpp-payment-method-card-expiry-label::before,
.sfpp-payment-method-card-cvc-label::before,
.sfpp-payment-method-iban-label::before {
  content: "*";
  color: #A3080F; }

.sfpp-payment-method-body-card .sfpp-payment-method-card-number-element,
.sfpp-payment-method-body-card .sfpp-payment-method-card-expiry-element,
.sfpp-payment-method-body-card .sfpp-payment-method-card-cvc-element,
.sfpp-payment-method-body-eps .sfpp-payment-method-eps-bank-element,
.sfpp-payment-method-body-ideal .sfpp-payment-method-ideal-bank-element,
.sfpp-payment-method-body-sepa_debit .sfpp-payment-method-iban-element {
  border-color: rgba(0, 0, 0, 0.125);
  border-radius: var(--border-radius); }

.sfpp-payment-method-body .sfpp-payment-method-element--invalid {
  border-color: #A3080F; }

.salesforce-payments-element .sfpp-payment-instrument {
  border-color: rgba(0, 0, 0, 0.125); }

.salesforce-payments-element .sfpp-payment-instrument .sfpp-payment-instrument-description {
  font-size: 0.938em; }

.data-checkout-stage[data-checkout-stage] button.submit-billing {
  display: none; }

.data-checkout-stage[data-checkout-stage] button.hidden {
  display: none !important; }

.data-checkout-stage[data-checkout-stage=payment] button.submit-billing {
  display: block; }

.data-checkout-stage .salesforce-paymentrequest-element {
  display: block; }

.data-checkout-stage[data-checkout-stage=customer] .salesforce-paymentrequest-element {
  display: none; }

.salesforce-paymentrequest-element:not(:empty) {
  margin-bottom: 1rem; }

.express-checkout-buttons {
  min-height: 2.75rem; }
  .express-checkout-buttons .salesforce-paymentrequest-element {
    border: 1px solid transparent; }
    .express-checkout-buttons .salesforce-paymentrequest-element:not(:empty) {
      margin: 0; }

.data-checkout-stage .card.ghost {
  display: none; }

.data-checkout-stage .salesforce-paymentrequest-element {
  display: block; }

.data-checkout-stage[data-checkout-stage=customer] .salesforce-paymentrequest-element {
  display: none; }

.express-checkout-buttons {
  min-height: 2.75rem; }

.salesforce-paymentrequest-element {
  line-height: 0;
  min-height: 40px;
  border: 1px solid transparent; }
  .salesforce-paymentrequest-element:not(.salesforce-paymentrequest-element-loading) div:first-child:nth-last-child(2),
  .salesforce-paymentrequest-element:not(.salesforce-paymentrequest-element-loading) div:first-child:nth-last-child(2) ~ div {
    display: inline-block;
    width: 50%;
    margin-top: 0; }

.salesforce-paymentrequest-element-loading div {
  display: none; }

@keyframes express-checkout-buttons-background {
  from {
    background-position: 0 0; }
  to {
    background-position: 200% 0; } }

.salesforce-paymentrequest-element-skeleton button {
  background: #f8f9fa;
  /* For browsers that do not support gradients */
  background: linear-gradient(45deg, #dee2e6, #e9ecef, #dee2e6, #e9ecef, #dee2e6, #e9ecef, #dee2e6, #e9ecef, #dee2e6, #e9ecef);
  /* Standard syntax */
  background-size: 200% 200%;
  animation: express-checkout-buttons-background 10s linear infinite;
  width: 100%;
  height: 40px; }

.in-store-inventory-dialog .store-locator-container,
.pickup-in-store .store-locator-container {
  padding-left: 0;
  padding-right: 0; }
  .in-store-inventory-dialog .store-locator-container .store-result label::before,
  .in-store-inventory-dialog .store-locator-container .store-result label::after,
  .pickup-in-store .store-locator-container .store-result label::before,
  .pickup-in-store .store-locator-container .store-result label::after {
    margin-top: 2px;
    margin-left: 2px; }

.pickup-in-store {
  margin-bottom: 16px; }
  .pickup-in-store .select-store,
  .pickup-in-store ~ .change-store {
    margin-top: 16px; }
  .pickup-in-store .results-card .card-title {
    border-top: none; }

.cart-page .line-item-store {
  margin-top: 16px; }
  .cart-page .line-item-store .store-details {
    margin-left: 16px; }

/*
 * Based off of Yotpo CSS Widget Version: 2020-04-26_10-52-32
 */
.yotpo .main-widget a {
  color: var(--brand-primary);
  font-weight: normal;
  text-decoration: none;
  cursor: pointer;
  color: var(--brand-primary) !important; }
  .yotpo .main-widget a:hover {
    color: var(--brand-secondary);
    text-decoration: underline; }
  .dark-theme .yotpo .main-widget a {
    color: #FFFFFF; }
    .dark-theme .yotpo .main-widget a:hover {
      color: #FFFFFF; }
  .yotpo .main-widget a:hover {
    color: var(--brand-secondary) !important;
    text-decoration: underline !important; }

.yotpo .main-widget div, .yotpo .main-widget span, .yotpo .main-widget p, .yotpo .main-widget a, .yotpo .main-widget img, .yotpo .main-widget i, .yotpo .main-widget strong, .yotpo .main-widget sup, .yotpo .main-widget ul, .yotpo .main-widget li, .yotpo .main-widget form, .yotpo .main-widget label {
  letter-spacing: 0.1em;
  font-size: calc(var(--base-font-size));
  font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
  line-height: 1.43;
  color: var(--base-font-color); }
  @media (min-width: 1024px) {
    .yotpo .main-widget div, .yotpo .main-widget span, .yotpo .main-widget p, .yotpo .main-widget a, .yotpo .main-widget img, .yotpo .main-widget i, .yotpo .main-widget strong, .yotpo .main-widget sup, .yotpo .main-widget ul, .yotpo .main-widget li, .yotpo .main-widget form, .yotpo .main-widget label {
      font-size: calc(var(--base-font-size)); } }
  .yotpo .main-widget div:focus, .yotpo .main-widget span:focus, .yotpo .main-widget p:focus, .yotpo .main-widget a:focus, .yotpo .main-widget img:focus, .yotpo .main-widget i:focus, .yotpo .main-widget strong:focus, .yotpo .main-widget sup:focus, .yotpo .main-widget ul:focus, .yotpo .main-widget li:focus, .yotpo .main-widget form:focus, .yotpo .main-widget label:focus {
    outline: none; }

.yotpo .main-widget .yotpo-drop-down-layout .yotpo-dropdown-button .selected {
  display: inline; }

.yotpo .main-widget .yotpo-drop-down-layout .yotpo-dropdown-button .yotpo-icon {
  padding-top: 0; }

.yotpo .main-widget .yotpo-drop-down-layout .yotpo-dropdown .selected-item {
  color: var(--brand-primary) !important; }

.yotpo .yotpo-icon {
  font-family: "icomoon" !important; }
  .yotpo .yotpo-icon:before {
    font-family: "icomoon" !important; }

.yotpo .rating-star, .yotpo .review-star {
  height: auto;
  line-height: normal;
  vertical-align: middle;
  font-size: 14px !important; }
  .yotpo .rating-star:before, .yotpo .review-star:before {
    font-family: "icomoon" !important; }
  .product-tile .yotpo .rating-star, .product-tile .yotpo .review-star {
    font-size: var(--product-tile-star-size) !important; }

.yotpo .review-star {
  cursor: pointer; }

.yotpo .yotpo-icon-star:before {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: inherit;
  color: var(--product-tile-star-color);
  font-weight: normal; }

.yotpo .yotpo-icon-star[class*="btn-"]:not(.title) {
  padding-left: calc(var(--button-padding-x) + 20px); }
  .yotpo .yotpo-icon-star[class*="btn-"]:not(.title):before {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: var(--product-tile-star-color);
    font-weight: normal;
    position: absolute;
    transform: translateX(-30px); }
  .yotpo .yotpo-icon-star[class*="btn-"]:not(.title).btn-sm, .yotpo .btn-group-sm > .yotpo-icon-star.btn[class*="btn-"]:not(.title) {
    padding-left: calc(20px + 20px); }
    .yotpo .yotpo-icon-star[class*="btn-"]:not(.title).btn-sm:before, .yotpo .btn-group-sm > .yotpo-icon-star.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: var(--product-tile-star-color);
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .yotpo .yotpo-icon-star[class*="btn-"]:not(.title) [class*="icon-"],
  .yotpo .yotpo-icon-star[class*="btn-"]:not(.title) [class^="icon-"],
  .yotpo .yotpo-icon-star[class*="btn-"]:not(.title) .fa {
    display: none; }

.yotpo .yotpo-icon-half-star:before {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: inherit;
  color: var(--product-tile-star-color);
  font-weight: normal; }

.yotpo .yotpo-icon-half-star[class*="btn-"]:not(.title) {
  padding-left: calc(var(--button-padding-x) + 20px); }
  .yotpo .yotpo-icon-half-star[class*="btn-"]:not(.title):before {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: var(--product-tile-star-color);
    font-weight: normal;
    position: absolute;
    transform: translateX(-30px); }
  .yotpo .yotpo-icon-half-star[class*="btn-"]:not(.title).btn-sm, .yotpo .btn-group-sm > .yotpo-icon-half-star.btn[class*="btn-"]:not(.title) {
    padding-left: calc(20px + 20px); }
    .yotpo .yotpo-icon-half-star[class*="btn-"]:not(.title).btn-sm:before, .yotpo .btn-group-sm > .yotpo-icon-half-star.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: var(--product-tile-star-color);
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .yotpo .yotpo-icon-half-star[class*="btn-"]:not(.title) [class*="icon-"],
  .yotpo .yotpo-icon-half-star[class*="btn-"]:not(.title) [class^="icon-"],
  .yotpo .yotpo-icon-half-star[class*="btn-"]:not(.title) .fa {
    display: none; }

.yotpo .yotpo-icon-empty-star:before {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: inherit;
  color: var(--product-tile-star-color);
  font-weight: normal; }

.yotpo .yotpo-icon-empty-star[class*="btn-"]:not(.title) {
  padding-left: calc(var(--button-padding-x) + 20px); }
  .yotpo .yotpo-icon-empty-star[class*="btn-"]:not(.title):before {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: var(--product-tile-star-color);
    font-weight: normal;
    position: absolute;
    transform: translateX(-30px); }
  .yotpo .yotpo-icon-empty-star[class*="btn-"]:not(.title).btn-sm, .yotpo .btn-group-sm > .yotpo-icon-empty-star.btn[class*="btn-"]:not(.title) {
    padding-left: calc(20px + 20px); }
    .yotpo .yotpo-icon-empty-star[class*="btn-"]:not(.title).btn-sm:before, .yotpo .btn-group-sm > .yotpo-icon-empty-star.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: var(--product-tile-star-color);
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .yotpo .yotpo-icon-empty-star[class*="btn-"]:not(.title) [class*="icon-"],
  .yotpo .yotpo-icon-empty-star[class*="btn-"]:not(.title) [class^="icon-"],
  .yotpo .yotpo-icon-empty-star[class*="btn-"]:not(.title) .fa {
    display: none; }

.yotpo.yotpo-main-widget .main-widget .yotpo-default-button,
.yotpo input.yotpo-default-button.primary-color-btn {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  border-width: 1px;
  border-style: solid;
  font-family: var(--button-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif);
  text-transform: var(--button-text-transform);
  transition: all 200ms ease-out;
  text-decoration: none;
  border-radius: var(--button-border-radius);
  text-align: center;
  font-weight: var(--button-font-weight);
  font-size: var(--button-font-size);
  letter-spacing: 0.1em;
  line-height: 18px;
  padding: var(--button-padding-y) var(--button-padding-x) var(--button-padding-y) var(--button-padding-x);
  font-family: var(--button-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif);
  text-transform: var(--button-text-transform);
  color: var(--button-primary-color);
  background-color: var(--button-primary-background);
  border-width: var(--button-primary-border-width);
  border-radius: var(--button-border-radius);
  border-color: var(--button-primary-border-color);
  text-decoration: none;
  text-align: center;
  font-weight: var(--button-font-weight);
  padding: var(--button-padding-y) var(--button-padding-x) var(--button-padding-y) var(--button-padding-x);
  background-color: var(--button-primary-background) !important;
  border: 1px solid var(--button-primary-border-color) !important;
  height: auto; }
  .yotpo.yotpo-main-widget .main-widget .yotpo-default-button:hover, .yotpo.yotpo-main-widget .main-widget .yotpo-default-button:focus, .yotpo.yotpo-main-widget .main-widget .yotpo-default-button[aria-describedby*="popover"],
  .yotpo input.yotpo-default-button.primary-color-btn:hover,
  .yotpo input.yotpo-default-button.primary-color-btn:focus,
  .yotpo input.yotpo-default-button.primary-color-btn[aria-describedby*="popover"] {
    color: var(--button-primary-color-hover);
    background-color: var(--button-primary-background-hover);
    border-color: var(--button-primary-border-color-hover);
    text-decoration: none; }
  .dark-theme .yotpo.yotpo-main-widget .main-widget .yotpo-default-button, .dark-theme
  .yotpo input.yotpo-default-button.primary-color-btn {
    color: var(--button-primary-color); }
    .dark-theme .yotpo.yotpo-main-widget .main-widget .yotpo-default-button:hover, .dark-theme
    .yotpo input.yotpo-default-button.primary-color-btn:hover {
      color: var(--button-primary-color-hover); }
  .yotpo.yotpo-main-widget .main-widget .yotpo-default-button[class*="icon-"], .yotpo.yotpo-main-widget .main-widget .yotpo-default-button[class^="icon-"],
  .yotpo input.yotpo-default-button.primary-color-btn[class*="icon-"],
  .yotpo input.yotpo-default-button.primary-color-btn[class^="icon-"] {
    padding-left: calc(var(--button-padding-x) * 2); }
    .yotpo.yotpo-main-widget .main-widget .yotpo-default-button[class*="icon-"]:before, .yotpo.yotpo-main-widget .main-widget .yotpo-default-button[class^="icon-"]:before,
    .yotpo input.yotpo-default-button.primary-color-btn[class*="icon-"]:before,
    .yotpo input.yotpo-default-button.primary-color-btn[class^="icon-"]:before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
  .yotpo.yotpo-main-widget .main-widget .yotpo-default-button:hover,
  .yotpo input.yotpo-default-button.primary-color-btn:hover {
    background-color: var(--button-primary-background-hover) !important;
    background: var(--button-primary-background-hover) !important;
    border: 1px solid var(--button-primary-border-color-hover) !important; }
  .yotpo.yotpo-main-widget .main-widget .yotpo-default-button .yotpo-icon::before, .yotpo.yotpo-main-widget .main-widget .yotpo-default-button .write-question-review-button-text,
  .yotpo input.yotpo-default-button.primary-color-btn .yotpo-icon::before,
  .yotpo input.yotpo-default-button.primary-color-btn .write-question-review-button-text {
    color: var(--button-primary-color) !important; }
  .yotpo.yotpo-main-widget .main-widget .yotpo-default-button .write-question-review-button-text,
  .yotpo input.yotpo-default-button.primary-color-btn .write-question-review-button-text {
    width: auto;
    height: auto;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit !important;
    font-weight: inherit;
    font-stretch: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    text-align: inherit; }

.yotpo .yotpo-icon-btn-small > span:before {
  color: var(--brand-primary); }

.yotpo .yotpo-icon-btn-small:hover > span:before {
  color: var(--brand-secondary); }

.yotpo.yotpo-small .yotpo-bottomline .yotpo-stars {
  display: flex;
  align-items: center; }

.yotpo.yotpo-small .yotpo-bottomline .write-review-btn-hidden {
  display: none; }

.yotpo.yotpo-small .yotpo-bottomline .text-m {
  display: none;
  font-size: 13px;
  margin-left: 5px;
  color: #C5C5C5 !important; }
  .yotpo.yotpo-small .yotpo-bottomline .text-m:hover {
    color: #767676 !important; }

.product-tile .yotpo.yotpo-small .yotpo-bottomline .text-m {
  display: none;
  font-size: 11px;
  color: #C5C5C5 !important; }
  .product-tile .yotpo.yotpo-small .yotpo-bottomline .text-m:hover {
    color: #767676 !important; }

.yotpo.yotpo-main-widget .yotpo-label-container {
  text-align: right;
  border-top: 1px solid var(--base-border-color); }
  .yotpo.yotpo-main-widget .yotpo-label-container .yotpo-logo-link-new {
    display: inline-block; }
  .yotpo.yotpo-main-widget .yotpo-label-container .yotpo-logo-title,
  .yotpo.yotpo-main-widget .yotpo-label-container .yotpo-logo-btn {
    vertical-align: middle;
    color: var(--brand-primary); }
  .yotpo.yotpo-main-widget .yotpo-label-container .yotpo-logo-title {
    letter-spacing: 0.1em;
    font-size: calc(var(--base-font-size)); }
    @media (min-width: 1024px) {
      .yotpo.yotpo-main-widget .yotpo-label-container .yotpo-logo-title {
        font-size: calc(var(--base-font-size)); } }
    .yotpo.yotpo-main-widget .yotpo-label-container .yotpo-logo-title:hover {
      color: var(--brand-secondary); }
  .yotpo.yotpo-main-widget .yotpo-label-container .yotpo-logo-btn {
    font-size: 24px; }

.yotpo.yotpo-main-widget .yotpo-bottomline {
  text-align: center; }
  .yotpo.yotpo-main-widget .yotpo-bottomline .bottom-line-items {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0; }
  .yotpo.yotpo-main-widget .yotpo-bottomline .avg-score {
    font-family: var(--h2-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
    font-size: var(--h2-mobile-size);
    line-height: 1.14;
    font-weight: 700;
    letter-spacing: 0.01em;
    text-transform: none;
    font-style: normal;
    width: 100%;
    display: block;
    padding-top: 0;
    float: none;
    height: auto;
    color: var(--base-font-color); }
    @media (min-width: 769px) {
      .yotpo.yotpo-main-widget .yotpo-bottomline .avg-score {
        font-size: var(--h2-tablet-size);
        line-height: 1.17;
        font-weight: 700;
        letter-spacing: 0.01em;
        text-transform: none;
        font-style: normal; } }
    @media (min-width: 1024px) {
      .yotpo.yotpo-main-widget .yotpo-bottomline .avg-score {
        font-size: var(--h2-desktop-size);
        line-height: 1.15;
        font-weight: 700;
        letter-spacing: 0.01em;
        text-transform: none;
        font-style: normal; } }
  .yotpo.yotpo-main-widget .yotpo-bottomline .rating-stars-container {
    float: none;
    height: auto;
    width: auto;
    line-height: normal;
    vertical-align: top;
    margin-left: 0;
    padding-left: 5px;
    white-space: nowrap; }
    @media (min-width: 769px) {
      .yotpo.yotpo-main-widget .yotpo-bottomline .rating-stars-container {
        padding-right: 5px; } }
    .yotpo.yotpo-main-widget .yotpo-bottomline .rating-stars-container .rating-star {
      height: auto;
      float: none; }
      .yotpo.yotpo-main-widget .yotpo-bottomline .rating-stars-container .rating-star::before {
        font-size: 20px; }
        @media (min-width: 769px) {
          .yotpo.yotpo-main-widget .yotpo-bottomline .rating-stars-container .rating-star::before {
            font-size: 20px; } }
  .yotpo.yotpo-main-widget .yotpo-bottomline .reviews-qa-labels-container {
    float: none;
    display: inline-block;
    padding-top: 0;
    margin-left: 0;
    position: static;
    height: 28px;
    line-height: 28px;
    vertical-align: middle; }
    .yotpo.yotpo-main-widget .yotpo-bottomline .reviews-qa-labels-container .reviews-qa-label {
      vertical-align: middle; }
  @media (min-width: 769px) {
    .yotpo.yotpo-main-widget .yotpo-bottomline .bottom-line-items {
      text-align: center;
      margin-bottom: 10px; } }

.yotpo.yotpo-main-widget .main-widget form .write-form .yotpo-regular-box {
  border-top: 0; }

.yotpo.yotpo-main-widget .main-widget form .write-form .yotpo-header-title {
  margin: 0;
  font-family: var(--h5-font, var(--header-font, var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif));
  font-size: var(--h5-mobile-size);
  line-height: 1.25;
  font-weight: 400;
  letter-spacing: 0.01em;
  text-transform: none;
  font-style: normal; }
  @media (min-width: 769px) {
    .yotpo.yotpo-main-widget .main-widget form .write-form .yotpo-header-title {
      font-size: var(--h5-tablet-size);
      line-height: 1.25;
      font-weight: 400;
      letter-spacing: 0.01em;
      text-transform: none;
      font-style: normal; } }
  @media (min-width: 1024px) {
    .yotpo.yotpo-main-widget .main-widget form .write-form .yotpo-header-title {
      font-size: var(--h5-desktop-size);
      line-height: 1.2;
      font-weight: 400;
      letter-spacing: 0.01em;
      text-transform: none;
      font-style: normal; } }

.yotpo.yotpo-main-widget .main-widget form .write-form .yotpo-mandatory-explain {
  display: none;
  font-size: var(--base-font-size);
  color: #A3080F; }

.yotpo.yotpo-main-widget .main-widget form .write-form .yotpo-mandatory-mark {
  display: none; }

.yotpo.yotpo-main-widget .main-widget form .write-form br {
  display: none; }

.yotpo.yotpo-main-widget .main-widget form .write-form label.y-label, .yotpo.yotpo-main-widget .main-widget form .write-form span.y-label {
  padding: 0;
  color: var(--base-font-color);
  font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
  font-weight: normal;
  font-size: var(--base-font-size);
  margin-bottom: 6px;
  text-transform: none;
  letter-spacing: 0.1em; }
  .yotpo.yotpo-main-widget .main-widget form .write-form label.y-label .form-input-error, .yotpo.yotpo-main-widget .main-widget form .write-form span.y-label .form-input-error {
    color: #A3080F;
    font-size: var(--base-font-size); }

.yotpo.yotpo-main-widget .main-widget form .write-form .y-input {
  margin-top: 0;
  margin-bottom: 5px;
  display: block;
  border-color: #C5C5C5;
  background-color: #FFFFFF;
  outline: none;
  box-shadow: none;
  color: var(--base-font-color);
  border-width: 1px;
  border-radius: var(--border-radius);
  font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.1em;
  padding-top: 12px;
  padding-bottom: 14px;
  padding-left: 20px;
  padding-right: 20px;
  height: calc(16px + 12px + 14px + (1px*2));
  outline: 0; }
  @media (min-width: 769px) {
    .yotpo.yotpo-main-widget .main-widget form .write-form .y-input {
      font-size: var(--base-font-size); } }
  .yotpo.yotpo-main-widget .main-widget form .write-form .y-input::placeholder {
    color: #767676; }
  .yotpo.yotpo-main-widget .main-widget form .write-form .y-input:hover {
    border-color: var(--brand-secondary);
    outline: none;
    box-shadow: 0 0 0 1px var(--brand-secondary); }
  .yotpo.yotpo-main-widget .main-widget form .write-form .y-input:focus {
    border-color: var(--brand-tertiary);
    outline: none;
    box-shadow: none; }
  .yotpo.yotpo-main-widget .main-widget form .write-form .y-input.error {
    border-color: #A3080F; }

.yotpo.yotpo-main-widget .main-widget form .write-form .connect-wrapper {
  padding-left: 0; }

.yotpo.yotpo-main-widget .main-widget form .write-form .submit-button .form-input-error {
  color: #A3080F;
  font-size: var(--base-font-size); }

.yotpo.yotpo-main-widget .main-widget form .socialize-wrapper {
  position: static; }
  .yotpo.yotpo-main-widget .main-widget form .socialize-wrapper .socialize {
    display: block;
    float: none; }
    .yotpo.yotpo-main-widget .main-widget form .socialize-wrapper .socialize .yotpo-icon-btn {
      padding: 6px 11px !important; }
      .yotpo.yotpo-main-widget .main-widget form .socialize-wrapper .socialize .yotpo-icon-btn[data-network="linkedin"] {
        display: none; }

.yotpo.yotpo-main-widget .main-widget form .yotpo-or {
  display: block;
  margin-top: 0;
  width: auto;
  margin-bottom: 5px;
  text-align: left; }

.yotpo.yotpo-main-widget .yotpo-messages .yotpo-thank-you .yotpo-thankyou-header span {
  color: var(--brand-primary); }

.yotpo.yotpo-main-widget .new-yotpo-small-box {
  border: none; }
  .yotpo.yotpo-main-widget .new-yotpo-small-box .yotpo-nav {
    border-bottom: none; }
    .yotpo.yotpo-main-widget .new-yotpo-small-box .yotpo-nav ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      width: 100% !important; }
      .yotpo.yotpo-main-widget .new-yotpo-small-box .yotpo-nav ul .yotpo-nav-tab {
        font: 14px var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
        text-transform: none;
        font-weight: bold;
        letter-spacing: 0.05em;
        padding-top: 15px;
        padding-right: 16px;
        padding-bottom: 11px;
        padding-left: 16px;
        text-decoration: none;
        background: transparent;
        color: #767676;
        border-top: var(--tab-border-top) solid var(--tab-border-color);
        border-right: var(--tab-border-right) solid var(--tab-border-color);
        border-bottom: var(--tab-border-bottom) solid var(--tab-border-color);
        border-left: var(--tab-border-left) solid var(--tab-border-color);
        margin: 0;
        flex: 1 1 auto; }
        .yotpo.yotpo-main-widget .new-yotpo-small-box .yotpo-nav ul .yotpo-nav-tab:hover {
          text-decoration: none;
          background: transparent;
          color: var(--brand-primary);
          border-top: none;
          border-right: none;
          border-bottom: var(--tab-border-bottom) solid var(--tab-border-color);
          border-left: none; }
        .yotpo.yotpo-main-widget .new-yotpo-small-box .yotpo-nav ul .yotpo-nav-tab.yotpo-active {
          text-decoration: none;
          background: transparent;
          color: var(--brand-primary);
          border-top: var(--tab-border-top-active) solid var(--tab-border-color-active);
          border-right: var(--tab-border-right-active) solid var(--tab-border-color-active);
          border-bottom: var(--tab-border-bottom-active) solid var(--tab-border-color-active);
          border-left: var(--tab-border-left-active) solid var(--tab-border-color-active); }
      @media (min-width: 769px) {
        .yotpo.yotpo-main-widget .new-yotpo-small-box .yotpo-nav ul {
          width: 100%;
          display: block;
          border-bottom: var(--tab-border-bottom) solid var(--tab-border-color); }
          .yotpo.yotpo-main-widget .new-yotpo-small-box .yotpo-nav ul .yotpo-nav-tab {
            font: 14px var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
            text-transform: none;
            font-weight: bold;
            letter-spacing: 0.05em;
            padding-top: 15px;
            padding-right: 16px;
            padding-bottom: 11px;
            padding-left: 16px;
            text-decoration: none;
            background: transparent;
            color: #767676;
            border-top: var(--tab-border-top) solid var(--tab-border-color);
            border-right: var(--tab-border-right) solid var(--tab-border-color);
            border-bottom: var(--tab-border-bottom) solid var(--tab-border-color);
            border-left: var(--tab-border-left) solid var(--tab-border-color);
            margin: 0;
            margin-bottom: -1px; }
            .yotpo.yotpo-main-widget .new-yotpo-small-box .yotpo-nav ul .yotpo-nav-tab:hover {
              text-decoration: none;
              background: transparent;
              color: var(--brand-primary);
              border-top: none;
              border-right: none;
              border-bottom: var(--tab-border-bottom) solid var(--tab-border-color);
              border-left: none; }
            .yotpo.yotpo-main-widget .new-yotpo-small-box .yotpo-nav ul .yotpo-nav-tab.yotpo-active {
              text-decoration: none;
              background: transparent;
              color: var(--brand-primary);
              border-top: var(--tab-border-top-active) solid var(--tab-border-color-active);
              border-right: var(--tab-border-right-active) solid var(--tab-border-color-active);
              border-bottom: var(--tab-border-bottom-active) solid var(--tab-border-color-active);
              border-left: var(--tab-border-left-active) solid var(--tab-border-color-active); }
            .yotpo.yotpo-main-widget .new-yotpo-small-box .yotpo-nav ul .yotpo-nav-tab.yotpo-active {
              background-color: #FFFFFF; } }
      .yotpo.yotpo-main-widget .new-yotpo-small-box .yotpo-nav ul .yotpo-nav-tab.yotpo-active {
        background-color: #FFFFFF !important; }
      .yotpo.yotpo-main-widget .new-yotpo-small-box .yotpo-nav ul .yotpo-nav-tab .yotpo-nav-wrapper {
        border: none;
        padding: 0;
        color: unset;
        max-width: none;
        text-align: center; }
        .yotpo.yotpo-main-widget .new-yotpo-small-box .yotpo-nav ul .yotpo-nav-tab .yotpo-nav-wrapper span {
          color: unset; }

.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .free-text-search .free-text-search-input {
  border-color: #C5C5C5;
  background-color: #FFFFFF;
  outline: none;
  box-shadow: none;
  color: var(--base-font-color);
  border-width: 1px;
  border-radius: var(--border-radius);
  font-family: var(--primary-font, var(--backup-font)), helvetica, arial, sans-serif;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.1em;
  padding-top: 12px;
  padding-bottom: 14px;
  padding-left: 20px;
  padding-right: 20px;
  height: calc(16px + 12px + 14px + (1px*2));
  outline: 0;
  padding-left: 35px;
  margin: 0; }
  @media (min-width: 769px) {
    .yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .free-text-search .free-text-search-input {
      font-size: var(--base-font-size); } }
  .yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .free-text-search .free-text-search-input::placeholder {
    color: #767676; }
  .yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .free-text-search .free-text-search-input:hover {
    border-color: var(--brand-secondary);
    outline: none;
    box-shadow: 0 0 0 1px var(--brand-secondary); }
  .yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .free-text-search .free-text-search-input:focus {
    border-color: var(--brand-tertiary);
    outline: none;
    box-shadow: none; }

.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .free-text-search .clear-text-icon,
.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .free-text-search .magnifying-glass-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--brand-primary) !important; }

.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .filters-dropdown[data-analytic-label="images"] {
  display: none; }

.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .yotpo-regular-box .yotpo-header .yotpo-icon-profile {
  background: var(--brand-secondary); }
  .yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .yotpo-regular-box .yotpo-header .yotpo-icon-profile .yotpo-user-letter {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }

.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .yotpo-regular-box .yotpo-header .yotpo-user-name {
  color: var(--brand-primary); }

.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .yotpo-regular-box .yotpo-header.yotpo-verified-user .yotpo-icon-circle-checkmark {
  color: #008827; }

.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .yotpo-regular-box .yotpo-header.yotpo-store-owner .yotpo-icon-circle-checkmark {
  color: #007c9e; }

.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .yotpo-regular-box .yotpo-main .yotpo-review-wrapper .yotpo-read-more {
  color: var(--brand-primary); }
  .yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .yotpo-regular-box .yotpo-main .yotpo-review-wrapper .yotpo-read-more:hover {
    color: var(--brand-secondary); }

.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .yotpo-regular-box .yotpo-footer .yotpo-action > span {
  color: var(--brand-primary); }

.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .yotpo-regular-box .yotpo-footer .yotpo-action:hover > span {
  color: var(--brand-secondary); }

.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .yotpo-regular-box .yotpo-footer .yotpo-icon-seperator:before {
  color: transparent; }

.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .yotpo-regular-box .yotpo-comments-box {
  border-left: none; }

.yotpo.yotpo-main-widget .main-widget .yotpo-nav-content .yotpo-questions {
  padding-top: 20px; }

.yotpo .color-blue {
  background: #007c9e; }

.yotpo .color-red {
  background: #A3080F; }

.yotpo .color-yellow {
  background: #FFCC00; }

.yotpo .color-green {
  background: #008827; }

.yotpo .color-gray {
  background: #BEBEBE; }

.yotpo .color-gray-light {
  background: #C5C5C5; }

.yotpo .color-gray-dark {
  background: #767676; }

.yotpo .color-primary {
  background: var(--brand-primary); }

.yotpo .color-primary-d1 {
  background: hsl(var(--brand-primary-hue), var(--brand-primary-saturation), calc(var(--brand-primary-lightness) - 40%)); }

.yotpo .color-primary-d2 {
  background: hsl(var(--brand-primary-hue), var(--brand-primary-saturation), calc(var(--brand-primary-lightness) - 80%)); }

.yotpo .color-primary-l1 {
  background: hsl(var(--brand-primary-hue), var(--brand-primary-saturation), calc(var(--brand-primary-lightness) + 20%)); }

.yotpo .color-primary-l2 {
  background: hsl(var(--brand-primary-hue), var(--brand-primary-saturation), calc(var(--brand-primary-lightness) + 40%)); }

.yotpo .color-primary-l3 {
  background: hsl(var(--brand-primary-hue), var(--brand-primary-saturation), calc(var(--brand-primary-lightness) + 60%)); }

.yotpo .color-primary-l4 {
  background: hsl(var(--brand-primary-hue), var(--brand-primary-saturation), calc(var(--brand-primary-lightness) + 80%)); }

.yotpo .font-color-blue {
  color: #007c9e; }

.yotpo .font-color-yellow {
  color: #FFCC00; }

.yotpo .font-color-green {
  color: #008827; }

.yotpo .font-color-gray {
  color: #BEBEBE; }

.yotpo .font-color-gray-light {
  color: #C5C5C5; }

.yotpo .font-color-gray-dark {
  color: #767676; }

.yotpo .font-color-primary-d1 {
  color: hsl(var(--brand-primary-hue), var(--brand-primary-saturation), calc(var(--brand-primary-lightness) - 40%)); }

.yotpo .font-color-primary-d2 {
  color: hsl(var(--brand-primary-hue), var(--brand-primary-saturation), calc(var(--brand-primary-lightness) - 80%)); }

.yotpo .font-color-primary-l1 {
  color: hsl(var(--brand-primary-hue), var(--brand-primary-saturation), calc(var(--brand-primary-lightness) + 20%)); }

.yotpo .font-color-primary-l2 {
  color: hsl(var(--brand-primary-hue), var(--brand-primary-saturation), calc(var(--brand-primary-lightness) + 40%)); }

.yotpo .font-color-primary-l3 {
  color: hsl(var(--brand-primary-hue), var(--brand-primary-saturation), calc(var(--brand-primary-lightness) + 60%)); }

.yotpo .font-color-primary-l4 {
  color: hsl(var(--brand-primary-hue), var(--brand-primary-saturation), calc(var(--brand-primary-lightness) + 80%)); }

/* ALL FILES KEPT LOCAL, PREVENTS ACCIDENTIAL BASE STYLES INCLUSION */
button.dw-apple-pay-button.applePaySpecificityOverride {
  width: 100%;
  display: block;
  padding: var(--button-padding-y) var(--button-padding-x) var(--button-padding-y) var(--button-padding-x);
  margin-bottom: 16px; }

.dw-apple-pay-button.apple-pay-cart,
.dw-apple-pay-button.apple-pay-cart:hover {
  padding: 0.5em 1em;
  margin: auto;
  border-radius: var(--border-radius); }

.checkout-and-applepay .btn {
  margin: 1%; }

@media (max-width: 543.98px) {
  .checkout-continue .checkout-and-applepay {
    padding: 0 15px 1.625em; } }

.dw-apple-pay-button.apple-pay-pdp,
.dw-apple-pay-button.apple-pay-pdp:hover {
  padding: 0.5em 1em;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  margin: auto;
  border-radius: var(--border-radius); }

isapplepay.btn {
  display: none !important; }

.add-to-cart,
.add-to-cart-global {
  flex-basis: 0;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis; }
  @media (max-width: 543.98px) {
    .add-to-cart,
    .add-to-cart-global {
      max-width: 100%; } }

.cart-and-ipay {
  display: flex;
  justify-content: center; }

.applepay-tab-wrapper {
  display: none; }

body.apple-pay-enabled .applepay-tab-wrapper {
  display: block; }

.card-number-wrapper[data-type="maestro"]::after {
  background-image: url("../../images/payment-types.png");
  background-size: auto;
  background-position: -95px -156px; }

.card-number-wrapper[data-type="jcb"]::after {
  background-image: url("../../images/JCB.png");
  background-size: contain; }

.card-number-wrapper[data-type="diners"]::after {
  background-image: url("../../images/payment-types.png");
  background-size: auto;
  background-position: -230px -206.5px; }

#weChatModal .modal-dialog {
  display: block;
  background-color: #fff;
  z-index: 9999;
  display: block; }

#weChatModal .modal-body {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif; }

#weChatModal .modal-backdrop {
  opacity: 0.5;
  z-index: 1; }

#deliveryAddressVerificationModal .modal-dialog {
  background-color: #fff;
  z-index: 9999; }

#deliveryAddressVerificationModal .modal-body {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif; }
  #deliveryAddressVerificationModal .modal-body .originalAddress {
    float: left;
    margin-left: 5%;
    width: 42%; }
    #deliveryAddressVerificationModal .modal-body .originalAddress span {
      font-size: 1.2rem;
      display: block;
      margin-bottom: 10%; }
    #deliveryAddressVerificationModal .modal-body .originalAddress p {
      margin: 0%; }
  #deliveryAddressVerificationModal .modal-body .standardAddress {
    float: right;
    clear: none;
    width: 42%;
    position: relative;
    margin-right: 5%;
    margin-left: 5%; }
    @media (max-width: 543.98px) {
      #deliveryAddressVerificationModal .modal-body .standardAddress {
        width: 45%;
        margin-right: 0%; } }
    #deliveryAddressVerificationModal .modal-body .standardAddress span {
      font-size: 1.2rem;
      display: block;
      margin-bottom: 10%; }
    #deliveryAddressVerificationModal .modal-body .standardAddress p {
      margin: 0%; }
  #deliveryAddressVerificationModal .modal-body .dav-buttons-div {
    display: inline-block;
    width: 100%; }
    #deliveryAddressVerificationModal .modal-body .dav-buttons-div .useOrigAddress {
      float: left;
      width: 40%;
      white-space: normal;
      margin-left: 5%;
      margin-top: 2%; }
    #deliveryAddressVerificationModal .modal-body .dav-buttons-div .useStdAddress {
      float: right;
      width: 42%;
      white-space: normal;
      margin-right: 5%;
      margin-top: 2%; }
      @media (max-width: 543.98px) {
        #deliveryAddressVerificationModal .modal-body .dav-buttons-div .useStdAddress {
          margin-right: 3%; } }
  #deliveryAddressVerificationModal .modal-body .continueWithThisAddress {
    margin-top: 3%;
    width: 60%;
    white-space: normal; }
    @media (max-width: 543.98px) {
      #deliveryAddressVerificationModal .modal-body .continueWithThisAddress {
        width: 85%; } }

#deliveryAddressVerificationModal .modal-backdrop {
  opacity: 0.5;
  z-index: 1; }

.DAVModalResourceStrings {
  display: none; }

#secureAcceptanceIframe iframe[name="hss_iframe"] {
  display: block;
  margin: auto;
  transform: scale(1.18, 1);
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-top: -2px; }

.data-checkout-stage[data-checkout-stage] .card.secure-acceptance-iframe {
  display: none; }

.data-checkout-stage[data-checkout-stage] button.sa_redirect {
  display: none; }

.data-checkout-stage[data-checkout-stage] button.alipay, .data-checkout-stage[data-checkout-stage] button.sof, .data-checkout-stage[data-checkout-stage] button.eps, .data-checkout-stage[data-checkout-stage] button.idl, .data-checkout-stage[data-checkout-stage] button.gpy, .data-checkout-stage[data-checkout-stage] button.mch, .data-checkout-stage[data-checkout-stage] button.klarna, .data-checkout-stage[data-checkout-stage] button.credit_card, .data-checkout-stage[data-checkout-stage] button.dw_google_pay, .data-checkout-stage[data-checkout-stage] button.wechat {
  display: none; }

.data-checkout-stage[data-checkout-stage] button.sa_iframe {
  display: none; }

.data-checkout-stage[data-checkout-stage] button.sa_silentpost {
  display: none; }

.data-checkout-stage[data-checkout-stage] button.sa_flex {
  display: none; }

.data-checkout-stage[data-checkout-stage=payment] .card.secure-acceptance-iframe {
  display: none; }

.data-checkout-stage[data-checkout-stage=payment] button#showSubmitPayment {
  display: block; }

.data-checkout-stage[data-checkout-stage=payment] button#hideSubmitPayment {
  display: none; }

.data-checkout-stage[data-checkout-stage=payment] button.sa_redirect {
  display: none; }

.data-checkout-stage[data-checkout-stage=payment] button.sa_iframe {
  display: none; }

.data-checkout-stage[data-checkout-stage=payment] button.alipay, .data-checkout-stage[data-checkout-stage=payment] button.sof, .data-checkout-stage[data-checkout-stage=payment] button.eps, .data-checkout-stage[data-checkout-stage=payment] button.idl, .data-checkout-stage[data-checkout-stage=payment] button.gpy, .data-checkout-stage[data-checkout-stage=payment] button.mch, .data-checkout-stage[data-checkout-stage=payment] button.klarna, .data-checkout-stage[data-checkout-stage=payment] button.credit_card, .data-checkout-stage[data-checkout-stage=payment] button.dw_google_pay, .data-checkout-stage[data-checkout-stage=payment] button.wechat {
  display: none; }

.data-checkout-stage[data-checkout-stage=payment] button.sa_silentpost {
  display: none; }

.data-checkout-stage[data-checkout-stage=payment] button.sa_flex {
  display: none; }

.data-checkout-stage #cardNumber-container {
  height: 43px; }

.data-checkout-stage[data-checkout-stage=placeOrder] .card.secure-acceptance-iframe,
.data-checkout-stage[data-checkout-stage=placeOrder] .card.payment-summary,
.data-checkout-stage[data-checkout-stage=placeOrder] .shipping-summary {
  display: block; }

.data-checkout-stage[data-checkout-stage=submitted] button.sasilentpost {
  display: none; }

.data-checkout-stage[data-checkout-stage=submitted] .card.secure-acceptance-iframe {
  display: none; }

.data-checkout-stage[data-checkout-stage=placeOrder] button.sa_redirect {
  display: block; }

.data-checkout-stage[data-checkout-stage=placeOrder] button.sa_iframe {
  display: block; }

.data-checkout-stage[data-checkout-stage=placeOrder] button.sa_silentpost {
  display: block; }

.data-checkout-stage[data-checkout-stage=placeOrder] button.alipay, .data-checkout-stage[data-checkout-stage=placeOrder] button.sof, .data-checkout-stage[data-checkout-stage=placeOrder] button.eps, .data-checkout-stage[data-checkout-stage=placeOrder] button.idl, .data-checkout-stage[data-checkout-stage=placeOrder] button.gpy, .data-checkout-stage[data-checkout-stage=placeOrder] button.mch, .data-checkout-stage[data-checkout-stage=placeOrder] button.klarna, .data-checkout-stage[data-checkout-stage=placeOrder] button.credit_card, .data-checkout-stage[data-checkout-stage=placeOrder] button.dw_google_pay, .data-checkout-stage[data-checkout-stage=placeOrder] button.wechat {
  display: block; }

.data-checkout-stage[data-checkout-stage=placeOrder] button.sa_flex {
  display: block; }

.data-checkout-stage[data-checkout-stage=shipping] button.paypal {
  display: none; }

.data-checkout-stage[data-checkout-stage=payment] button.alipay, .data-checkout-stage[data-checkout-stage=payment] button.sof, .data-checkout-stage[data-checkout-stage=payment] button.eps, .data-checkout-stage[data-checkout-stage=payment] button.idl, .data-checkout-stage[data-checkout-stage=payment] button.gpy, .data-checkout-stage[data-checkout-stage=payment] button.mch, .data-checkout-stage[data-checkout-stage=payment] button.klarna, .data-checkout-stage[data-checkout-stage=payment] button.credit_card, .data-checkout-stage[data-checkout-stage=payment] button.dw_google_pay, .data-checkout-stage[data-checkout-stage=payment] button.paypal, .data-checkout-stage[data-checkout-stage=payment] button.wechat {
  display: none; }

.account-image {
  background-image: url(../../images/account.jpg);
  background-position-y: 40%; }

#sp-my-subscriptions {
  min-height: 30em; }

#sp-my-subscriptions .sp-field.custom-select select, #sp-my-subscriptions select.sp-field.form-control select, #sp-my-subscriptions select.sp-field select {
  border: none; }

.delivery-interval-group #delivery-periods {
  max-width: 50px; }

.trackingpage img.track_logo {
  height: 25px; }

.vertexError {
  background-color: #F2F2F2;
  padding: 20px;
  color: #A3080F; }

.vertexHint {
  padding: 10px 0;
  text-align: left;
  font-size: 12px;
  color: #A3080F; }

.vertexSuggestions {
  background-color: #F2F2F2;
  padding: 20px; }
  .vertexSuggestions #vertex-suggestions {
    width: 100%; }

.paymentmethodwarning {
  font-weight: bold;
  color: #A3080F; }

.ratings {
  display: none; }
